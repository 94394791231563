import { ErrorBanner } from "components/Error/ErrorBanner";
import AssetRegisterList from "pages/assetRegister/AssetRegisterListPage/AssetRegisterList";
import type React from "react";
import { useState } from "react";
import HeadingEditorForm from "../../../components/Forms/HeadingEditorForm";
import ObjectPropertyItem from "../../../components/Headers/ObjectPropertyItem";
import AssetRegisterStatusLabel from "../../../components/Labels/AssetRegisterStatusLabel";
import ObjectDetailHeading from "../../../components/Layout/ObjectDetailHeading";
import RoutedTabGroupInterface from "../../../components/TabGroup/RoutedTabGroupInterface";
import { Heading } from "../../../components/Theme/heading";
import { locationToBreadcrumb } from "../../../components/navigation/breadcrumbs/Breadcrumbs";
import CopyToClipboard from "../../../components/utils/CopyToClipboard";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import EventList from "../../../containers/event/EventList";
import type { GetEntityResultProps } from "../../../core/action/GetEntity";
import useUpdateEntity from "../../../hooks/updateEntity";
import { locationSchema } from "../../../model/location";
import type { Location } from "../../../openapi/model/location";
import { Status } from "../../../openapi/model/status";
import AssetPositionCreateForm from "../assetPositions/AssetPositionCreateForm";
import AssetPositionDeleteTab from "../assetPositions/tabs/AssetPositionDeleteTab";
import LocationAccountTab from "./LocationAccountTab";

export default function LocationDisplay({
  data,
  refresh,
  error,
  loading,
}: GetEntityResultProps<any>): React.ReactElement {
  const location = data;
  const deleted = location?.status === Status.DELETED;

  const updateEntity = useUpdateEntity<Location>(
    "LOCATION",
    location?.locationId,
    () => refresh(),
  );
  const [refreshed, setRefreshed] = useState<Date>(new Date());

  const updateList = (): void => {
    setRefreshed(new Date());
  };

  const deleteLocation = (): Promise<any> => {
    if (location) {
      return updateEntity.updateEntity({
        ...location,
        status: Status.DELETED,
      });
    } else {
      throw new Error("Can not delete with a location");
    }
  };

  return (
    <OneCardPage
      headerTitle={"Location Detail"}
      breadcrumbs={location && [locationToBreadcrumb(location)]}
      loading={loading}
      data-cy={"location-detail"}
      setPageTitle={() => `${location.address}`}
    >
      {error && <ErrorBanner error={error} />}
      {location && (
        <>
          <ObjectDetailHeading
            leftColumn={{
              content: (
                <>
                  <HeadingEditorForm
                    update={updateEntity}
                    label={"Address"}
                    values={location}
                    title={"Address"}
                    fieldName={"address"}
                    validator={locationSchema.address}
                    editActionUserType={"editor"}
                  />
                  <HeadingEditorForm
                    update={updateEntity}
                    label={"Reference"}
                    values={location}
                    title={"Reference"}
                    fieldName={"customerReference"}
                    // validator={locationSchema.address}
                    editActionUserType={"editor"}
                    defaultValue={"-"}
                  />
                  <HeadingEditorForm
                    update={updateEntity}
                    label={"Group"}
                    values={location}
                    title={"Group"}
                    fieldName={"group"}
                    defaultValue={"-"}
                    validator={locationSchema.group}
                    editActionUserType={"superadmin"}
                  />
                </>
              ),
            }}
            rightColumn={{
              content: (
                <>
                  {deleted && (
                    <ObjectPropertyItem title={"Status"}>
                      <AssetRegisterStatusLabel status={location.status} />
                    </ObjectPropertyItem>
                  )}
                  <ObjectPropertyItem title={"Scheme"} privacyMode>
                    {location.schemeId}
                  </ObjectPropertyItem>
                  <ObjectPropertyItem title={"LocationID"}>
                    <CopyToClipboard value={location?.locationId} slice={8} />
                  </ObjectPropertyItem>
                  <ObjectPropertyItem title={"Created"}>
                    {formatDate(location?.createdAt, undefined, false)}
                  </ObjectPropertyItem>
                  <ObjectPropertyItem title={"Updated"}>
                    {formatDate(location?.lastUpdated, undefined, false)}
                  </ObjectPropertyItem>
                </>
              ),
            }}
          />
          <RoutedTabGroupInterface
            tabs={[
              {
                tabName: "Asset Positions",
                tabPath: "positions",
                tabContent: (
                  <>
                    <Heading>Asset Positions</Heading>
                    <AssetRegisterList
                      companyId={location.companyId}
                      schemeId={location.schemeId}
                      locationId={location.locationId}
                      download={false}
                      createAssetTags={false}
                      showLocation={false}
                      lastChange={refreshed}
                    />
                    <DisplayIfUserType userTypes={"admin"}>
                      <Heading>Add New Asset Position</Heading>
                      <AssetPositionCreateForm
                        location={location}
                        postCreateActions={updateList}
                      />
                    </DisplayIfUserType>
                  </>
                ),
                disabled: deleted,
              },
              {
                tabName: "Accounts",
                tabPath: "accounts",
                tabContent: <LocationAccountTab location={location} />,
                userTypes: "superadmin",
                disabled: deleted,
              },

              {
                tabName: "Events",
                tabPath: "events",
                tabContent: (
                  <>
                    <Heading>Location Events</Heading>
                    <EventList
                      locationId={location.locationId}
                      companyId={location.companyId}
                    />
                  </>
                ),
              },
              {
                tabName: "Delete",
                tabPath: "delete",
                tabContent: (
                  <AssetPositionDeleteTab
                    assetPosition={location}
                    refresh={refresh}
                    delete={deleteLocation}
                    title={"Location"}
                  />
                ),
                userTypes: "admin",
                disabled: false,
              },
            ]}
            topLevelPath={`/admin/asset_register/location/${location.locationId}`}
          />
        </>
      )}
    </OneCardPage>
  );
}
