import type React from "react";
import type { Location } from "../../openapi/model/location";
import CompanyNavLink from "../navigation/CompanyNavLink";

const LocationIdLink = (props: {
  location?: Location;
  locationId?: string;
  full?: boolean;
}): React.ReactElement => {
  const { location, locationId } = props;
  const actualLocationId = location ? location.locationId : locationId;
  if (actualLocationId) {
    const displayLocation = props.full
      ? actualLocationId
      : `...${actualLocationId.slice(24)}`;
    return (
      <CompanyNavLink
        inline={true}
        to={{ pathname: `/admin/location/${actualLocationId}` }}
      >
        {displayLocation}
      </CompanyNavLink>
    );
  }
  throw new Error("One of location or locationId must be set");
};

export default LocationIdLink;
