import type { Dayjs } from "dayjs";
import type React from "react";
import ErrorOverlappingBanner from "../../../../components/Error/ErrorOverlappingBanner";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import type {
  ColumnMap,
  TimeSeriesDataParams,
} from "../../../../model/deviceTimeSeries";
import ChartForSingleTimeSeriesItem from "./ChartForSingleTimeSeriesItem";

const GenericCharts = (props: {
  mappedTimeSeriesColumns: ColumnMap;
  times: Dayjs[];
  params: TimeSeriesDataParams;
  loading: boolean;
  error?: Error;
}): React.ReactElement => {
  return (
    <>
      {props.loading || props.error ? (
        <>
          <BlockSpinner loading={props.loading}>
            <ErrorOverlappingBanner error={props.error}>
              <ChartForSingleTimeSeriesItem
                column={{
                  name: "Data Field 1",
                  unit: "?",
                  data: [],
                  type: "instantaneous",
                }}
                times={[]}
                params={{}}
                loading={props.loading}
              />
            </ErrorOverlappingBanner>
          </BlockSpinner>
          <BlockSpinner loading={props.loading}>
            <ErrorOverlappingBanner error={props.error}>
              <ChartForSingleTimeSeriesItem
                column={{
                  name: "Data Field 2",
                  unit: "?",
                  data: [],
                  type: "instantaneous",
                }}
                times={[]}
                params={{}}
                loading={props.loading}
              />
            </ErrorOverlappingBanner>
          </BlockSpinner>
        </>
      ) : (
        Object.entries(props.mappedTimeSeriesColumns).map(([name, column]) => {
          return (
            <ChartForSingleTimeSeriesItem
              column={column}
              times={props.times}
              params={props.params}
              key={name}
              loading={false}
            />
          );
        })
      )}
    </>
  );
};

export default GenericCharts;
