import SelectedScheme from "components/Scheme/SelectedScheme";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import { AssetType } from "openapi/model/assetType";
import AssetRegisterList from "pages/assetRegister/AssetRegisterListPage/AssetRegisterList";
import { AssetRegisterListHeading } from "pages/assetRegister/AssetRegisterListPage/AssetRegisterListItem";
import type React from "react";
import { Button } from "../../../components/Theme/button";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";
import WirelessClusterListRow from "./WirelessClusterListRow";

export default function WirelessClusterListPage(): React.ReactElement {
  return (
    <OneCardPage
      headerTitle={"Wireless Clusters"}
      breadcrumbs={[
        {
          url: "/admin/wireless_cluster",
          name: "Wireless Clusters",
        },
      ]}
      setPageTitle={schemeObjectPageTitle("Wireless Clusters")}
    >
      <SelectedScheme>
        {(scheme) => (
          <>
            <AssetRegisterList
              {...scheme}
              assetType={AssetType.WirelessCluster}
              download={false}
              createAssetTags={false}
              header={
                <AssetRegisterListHeading
                  column1={"Name"}
                  column2={"Gateways"}
                  column3={"Assets"}
                />
              }
              row={WirelessClusterListRow}
            />
            {scheme.schemeId && (
              <div className={"flex gap-2"}>
                <DisplayIfUserType userTypes={"admin"}>
                  <CompanyNavLink
                    to={{ pathname: "create" }}
                    button={{
                      color: "brandLight",
                    }}
                  >
                    Add Wireless Cluster
                  </CompanyNavLink>
                </DisplayIfUserType>
              </div>
            )}
          </>
        )}
      </SelectedScheme>
    </OneCardPage>
  );
}
