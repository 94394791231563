import type { AssetRegisterDataItem } from "../../../components/Table/AssetRegisterData";

export const QUALITY_AGGREGATIONS = [
  "expected_rows",
  "received_rows_complete",
  "received_rows_incomplete",
  "received_rows_with_errors",
  "received_rows_with_value_errors",
];

export interface AssetDataQualityDataItem extends AssetRegisterDataItem {
  expectedRows?: number;
  missingRows?: number;
  receivedRowsComplete?: number;
  receivedRowsIncomplete?: number;
  receivedRowsWithErrors?: number;
  receivedRowsWithValueErrors?: number;
}
