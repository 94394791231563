import clsx from "clsx";
import type React from "react";

const styles = {
  base: "block ",
  sizes: {
    sm: ["text-[10px] uppercase text-gray-600"],
    md: ["leading-3 text-base text-gray-900 font-medium"],
    lg: ["leading-6 text-lg text-gray-900 font-medium"],
  },
  padding: {
    sm: [""],
    md: ["py-2"],
    lg: ["py-3"],
  },
  margin: {
    0: "",
    1: "mt-1",
    2: "mt-2",
    3: "mt-3",
    4: "mt-4",
  },
};

export interface LabelProps {
  label?: string;
  size?: keyof typeof styles.sizes;
  showErrors?: boolean;
  margin?: keyof typeof styles.margin;
}

/* TOOD: showErrors */

export default function NewLabelWrapper(
  props: LabelProps &
    React.PropsWithChildren<{
      for?: string;
    }>,
): React.ReactElement {
  const { label, children } = props;

  return (
    <div className={clsx(styles.padding[props.size || "md"])}>
      {label && (
        <label
          className={clsx(styles.base, styles.sizes[props.size || "md"])}
          htmlFor={props.for}
        >
          {label}
        </label>
      )}
      <div
        className={clsx(
          styles.margin[props.margin === undefined ? 2 : props.margin],
        )}
      >
        {children}
      </div>
    </div>
  );
}
