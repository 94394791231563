import SelectedScheme from "components/Scheme/SelectedScheme";
import AlarmWidget from "containers/event/AlarmWidget";
import AssetPositionCountWidget from "pages/assetRegister/assets/widgets/AssetPositionCountWidget";
import DailyReadSuccessWidget from "pages/assetRegister/assets/widgets/DailyReadSuccessWidget/DailyReadSuccessWidget";
import LatestTimeSeriesStatsWidget from "pages/assetRegister/assets/widgets/LatestTimeSeriesStatsWidget";
import type React from "react";
import { useSelectedCompany } from "reducers/company";
import { Col, Container } from "../../components/Theme/grid";
import useSetPageTitle from "../../hooks/setPageTitle";

export default function DashboardPage(): React.ReactElement {
  useSetPageTitle({
    setPageTitle: ({ company, scheme }) => {
      return scheme
        ? `Dashboard: ${company?.name} - ${scheme.schemeName}`
        : `Dashboard: ${company?.name}`;
    },
  });

  const company = useSelectedCompany();

  return (
    <>
      {company && (
        <Container>
          <Col sm={12} md={4} lg={4} xl={4}>
            <LatestTimeSeriesStatsWidget
              title={"Asset Data Age"}
              group={"meter"}
            />
          </Col>
          <Col sm={12} md={4} lg={4} xl={4}>
            <LatestTimeSeriesStatsWidget
              title={"Gateway Last Contact"}
              group={"gateway"}
            />
          </Col>
          <Col sm={12} md={4} lg={4} xl={4}>
            <SelectedScheme>
              {(props) => <AlarmWidget {...props} />}
            </SelectedScheme>
          </Col>
          <Col sm={12} md={12} lg={6} xl={6}>
            <DailyReadSuccessWidget />
          </Col>
          <Col
            className={"d-md-none d-sm-none d-xs-none d-lg-block"}
            lg={6}
            xl={6}
          >
            <AssetPositionCountWidget />
          </Col>
        </Container>
      )}
    </>
  );
}
