import type React from "react";
import { ButtonGroupField } from "../Forms/ButtonGroup";

import { GatewaySettingsType } from "../../openapi/model/gatewaySettingsType";

export default function AssetGatewaySettingTypeSwitch(props: {
  fieldName: string;
  submit?: any;
  title?: string;
  includeDeviceNormal?: boolean;
}): React.ReactElement {
  const { fieldName, title = "Device Settings" } = props;

  return (
    <ButtonGroupField
      fieldName={fieldName}
      label={{ label: title }}
      options={[
        { label: "Default", value: GatewaySettingsType.ELVACODEFAULT },
        {
          label: "CMi Wireless Only",
          value: GatewaySettingsType.ELVACOCMIWIRELESSONLY,
        },
      ]}
      onChange={(): void => {
        if (props.submit) {
          props.submit();
        }
      }}
    />
  );
}
