import { OneCardPage } from "components/utils/OneCardPage";
import type React from "react";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import { useSelectedCompany } from "../../reducers/company";
import { useSelectedScheme } from "../../reducers/scheme";
import JobCreateForm from "./JobCreateForm";

const JobCreatePage = (): React.ReactElement => {
  const companyId = useSelectedCompany()?.companyId;
  const schemeId = useSelectedScheme()?.schemeId;
  return (
    <>
      {companyId && (
        <OneCardPage
          headerTitle={"Create New Data Job"}
          setPageTitle={schemeObjectPageTitle("Create New Job")}
        >
          <JobCreateForm companyId={companyId} schemeId={schemeId} />
        </OneCardPage>
      )}
    </>
  );
};

export default JobCreatePage;
