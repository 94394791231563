import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import { isString } from "lodash-es";
import type { ParsedQs } from "qs";
import type React from "react";
import { useCallback } from "react";
import DateRangePicker from "../../../../../components/Forms/DateRangePicker/DateRangePicker";
import { Button } from "../../../../../components/Theme/button";
import useGetParsedSearchString from "../../../../../hooks/getParsedSearchString";

export interface GatewayListFilterParams {
  startDatetime?: Dayjs;
  endDatetime?: Dayjs;
}

export const useGetParsedGatewayFileListFilterParams = () =>
  useGetParsedSearchString<GatewayListFilterParams>(
    useCallback((p: ParsedQs): GatewayListFilterParams => {
      return {
        startDatetime: isString(p.startDatetime)
          ? dayjs(p.startDatetime)
          : undefined,
        endDatetime: isString(p.endDatetime) ? dayjs(p.endDatetime) : undefined,
      };
    }, []),
  );

const GatewayFileListFilterForm = (): React.ReactElement => {
  const { ready, updateFilters, params } =
    useGetParsedGatewayFileListFilterParams();

  return (
    <>
      {ready && (
        <Formik
          initialValues={params}
          onSubmit={(x): void => {
            updateFilters(x);
          }}
        >
          {(): React.ReactElement => (
            <Form>
              <div className={"flex gap-2 justify-end"}>
                <div className={"md:min-w-80 flex-grow md:flex-grow-0"}>
                  <DateRangePicker
                    label={{ label: "Date range", size: "sm" }}
                    startDateName={"startDatetime"}
                    endDateName={"endDatetime"}
                    maxDate={dayjs().endOf("day")}
                    clearable={true}
                    minDays={0}
                  />
                </div>
                <div className={"h-full mt-auto"}>
                  <Button
                    type={"submit"}
                    color={"brandLight"}
                    className={"h-[38px]"}
                  >
                    Filter
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default GatewayFileListFilterForm;
