import { Button } from "components/Theme/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/Theme/table";
import React from "react";
import { Badge } from "../../../components/Theme/badge";
import CopyToClipboard from "../../../components/utils/CopyToClipboard";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates";
import type { WirelessSurveyDataCollectorOut } from "../../../openapi/model/wirelessSurveyDataCollectorOut";
import AssetContainer, {
  type OptionalAssetChildProps,
} from "../assets/AssetContainer";
import { isGatewayAsset } from "../assets/model";
import WirelessSurveyDataCollectorCloseButton from "./WirelessSurveyDataCollectorCloseButton";

function WirelessSurveyDataCollectorsList(props: {
  dataCollectors: WirelessSurveyDataCollectorOut[];
  refresh?: () => any;
}) {
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Data Collector ID</TableHeader>
            <TableHeader>Data Collector Name</TableHeader>
            <TableHeader>Gateway Serial Number</TableHeader>
            <TableHeader>Data Collection Times</TableHeader>
            <TableHeader>&nbsp;</TableHeader>
          </TableRow>
        </TableHead>
        {props.dataCollectors.map((dataCollector) => (
          <TableBody key={dataCollector.dataCollectorId}>
            <TableRow>
              <TableCell>
                <CopyToClipboard
                  value={dataCollector.dataCollectorId}
                  slice={8}
                />
              </TableCell>
              <TableCell>{dataCollector.dataCollectorName}</TableCell>
              <TableCell>
                {dataCollector.serialNumber}{" "}
                <AssetContainer
                  assetId={dataCollector.assetId}
                  companyId={dataCollector.companyId}
                >
                  {({ asset, loading }: OptionalAssetChildProps) => (
                    <>
                      {loading
                        ? "LOADING"
                        : asset &&
                          isGatewayAsset(asset) &&
                          (asset.state?.connection || "UNKNOWN")}
                    </>
                  )}
                </AssetContainer>
              </TableCell>
              <TableCell>
                {formatDate(dataCollector.startDateTime, undefined, true)}-
                {dataCollector.endDateTime === undefined
                  ? "now"
                  : formatDate(dataCollector.endDateTime, undefined, true)}
              </TableCell>
              <TableCell>
                {dataCollector.endDateTime === undefined ? (
                  <WirelessSurveyDataCollectorCloseButton
                    dataCollector={dataCollector}
                    postUpdate={props.refresh}
                  />
                ) : (
                  <Badge color={"warning"}>Closed</Badge>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        ))}
      </Table>
      {props.refresh && (
        <div className={"text-right"}>
          {/*TODO: small button */}
          <Button size={"sm"} color={"brandLight"} onClick={props.refresh}>
            Refresh
          </Button>
        </div>
      )}
    </div>
  );
}

export default WirelessSurveyDataCollectorsList;
