import axios, { type AxiosError } from "axios";
import type { Entities } from "constants/entities";
import { isNil, lowerCase, startCase } from "lodash-es";

export type ErrorTypes = "NOT_FOUND" | "UNKNOWN";

class TypedError extends Error {
  public errorType: ErrorTypes;

  constructor(message: string) {
    super(message);
    this.errorType = "UNKNOWN";
  }
}

class NotFoundError extends TypedError {
  constructor(message: string) {
    super(message);
    this.errorType = "NOT_FOUND";
  }
}

export function NotFoundErrorForEntity(
  entityType: Entities,
  entityId: string,
): NotFoundError {
  return new NotFoundError(
    `${startCase(lowerCase(entityType))} with id ${entityId} was not found.`,
  );
}

export function IsNotFoundError(
  error?: AxiosError | TypedError | null,
  data?: any,
): boolean {
  return (
    (error && axios.isAxiosError(error) && error?.response?.status === 400) ||
    error instanceof NotFoundError ||
    isNil(data)
  );
}

export { TypedError, NotFoundError };
