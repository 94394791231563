import React from "react";
import { useSelectedCompany } from "../../reducers/company";
import { useSelectedScheme } from "../../reducers/scheme";

export interface SchemeElementProps {
  companyId: string;
  schemeId?: string;
}

type Props = {
  children:
    | ((props: SchemeElementProps) => React.ReactElement)
    | React.ReactElement<SchemeElementProps>;
};

export default function SelectedScheme(props: Props): React.ReactElement {
  const schemeId: string | undefined = useSelectedScheme()?.schemeId;
  const companyId: string = useSelectedCompany()?.companyId || "NONE";

  return (
    <>
      {companyId &&
        (React.isValidElement(props.children)
          ? React.cloneElement(props.children, {
              companyId,
              schemeId,
            })
          : props.children({ companyId, schemeId }))}
    </>
  );
}
