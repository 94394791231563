import React, { useState } from "react";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { Button } from "../../../components/Theme/button";
import {
  Dialog,
  DialogBody,
  DialogTitle,
} from "../../../components/Theme/dialog";
import type { UpdateEntity } from "../../../hooks/updateEntity";
import type { WirelessSurveyDataCollectorOut } from "../../../openapi/model/wirelessSurveyDataCollectorOut";
import WirelessSurveySamplePointForm from "./WirelessSurveySamplePointForm";

function WirelessSurveySamplePointAddModal(props: {
  dataCollector: WirelessSurveyDataCollectorOut;
  refresh?: () => any;
  update: UpdateEntity<WirelessSurveyDataCollectorOut>;
  disabled?: boolean;
}) {
  const { update } = props;

  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add Sample Point</DialogTitle>
        <DialogBody>
          <BlockSpinner loading={update.updating}>
            <WirelessSurveySamplePointForm
              dataCollector={props.dataCollector}
              refresh={() => {
                props.refresh?.();
                setOpen(false);
              }}
              onCancel={() => setOpen(false)}
              submit={(v) => {
                return update
                  .updateEntity({
                    ...props.dataCollector,
                    samplePoints: [
                      ...props.dataCollector.samplePoints,
                      {
                        ...v,
                        startDateTime: v.startDateTime.toDate(),
                        endDateTime: v.endDateTime?.toDate(),
                      },
                    ],
                  })
                  .finally(() => {
                    setOpen(false);
                    props.refresh?.();
                  });
              }}
            />
          </BlockSpinner>
        </DialogBody>
      </Dialog>
      <Button
        color={"green"}
        disabled={props.disabled}
        onClick={() => setOpen(true)}
      >
        Add
      </Button>
    </>
  );
}

export default WirelessSurveySamplePointAddModal;
