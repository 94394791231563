import { Form, Formik } from "formik";
import type { FormikHelpers } from "formik/dist/types";
import { isEqual } from "lodash-es";
import type React from "react";
import * as Yup from "yup";
import NewButton from "../../../components/Forms/NewButton/NewButton";
import NewLabelWrapper from "../../../components/Forms/NewLabelWrapper";
import NewLocationGroupSelect from "../../../components/LocationGroup/NewLocationGroupSelect";
import { Col, Container } from "../../../components/Theme/grid";

export interface LatestDataReportQueryFormValues {
  group?: string;
}

export interface LatestDataReportQueryFormProps {
  onSubmit: (values: LatestDataReportQueryFormValues) => Promise<any>;
  initialValues?: LatestDataReportQueryFormValues;
}

export default function LatestDataReportQueryForm(
  props: LatestDataReportQueryFormProps,
) {
  const { initialValues = {} as LatestDataReportQueryFormValues, onSubmit } =
    props;
  const wide = true;
  const showErrors = true;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({})}
      onSubmit={(
        values: LatestDataReportQueryFormValues,
        helpers: FormikHelpers<LatestDataReportQueryFormValues>,
      ): Promise<any> => {
        helpers.setSubmitting(true);
        return onSubmit(values).finally(() => helpers.setSubmitting(false));
      }}
    >
      {({ values, submitForm }): React.ReactElement => {
        const isChanged = !isEqual(values, initialValues);

        return (
          <Form>
            <Container gap={0}>
              <Col md={wide ? 9 : 12}>
                <div>
                  <NewLocationGroupSelect
                    fieldName={"group"}
                    label={{ label: "Group", size: "sm", showErrors }}
                  />
                </div>
              </Col>
              <Col md={wide ? 3 : 12}>
                <div>
                  <NewLabelWrapper
                    size={"sm"}
                    showErrors={true}
                    label={"\u00A0"}
                  >
                    <NewButton
                      color={"brandLight"}
                      className={"w-full"}
                      onClick={submitForm}
                      disabled={!isChanged}
                    >
                      Search
                    </NewButton>
                  </NewLabelWrapper>
                </div>
              </Col>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
}
