import PrivacyMode from "components/Text/PrivacyMode";
import CopyToClipboard from "components/utils/CopyToClipboard";
import dayjs from "dayjs";
import type React from "react";
import AssetTypeHumanLabel from "../../../components/Asset/AssetTypeHumanLabel";
import ObjectPropertyItem from "../../../components/Headers/ObjectPropertyItem";
import ObjectDetailHeading from "../../../components/Layout/ObjectDetailHeading";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";
import HighlightNullAndOldDates, {
  formatDate,
} from "../../../components/utils/HighlightNullAndOldDates";
import useUpdateEntity from "../../../hooks/updateEntity";
import type { Asset } from "../../../openapi/model/asset";
import type { AssetPosition } from "../../../openapi/model/assetPosition";
import type { DeviceConfiguration } from "../../../openapi/model/deviceConfiguration";
import type { GatewayAsset } from "../../../openapi/model/gatewayAsset";
import type { GatewayState } from "../../../openapi/model/gatewayState";
import type { Location } from "../../../openapi/model/location";
import type { WirelessReceiverState } from "../../../openapi/model/wirelessReceiverState";
import AssetCompanyAssignForm from "./AssetCompanyAssignForm";
import AssetTypeChangeForm from "./AssetTypeChangeForm";
import GatewayLink from "./gateway/GatewayLink";
import GatewayStateLabel from "./gateway/GatewayStateLabel";

export default function InnerAssetPreview(props: {
  asset?: Asset;
  assetPosition?: AssetPosition;
  location?: Location;
  refresh?: () => any;
}): React.ReactElement {
  const { asset, assetPosition, location } = props;

  const updateEntity = useUpdateEntity<Asset>(
    "ASSET",
    asset?.assetId as string,
    props.refresh,
  );

  const [openModal, modal] = AssetTypeChangeForm(
    asset as Asset,
    updateEntity,
    "Asset ",
    false,
  );

  const assigned =
    asset?.assetPositionId !== undefined && asset?.assetPositionId !== null;
  const isGateway = asset?.assetType === "gateway" || false;
  const isReceiver = asset?.assetType === "wireless_receiver" || false;

  return (
    <>
      {modal}
      <ObjectDetailHeading
        leftColumn={{
          content: (
            <>
              {assigned ? (
                <>
                  <ObjectPropertyItem
                    title={"Location Address"}
                    type={"heading"}
                  >
                    <CompanyNavLink
                      className={"p-0 d-inline"}
                      to={{
                        pathname: `/admin/location/${location?.locationId}`,
                      }}
                    >
                      <PrivacyMode>{location?.address}</PrivacyMode>
                    </CompanyNavLink>
                  </ObjectPropertyItem>
                  <ObjectPropertyItem
                    title={"Position Reference"}
                    type={"heading"}
                  >
                    <CompanyNavLink
                      className={"p-0 d-inline"}
                      to={{
                        pathname: `/admin/asset_position/${assetPosition?.assetPositionId}`,
                      }}
                    >
                      <PrivacyMode>
                        {assetPosition?.customerReference ||
                          "No Position Reference"}
                      </PrivacyMode>
                    </CompanyNavLink>
                  </ObjectPropertyItem>{" "}
                </>
              ) : (
                <ObjectPropertyItem
                  title={"Location - Position"}
                  type={"heading"}
                >
                  <h2 className={"text-warning"}>Unassigned</h2>
                </ObjectPropertyItem>
              )}
              <ObjectPropertyItem title={"Serial Number"} type={"heading"}>
                {asset && (
                  <CopyToClipboard
                    value={asset.serialNumber}
                    privacyMode={true}
                  />
                )}
              </ObjectPropertyItem>
            </>
          ),
        }}
        rightColumn={{
          content: (
            <>
              <ObjectPropertyItem
                title={"Type"}
                editActionUserType={"superadmin"}
                editAction={
                  asset?.assetPositionId === undefined ? openModal : undefined
                }
              >
                {asset && (
                  <span>
                    <AssetTypeHumanLabel asset={asset} />
                  </span>
                )}
              </ObjectPropertyItem>
              <ObjectPropertyItem title={"Manufacturer"} type={"detail"}>
                {asset?.manufacturer || "-"}
              </ObjectPropertyItem>
              <ObjectPropertyItem title={"Model"} type={"detail"}>
                <span>{!isGateway ? asset?.version : asset?.model || "-"}</span>
              </ObjectPropertyItem>
              {!isGateway && (
                <GatewayLink deviceAssetPosition={assetPosition} />
              )}
              {isReceiver && (
                <ObjectPropertyItem title={"Status"} type={"detail"}>
                  {asset && (
                    <GatewayStateLabel
                      state={
                        (asset.state as WirelessReceiverState)
                          .configurationState
                      }
                      size={"sm"}
                      className={"px-1 py-1"}
                    />
                  )}
                </ObjectPropertyItem>
              )}
              {isGateway && (
                <>
                  <ObjectPropertyItem title={"Last contact"} type={"detail"}>
                    {asset && (
                      <HighlightNullAndOldDates
                        ageHighlight={dayjs.duration(1, "days")}
                      >
                        {(asset as GatewayAsset).lastContact}
                      </HighlightNullAndOldDates>
                    )}
                  </ObjectPropertyItem>
                  <ObjectPropertyItem title={"Status"} type={"detail"}>
                    {asset && (
                      <GatewayStateLabel
                        state={(asset.state as GatewayState).configurationState}
                        size={"sm"}
                        className={"px-1 py-1"}
                      />
                    )}
                  </ObjectPropertyItem>

                  <ObjectPropertyItem title={"Firmware"} type={"detail"}>
                    {(asset && (asset as GatewayAsset).firmwareVersion) || "-"}
                  </ObjectPropertyItem>
                  <ObjectPropertyItem title={"IP Address"} type={"detail"}>
                    <CopyToClipboard
                      value={(asset as GatewayAsset).ipAddress || "-"}
                      privacyMode={true}
                    />
                  </ObjectPropertyItem>
                  <ObjectPropertyItem title={"Sim card"} type={"detail"}>
                    <CopyToClipboard
                      value={(asset as GatewayAsset).simCardIccid || "-"}
                      privacyMode={true}
                    />
                  </ObjectPropertyItem>
                </>
              )}
              {asset?.configuration &&
                "mbus" &&
                (asset.configuration as DeviceConfiguration).mbus
                  ?.addressingMode === "primary" && (
                  <ObjectPropertyItem title={"Addressing mode"} type={"detail"}>
                    Primary (address:{" "}
                    {
                      (asset.configuration as DeviceConfiguration).mbus
                        ?.primaryAddress
                    }
                    )
                  </ObjectPropertyItem>
                )}{" "}
              <ObjectPropertyItem title={"Asset Id"} type={"detail"}>
                <CopyToClipboard value={asset?.assetId} slice={8} />
              </ObjectPropertyItem>
              <ObjectPropertyItem title={"Created"} type={"detail"}>
                {formatDate(asset?.createdAt, undefined, false)}
              </ObjectPropertyItem>
            </>
          ),
        }}
      />
      {asset?.companyId === "NONE" && asset?.assetType === "gateway" && (
        <AssetCompanyAssignForm asset={asset} />
      )}
    </>
  );
}
