import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import NewTextFormComponent from "../../components/Forms/NewTextFormComponent";
import { yupRequiredString } from "../../components/Forms/yupValidators";
import { Button } from "../../components/Theme/button";
import { CumulativeValueTimeSeriesField } from "../../openapi/model/cumulativeValueTimeSeriesField";
import CumulativeDataSelect from "./CumulativeDataSelect";
import type { TariffServiceDescription } from "./model";

export interface NewTariffServiceFormProps {
  create: (service: TariffServiceDescription) => Promise<any>;
}
function NewTariffServiceForm(props: NewTariffServiceFormProps) {
  return (
    <Formik
      initialValues={
        {
          serviceName: "",
          consumptionType: CumulativeValueTimeSeriesField.EnergyHeating,
        } as TariffServiceDescription
      }
      onSubmit={props.create}
      validationSchema={Yup.object().shape({
        serviceName: yupRequiredString,
        consumptionType: yupRequiredString,
      })}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <NewTextFormComponent
            fieldName={"serviceName"}
            label={{ label: "Service Name" }}
            disabled={isSubmitting}
          />
          <CumulativeDataSelect
            fieldName={"consumptionType"}
            label={{ label: "Consumption Type" }}
            disabled={isSubmitting}
          />
          <Button
            disabled={isSubmitting}
            onClick={submitForm}
            color={"brandLight"}
          >
            Add
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default NewTariffServiceForm;
