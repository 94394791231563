import React from "react";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { PageHeadingBlock } from "../../components/Theme/heading";
import UserCompanyList from "./UserCompanyList";
import AuthenticationCard from "./authentication/AuthenticationCard";

export default function ProfilePage() {
  return (
    <>
      <Breadcrumbs
        breadcrumbs={[{ url: "/admin/profile", name: "Profile" }]}
        showSchemeInBreadcrumbs={false}
        showCompanyInBreadcrumbs={false}
      />
      <PageHeadingBlock>Your profile</PageHeadingBlock>

      <AuthenticationCard />
      <UserCompanyList />
    </>
  );
}
