import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type React from "react";
import UncontrolledTooltip from "../../../../components/Theme/tooltip";
import type { ParsedAssetDefinition } from "./UploadPage";

export const ErrorRowInfoTooltipIcon = (props: {
  item: ParsedAssetDefinition;
  index: number;
}): React.ReactElement => {
  const { item, index } = props;

  const tooltipContent = (): string => {
    return (
      item.errors
        ?.map((err) => {
          return err.error;
        })
        .join(", ") || ""
    );
  };

  return (
    <div key={index}>
      {item.errors && (
        <UncontrolledTooltip placement="right" message={tooltipContent()}>
          <FontAwesomeIcon icon={faInfoCircle} fixedWidth />
        </UncontrolledTooltip>
      )}
    </div>
  );
};
