import dayjs from "dayjs";
import { isNil } from "lodash-es";
import type { AssetPosition } from "openapi/model/assetPosition";
import { NoteOutput } from "openapi/model/noteOutput";
import type React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelectedCompany } from "reducers/company";
import { useSelectedScheme } from "reducers/scheme";
import AssetRegisterStatusLabel from "../../components/Labels/AssetRegisterStatusLabel";
import GetListPaginatedTable from "../../components/Pagination/GetListPaginatedTable";
import { StackedListTwoColumnItem } from "../../components/Theme/stacked-list";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import PaginatedApiToCSVExportButton from "../../components/utils/CSVExport/PaginatedApiToCSVExportButton";
import type { ExpandedNote } from "../../openapi/model/expandedNote";
import type { Note } from "../../openapi/model/note";
import CreateNoteModalButton from "./CreateNoteModalButton";
import NoteModal from "./NoteModal";

interface Props {
  assetPosition?: AssetPosition;
}

function NoteListRow(
  note: Note | ExpandedNote,
  update: (updatedItem: Note) => void,
): React.ReactElement {
  const expanded = !isNil((note as ExpandedNote).locationId);

  return (
    <StackedListTwoColumnItem
      key={note.noteId}
      column1={
        <div className={""}>
          <NoteModal note={note} onUpdate={update}>
            {({ openModal }) => {
              return (
                <h4
                  className={"mb-0"}
                  onClick={openModal}
                  onKeyDown={openModal}
                >
                  {note.title}
                  <AssetRegisterStatusLabel
                    status={note.status}
                    className={"ml-2"}
                  />
                </h4>
              );
            }}
          </NoteModal>
          {expanded && (
            <>
              <small className={"pr-2"}>
                <CompanyNavLink
                  to={{
                    pathname: `/admin/location/${
                      (note as ExpandedNote).locationId
                    }`,
                  }}
                  external={true}
                >
                  {(note as ExpandedNote).address}
                </CompanyNavLink>
              </small>
              <small className={"pr-2"}>
                Position:{" "}
                <CompanyNavLink
                  to={{
                    pathname: `/admin/asset_position/${note.assetPositionId}`,
                  }}
                  external={true}
                >
                  {(note as ExpandedNote).customerReference ||
                    `...${note.assetPositionId.slice(26)}`}
                </CompanyNavLink>
              </small>
            </>
          )}
        </div>
      }
      column2={
        <span className={"text-xs text-gray-600 italic"}>
          {dayjs(note.createdAt).format("LLL")} by {note.creator.userName}
        </span>
      }
    />
  );
}

export default function NoteList(props: Props): React.ReactElement {
  const { assetPosition } = props;

  const selectedCompany = useSelectedCompany();
  const selectedScheme = useSelectedScheme();

  const [lastChange, setLastChange] = useState(new Date());

  const params = {
    companyId: selectedCompany?.companyId,
    schemeId: selectedScheme?.schemeId,
    assetPositionId: assetPosition?.assetPositionId,
    output: assetPosition ? NoteOutput.NOTE : NoteOutput.EXPANDED,
  };

  const filename: string = assetPosition
    ? `notes_export_${assetPosition.companyId}_${assetPosition.schemeId}_${assetPosition.assetPositionId}`
    : selectedScheme
      ? `notes_export_${selectedScheme.companyId}_${selectedScheme.schemeId}`
      : `notes_export_${selectedCompany?.companyId}`;

  return (
    <>
      {assetPosition && (
        <CreateNoteModalButton
          assetPosition={assetPosition}
          onCreate={() => setLastChange(new Date())}
        />
      )}

      <GetListPaginatedTable
        entityType={"NOTE"}
        params={params}
        lastChange={lastChange}
        cursor={{ maxItems: 25 }}
        data-cy={"note-list"}
        row={NoteListRow}
        header={[null, 4]}
        additionalFunctionButtons={
          <PaginatedApiToCSVExportButton
            entityType={"NOTE"}
            params={params as unknown as Record<string, string>}
            columnOrder={[
              "title",
              "content",
              "companyId",
              "schemeId",
              "locationId",
              "address",
              "assetPositionId",
              "customerReference",
              "createdAt",
              "creator.userId",
              "creator.userName",
              "noteId",
              "status",
            ]}
            filename={filename}
          />
        }
      />
    </>
  );
}
