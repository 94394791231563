import type React from "react";
import SelectedScheme from "../../../components/Scheme/SelectedScheme";
import AggregatedTimeSeriesStatsReport from "../../../components/TimeSeries/AggregatedTimeSeriesStatsReport/AggregatedTimeSeriesStatsReport";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";

export default function StatsTimeSeriesDataReportPage(): React.ReactElement {
  return (
    <OneCardPage
      headerTitle={"Stats Explorer"}
      breadcrumbs={[
        { url: "/admin/report/time_series/stats", name: "Stats Explorer" },
      ]}
      setPageTitle={schemeObjectPageTitle("Statistics Explorer")}
    >
      <SelectedScheme>
        {(schemeProps) => {
          return <AggregatedTimeSeriesStatsReport {...schemeProps} />;
        }}
      </SelectedScheme>
    </OneCardPage>
  );
}
