import dayjs from "dayjs";
import React from "react";
import { GroupBy } from "../../../openapi/model/groupBy";
import { Period } from "../../../openapi/model/period";
import { meterOptions } from "../../Asset/AssetTypeSelectCombo";
import AggregatedTimeSeriesDataWrapper from "../AggregatedTimeSeriesDataWrapper/AggregatedTimeSeriesDataWrapper";
import { mapFormValuesToTimeSeriesParams } from "../AggregatedTimeSeriesQueryForm/AggregatedTimeSeriesQueryForm";
import AggregatedTimeSeriesStatsReportView from "../AggregatedTimeSeriesStatsReportView/AggregatedTimeSeriesStatsReportView";
import AggregatedTimeSeriesStatsWrapper from "../AggregatedTimeSeriesStatsWrapper/AggregatedTimeSeriesStatsWrapper";
import TimeSeriesQueryUrlWrapper from "../TimeSeriesQueryUrlWrapper/TimeSeriesQueryUrlWrapper";
import type { AssetType, NewTimeSeriesRequestParams } from "../model";

export interface AggregatedTimeSeriesStatsReportProps {
  companyId: string;
  schemeId?: string;
}

export function AggregatedTimeSeriesStatsReportComponent({
  companyId,
  schemeId,
  params,
  onChange,
  showForm,
}: {
  companyId: string;
  schemeId?: string;
  params: NewTimeSeriesRequestParams;
  onChange?: (v: NewTimeSeriesRequestParams) => void;
  showForm?: boolean;
}) {
  return (
    <AggregatedTimeSeriesStatsWrapper
      companyId={companyId}
      schemeId={schemeId}
      params={{ ...params, group: undefined, useCase: undefined }}
    >
      {(data) => (
        <>
          {schemeId ? (
            <AggregatedTimeSeriesDataWrapper
              companyId={companyId}
              schemeId={schemeId}
              params={{ ...params, period: Period.ALL }}
            >
              {(aggregatedData) => (
                <AggregatedTimeSeriesStatsReportView
                  companyId={companyId}
                  schemeId={schemeId}
                  data={data}
                  aggregatedData={aggregatedData}
                  showForm={showForm}
                  onSubmit={(v) => {
                    onChange?.(
                      mapFormValuesToTimeSeriesParams(v, true, false, true),
                    );
                    return Promise.resolve();
                  }}
                  request={params}
                />
              )}
            </AggregatedTimeSeriesDataWrapper>
          ) : (
            <AggregatedTimeSeriesStatsReportView
              companyId={companyId}
              schemeId={schemeId}
              data={data}
              showForm={showForm}
              onSubmit={(v) => {
                onChange?.(mapFormValuesToTimeSeriesParams(v, true, false));
                return Promise.resolve();
              }}
              request={params}
            />
          )}
        </>
      )}
    </AggregatedTimeSeriesStatsWrapper>
  );
}

export default function AggregatedTimeSeriesStatsReport(
  props: AggregatedTimeSeriesStatsReportProps,
) {
  return (
    <TimeSeriesQueryUrlWrapper
      defaultValues={{
        assetType: meterOptions as AssetType[],
        parameter: "Power",
        aggregation: "mean",
        period: Period.DAILY,
        startDate: dayjs().startOf("day").subtract(14, "days"),
        endDate: dayjs().endOf("day").subtract(1, "days"),
        groupBy: GroupBy.NONE,
      }}
    >
      {(
        params: NewTimeSeriesRequestParams,
        onChange: (v: NewTimeSeriesRequestParams) => void,
      ) => (
        <AggregatedTimeSeriesStatsReportComponent
          params={params}
          companyId={props.companyId}
          schemeId={props.schemeId}
          onChange={onChange}
        />
      )}
    </TimeSeriesQueryUrlWrapper>
  );
}
