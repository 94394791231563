import {
  SelectComboField,
  type SelectComboFieldProps,
} from "components/Forms/SelectCombo/SelectCombo";
import React from "react";
import { getAggregations } from "../model";

interface AssetAggregationSelectComboProps
  extends Omit<SelectComboFieldProps, "options"> {
  assetParameter: string;
}

function renderAggregationLabel(value: string): string {
  return value
    .replaceAll("(", "- ")
    .replaceAll("_", " ")
    .replaceAll(")", "")
    .toLowerCase()
    .replace(/(\s\w|^\w)/g, (txt) => txt.toUpperCase());
}

function AssetAggregationSelectCombo(props: AssetAggregationSelectComboProps) {
  const { assetParameter, ...rest } = props;

  return (
    <SelectComboField
      {...rest}
      options={getAggregations(assetParameter).map((v) => {
        return { label: renderAggregationLabel(v), value: v };
      })}
    />
  );
}

export default AssetAggregationSelectCombo;
