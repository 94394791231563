import React from "react";
import useCreateEntity from "../../hooks/createEntity";
import type { PaginatedData } from "../../hooks/getList";
import type { Scheme } from "../../openapi/model/scheme";
import type { TariffIn } from "../../openapi/model/tariffIn";
import type { TariffOut } from "../../openapi/model/tariffOut";
import { useSelectedScheme } from "../../reducers/scheme";
import TariffCreateForm from "./TariffCreateForm";
import TariffHomepage from "./TariffHomepage";

function SchemeTariffListDisplay(props: { data: PaginatedData<TariffOut> }) {
  const scheme = useSelectedScheme() as Scheme;
  const create = useCreateEntity<TariffIn, TariffOut>("TARIFF");
  switch (props.data.data.length) {
    case 0:
      return <TariffCreateForm scheme={scheme} create={create} />;
    case 1:
      return <TariffHomepage tariff={props.data.data[0]} />;
  }
  throw new Error(
    `More than 1 tariff was returned for scheme ${scheme.companyId}/${scheme.schemeId}.`,
  );
}

export default SchemeTariffListDisplay;
