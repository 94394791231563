import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik, type FormikHelpers } from "formik";
import type { Company } from "openapi/model/company";
import React from "react";
import * as Yup from "yup";
import DateRangePicker from "../../../components/Forms/DateRangePicker/DateRangePicker";
import {
  SelectComboField,
  type Value,
} from "../../../components/Forms/SelectCombo/SelectCombo";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { Button } from "../../../components/Theme/button";
import AggregatedTimeSeriesQuerySubForm, {
  AggregatedTimeSeriesQueryDefaultValues,
} from "../../../components/TimeSeries/AggregatedTimeSeriesQueryForm/AggregatedTimeSeriesQuerySubForm";
import type { AggregatedDataReportFormat } from "../../../openapi/model/aggregatedDataReportFormat";
import type { AllDataReportFormat } from "../../../openapi/model/allDataReportFormat";
import type { BillingReportFormat } from "../../../openapi/model/billingReportFormat";
import type { CumulativeDataReportFormat } from "../../../openapi/model/cumulativeDataReportFormat";
import type { ElvacoX109ReportFormat } from "../../../openapi/model/elvacoX109ReportFormat";
import type { LatestDataReportFormat } from "../../../openapi/model/latestDataReportFormat";
import { OutputType } from "../../../openapi/model/outputType";
import type { ReportExecutionIn } from "../../../openapi/model/reportExecutionIn";
import type { Scheme } from "../../../openapi/model/scheme";
import AllDataReportConfigurationSubForm, {
  AllDataReportConfigurationInitialValues,
} from "../../jobs/FormatConfigurationForms/AllDataReportConfigurationForm";
import BillingConfigurationSubForm, {
  BillingConfigurationInitialValues,
} from "../../jobs/FormatConfigurationForms/BillingConfigurationForm";
import X109ConfigurationSubForm, {
  X109ConfigurationInitialValues,
  X110ConfigurationInitialValues,
} from "../../jobs/FormatConfigurationForms/X109ConfigurationForm";

interface FormValues
  extends Omit<
    ReportExecutionIn,
    "endDate" | "startDate" | "companyId" | "schemeId"
  > {
  startDate: Dayjs;
  endDate: Dayjs;
}

export interface ReportConfigurationFormProps {
  company: Company;
  scheme?: Scheme;
  outputTypeFilter?: OutputType[];
  create: (report: ReportExecutionIn) => Promise<any>;
}

export type ReportFormat =
  | BillingReportFormat
  | ElvacoX109ReportFormat
  | AllDataReportFormat
  | LatestDataReportFormat
  | CumulativeDataReportFormat
  | AggregatedDataReportFormat;

function ReportConfigurationForm(props: ReportConfigurationFormProps) {
  const locked = false;
  const {
    outputTypeFilter = [
      OutputType.LATEST,
      OutputType.ELVACOX109,
      OutputType.ELVACOX110,
      OutputType.ALLDATA,
      OutputType.CUMULATIVEDATA,
      OutputType.AGGREGATEDDATA,
    ],
  } = props;

  const defaultValuesForReport = (reportType: OutputType): ReportFormat => {
    switch (reportType) {
      case "ELVACO_X109":
        return { ...X109ConfigurationInitialValues, format: reportType };
      case "ELVACO_X110":
        return { ...X110ConfigurationInitialValues, format: reportType };
      case "CUMULATIVE_DATA":
        return { ...BillingConfigurationInitialValues(), format: reportType };
      case "ALL_DATA":
        return {
          ...AllDataReportConfigurationInitialValues,
          format: reportType,
        };
      case "AGGREGATED_DATA":
        return {
          configuration: AggregatedTimeSeriesQueryDefaultValues,
          format: reportType,
        } as unknown as AggregatedDataReportFormat; /* handle that paramater/aggregation ins't correct in the form */
      default:
        return { format: reportType, configuration: undefined };
    }
  };

  const initialValues: FormValues = {
    startDate: dayjs().startOf("month"),
    endDate: dayjs().endOf("day").subtract(1, "day"),
    reportFormat: defaultValuesForReport(outputTypeFilter[0]),
  };

  const mapFormToReport = (
    reportType: OutputType,
    values: any,
  ): ReportFormat => {
    const configuration = values.configuration;
    switch (reportType) {
      case "AGGREGATED_DATA":
        return {
          format: "AGGREGATED_DATA",
          configuration: {
            includedAssetTypes: configuration.assetType,
            aggregation: {
              parameter: configuration.parameter,
              aggregation: configuration.aggregation,
            },
            period: configuration.period,
            viewBy: configuration.viewBy,
          },
        } as AggregatedDataReportFormat;

      default:
        return values.reportFormat;
    }
  };

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    setSubmitting(true);
    props
      .create({
        reportFormat: mapFormToReport(
          values.reportFormat.format as OutputType,
          values.reportFormat,
        ),
        schemeId: props.scheme?.schemeId,
        companyId: props.company.companyId,
        startDate: values.startDate.format("YYYY-MM-DD"),
        endDate: values.endDate.format("YYYY-MM-DD"),
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        startDate: Yup.string().required(),
        endDate: Yup.string().required(),
      })}
    >
      {({ values, isSubmitting, setFieldValue, isValid }) => {
        return (
          <Form>
            <BlockSpinner loading={isSubmitting}>
              <div className={"m-w-xl"}>
                <SelectComboField
                  fieldName={"reportFormat.format"}
                  label={{
                    label: "Report Format",
                    size: "sm",
                    showErrors: true,
                  }}
                  options={[
                    {
                      label: "Cumulative Data",
                      value: OutputType.CUMULATIVEDATA,
                    },
                    {
                      label: "Aggregated Data",
                      value: OutputType.AGGREGATEDDATA,
                    },
                    {
                      label: "Latest Data",
                      value: OutputType.LATEST,
                    },
                    {
                      label: "Elvaco X109",
                      value: OutputType.ELVACOX109,
                    },
                    {
                      label: "Elvaco X110",
                      value: OutputType.ELVACOX110,
                    },
                    {
                      label: "All data",
                      value: OutputType.ALLDATA,
                    },
                    {
                      label: "Billing",
                      value: OutputType.BILLING,
                    },
                  ].filter((v) => outputTypeFilter.includes(v.value))}
                  onChange={(event) => {
                    return setFieldValue(
                      "reportFormat",
                      defaultValuesForReport(event as Value as OutputType),
                    );
                  }}
                  disabled={locked || outputTypeFilter.length === 1}
                />
                {/* TODO: label 'Report date range' */}
                <DateRangePicker
                  startDateName={"startDate"}
                  endDateName={"endDate"}
                />
                {values.reportFormat.format === OutputType.ELVACOX109 && (
                  <X109ConfigurationSubForm
                    namespace={"reportFormat"}
                    disabled={locked}
                  />
                )}
                {values.reportFormat.format === OutputType.ELVACOX110 && (
                  <X109ConfigurationSubForm
                    namespace={"reportFormat"}
                    disabled={locked}
                  />
                )}
                {values.reportFormat.format === OutputType.CUMULATIVEDATA && (
                  <BillingConfigurationSubForm
                    namespace={"reportFormat"}
                    disabled={locked}
                  />
                )}
                {values.reportFormat.format === OutputType.ALLDATA && (
                  <AllDataReportConfigurationSubForm
                    namespace={"reportFormat"}
                    disabled={locked}
                  />
                )}
                {values.reportFormat.format === OutputType.AGGREGATEDDATA && (
                  <AggregatedTimeSeriesQuerySubForm
                    namespace={"reportFormat.configuration"}
                    wide={false}
                    showErrors={true}
                    showLocationGroupSelect={false}
                    disabled={locked}
                  />
                )}
                <Button
                  color={"brandLight"}
                  disabled={!isValid}
                  type={"submit"}
                >
                  Execute Report
                </Button>
              </div>
            </BlockSpinner>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ReportConfigurationForm;
