import {
  camelCase,
  isArray,
  isNil,
  isString,
  startCase,
  upperCase,
} from "lodash-es";
import type { ParsedQs } from "qs";

export function convertStringToOpenApiEnum<E, K extends string = string>(
  str: string,
  type: { [key in K]: E },
  uppercase = false,
): E | undefined {
  const val = (uppercase ? upperCase : startCase)(camelCase(str)).replace(
    / /g,
    "",
  );
  if (val in type) {
    return type[val as keyof typeof type];
  }
  return undefined;
}

export function matchStringsToEnum<E, K extends string = string>(
  lst: string | string[] | ParsedQs | ParsedQs[] | undefined,
  type: { [key in K]: E },
  uppercase = false,
): E[] | undefined {
  if (isNil(lst)) {
    return undefined;
  }
  if (isArray(lst)) {
    return lst
      .map((v) => {
        if (isString(v)) {
          return convertStringToOpenApiEnum<E, K>(v, type, uppercase);
        } else {
          return undefined;
        }
      })
      .filter((v) => !isNil(v)) as E[];
  }
  const val = isString(lst)
    ? convertStringToOpenApiEnum<E, K>(lst, type, uppercase)
    : undefined;
  if (val) {
    return [val as E];
  }
}
