import React from "react";
import type { Company } from "../openapi/model/company";
import type { Scheme } from "../openapi/model/scheme";
import { useSelectedCompany } from "../reducers/company";
import { useSelectedScheme } from "../reducers/scheme";

export type SetTitleFunction = (props: {
  company: Company | undefined;
  scheme: Scheme | undefined;
}) => string;

interface setPageTitleProps {
  setPageTitle?: SetTitleFunction;
}

export function companyObjectPageTitle(entityName: string): SetTitleFunction {
  return ({ company }) => entityName + (company ? `: ${company.name}` : "");
}

export function schemeObjectPageTitle(
  entityName: string,
  schemeFirst = false,
): SetTitleFunction {
  return ({ scheme }) =>
    schemeFirst
      ? (scheme ? `${scheme.schemeName}: ` : "") + entityName
      : entityName + (scheme ? `: ${scheme.schemeName}` : "");
}

export default function useSetPageTitle({ setPageTitle }: setPageTitleProps) {
  const company = useSelectedCompany();
  const scheme = useSelectedScheme();

  return React.useEffect(() => {
    document.title = setPageTitle
      ? setPageTitle({ company, scheme })
      : "Meterpoint";
    return () => {
      document.title = "Meterpoint";
    };
  }, [company, scheme, setPageTitle]);
}
