import type React from "react";
import { useEffect, useMemo } from "react";
import type { GetEntityResultProps } from "../../core/action/GetEntity";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import type { BulkUpdateExecutionStats } from "../../openapi/model/bulkUpdateExecutionStats";
import type { BulkUpdateInstruction } from "../../openapi/model/bulkUpdateInstruction";
import { BulkUpdateStatus } from "../../openapi/model/bulkUpdateStatus";
import BlockSpinner from "../Spinners/BlockSpinner";
import StateTabGroupInterface from "../TabGroup/StateTabGroupInterface";
import type { Tab } from "../TabGroup/model";
import { Heading } from "../Theme/heading";
import { OneCardPage } from "../utils/OneCardPage";
import ExecuteBulkUpdateButton from "./ExecuteBulkUpdateButton";
import GenericUpdateErrorsBanner from "./GenericUpdateErrorsBanner";
import GenericUpdateList from "./GenericUpdateList";
import UnusedColumnsBanner from "./UnusedColumnsBanner";
import UpdateProgressBanner from "./UpdateProgressBanner";

export interface GenericUpdateProps {
  title: string;
  header: string;
  tableFieldHeaders: string[];
  tableFields: string[];
  objectKey: string;
}

interface Props extends GenericUpdateProps, GetEntityResultProps<any> {}

const filterPayloadList = (
  instructions: BulkUpdateInstruction[],
  filter: string,
  errors = false,
): BulkUpdateInstruction[] => {
  return instructions.filter((instruction: any) => {
    const hasErrors =
      instruction.validationErrors.length > 0 ||
      (instruction.immutableChangeErrors &&
        instruction.immutableChangeErrors.length > 0);
    return (
      instruction.type === filter &&
      ((hasErrors && errors) || (!hasErrors && !errors))
    );
  });
};

const arrayToObject = (array: any[], key: string): any =>
  array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});

const idKeyProperty = (objectType: string): string => {
  const idKeys: { [key: string]: string } = {
    asset_position: "assetPositionId",
    location: "locationId",
    asset: "assetId",
  };
  return idKeys[objectType];
};

const GenericUpdatesDisplay = (props: Props): React.ReactElement => {
  const { data, loading, refresh } = props;

  const selected = useMemo(
    () =>
      data && {
        ...data,
        existingMap: arrayToObject(
          data.existing,
          idKeyProperty(data.objectType),
        ),
      },
    [data],
  );

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined = undefined;
    const setTimer = (): void => {
      if (selected) {
        const status = selected.status;

        if (
          selected &&
          (status === BulkUpdateStatus.EXECUTIONSTARTED ||
            status === BulkUpdateStatus.PREVIEWGENERATING)
        ) {
          if (timer) {
            clearTimeout(timer);
          }
          timer = setTimeout(() => {
            refresh();
          }, 5000);
        }
      }
    };
    setTimer();
    return () => timer && clearTimeout(timer);
  }, [selected, refresh]);

  const tabContent = (
    title: string,
    list: BulkUpdateInstruction[],
    index: string,
    actioned: boolean,
  ): Tab => {
    return {
      tabName: title,
      tabContent: (
        <GenericUpdateList
          title={title}
          list={list}
          listPrefix={index}
          tableFieldHeaders={props.tableFieldHeaders}
          tableFields={props.tableFields}
          existingMap={selected.existingMap}
          actioned={actioned}
          objectKey={props.objectKey}
        />
      ),
      tabPath: title,
    };
  };

  const executed = selected?.status !== BulkUpdateStatus.PREVIEWCREATED;

  const lists: {
    title: string;
    list: BulkUpdateInstruction[];
    render?: () => React.ReactElement;
  }[] = selected
    ? [
        { title: "All", list: selected.preview },
        {
          title: "New",
          list: filterPayloadList(selected.preview, "NEW"),
        },
        {
          title: "Invalid New ",
          list: filterPayloadList(selected.preview, "NEW", true),
        },
        {
          title: "Valid Changes",
          list: filterPayloadList(selected.preview, "UPDATE"),
        },
        {
          title: "Invalid Changes",
          list: filterPayloadList(selected.preview, "UPDATE", true),
        },
        {
          title: "Unchanged",
          list: filterPayloadList(selected.preview, "UNCHANGED"),
        },
        {
          title: "Rows Not Found on Server",
          list: filterPayloadList(selected.preview, "NOT_FOUND"),
        },
        {
          title: "Rows Found on Server Deleted",
          list: filterPayloadList(selected.preview, "DELETED"),
        },
      ].filter((l) => l.list.length > 0)
    : [];

  const actioned = selected?.status !== BulkUpdateStatus.PREVIEWCREATED;
  const header = props.header + (actioned ? " Status" : " Preview");

  return (
    <OneCardPage
      headerTitle={header}
      setPageTitle={schemeObjectPageTitle(
        `Bulk Update (${selected?.updateId})`,
        true,
      )}
    >
      <BlockSpinner loading={loading}>
        <div style={{ minHeight: "300px" }}>
          <Heading>Update: {selected?.updateId}</Heading>
          {selected ? (
            <>
              {executed && (
                <UpdateProgressBanner
                  status={selected.status}
                  previewStats={selected.previewStats}
                  executeStats={
                    selected.executeStats as BulkUpdateExecutionStats
                  }
                />
              )}

              {!executed && (
                <GenericUpdateErrorsBanner
                  previewStats={selected.previewStats}
                />
              )}
              <UnusedColumnsBanner unusedColumns={selected.ignoredFields} />
              {selected.preview.length > 0 ? (
                <StateTabGroupInterface
                  tabs={lists.map((value, index) => {
                    return tabContent(
                      value.title,
                      value.list,
                      index.toString(),
                      actioned,
                    );
                  })}
                />
              ) : (
                <Heading>No content in this update.</Heading>
              )}
              {/*<GenericUpdateToCsvExportButton instructions={this.selected.preview} idKey={this.props.objectKey}*/}
              {/*                                existingMap={this.selected.existingMap}/>*/}
              {selected.status === BulkUpdateStatus.PREVIEWCREATED && (
                <ExecuteBulkUpdateButton
                  companyId={selected.companyId}
                  schemeId={selected.schemeId}
                  updateId={selected.updateId}
                  refresh={refresh}
                />
              )}
            </>
          ) : (
            <p>Update loading</p>
          )}
        </div>
      </BlockSpinner>
    </OneCardPage>
  );
};

export default GenericUpdatesDisplay;
