import { useField } from "formik";
import React from "react";
import SingleDatePicker from "../../../../components/Forms/SingleDatePicker";

export default function AssetPositionAssetLinkDateSelect(props: {
  serialNumber?: string;
  customerReference?: string;
  newLink?: boolean;
}) {
  const { serialNumber, customerReference } = props;
  const [installDate] = useField("installDate");
  const [uninstallDate] = useField("uninstallDate");

  const installDateText = installDate.value
    ? `${installDate.value.format("dddd D MMMM YYYY")} 00:00:00`
    : "forever";
  const uninstallDateText = uninstallDate.value
    ? `${uninstallDate.value
        .clone()
        .subtract(1, "days")
        .format("dddd D MMMM YYYY")} 23:59:59`
    : "forever";

  return (
    <>
      <h4>Installed</h4>
      <SingleDatePicker
        dateName={"installDate"}
        showPresets={false}
        placeholder={"Forever"}
        timeZone={"Europe/London"}
      />
      <h4>Uninstalled</h4>
      <SingleDatePicker
        dateName={"uninstallDate"}
        showPresets={false}
        placeholder={"Forever"}
        timeZone={"Europe/London"}
      />
      {props.newLink ? (
        <>
          Data for new asset with be used from {installDateText} till{" "}
          {uninstallDateText}.
        </>
      ) : (
        <>
          {" "}
          Data from {serialNumber} will be used for position {customerReference}{" "}
          from {installDateText} till {uninstallDateText}.
        </>
      )}
    </>
  );
}
