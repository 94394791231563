import type React from "react";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import useGetEntity from "../../hooks/getEntity";
import type { StreamingConfiguration } from "../../openapi/model/streamingConfiguration";
import { useSelectedCompany } from "../../reducers/company";
import StreamingSettingsForm from "./StreamingSettingsForm";

export default function StreamingSettingsFormPage(): React.ReactElement {
  const company = useSelectedCompany();
  const [{ data, loading, error }] = useGetEntity<StreamingConfiguration>({
    entityType: "STREAMING_CONFIGURATION",
    entityId: "",
    companyId: company?.companyId,
    singletonApi: true,
  });

  return (
    <>
      <BlockSpinner loading={loading}>
        {!loading && data && (
          <StreamingSettingsForm streamingConfiguration={data} />
        )}
        {!loading && error && <ErrorBanner error={error} />}
      </BlockSpinner>
    </>
  );
}
