import RequireScheme from "components/Scheme/RequireScheme";
import type React from "react";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";
import { useRequiredSelectedCompanyId } from "../../../reducers/company";
import { useSelectedSchemeId } from "../../../reducers/scheme";
import LatestTimeSeriesDataReport from "./LatestTimeSeriesDataReport";

const SchemeLatestTimeSeriesReport = (): React.ReactElement => {
  const companyId = useRequiredSelectedCompanyId();
  const scheme = useSelectedSchemeId();
  return (
    <OneCardPage
      headerTitle={"Reporting"}
      breadcrumbs={[{ url: "/admin/report/time_series", name: "Latest Data" }]}
      setPageTitle={schemeObjectPageTitle("Lastest Data")}
    >
      <RequireScheme>
        <LatestTimeSeriesDataReport
          companyId={companyId}
          schemeId={scheme as string}
        />
      </RequireScheme>
    </OneCardPage>
  );
};

export default SchemeLatestTimeSeriesReport;
