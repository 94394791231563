import clsx from "clsx";
import AssetTypeHumanLabel from "components/Asset/AssetTypeHumanLabel";
import AssetUseCaseHumanLabel from "components/Asset/AssetUseCaseHumanLabel";
import AssetRegisterStatusLabel from "components/Labels/AssetRegisterStatusLabel";
import SchemeLabel from "components/Scheme/SchemeLabel";
import PrivacyMode from "components/Text/PrivacyMode";
import {
  StackedListHeadingContent,
  StackedListThreeColumnItem,
} from "components/Theme/stacked-list";
import CompanyNavLink from "components/navigation/CompanyNavLink";
import type { AssetRegisterItem } from "openapi/model/assetRegisterItem";
import AssetStateLabel from "pages/assetRegister/assets/AssetStateLabel";
import type React from "react";

export default function AssetRegisterListItem(props: {
  assetRegisterItem: AssetRegisterItem;
  showScheme?: boolean;
  showSchemeBp?: string;
  showType?: boolean;
  showReference?: boolean;
  showAsset?: boolean;
  denseColumn?: (props: {
    assetRegisterItem: AssetRegisterItem;
  }) => React.ReactNode;
  extensionLarge?: React.ReactNode;
  extensionSmall?: React.ReactNode;
  actionButtons?: (props: {
    assetRegisterItem: AssetRegisterItem;
  }) => React.ReactNode;
  showLocation?: boolean;
}): React.ReactElement {
  const { location, assetPosition, asset } = props.assetRegisterItem;
  const { showScheme = true, showType = true } = props;

  return (
    <StackedListThreeColumnItem
      key={assetPosition.assetPositionId}
      data-cy={"asset-register-list-item"}
      column1={
        <div className="min-w-0 flex-auto">
          <div>
            <CompanyNavLink
              to={{ pathname: `/admin/location/${location?.locationId}` }}
              dataCy={"locationId"}
            >
              <PrivacyMode>{location?.address}</PrivacyMode>
            </CompanyNavLink>
          </div>
          <div className={"text-sm"}>
            {showScheme && (
              <>
                <SchemeLabel schemeId={location.schemeId} />
                {" | "}
              </>
            )}
            <PrivacyMode>
              {location.customerReference || "No Reference"}
            </PrivacyMode>
            {" | "}
            <PrivacyMode>{location.group || "No group"}</PrivacyMode>
          </div>
        </div>
      }
      column2={
        <div className="min-w-0 flex-auto">
          <div>
            <CompanyNavLink
              to={{
                pathname: `/admin/asset_position/${assetPosition?.assetPositionId}`,
              }}
              dataCy={"assetPositionId"}
            >
              <PrivacyMode>
                {assetPosition?.customerReference || "No Position Reference"}
              </PrivacyMode>
            </CompanyNavLink>
          </div>
          <div>
            <AssetUseCaseHumanLabel asset={assetPosition} />
            <AssetRegisterStatusLabel status={assetPosition.status} />
          </div>
        </div>
      }
      column3={
        <div className="min-w-0 flex-auto">
          <div className={"flex"}>
            <div className={clsx({ "w-1/2": showType })}>
              {asset ? (
                <>
                  <div>
                    <CompanyNavLink
                      to={{ pathname: `/admin/asset/${asset?.assetId}` }}
                      dataCy={"assetId"}
                    >
                      <PrivacyMode>{asset.serialNumber}</PrivacyMode>
                    </CompanyNavLink>
                  </div>
                  <div className={"text-sm d-block"}>
                    {asset?.manufacturer && (
                      <>
                        {" "}
                        <small>{asset.manufacturer}</small>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>Unassigned</>
              )}
            </div>
            {showType && (
              <div className={"w-1/2"}>
                <AssetTypeHumanLabel
                  asset={assetPosition}
                  iconLocation={"left"}
                />
                <AssetStateLabel asset={asset} className={"mr-2"} />
              </div>
            )}
          </div>
        </div>
      }
      column1Size={"sm:w-5/12"}
      column2Size={"sm:w-3/12"}
      column3Size={"sm:w-4/12"}
    />
  );
}

export function DenseAssetRegisterListItem(props: {
  assetRegisterItem: AssetRegisterItem;
  showScheme?: boolean;
  showType?: boolean;
  showReference?: boolean;
  showAsset?: boolean;
  column2: (props: {
    assetRegisterItem: AssetRegisterItem;
  }) => React.ReactElement;
  column3: (props: {
    assetRegisterItem: AssetRegisterItem;
  }) => React.ReactElement;
  column1Size?: string;
  column2Size?: string;
  column3Size?: string;
}): React.ReactElement {
  const { location, assetPosition, asset } = props.assetRegisterItem;
  const {
    assetRegisterItem,
    showScheme = true,
    // showType = true,
    // showReference = true,
    // showAsset = true,
    // showLocation = true,
  } = props;

  return (
    <StackedListThreeColumnItem
      key={assetPosition.assetPositionId}
      data-cy={"asset-register-list-item"}
      column1={
        <div className="min-w-0 flex-auto">
          <div>
            <CompanyNavLink
              to={{ pathname: `/admin/location/${location?.locationId}` }}
              dataCy={"locationId"}
            >
              <PrivacyMode>{location?.address}</PrivacyMode>
            </CompanyNavLink>
            {" | "}
            <CompanyNavLink
              to={{
                pathname: `/admin/asset_position/${assetPosition?.assetPositionId}`,
              }}
              dataCy={"assetPositionId"}
            >
              <PrivacyMode>
                {assetPosition?.customerReference || "No Position Reference"}
              </PrivacyMode>
            </CompanyNavLink>
            {" | "}
            <PrivacyMode>{location.group || "No group"}</PrivacyMode>
          </div>
          <div>
            {asset ? (
              <>
                <CompanyNavLink
                  to={{ pathname: `/admin/asset/${asset?.assetId}` }}
                  dataCy={"assetId"}
                >
                  <PrivacyMode>{asset.serialNumber}</PrivacyMode>
                </CompanyNavLink>
                <span className={"text-sm "}>
                  {asset?.manufacturer && (
                    <>
                      {" "}
                      <small>{asset.manufacturer}</small>
                    </>
                  )}
                </span>
              </>
            ) : (
              <>Unassigned</>
            )}
            {showScheme && (
              <span className={"text-sm"}>
                <>
                  (<SchemeLabel schemeId={location.schemeId} />)
                </>
              </span>
            )}
          </div>
        </div>
      }
      column2={props.column2({ assetRegisterItem })}
      column3={props.column3({ assetRegisterItem })}
      column1Size={"w-7/12 md:w-5/12"}
      column2Size={"w-5/12 md:w-3/12"}
      column3Size={"hidden md:w-4/12"}
    />
  );
}

export function AssetRegisterListHeading(props: {
  column1?: string;
  column2?: string;
  column3?: string;
  column1Size?: string;
  column2Size?: string;
  column3Size?: string;
}): React.ReactElement {
  const {
    column1 = "Location",
    column2 = "Position",
    column3 = "Asset",
    column1Size = "sm:w-5/12",
    column2Size = "sm:w-3/12",
    column3Size = "sm:w-4/12",
  } = props;
  return (
    <StackedListThreeColumnItem
      column1={<StackedListHeadingContent title={column1} />}
      column2={<StackedListHeadingContent title={column2} />}
      column3={<StackedListHeadingContent title={column3} />}
      column1Size={column1Size}
      column2Size={column2Size}
      column3Size={column3Size}
    />
  );
}
