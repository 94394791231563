import type { Dayjs } from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import type React from "react";
import type {
  ColumnDefinition,
  TimeSeriesDataParams,
} from "../../model/deviceTimeSeries";

import nodata from "highcharts/modules/no-data-to-display.js";
nodata(Highcharts);

export type DataPoint = [Dayjs, number | undefined];

export default function SingleBarChart(props: {
  data: DataPoint[];
  definition: ColumnDefinition;
  params: TimeSeriesDataParams;
  loading: boolean;
  isCumulative?: boolean;
}): React.ReactElement {
  const { isCumulative = true } = props;

  const values: [number, number | null][] = isCumulative
    ? props.data.map((v, index) => {
        const currentValue = Number.parseFloat(v[1] as unknown as string);
        const previousValue =
          index === 0
            ? undefined
            : Number.parseFloat(props.data[index - 1][1] as unknown as string);
        return [
          v[0].valueOf(),
          previousValue !== undefined ? currentValue - previousValue : null,
        ];
      })
    : props.data.map((v) => [v[0].valueOf(), v[1] || null]);
  const series: Highcharts.SeriesColumnOptions = {
    data: values,
    type: "column",
    name: props.definition?.name,
    animation: false,
  };

  const actualValues: number[] = values.map(([_, v]) => v as number);
  const defaultMax = props.definition.name === "Energy" ? 10000 : 1;

  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      min: props.params.startDatetime?.valueOf(),
      max: props.params.endDatetime?.valueOf(),
    },
    series: [series],
    yAxis: [
      {
        labels: {
          format: `{value}${props.definition.unit}`,
        },
        title: {
          text: props.definition.name,
        },
        min: 0,
        max: Math.max(...actualValues, defaultMax),
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: props.loading ? "Loading Data " : "No data for this time period",
    },
    noData: {
      style: {
        fontWeight: "normal",
        fontSize: "15px",
        color: "black",
      },
    },
    exporting: {
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadSVG",
          ],
        },
      },
    },
    time: {
      timezone: "Europe/London",
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

interface BarDefinition extends ColumnDefinition {
  color?: string;
}

export function StackedBarChart(props: {
  data: DataPoint[][];
  definition: BarDefinition[];
  startDatetime?: Dayjs;
  endDatetime?: Dayjs;
  loading: boolean;
  yAxisTitle?: string;
}): JSX.Element {
  const values: (number | null)[][] = props.data.map((series) =>
    series.map((v) => v[1] || null),
  );

  const categories: string[] = props.data[0].map((v) => v[0].format("MMM YY"));

  const series: Highcharts.SeriesColumnOptions[] = values.map(
    (seriesData, i) => {
      return {
        data: seriesData,
        type: "column",
        name: props.definition[i]?.name,
        animation: false,
        color: props.definition[i]?.color,
        tooltip: {},
      };
    },
  );

  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    xAxis: {
      categories: categories,
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: `{series.name}: {point.y:.0f}${props.definition[0].unit}<br>Total: {point.stackTotal:.0f}${props.definition[0].unit}`,
    },
    series: series,
    yAxis: {
      labels: {
        format: "{value}",
      },
      title: {
        text: `${props.yAxisTitle || ""} (${props.definition[0].unit})`,
      },
      stackLabels: {
        enabled: true,
        format: "{total:.1f}",
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: props.loading ? "Loading Data " : "No data for this time period",
    },
    plotOptions: {
      column: { stacking: "normal" },
    },
    noData: {
      style: {
        fontWeight: "normal",
        fontSize: "15px",
        color: "black",
      },
    },
    exporting: {
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadSVG",
          ],
        },
      },
    },
    time: {
      timezone: "Europe/London",
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
