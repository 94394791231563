import type { FormikProps, FormikValues } from "formik";
import type { UpdateEntity } from "hooks/updateEntity";
import { isNil } from "lodash-es";
import type { Asset } from "openapi/model/asset";
import React, { useRef } from "react";
import AssetUseCaseSelect from "../../../components/Asset/AssetUseCaseSelect";
import EditModal from "../../../components/Forms/EditModal";
import type { AssetPosition } from "../../../openapi/model/assetPosition";
import { AssetType } from "../../../openapi/model/assetType";
import AssetTypeSelect from "./AssetTypeSelect";

function InnerAssetTypeChangeForm<T>(
  props: {
    title: string;
    fieldName: keyof T;
    showUseCase: boolean;
  } & FormikProps<T>,
): React.ReactElement {
  const { title, fieldName, showUseCase, ...formikProps } = props;
  const { values, setFieldValue } = formikProps;
  React.useEffect(() => {
    if (
      showUseCase &&
      [
        AssetType.HeatMeter,
        AssetType.CoolingMeter,
        AssetType.HeatingAndCoolingMeter,
      ].indexOf(values[fieldName] as unknown as AssetType) < 0
    ) {
      setFieldValue("useCase", "undefined");
    }
  }, [values, fieldName, showUseCase, setFieldValue]);

  return (
    <>
      <h6>Change {title} Type</h6>
      <AssetTypeSelect fieldName={fieldName as string} />
      {showUseCase && (
        <AssetUseCaseSelect
          fieldName={"useCase"}
          includeNone={true}
          disabled={
            isNil(values[fieldName]) ||
            [
              AssetType.HeatMeter,
              AssetType.CoolingMeter,
              AssetType.HeatingAndCoolingMeter,
            ].indexOf(values[fieldName] as unknown as AssetType) === -1
          }
        />
      )}
    </>
  );
}

export default function AssetTypeChangeForm<
  T extends FormikValues = Asset | AssetPosition,
>(
  object: T,
  updateEntity: UpdateEntity<T>,
  title: string,
  assetPosition: boolean,
) {
  const inputRef = useRef();
  const setModalFocus = () => {
    const node = inputRef.current as any;
    node?.focus();
  };

  return EditModal<T>({
    title: title,
    values: object,
    update: {
      ...updateEntity,
      updateEntity: (entity, path) => {
        if (
          Object.prototype.hasOwnProperty.call(entity, "useCase") &&
          ((entity as unknown as AssetPosition).useCase as string) ===
            "undefined"
        ) {
          (entity as unknown as AssetPosition).useCase = undefined;
        }
        return updateEntity.updateEntity(entity, path);
      },
    },
    children: (props) => (
      <InnerAssetTypeChangeForm
        title={title}
        showUseCase={assetPosition}
        fieldName={
          assetPosition
            ? ("assetPositionType" as keyof T)
            : ("assetType" as keyof T)
        }
        {...props}
      />
    ),
    setModalFocus,
  });
}
