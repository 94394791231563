import { Heading } from "components/Theme/heading";
import type React from "react";
import DisplayIfUserType from "../../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import type { GatewayAsset } from "../../../../openapi/model/gatewayAsset";
import GatewayConfigurationDropdown from "./GatewayConfigurationDropdown";
import GatewayConnectionDisplay from "./GatewayConnectionDisplay";
import GatewaySettingForm from "./GatewaySettingForm";

export default function GatewayConfigurationTab(props: {
  gateway: GatewayAsset;
}): React.ReactElement {
  const { gateway } = props;
  return (
    <>
      <DisplayIfUserType userTypes={"superadmin"}>
        <Heading>Gateway Settings</Heading>
        <GatewaySettingForm gateway={gateway} />
      </DisplayIfUserType>

      <GatewayConnectionDisplay gateway={gateway} />

      <DisplayIfUserType userTypes={"admin"}>
        <GatewayConfigurationDropdown gateway={gateway} />
      </DisplayIfUserType>
    </>
  );
}
