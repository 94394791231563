export const inputStyles = {
  base: "py-1 pl-4 ",
  height: "min-h-[38px]",
  text: "text-sm/6 text-zinc-700 placeholder:text-zinc-500 placeholder:italic",
  placeholderText: "text-zinc-500 italic text-sm/6",
  border: "rounded-lg border border-zinc-950/10 ring-gray-300 ring-1 border-0",
  background:
    "bg-white disabled:bg-gray-200 aria-disabled:bg-gray-200 disabled:opacity-40 aria-disabled:opacity-40",
  // disabled: "bg-gray-200"
};

export const allInputStyles = [
  inputStyles.base,
  inputStyles.height,
  inputStyles.text,
  inputStyles.border,
  inputStyles.background,
];
