import React, { createContext, useReducer } from "react";
import type { EntityResult } from "../hooks/getEntity";

interface CachedItem<T> extends EntityResult<T> {
  loading: boolean;
  updating?: boolean;
  updatingError?: Error;
}

interface assetPositionCacheState {
  [assetPositionId: string]: CachedItem<string[]>;
}

type CacheActions =
  | "ITEM_EXISTS"
  | "FETCHING_ITEM"
  | "FETCHED_ITEM"
  | "UPDATING_ITEM"
  | "UPDATED_ITEM";

interface AssetPositionCacheAction {
  type: CacheActions;
  assetPositionId: string;
  payload?: EntityResult<string[]>;
}

function updateStateForItem(
  state: assetPositionCacheState,
  assetPositionId: string,
  updates: Partial<CachedItem<string[]>>,
) {
  return {
    ...state,
    [assetPositionId]: {
      ...state[assetPositionId],
      ...updates,
    },
  };
}

const Reducer = (
  state: assetPositionCacheState,
  action: AssetPositionCacheAction,
): assetPositionCacheState => {
  switch (action.type) {
    case "ITEM_EXISTS":
      return updateStateForItem(state, action.assetPositionId, {
        loading: false,
      });
    case "FETCHING_ITEM":
      return updateStateForItem(state, action.assetPositionId, {
        loading: true,
      });
    case "FETCHED_ITEM":
      return updateStateForItem(state, action.assetPositionId, {
        ...action.payload,
        loading: false,
      });
    case "UPDATING_ITEM":
      return updateStateForItem(state, action.assetPositionId, {
        updating: true,
      });
    case "UPDATED_ITEM":
      return updateStateForItem(state, action.assetPositionId, {
        updating: false,
      });
    default:
      return state;
  }
};

const AssetPositionCacheContext = createContext<{
  state: assetPositionCacheState;
  dispatch: React.Dispatch<AssetPositionCacheAction>;
}>({ state: {}, dispatch: () => null });

const AssetPositionCacheProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(Reducer, {});
  return (
    <AssetPositionCacheContext.Provider value={{ state, dispatch }}>
      {children}
    </AssetPositionCacheContext.Provider>
  );
};

function useGetCachedAssetPosition(
  assetPositionId: string,
): CachedItem<string[]> | undefined {
  const { state, dispatch } = React.useContext(AssetPositionCacheContext);
  const item = state[assetPositionId];
  if (!item) {
    dispatch({ type: "ITEM_EXISTS", assetPositionId });
  }
  return item;
}

export {
  AssetPositionCacheContext,
  Reducer,
  useGetCachedAssetPosition,
  AssetPositionCacheProvider,
};
