import BlockSpinner from "components/Spinners/BlockSpinner";
import { Table } from "components/Theme/table";
import React from "react";
import type { WirelessSurveyDataCollectorOut } from "../../../openapi/model/wirelessSurveyDataCollectorOut";
import WirelessSurveySamplePointTableRows from "./WirelessSurveySamplePointTableRows";

function WirelessSurveySamplePointTab(props: {
  dataCollectors?: WirelessSurveyDataCollectorOut[];
  loading: boolean;
  refresh?: () => any;
}) {
  const { dataCollectors, loading } = props;
  return (
    <BlockSpinner loading={loading}>
      <Table className={"table-sm"}>
        <thead>
          <tr>
            <th>Data Collector</th>
            <th>Sample Point Name</th>
            <th>Sample Point Start</th>
            <th>Sample Point End</th>
            <th> </th>
          </tr>
        </thead>
        {dataCollectors
          ?.sort((a, b) => (a.dataCollectorName > b.dataCollectorName ? 1 : -1))
          .map((dataCollector) => (
            <WirelessSurveySamplePointTableRows
              key={dataCollector.dataCollectorId}
              dataCollector={dataCollector}
              refresh={props.refresh}
            />
          ))}
      </Table>
    </BlockSpinner>
  );
}

export default WirelessSurveySamplePointTab;
