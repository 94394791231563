import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import type { FormikProps } from "formik";
import type React from "react";
import { useEffect, useRef, useState } from "react";
import EditModal from "../../../../components/Forms/EditModal";
import { Button } from "../../../../components/Theme/button";
import type { UpdateEntity } from "../../../../hooks/updateEntity";
import type { AssetPositionAssetLinkOut } from "../../../../openapi/model/assetPositionAssetLinkOut";
import AssetPositionAssetLinkForm from "./AssetPositionAssetLinkForm";
import type { EditableAssetPositionAssetLinkOut } from "./model";

function AssetPositionAssetUpdateModal(props: {
  link: AssetPositionAssetLinkOut;
  update: UpdateEntity<AssetPositionAssetLinkOut>;
  buttonContent?: React.ReactNode;
  className?: string;
}) {
  const inputRef = useRef();
  const setModalFocus = () => {
    const node = inputRef.current as any;
    node?.focus();
  };

  const {
    buttonContent = (
      <span>
        <FontAwesomeIcon icon={faEdit} fixedWidth />
        Edit
      </span>
    ),
  } = props;

  const [values, setValues] = useState<EditableAssetPositionAssetLinkOut>({
    ...props.link,
    installDate: undefined,
    uninstallDate: undefined,
  });

  useEffect(() => {
    setValues({
      ...props.link,
      installDate: props.link.installDate && dayjs(props.link.installDate),
      uninstallDate:
        props.link.uninstallDate && dayjs(props.link.uninstallDate),
    });
  }, [props.link]);
  const updateFunction = (
    entity: Partial<EditableAssetPositionAssetLinkOut>,
    path?: string,
  ): Promise<void> => {
    return props.update.updateEntity(
      {
        ...entity,
        installDate: entity.installDate?.toDate(),
        uninstallDate: entity.uninstallDate?.toDate(),
      },
      path,
    );
  };

  const [setModalOpen, modal] = EditModal<EditableAssetPositionAssetLinkOut>({
    setModalFocus,
    values,
    children: (formikProps: FormikProps<EditableAssetPositionAssetLinkOut>) => (
      <AssetPositionAssetLinkForm {...formikProps} />
    ),
    title: "Edit Link",
    update: { ...props.update, updateEntity: updateFunction },
  });

  return (
    <>
      {modal}
      <Button
        onClick={setModalOpen}
        color={"brandLight"}
        className={props.className}
        size={"sm"}
        paddingY={1.5}
      >
        {buttonContent}
      </Button>
    </>
  );
}

export default AssetPositionAssetUpdateModal;
