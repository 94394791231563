import React from "react";
import StateTabGroupInterface from "../../../../components/TabGroup/StateTabGroupInterface";
import { Divider } from "../../../../components/Theme/divider";
import { Heading } from "../../../../components/Theme/heading";
import DisplayIfUserType from "../../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import type { AssetPosition } from "../../../../openapi/model/assetPosition";
import CreateAssetForm from "../CreateAssetForm";
import AssetPositionAssetLinkCreateForm from "../assetPositionAssetLinks/AssetPositionAssetLinkCreateForm";
import AssetPositionAssetList from "../assetPositionAssetLinks/AssetPositionAssetList";

function AssetPositionAssetHistoryTab(props: {
  assetPosition: AssetPosition;
  refresh: () => void;
}) {
  const { assetPosition, refresh } = props;
  return (
    <>
      <Heading>Asset Position Asset History</Heading>
      <AssetPositionAssetList
        companyId={assetPosition.companyId}
        schemeId={assetPosition.schemeId}
        assetPositionId={assetPosition.assetPositionId}
        refreshList={refresh}
      />
      <DisplayIfUserType userTypes={"admin"}>
        <Divider className={"py-4"} />
        <div className={"py-4"}>
          <Heading>Assign Asset</Heading>
          <StateTabGroupInterface
            tabs={[
              {
                tabName: "Select",
                tabPath: "select",
                tabContent: (
                  <AssetPositionAssetLinkCreateForm
                    assetPosition={assetPosition}
                    refresh={refresh}
                  />
                ),
              },
              {
                tabName: "Create",
                tabPath: "create",
                tabContent: (
                  <>
                    <h4>Create Asset</h4>
                    <CreateAssetForm
                      assetPosition={assetPosition}
                      refresh={refresh}
                    />
                  </>
                ),
              },
            ]}
          />
        </div>
      </DisplayIfUserType>
    </>
  );
}

export default AssetPositionAssetHistoryTab;
