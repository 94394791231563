/*
Sort functions to assist with tables
 */

import { get, isNaN as loIsNaN } from "lodash-es";
import type { TableRow } from "react-data-table-component";

const sortNumbers =
  (
    propertyName: string,
    undefinedLow = true,
    percentagePropertyName?: string,
  ) =>
  (rowA: TableRow, rowB: TableRow): number => {
    const undefinedValue = undefinedLow
      ? Number.NEGATIVE_INFINITY
      : Number.POSITIVE_INFINITY;
    if (percentagePropertyName) {
      const a =
        get(rowA as unknown as any, propertyName, undefinedValue) /
        get(rowA as unknown as any, percentagePropertyName, 1);
      const b =
        get(rowB as unknown as any, propertyName, undefinedValue) /
        get(rowB as unknown as any, percentagePropertyName, 1);
      return numericCompare(
        loIsNaN(a) ? undefinedValue : a,
        loIsNaN(b) ? undefinedValue : b,
      );
    } else {
      return numericCompare(
        get(rowA as unknown as any, propertyName, undefinedValue),
        get(rowB as unknown as any, propertyName, undefinedValue),
      );
    }
  };

const numericCompare = (a: number, b: number): number => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export default sortNumbers;
