import { isUndefined } from "lodash-es";
import React from "react";
import CreateAndGetListCombo from "../../components/CreateAndGetListCombo";
import { PageHeadingBlock } from "../../components/Theme/heading";
import { OutputType } from "../../openapi/model/outputType";
import { useSelectedCompany } from "../../reducers/company";
import { useSelectedScheme } from "../../reducers/scheme";
import ReportExecutionCreator from "./executions/ReportExecutionCreator";
import ReportExecutionList from "./executions/ReportExecutionList";

function BillingReportPage() {
  const company = useSelectedCompany();
  const scheme = useSelectedScheme();

  if (isUndefined(company)) {
    throw new Error("Company must be selected");
  }
  return (
    <CreateAndGetListCombo
      entityType={"REPORT_EXECUTION"}
      listParams={{
        companyId: company?.companyId,
        schemeId: scheme?.schemeId,
        format: [OutputType.BILLING],
      }}
      cursor={{ maxItems: 15 }}
    >
      {({ create, createError, creating, list }) => (
        <>
          <PageHeadingBlock>Export Billing Report</PageHeadingBlock>

          <ReportExecutionCreator
            scheme={scheme}
            company={company}
            {...{ create, createError, creating }}
            outputTypeFilter={[OutputType.BILLING]}
          />
          <PageHeadingBlock>Previous Executions</PageHeadingBlock>
          <ReportExecutionList list={list} />
        </>
      )}
    </CreateAndGetListCombo>
  );
}

export default BillingReportPage;
