import { isNil } from "lodash-es";
import { AssetUseCase } from "openapi/model/assetUseCase";
import type React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../Forms/SelectCombo/SelectCombo";

export default function AssetUseCaseSelect(
  props: Omit<SelectComboFieldProps, "options"> & {
    includeAll?: boolean;
    includeNone?: boolean;
  },
): React.ReactElement {
  const { label, ...rest } = props;
  const options: Option[] = [
    props.includeAll ? { label: "All", value: "undefined" } : undefined,
    props.includeNone ? { label: "None", value: AssetUseCase.NONE } : undefined,
    { label: "Domestic", value: AssetUseCase.DOMESTIC },
    { label: "Commercial", value: AssetUseCase.COMMERCIAL },
    { label: "Process", value: AssetUseCase.PROCESS },
  ].filter((v) => !isNil(v)) as Option[];

  return (
    <SelectComboField
      label={label || { label: "Use Case" }}
      options={options}
      {...rest}
    />
  );
}
