import { XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import type React from "react";
import { useState } from "react";

const styles = {
  margin: {
    0: "",
    1: "my-1",
    2: "my-2",
    3: "my-3",
    4: "my-4",
  },
};

interface AlertBannerProps extends React.PropsWithChildren {
  className?: string;
  textClassName?: string;
  margin?: keyof typeof styles.margin;
}

export default function AlertBanner(
  props: AlertBannerProps,
): React.ReactElement {
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);

  return (
    <>
      {visible && (
        <div>
          <div
            className={clsx(
              "pointer-events-auto flex items-center justify-between gap-x-6  px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5",
              props.className || "bg-amber-700",
              styles.margin[props.margin || 0],
            )}
          >
            <span
              className={clsx([
                "text-sm leading-6",
                props.textClassName || "text-white",
              ])}
            >
              {props.children}
            </span>
            <button
              type="button"
              className="-m-1.5 flex-none p-1.5"
              onClick={onDismiss}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon
                aria-hidden="true"
                className={clsx("h-5 w-5", props.textClassName || "text-white")}
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
