import type React from "react";
import { GroupBy } from "../../../openapi/model/groupBy";
import {
  ButtonGroupField,
  type ButtonGroupFieldProps,
  type Option,
} from "../ButtonGroup";

interface CommonProps extends Omit<ButtonGroupFieldProps, "options"> {
  noneLabel?: string;
  includeLocationGroup?: boolean;
  includeScheme?: boolean;
}

export default function GroupByRadioButtonGroupField(
  props: CommonProps,
): React.ReactElement {
  const { noneLabel, includeLocationGroup, includeScheme, ...rest } = props;
  const options: Option[] = [
    { label: noneLabel || "NONE", value: GroupBy.NONE },
    includeScheme && { label: " SCHEME", value: GroupBy.SCHEME },
    includeLocationGroup && { label: "GROUP", value: GroupBy.GROUPALL },
  ].filter((v) => {
    return v !== undefined && v !== false;
  });

  return <ButtonGroupField {...rest} options={options} />;
}
