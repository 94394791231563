import type { FormikValues } from "formik";
import type React from "react";
import ObjectPropertyItem from "../Headers/ObjectPropertyItem";
import PrivacyMode from "../Text/PrivacyMode";
import EditStringModal, { type EditStringModalProps } from "./EditStringModal";

interface HeadingEditorFormProps<T extends FormikValues>
  extends EditStringModalProps<T> {
  defaultValue?: string;
  privacyMode?: boolean;
  editActionUserType?: "viewer" | "editor" | "admin" | "superadmin";
}

function HeadingEditorForm<T extends FormikValues>(
  props: HeadingEditorFormProps<T>,
): React.ReactElement {
  const { title, values, fieldName, defaultValue, privacyMode = true } = props;
  const [openModal, modal] = EditStringModal<T>(props);
  const value: string | undefined =
    (values[fieldName] as unknown as string | undefined) || defaultValue;

  return (
    <>
      {modal}
      <ObjectPropertyItem
        title={title}
        type={"heading"}
        editAction={openModal}
        editActionUserType={props.editActionUserType}
      >
        {privacyMode ? (
          <PrivacyMode>{value as string} </PrivacyMode>
        ) : (
          <>{value}</>
        )}
      </ObjectPropertyItem>
    </>
  );
}

export default HeadingEditorForm;
