import type React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";
import { VolumeUnits } from "../../../openapi/model/volumeUnits";

const VolumeUnitSelect = (
  props: Omit<SelectComboFieldProps, "options">,
): React.ReactElement => {
  const options: Option[] = [
    { label: "m3", value: VolumeUnits.M3 },
    { label: "Litres", value: VolumeUnits.Litres },
  ];

  return <SelectComboField options={options} {...props} />;
};

export default VolumeUnitSelect;
