import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import { isNumber } from "lodash-es";
import type React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { ButtonGroupField } from "../../../components/Forms/ButtonGroup";
import { inclusiveDatePeriod } from "../../../components/Forms/DateTimePicker";
import RequireScheme from "../../../components/Scheme/RequireScheme";
import { Col, Container } from "../../../components/Theme/grid";
import { parseSearchString } from "../../../components/navigation/SearchString";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";
import type { AggregatedReportRequest } from "../../../openapi/model/aggregatedReportRequest";
import type { AssetRegisterAggregatedTimeSeriesResponse } from "../../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import type { AssetRegisterItemAggregatedTimeSeries } from "../../../openapi/model/assetRegisterItemAggregatedTimeSeries";
import AggregatedTimeSeriesApiWrapper from "../aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper";
import DateRangeOnlySelectorForm from "../vwartReport/DateRangeOnlySelectorForm";
import DataQualityReportTable from "./DataQualityReportTable";
import SummaryDataQualityTimeSeriesAreaChart from "./SummaryDataQualityTimeSeriesAreaChart";
import { type AssetDataQualityDataItem, QUALITY_AGGREGATIONS } from "./model";

interface VWARTReportParams {
  startDatetime?: Dayjs;
  endDatetime?: Dayjs;
}

const searchDatetimeParams = (
  searchString: string,
): Required<VWARTReportParams> => {
  const [startDate, endDate] = inclusiveDatePeriod();
  const searchParams = parseSearchString(searchString);
  const startDatetime = searchParams.startDatetime
    ? dayjs(searchParams.startDatetime as string)
    : startDate;
  const endDatetime = searchParams.startDatetime
    ? dayjs(searchParams.endDatetime as string)
    : dayjs(+endDate);
  return { startDatetime, endDatetime };
};

const sumValues = (values: any[]): number => {
  return values.filter(isNumber).reduce((sum, current) => sum + current, 0);
};

const mapData = (
  data: AssetRegisterAggregatedTimeSeriesResponse,
): Array<AssetDataQualityDataItem> => {
  return data.data.map((value: AssetRegisterItemAggregatedTimeSeries) => {
    const { aggregations, ...asset } = value;
    let mappedAggregations = Object.fromEntries(
      Object.entries(aggregations.qualityAssessment).map(([key, data]) => {
        return [key, sumValues(data)];
      }),
    );
    mappedAggregations = {
      ...mappedAggregations,
      missingRows:
        mappedAggregations.expectedRows -
        mappedAggregations.receivedRowsComplete -
        mappedAggregations.receivedRowsIncomplete -
        mappedAggregations.receivedRowsWithErrors -
        mappedAggregations.receivedRowsWithValueErrors,
    };
    return {
      ...asset,
      ...mappedAggregations,
    };
  });
};

const DataQualityReportByRows = (props: {
  companyId?: string;
  schemeId?: string;
}): React.ReactElement => {
  const { startDatetime, endDatetime } = searchDatetimeParams(
    useLocation().search,
  );

  const params = (): AggregatedReportRequest => {
    if (props.companyId && props.schemeId) {
      return {
        companyId: props.companyId,
        schemeId: props.schemeId,
        aggregations: [
          ...QUALITY_AGGREGATIONS.map((value) => {
            return {
              parameter: "Quality Assessment",
              aggregation: value,
            };
          }),
        ],
        startTime: startDatetime.toDate(),
        endTime: endDatetime.toDate(),
        period: "DAILY",
      };
    } else {
      throw new Error("Scheme or Company is not selected.");
    }
  };

  const [expandedTable, setExpandedTable] = useState(false);

  return (
    <OneCardPage
      headerTitle={"Data Quality Report"}
      breadcrumbs={[
        { url: "/admin/report/data_quality", name: "Data Quality" },
      ]}
      setPageTitle={schemeObjectPageTitle("Data Quality")}
    >
      <RequireScheme>
        <>
          <DateRangeOnlySelectorForm
            {...searchDatetimeParams(useLocation().search)}
          />
          <Formik
            initialValues={{ expanded: false }}
            onSubmit={(x): void => {
              setExpandedTable(x.expanded);
            }}
          >
            {({ submitForm }): React.ReactElement => (
              <Form>
                <ButtonGroupField
                  fieldName={"expanded"}
                  label={{ label: "Expanded" }}
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  onChange={async (): Promise<void> => {
                    await submitForm();
                  }}
                />
              </Form>
            )}
          </Formik>
          {props.companyId && props.schemeId && (
            <AggregatedTimeSeriesApiWrapper request={params()}>
              {({ data }) => (
                <>
                  <Container>
                    <SummaryDataQualityTimeSeriesAreaChart data={data} />

                    {data && (
                      <Col sm={12}>
                        <DataQualityReportTable
                          data={mapData(data)}
                          {...{ startDatetime, endDatetime }}
                          expanded={expandedTable}
                          downloadFilename={`${props.schemeId}_data_quality_report`}
                        />
                      </Col>
                    )}
                  </Container>
                </>
              )}
            </AggregatedTimeSeriesApiWrapper>
          )}
        </>
      </RequireScheme>
    </OneCardPage>
  );
};

export default DataQualityReportByRows;
