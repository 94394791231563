import type React from "react";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";

interface GuruApiIntegrationFormProps {
  namespace: string;
}

export default function GuruApiIntegrationForm(
  props: GuruApiIntegrationFormProps,
): React.ReactElement {
  const withNamespace = (field: string) => {
    return `${props.namespace}.${field}`;
  };
  return (
    <>
      <NewTextFormComponent
        fieldName={withNamespace("apiKey")}
        label={{ label: "API Key" }}
      />
      <NewTextFormComponent
        fieldName={withNamespace("siteId")}
        label={{ label: "Site ID" }}
      />
    </>
  );
}
