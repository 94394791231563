import { type Entities, EntitiesPathMap } from "../constants/entities";

import axios from "axios";
import { useState } from "react";
import Config from "../Config";

export interface DeleteEntity {
  deleteEntity: () => Promise<void>;
  deleting: boolean;
  error?: Error;
}

export interface DeleteEntityProps {
  entityType: Entities;
  entityId: string;
  postDelete?: () => any;
  path?: string;
}

const useDeleteEntity = (
  entityType: Entities,
  entityId: string,
  postDelete?: () => any,
  path?: string,
) => {
  const deleteEntity = (): Promise<void> => {
    updateState((s) => {
      return { ...s, deleting: true };
    });
    return axios
      .delete(
        `${Config.apiGateway.URL}/${path ? path : `${EntitiesPathMap[entityType]}/${entityId}`}`,
      )
      .then(() => {
        postDelete?.();
        updateState((s) => {
          return { ...s, deleting: false, error: undefined };
        });
        return;
      })
      .catch((error) => {
        updateState((s) => {
          return { ...s, deleting: false, error };
        });
        throw error;
      });
  };

  const [state, updateState] = useState<DeleteEntity>({
    deleting: false,
    deleteEntity,
  });

  return state;
};

export default useDeleteEntity;
