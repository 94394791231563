import clsx from "clsx";
import { ErrorMessage, Field, useField } from "formik";
import type React from "react";

interface AuthFieldProps {
  name: string;
  label: string;
  type: string;
}

export default function AuthField(props: AuthFieldProps): React.ReactElement {
  const labelStyles = {
    base: "block form-control-label text-white leading-3 font-light py-2 mt-2",
  };

  const inputStyles = {
    base: "block w-full rounded-lg border-0 py-[7px] sm:text-sm sm:leading-6 ring-1",
    invalid:
      "pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6",
    valid:
      "text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-brand-light-600",
  };

  const { label, name, type } = props;

  const [_, fieldMeta] = useField(name);

  return (
    <>
      <label className={clsx(labelStyles.base)} htmlFor={`login${name}`}>
        {label}
      </label>
      <Field
        aria-describedby="inputGroupPrepend"
        id={`login${name}`}
        type={type}
        name={name}
        className={clsx(
          inputStyles.base,
          fieldMeta.error && fieldMeta.touched
            ? inputStyles.invalid
            : inputStyles.valid,
        )}
      />
      <ErrorMessage
        name={name}
        component="div"
        className="text-zinc-200 my-2 mx-2"
      />
    </>
  );
}
