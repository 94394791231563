import React from "react";
import { Button } from "../../components/Theme/button";
import { Dialog, DialogBody, DialogTitle } from "../../components/Theme/dialog";
import NewTariffServiceForm, {
  type NewTariffServiceFormProps,
} from "./NewTariffServiceForm";

export default function NewTariffServiceButton(
  props: NewTariffServiceFormProps,
): React.ReactElement {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Add Service</DialogTitle>
        <DialogBody>
          <NewTariffServiceForm
            create={(v) => {
              return props.create(v).then(() => setOpen(false));
            }}
          />
        </DialogBody>
      </Dialog>
      <Button color={"brandLight"} onClick={() => setOpen(true)}>
        Add New Service
      </Button>
    </>
  );
}
