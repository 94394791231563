import { isUndefined } from "lodash-es";
import type React from "react";
import type { Asset } from "../../openapi/model/asset";
import CompanyNavLink from "../navigation/CompanyNavLink";

interface Props {
  asset?: Asset;
  assetId?: string;
  full?: boolean;
  placeholder?: boolean;
  text?: string;
}

const AssetIdLink = (props: Props): React.ReactElement => {
  const { asset, assetId, placeholder } = props;
  const actualAssetId = asset ? asset.assetId : assetId;

  const usePlaceholder = isUndefined(actualAssetId) && placeholder;

  if (actualAssetId) {
    if (actualAssetId !== "NONE") {
      const displayId = props.full
        ? actualAssetId
        : `...${actualAssetId.slice(24)}`;
      return (
        <CompanyNavLink to={{ pathname: `/admin/asset/${actualAssetId}` }}>
          {props.text || displayId}
        </CompanyNavLink>
      );
    } else {
      return <>-</>;
    }
  } else if (usePlaceholder) {
    return <CompanyNavLink to={{ pathname: "#" }}>...</CompanyNavLink>;
  }
  throw new Error("One of asset or assetId must be set");
};

export default AssetIdLink;
