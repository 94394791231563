import logo from "assets/img/brand/meterpoint.png";
/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

class AdminNavbar extends React.Component {
  render() {
    return (
      <Link to={{ pathname: "/" }} className={"px-4"}>
        <img alt="Welcome to Meterpoint" src={logo} className={"h-16"} />
      </Link>
    );
  }
}

export default AdminNavbar;
