import type { Company } from "openapi/model/company";
import type React from "react";
import {
  type Option,
  SelectComboField,
} from "../../components/Forms/SelectCombo/SelectCombo";
import { useCompanies } from "../../reducers/company";

interface PProps {
  fieldName: string;
  allowNone?: boolean;
}

type Props = PProps;

const CompanySelectDropdown = (props: Props): React.ReactElement => {
  const { fieldName, allowNone } = props;

  const companies = useCompanies() || [];
  let options: Option[] = companies.map((company: Company) => {
    return {
      value: company.companyId,
      label: company.name,
    };
  });

  options = allowNone
    ? [{ value: "", label: "Select Company" }, ...options]
    : options;

  return (
    <SelectComboField
      label={{ label: "Company" }}
      options={options}
      fieldName={fieldName}
    />
  );
};

export default CompanySelectDropdown;
