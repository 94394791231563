import type React from "react";
import type { PaginatedData } from "../../hooks/getList";
import type { Cursor } from "../../openapi/model/cursor";
import List, { type ListProps } from "../List/List";
import ApiPagination from "./ApiPagination";

export interface ApiPaginatedListProps
  extends Omit<ListProps, "data" | "missingElements"> {
  cursor?: Cursor;
  data?: PaginatedData<any>;
  showPagination?: boolean;
  additionalFunctionButtons?: React.ReactElement;
}

export default function ApiPaginatedList(
  props: ApiPaginatedListProps,
): React.ReactElement {
  const { cursor, data, showPagination, additionalFunctionButtons, ...rest } =
    props;

  const requestedSize = (cursor as Cursor)?.maxItems || 10;
  const missingElements = Math.max(
    requestedSize - (props.data?.data.length || 0),
    0,
  );

  return (
    <>
      <List data={data?.data} missingElements={missingElements} {...rest} />

      {(showPagination === undefined || showPagination) && data && (
        <ApiPagination
          cursor={data.cursor}
          newCursor={data.setCursor}
          refresh={props.refresh}
        />
      )}
      {/*{(props.showDownload === undefined || props.showDownload) &&*/}
      {/*  props.exportToCSV && (*/}
      {/*    <CSVExportButton*/}
      {/*      download={props.exportToCSV}*/}
      {/*      sortColumns={props.exportSortColumns}*/}
      {/*    />*/}
      {/*  )}*/}
      {additionalFunctionButtons}
    </>
  );
}
