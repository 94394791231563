import { isUndefined } from "lodash-es";
import type React from "react";
import AssetIdLink from "../../../../components/Asset/AssetIdLink";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import { Button } from "../../../../components/Theme/button";
import DisplayIfUserType from "../../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import type { Asset } from "../../../../openapi/model/asset";
import type { GatewayAsset } from "../../../../openapi/model/gatewayAsset";
import CreateActionButton from "../../assetActions/CreateActionButton";
import AssetContainer, {
  type OptionalAssetChildProps,
} from "../AssetContainer";
import AssetStateLabel from "../AssetStateLabel";
import GatewayConfigurationDropdown from "../gateway/GatewayConfigurationDropdown";

const SimCardListContent = (props: {
  asset: Asset;
  refresh: () => any;
}): React.ReactElement => {
  if (props.asset.assetType !== "sim_card") {
    throw new Error("Unsupported asset type");
  }
  if (
    props.asset.parentAssetId === undefined ||
    props.asset.parentAssetId === null
  ) {
    return (
      <DisplayIfUserType userTypes={"superadmin"}>
        <CreateActionButton
          asset={props.asset}
          actionType={"CREATE_ELVACO_2100"}
          actionName={"Create New Gateway"}
          onComplete={props.refresh}
        />
      </DisplayIfUserType>
    );
  } else {
    return (
      <AssetContainer
        assetId={props.asset.parentAssetId}
        companyId={props.asset.companyId}
      >
        {({ asset, loading, refresh }: OptionalAssetChildProps) => (
          <BlockSpinner loading={loading}>
            <div>
              <small>
                <span className={"font-weight-bold"}>Assigned Gateway</span>
                <AssetIdLink
                  assetId={asset?.assetId}
                  placeholder={isUndefined(asset)}
                />{" "}
                {asset?.serialNumber || "Serial Number"}
              </small>
            </div>
            <div>
              <AssetStateLabel asset={asset} size={"sm"} />
            </div>
            <div>
              {asset ? (
                <GatewayConfigurationDropdown
                  gateway={asset as GatewayAsset}
                  size={"sm"}
                  refresh={refresh}
                />
              ) : (
                <Button disabled={true} color={"brandLight"}>
                  Configuration
                </Button>
              )}
            </div>
          </BlockSpinner>
        )}
      </AssetContainer>
    );
  }
};
export default SimCardListContent;
