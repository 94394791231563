import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { selectPropertyFromLocationHash } from "../../components/navigation/URL";
import { PrivacyContext } from "../../reducers/privacy";

const PrivacyModeInit = (props: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [init, setInit] = React.useState(false);

  const location = useLocation();
  const { dispatch } = React.useContext(PrivacyContext);

  useEffect(() => {
    const privacyMode = selectPropertyFromLocationHash(
      "privacy",
      location.hash,
    );
    const privacy = privacyMode ? privacyMode.toLowerCase() === "true" : false;
    if (privacy) {
      dispatch({ type: "TURN_PRIVACY_MODE_ON" });
    } else {
      dispatch({ type: "TURN_PRIVACY_MODE_OFF" });
    }
    setInit(true);
  }, [dispatch, location.hash]);

  return <>{init && props.children}</>;
};

export default PrivacyModeInit;
