import { useEffect, useState } from "react";

export function useHiddenOptionsData({
  isMultiline,
  ref,
  selectedOptionsCount,
  isCounterShown,
}: {
  isMultiline: boolean;
  ref: HTMLDivElement | null;
  selectedOptionsCount: number;
  isCounterShown: boolean;
}) {
  const [overflowIndex, setOverflowIndex] = useState(-1);

  // biome-ignore lint/correctness/useExhaustiveDependencies(isCounterShown): ifCounterShown changes we need to recalculate
  useEffect(() => {
    let finalOverflowingIndex = -1;

    if (ref?.children && !isMultiline) {
      const { bottom: parentBottom } = ref.getBoundingClientRect();
      let optionIndex = 0;
      let childIndex = 0;
      while (
        childIndex < ref.children.length &&
        optionIndex < selectedOptionsCount
      ) {
        const child = ref.children[childIndex];

        const { bottom: childBottom } = child.getBoundingClientRect();
        if (childBottom > parentBottom) {
          finalOverflowingIndex = optionIndex;
          break;
        }
        optionIndex++;
        childIndex++;
      }
    }
    console.log(finalOverflowingIndex);

    setOverflowIndex(finalOverflowingIndex);
  }, [ref, isMultiline, selectedOptionsCount, isCounterShown]);

  const hiddenOptionsCount =
    overflowIndex > 0 ? selectedOptionsCount - overflowIndex : 0;
  return { overflowIndex, hiddenOptionsCount };
}
