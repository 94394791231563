import type React from "react";
import type { GatewayAsset } from "../../../../openapi/model/gatewayAsset";

export default function GatewayListContent(props: {
  asset: GatewayAsset;
}): React.ReactElement {
  if (props.asset.assetType !== "gateway") {
    throw new Error("Unsupported asset type");
  }

  return (
    <>
      {props.asset.simCardIccid && <>Sim Iccid: {props.asset.simCardIccid}</>}
    </>
  );
}
