import { useEffect, useState } from "react";
import tailwindConfig from "tailwind-config";
import resolveConfig from "tailwindcss/resolveConfig";

const fullConfig = resolveConfig(tailwindConfig);

const screens = fullConfig.theme.screens;
export default function useScreenBreakpoint(
  query: keyof typeof screens,
): boolean {
  const mediaQuery = `(min-width: ${screens[query]})`;
  const matchQueryList = window.matchMedia(mediaQuery);
  const [isMatch, setMatch] = useState<boolean>(false);
  const onChange = (e: MediaQueryListEvent) => setMatch(e.matches);
  // biome-ignore lint/correctness/useExhaustiveDependencies(onChange): this changes every rerender so we dont want it in here
  useEffect(() => {
    setMatch(matchQueryList.matches);
    matchQueryList.addEventListener("change", onChange);
    return () => matchQueryList.removeEventListener("change", onChange);
  }, [matchQueryList]);
  return isMatch;
}
