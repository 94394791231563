import type { ParseResult } from "papaparse";
import type { TableRow } from "react-data-table-component";
import type * as yup from "yup";
import type { ValidationError } from "yup";
import { unFlattenData } from "../../../../components/BulkUpdate/BulkUpdate";

export interface UploadDefinition<T> extends TableRow {
  success: boolean;
  original: any;
  errors?: SimpleParseError[];
  object?: T;
}
interface SimpleParseError {
  field?: string;
  error?: string;
}

export function convertCSVToDefinitions<I>(
  data: Promise<ParseResult<any>>,
  schema: yup.ObjectSchema<any>,
): Promise<UploadDefinition<I>[]> {
  return data.then((parseData) => {
    return Promise.all(
      unFlattenData(parseData.data).map((item) => {
        return schema
          .validate(item, { abortEarly: false })
          .then((parsed) => {
            return {
              success: true,
              original: item,
              object: parsed as I,
            };
          })
          .catch((err: ValidationError) => {
            return {
              success: false,
              original: item,
              errors: err.inner.map((innerErr) => {
                return { field: innerErr.path, error: innerErr.message };
              }),
            };
          });
      }),
    );
  });
}
