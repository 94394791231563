import type React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";
import { EnergyUnits } from "../../../openapi/model/energyUnits";

const EnergyUnitSelect = (
  props: Omit<SelectComboFieldProps, "options">,
): React.ReactElement => {
  const options: Option[] = [
    { label: "kWh", value: EnergyUnits.KWh },
    { label: "Wh", value: EnergyUnits.Wh },
    { label: "MWh", value: EnergyUnits.MWh },
  ];

  return <SelectComboField {...props} options={options} />;
};

export default EnergyUnitSelect;
