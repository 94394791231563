import type React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  convertStringUndefined,
  createSearchString,
  parseSearchString,
} from "../../../components/navigation/SearchString";
import { removeMapBlanks } from "../../../utils/object";
import type { LatestDataReportParams } from "./model";

interface LatestDataReportQueryUrlWrapperProps {
  defaultValues: LatestDataReportParams;
  children: (
    params: LatestDataReportParams,
    onChange: (v: LatestDataReportParams) => void,
  ) => React.ReactNode;
}

function LatestDataReportQueryUrlWrapper(
  props: LatestDataReportQueryUrlWrapperProps,
) {
  const navigate = useNavigate();
  const location = useLocation();

  const [init, setInit] = useState<boolean>(false);

  const searchParams = parseSearchString(location.search);
  const currentParams: Partial<LatestDataReportParams> = {
    group: (searchParams.group as string) || undefined,
  };

  const setLocationWithParams = (values: LatestDataReportParams): void => {
    navigate({
      search: createSearchString(convertStringUndefined(values)),
      hash: location.hash,
    });
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    setLocationWithParams({
      ...removeMapBlanks(currentParams),
      ...props.defaultValues,
    });
    setInit(true);
  }, []);

  return (
    <>
      {init &&
        props.children(
          { ...props.defaultValues, ...removeMapBlanks(currentParams) },
          setLocationWithParams,
        )}
    </>
  );
}

export default LatestDataReportQueryUrlWrapper;
