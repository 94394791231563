import { isString } from "lodash-es";
import type { Location, NavigateFunction } from "react-router-dom";
import type { Company } from "../../openapi/model/company";
import type { Scheme } from "../../openapi/model/scheme";

export const addCompanyAndSchemeToUrl = (
  location: Location,
  navigate: NavigateFunction,
  selectedCompany?: Company,
  selectedScheme?: Scheme,
  privacyMode?: boolean,
): void => {
  const newHash = getUrlHash(selectedCompany, selectedScheme, privacyMode);
  if (location.hash !== newHash) {
    console.log("update hash", newHash);
    navigate({
      ...location,
      hash: newHash,
    });
  }
};

export const getUrlHash = (
  selectedCompany?: Company | string,
  selectedScheme?: Scheme | string,
  privacyMode = false,
): string => {
  const companyHash = selectedCompany
    ? `company=${
        isString(selectedCompany) ? selectedCompany : selectedCompany.companyId
      }`
    : "company=NONE";
  const schemeHash = selectedScheme
    ? `scheme=${
        isString(selectedScheme) ? selectedScheme : selectedScheme.schemeId
      }`
    : "";
  let hash: string;
  if (companyHash !== "" && schemeHash !== "") {
    hash = [companyHash, schemeHash].join("&");
  } else {
    if (companyHash !== "") {
      hash = companyHash;
    } else {
      hash = schemeHash;
    }
  }
  if (privacyMode) {
    const privacyModeHash = "privacy=true";

    if (hash) {
      hash = [hash, privacyModeHash].join("&");
    } else {
      hash = privacyModeHash;
    }
  }
  return `#${hash}`;
};

export function parseHash(hash: string): { [key: string]: string } {
  return Object.fromEntries(
    hash
      .substring(1)
      .split("&")
      .map((v) => {
        return v.split("=");
      }),
  );
}

export const selectPropertyFromLocationHash = (
  param: string,
  hash: string | undefined,
): string | undefined => {
  if (hash) {
    const params = parseHash(hash);
    if (params[param]) {
      if (params[param] === "undefined" || params[param] === "null") {
        return undefined;
      }
      return params[param];
    }
  }
  return undefined;
};
