import type React from "react";

import DataTable, {
  type TableProps,
  type TableColumn,
} from "react-data-table-component";
import { usePrivacyMode } from "../../reducers/privacy";

export interface PrivacyModeTableColumn<T> extends TableColumn<T> {
  privacyMode?: boolean;
}

export interface PrivacyModeTableProps<T> extends TableProps<T> {
  columns: PrivacyModeTableColumn<T>[];
}

function addPrivacyModeStyleToColumn<T>(
  col: PrivacyModeTableColumn<T>,
): TableColumn<T> {
  if (col.privacyMode) {
    return {
      ...col,
      style: {
        ...col.style,
        color: "transparent",
        textShadow: "0 0 10px rgba(0,0,0,0.5)",
      },
    };
  }
  return col;
}

export default function PrivacyModeDataTable<T>(
  props: PrivacyModeTableProps<T>,
): React.ReactElement {
  const privacyMode = usePrivacyMode();

  const { columns, ...rest } = props;

  return (
    <div>
      <DataTable
        {...rest}
        columns={
          privacyMode ? columns.map(addPrivacyModeStyleToColumn) : columns
        }
      />
    </div>
  );
}
