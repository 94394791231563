import type { Dayjs } from "dayjs";
import { Form, Formik, type FormikHelpers } from "formik";
import type { Asset } from "openapi/model/asset";
import type React from "react";
import * as Yup from "yup";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import { SelectComboField } from "../../../components/Forms/SelectCombo/SelectCombo";
import { Button } from "../../../components/Theme/button";
import useCreateEntity from "../../../hooks/createEntity";
import { AssetClass } from "../../../openapi/model/assetClass";
import type { AssetPosition } from "../../../openapi/model/assetPosition";
import type { AssetType } from "../../../openapi/model/assetType";
import type { NewAsset } from "../../../openapi/model/newAsset";
import AssetTypeSelect from "../assets/AssetTypeSelect";
import AssetPositionAssetLinkDateSelect from "./assetPositionAssetLinks/AssetPositionAssetLinkDateSelect";

interface FormValues {
  serialNumber: string;
  manufacturer: string;
  assetType: AssetType;
  installDate?: Dayjs;
  uninstallDate?: Dayjs;
}

interface Props {
  assetPosition: AssetPosition;
  refresh?: () => void;
}

export default function CreateAssetForm(props: Props): React.ReactElement {
  const { createEntity } = useCreateEntity<Asset, NewAsset>("ASSET");
  const isGateway = props.assetPosition.assetPositionType === "gateway";

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);

    createEntity({
      serialNumber: values.serialNumber,
      manufacturer: isGateway ? values.manufacturer : undefined,
      assetClass: isGateway
        ? values.manufacturer === "evinox"
          ? AssetClass.Evinox
          : AssetClass.Mbus
        : AssetClass.Mbus,
      companyId: props.assetPosition.companyId,
      schemeId: props.assetPosition.schemeId,
      assetType: props.assetPosition.assetPositionType,
      currentLink: {
        assetPositionId: props.assetPosition.assetPositionId,
        installDate: values.installDate?.toDate(),
        uninstallDate: values.uninstallDate?.toDate(),
      },
    })
      .then(() => {
        props.refresh?.();
        resetForm();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    serialNumber: "",
    manufacturer: isGateway ? "elvaco" : "",
    assetType: props.assetPosition.assetPositionType,
  };

  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        serialNumber: Yup.string().required("Required"),
        manufacturer: isGateway
          ? Yup.string().required()
          : Yup.string().notRequired().max(0),
      })}
      validateOnMount={true}
    >
      {({ isSubmitting, isValid }) => (
        <Form data-cy={"create-asset-form"}>
          <>
            <NewTextFormComponent
              fieldName={"serialNumber"}
              label={{ label: "Serial Number" }}
            />
            <AssetTypeSelect
              fieldName={"assetType"}
              label={{ label: "AssetType" }}
              disabled={true}
            />
            {isGateway && (
              <SelectComboField
                fieldName={"manufacturer"}
                options={[
                  { label: "Elvaco", value: "elvaco" },
                  {
                    label: "Evinox",
                    value: "evinox",
                  },
                  { label: "Lansen", value: "lansen" },
                ]}
                label={{ label: "Manufacturer" }}
              />
            )}
            <h4>Dates</h4>
            <AssetPositionAssetLinkDateSelect newLink={true} />

            <Button
              type={"submit"}
              color={"brandLight"}
              disabled={isSubmitting || !isValid}
            >
              Add Asset
            </Button>
          </>
        </Form>
      )}
    </Formik>
  );
}
