import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import clsx from "clsx";
import type React from "react";
import { Link, type Path, type To } from "react-router-dom";
import { usePrivacyMode } from "reducers/privacy";
import { useSelectedCompany } from "../../reducers/company";
import { useSelectedScheme } from "../../reducers/scheme";
import { Button, type ButtonProps } from "../Theme/button";
import { getUrlHash } from "./URL";

interface Props {
  to: Partial<Path>;
  children: React.ReactNode;
  dataCy?: string;
  className?: string;
  onClick?: React.MouseEventHandler<any>;
  external?: boolean;
  inline?: boolean;
  colorClassName?: string;
  retainCompany?: boolean;
  button?: ButtonProps;
}

function CompanyNavLink(props: Props): React.ReactNode {
  const company = useSelectedCompany();
  const scheme = useSelectedScheme();
  const privacyMode = usePrivacyMode();

  const retainCompany = props.retainCompany || true;

  const to: To = {
    ...props.to,
    hash: retainCompany ? getUrlHash(company, scheme, privacyMode) : "",
  };

  const { colorClassName = "text-brand-light-600", inline = true } = props;

  const adjustProps = {
    to: to,
    onClick: props.onClick,
  };

  return (
    <>
      <Link {...adjustProps} data-cy={props.dataCy}>
        {props.button ? (
          <Button {...props.button}>{props.children}</Button>
        ) : (
          <span
            className={clsx(
              props.className,
              colorClassName,
              "px-0",
              inline && "inline-block",
              privacyMode && "blurry-text",
            )}
          >
            {props.children}
          </span>
        )}
      </Link>
      {props.external && (
        <Link
          to={to}
          className={classNames(props.className, "mx-1", {
            "d-inline-block": props.inline,
            "blurry-text": privacyMode,
          })}
          target={"_blank"}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </Link>
      )}
    </>
  );
}

export default CompanyNavLink;
