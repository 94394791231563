import React from "react";
import GetList, {
  type PaginatedListResultProps,
} from "../../../core/action/GetList";
import type { ReportExecutionOut } from "../../../openapi/model/reportExecutionOut";
import { useSelectedCompany } from "../../../reducers/company";
import { useSelectedScheme } from "../../../reducers/scheme";
import ReportExecutionList from "./ReportExecutionList";

interface ReportExecutionListContainerProps {
  jobId?: string;
}

function ReportExecutionListContainer(
  props: ReportExecutionListContainerProps,
) {
  const company = useSelectedCompany();
  const scheme = useSelectedScheme();

  return (
    <GetList
      entityType={"REPORT_EXECUTION"}
      params={{
        companyId: company?.companyId,
        schemeId: scheme?.schemeId,
        jobId: props.jobId,
      }}
      cursor={{ maxItems: 25 }}
    >
      {(data: PaginatedListResultProps<ReportExecutionOut>) => (
        <>
          {data ? (
            <ReportExecutionList list={data} showPagination={true} />
          ) : (
            <></>
          )}
        </>
      )}
    </GetList>
  );
}

export default ReportExecutionListContainer;
