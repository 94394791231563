import type React from "react";
import type { BulkUpdateExecutionStats } from "../../openapi/model/bulkUpdateExecutionStats";

interface Props {
  executionStats?: BulkUpdateExecutionStats;
}

const BulkUpdateProgressBar = (props: Props): React.ReactElement => {
  const { executionStats } = { ...props };

  if (executionStats) {
    const completed =
      (executionStats.created || 0) + (executionStats.updated || 0);
    const totalToDo =
      (executionStats.objects || 0) - (executionStats.noOp || 0);
    const failed = executionStats.failed || 0;

    return (
      <div>
        <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
          {completed > 0 && (
            <div
              className="bg-success text-xs font-medium text-green-100 text-center p-0.5 leading-none rounded-full"
              style={{ width: `${(completed / totalToDo) * 100}%` }}
            >
              {" "}
              {completed}
            </div>
          )}
          {failed > 0 && (
            <div
              className="bg-danger text-xs font-medium text-red-100 text-center p-0.5 leading-none rounded-full"
              style={{ width: `${(failed / totalToDo) * 100}%` }}
            >
              {" "}
              {failed}
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default BulkUpdateProgressBar;
