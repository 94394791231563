import type React from "react";
import useSetPageTitle, {
  type SetTitleFunction,
} from "../../hooks/setPageTitle";
import Breadcrumbs, { type BreadcrumbsProps } from "../Layout/Breadcrumbs";
import BlockSpinner from "../Spinners/BlockSpinner";
import { PageHeadingBlock } from "../Theme/heading";

interface Props extends Partial<BreadcrumbsProps> {
  headerTitle: string;
  children?: any;
  loading?: boolean;
  "data-cy"?: string;
  setPageTitle?: SetTitleFunction;
}

export function OneCardPage(props: Props): React.ReactElement {
  useSetPageTitle({ setPageTitle: props.setPageTitle });
  return (
    <div>
      {props.breadcrumbs && (
        <Breadcrumbs
          breadcrumbs={props.breadcrumbs}
          showSchemeInBreadcrumbs={props.showSchemeInBreadcrumbs}
          showCompanyInBreadcrumbs={props.showCompanyInBreadcrumbs}
        />
      )}
      <PageHeadingBlock className={"pb-4"}>
        {props.headerTitle}
      </PageHeadingBlock>
      <div data-cy={props["data-cy"]}>
        <BlockSpinner loading={props.loading || false}>
          {props.children}
        </BlockSpinner>
      </div>
    </div>
  );
}
