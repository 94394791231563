import { startCase } from "lodash-es";
import React from "react";
import AssetPositionIdLink from "../../../components/AssetPosition/AssetPositionIdLink";
import AssetPositionNumbersToStringList from "../../../components/AssetPosition/AssetPositionNumberSummaryList";
import FormatTextAtSize from "../../../components/Text/FormatTextAtSize";
import { StackedListThreeColumnItem } from "../../../components/Theme/stacked-list";
import type { AssetRegisterItem } from "../../../openapi/model/assetRegisterItem";

function IntegrationListRow(props: {
  showScheme?: boolean;
  assetRegisterItem: AssetRegisterItem;
  refresh?: () => void;
}) {
  const { assetRegisterItem } = props;
  const { assetPosition } = assetRegisterItem;

  return (
    <StackedListThreeColumnItem
      key={assetPosition.assetPositionId}
      column1={
        <div className="min-w-0 flex-auto">
          <AssetPositionIdLink
            assetPosition={assetRegisterItem.assetPosition}
            text={assetRegisterItem.location.address}
          />
        </div>
      }
      column2={
        <div className="min-w-0 flex-auto">
          <FormatTextAtSize
            breakpoint={"sm"}
            smallClassName={"text-xs"}
            smallBrackets={true}
          >
            {startCase(
              assetRegisterItem.assetPosition.configuration?.integration
                ?.integrationType,
            )}
          </FormatTextAtSize>
        </div>
      }
      column3={
        <div className="min-w-0 flex-auto text-xs md:text-base">
          <AssetPositionNumbersToStringList
            numbers={assetRegisterItem.assetPosition.childAssetPositions}
            includeGateways={false}
          />
        </div>
      }
      column1Size={"w-5/12"}
      column2Size={"w-3/12"}
      column3Size={"w-4/12"}
    />
  );
}

export default IntegrationListRow;
