import { API } from "aws-amplify";
import axios from "axios";
import { getAuthHeader } from "./auth";

export const loadFileFromApi = async (url: string): Promise<void> => {
  return API.get("management", url, await _getHeaders()).then(
    (data: string) => {
      window.open(data.replace(/['"]+/g, ""), "_blank");
    },
  );
};

export const loadFileFromApiToMemory = async (url: string): Promise<any> => {
  return API.get("management", url, await _getHeaders()).then(
    (data: string) => {
      const a = axios.create();
      return a.get(data.replace(/['"]+/g, ""), { responseType: "blob" });
    },
  );
};

const _getHeaders = async (): Promise<any> => {
  return {
    headers: await getAuthHeader(),
    responseType: "text",
  };
};
