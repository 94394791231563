import { ErrorBanner } from "components/Error/ErrorBanner";
import { Form, Formik, type FormikHelpers } from "formik";
import type { Location } from "openapi/model/location";
import type React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import { Button } from "../../../components/Theme/button";
import useCreateEntity from "../../../hooks/createEntity";
import { locationSchema } from "../../../model/location";

interface ComponentProps {
  companyId: string;
  schemeId: string;
}

interface FormValues {
  schemeId: string;
  address: string;
}

type Props = ComponentProps;

const NewLocationForm = ({ companyId, schemeId }: Props) => {
  const navigate = useNavigate();
  const { createEntity, error } = useCreateEntity<Location>("LOCATION");

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);

    createEntity({ ...values, companyId, schemeId })
      .then(([location]) => {
        resetForm();
        navigate(`/admin/location/${location.locationId}`);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    address: "",
    schemeId: "",
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={Yup.object().shape(locationSchema)}
      >
        {({ isValid, isSubmitting }): React.ReactElement => (
          <>
            <Form>
              <NewTextFormComponent
                fieldName={"address"}
                label={{ label: "Address" }}
              />
              <Button
                type="submit"
                color="brandLight"
                disabled={isSubmitting || !isValid}
              >
                Create
              </Button>
            </Form>
            <ErrorBanner error={error} />
          </>
        )}
      </Formik>
    </>
  );
};

export default NewLocationForm;
