import type { FormikProps } from "formik";
import { type RefObject, useEffect } from "react";
import { useSelectedScheme } from "../reducers/scheme";

export default function useSetFormValueOnSchemeChange<
  T = Record<string, unknown>,
>(
  formikRef: RefObject<FormikProps<T>>,
  fieldName: string,
  resetValue: string,
  submit = false,
) {
  const selectedScheme = useSelectedScheme();
  // biome-ignore lint/correctness/useExhaustiveDependencies(selectedScheme): want the trigger to occur when the selectedScheme changes
  useEffect(() => {
    if (formikRef) {
      formikRef.current?.setFieldValue(fieldName, resetValue);
      if (submit) {
        formikRef.current?.submitForm();
      }
    }
  }, [selectedScheme, fieldName, formikRef, resetValue, submit]);
}
