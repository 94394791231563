import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Theme/button";
import type React from "react";

interface EditButtonProps {
  text?: string;
  icon?: IconProp;
  onClick: () => any;
}

const EditButton = (props: EditButtonProps): React.ReactElement => {
  const { text, icon = faPen, ...rest } = props;
  return (
    <Button {...rest} color={"brandLight"}>
      {icon && <FontAwesomeIcon icon={icon} fixedWidth={true} />}
      {text}
    </Button>
  );
};

export default EditButton;
