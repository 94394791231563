import type { PieChartDataItem } from "openapi/model/pieChartDataItem";
import type React from "react";
import useGetData from "../../../hooks/getData";

interface Props {
  companyId: string;
  schemeId?: string;
  group?: string;
  render: PieChartDataComponent;
}

export type PieChartDataComponent = (props: {
  data?: Array<PieChartDataItem>;
  loading?: boolean;
  error?: Error | null;
}) => React.ReactElement;

const AssetLatestTimeSeriesStatsGetter = (props: Props): React.ReactElement => {
  const { companyId, schemeId, render } = props;
  const [{ data, loading, error }] = useGetData<Array<PieChartDataItem>>({
    path: "report/time_series/latest/stats",
    companyId,
    schemeId,
    params: { group: props.group || "meter" },
    singletonApi: true,
  });

  return <>{render({ data, loading, error })}</>;
};

export default AssetLatestTimeSeriesStatsGetter;
