import { Badge, type colors } from "components/Theme/badge";
import { startCase } from "lodash-es";
import type React from "react";
import { ConfigurationState } from "../../../../openapi/model/configurationState";
import { GatewayConfigurationState } from "../../../../openapi/model/gatewayConfigurationState";

const getColor = (
  state?: ConfigurationState | GatewayConfigurationState,
): keyof typeof colors => {
  if (state) {
    switch (state) {
      case ConfigurationState.INSYNC:
        return "success";
      case ConfigurationState.OUTOFSYNC:
        return "danger";
      case ConfigurationState.ERRORUPDATINGCONFIGURATION:
        return "danger";
      case GatewayConfigurationState.ERRORUPDATINGDEVICELIST:
        return "danger";
      case GatewayConfigurationState.ERRORUPGRADINGFIRMWARE:
        return "danger";
      default:
        return "warning";
    }
  }
  return "teal";
};

const getShortText = (
  state?: ConfigurationState | GatewayConfigurationState,
): string => {
  if (state) {
    switch (state) {
      case ConfigurationState.INSYNC:
        return "In Sync";
      case ConfigurationState.OUTOFSYNC:
        return "Out of Sync";
      case ConfigurationState.UNKNOWN:
        return "Unknown";
      case ConfigurationState.UPDATINGCONFIGURATION:
        return "Updating";
      case ConfigurationState.UPDATINGDEVICELIST:
        return "Updating";
      case GatewayConfigurationState.MBUSSEARCH:
        return "MBus Search";
      case "UPGRADING_FIRMWARE":
        return "Upgrading";
      default:
        return "Error";
    }
  }
  return "Unknown";
};

export default function GatewayStateLabel(
  props: {
    short?: boolean;
    state?: ConfigurationState | GatewayConfigurationState;
    size?: "sm" | "md" | "lg";
  } & Omit<React.HTMLAttributes<HTMLSpanElement>, "color">,
): React.ReactElement {
  const { size = "lg", state, short = false, ...rest } = props;

  const text = short
    ? getShortText(state)
    : state
      ? startCase(state)
      : "Unknown";

  return (
    <span className={`text-${size}`}>
      <Badge color={getColor(state)} {...rest}>
        {text}
      </Badge>
    </span>
  );
}
