import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import type React from "react";
import {
  AssetPositionRow,
  AssetRow,
  DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
  LocationRow,
} from "../../../components/Table/AssetRegisterData";
import type { AssetRegisterAggregatedTimeSeriesResponse } from "../../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";

import { startCase } from "lodash-es";
import numberFormatter from "../../../components/Table/Formatters";
import type { BillingData } from "../../../components/Table/model";
import PrivacyModeDataTable, {
  type PrivacyModeTableColumn,
} from "../../../components/Text/PrivacyModeDataTableCell";
import CSVExportButton from "../../../components/utils/CSVExport/CSVExportButton";
import {
  assetPositionSortValue,
  locationSortValue,
} from "../allPeriodReportTable/AssetRegisterReportTable";
import type { BillingReportRequestParams } from "./BillingReportSelectorForm";

dayjs.extend(utc);
dayjs.extend(timezone);

const dateToString = (
  date: Date,
  dateFormat?: string,
  timezone?: string,
): string => {
  const m = dayjs(date).tz(timezone ? timezone : "UTC");
  return dateFormat ? m.format(dateFormat) : m.format();
};

const mapBillingData = (
  data: AssetRegisterAggregatedTimeSeriesResponse,
  query: Required<BillingReportRequestParams>,
  dateFormat?: string,
  timezone?: string,
): Array<BillingData> => {
  return data.data.map((assetData) => {
    const { aggregations, ...asset } = assetData;

    switch (asset.assetType) {
      case "heat_meter":
      case "heating_and_cooling_meter":
        return {
          ...asset,
          billableType: "Energy (Heating)",
          billableUnit: query.configuration.energy?.units || "kWh",
          ...Object.fromEntries(
            data.times.map((e, i) => {
              return [
                dateToString(e, dateFormat, timezone),
                aggregations.energyHeating?.last?.[i],
              ];
            }),
          ),
        };
      case "cooling_meter":
        return {
          ...asset,
          billableType: "Energy (Cooling)",
          billableUnit: query.configuration.energy?.units || "kWh",
          ...Object.fromEntries(
            data.times.map((e, i) => {
              return [
                dateToString(e, dateFormat, timezone),
                aggregations.energyCooling?.last?.[i],
              ];
            }),
          ),
        };
      case "electricity_meter":
        return {
          ...asset,
          billableType: "Energy (Electrical Active Import)",
          billableUnit: query.configuration.energy?.units || "kWh",
          ...Object.fromEntries(
            data.times.map((e, i) => {
              return [
                dateToString(e, dateFormat, timezone),
                aggregations.energyElectricalActiveImport?.last?.[i],
              ];
            }),
          ),
        };
      default:
        return {
          ...asset,
          billableType: "volume",
          billableUnit: query.configuration.volume?.units || "m3",
          ...Object.fromEntries(
            data.times.map((e, i) => {
              return [
                dateToString(e, dateFormat, timezone),
                aggregations.volume?.last?.[i],
              ];
            }),
          ),
        };
    }
  });
};

const createCSV = (
  data: AssetRegisterAggregatedTimeSeriesResponse,
  query: Required<BillingReportRequestParams>,
): (() => Promise<any>) => {
  return () => {
    return new Promise<any>((resolve) =>
      resolve(mapBillingData(data, query, "DD/MM/YYYY", "Europe/London")),
    );
  };
};

const BillingReportView = (props: {
  schemeId: string;
  data: AssetRegisterAggregatedTimeSeriesResponse;
  query: Required<BillingReportRequestParams>;
}): React.ReactElement => {
  const billingData = mapBillingData(props.data, props.query);

  const periodLabel = props.query.configuration.periodLabel;
  const period = props.query.period;

  const mappedPeriod = {
    DAILY: "day",
    WEEKLY: "week",
    MONTHLY: "month",
    SEVEN_DAY: "seven day period",
    ALL: "all",
  }[period];

  const columns: PrivacyModeTableColumn<BillingData>[] = [
    {
      name: "Location",
      selector: locationSortValue,
      cell: LocationRow(props.query.startTime, props.query.endTime),
      sortable: true,
      width: "250px",
      compact: true,
      privacyMode: true,
    },
    {
      name: "Asset Position",
      selector: assetPositionSortValue,
      cell: AssetPositionRow(props.query.startTime, props.query.endTime),
      sortable: true,
      width: "200px",
      compact: true,
      privacyMode: true,
    },
    {
      name: "Asset Type",
      selector: (row: BillingData) => row.assetType,
      cell: (row: BillingData) => startCase(row.assetType),
      sortable: true,
      compact: true,
      width: "120px",
    },
    {
      name: "Asset",
      selector: (row: BillingData) => row.serialNumber || "-",
      sortable: true,
      cell: AssetRow(props.query.startTime, props.query.endTime, false, true),
      width: "150px",
      compact: true,
      privacyMode: true,
    },
    {
      name: "Type",
      selector: (row: BillingData) => row.billableType,
      width: "150px",
      compact: true,
    },
    {
      name: "Units",
      selector: (row: BillingData) => row.billableUnit,
      width: "75px",
      compact: true,
    },
    ...props.data.times.map((value) => {
      return {
        name: dayjs(value).format("DD/MM/YYYY"),
        selector: (row: BillingData) =>
          row[dayjs(value).format()] as string | number,
        right: true,
        sortable: true,
        width: "100px",
        compact: true,
        format: numberFormatter(dayjs(value).format()),
      };
    }),
  ];

  return (
    <>
      <div className={"w-full text-right"}>
        <CSVExportButton
          download={createCSV(props.data, props.query)}
          data={billingData}
          columnOrder={[
            ...DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
            "billableType",
            "billableUnit",
            ...props.data.times.map((value) =>
              dayjs(value).format("DD/MM/YYYY"),
            ),
          ]}
          filename={`${props.schemeId}_billing_report_${dateToString(
            props.data.times[0],
            "YYYY-MM-DD",
            "Europe/London",
          )}_${dateToString(
            props.data.times[props.data.times.length - 1],
            "YYYY-MM-DD",
            "Europe/London",
          )}`}
        />
      </div>
      {period !== "DAILY" &&
        (periodLabel === "left"
          ? `Last reading for ${mappedPeriod} beginning on`
          : `Last reading for ${mappedPeriod} ending on`)}
      <PrivacyModeDataTable
        columns={columns}
        data={billingData}
        pagination
        fixedHeader
        noHeader={true}
      />
    </>
  );
};

export default BillingReportView;
