import type React from "react";
import { useEffect } from "react";
import AssetTypeHumanLabel from "../../../components/Asset/AssetTypeHumanLabel";
import AssetUseCaseHumanLabel from "../../../components/Asset/AssetUseCaseHumanLabel";
import HeadingEditorForm from "../../../components/Forms/HeadingEditorForm";
import ObjectPropertyItem from "../../../components/Headers/ObjectPropertyItem";
import AssetRegisterStatusLabel from "../../../components/Labels/AssetRegisterStatusLabel";
import ObjectDetailHeading from "../../../components/Layout/ObjectDetailHeading";
import CopyToClipboard from "../../../components/utils/CopyToClipboard";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates";
import useUpdateEntity from "../../../hooks/updateEntity";
import { assetPositionSchema } from "../../../model/assetPosition";
import type { Asset } from "../../../openapi/model/asset";
import type { AssetPosition } from "../../../openapi/model/assetPosition";
import { AssetType } from "../../../openapi/model/assetType";
import type { Location } from "../../../openapi/model/location";
import { Status } from "../../../openapi/model/status";
import AssetTypeChangeForm from "../assets/AssetTypeChangeForm";
import GatewayLink from "../assets/gateway/GatewayLink";

interface Props {
  assetPosition: AssetPosition;
  asset?: Asset;
  location: Location;
  idLink?: boolean;
  refresh?: () => any;
}

export default function AssetPositionPreview(props: Props): React.ReactElement {
  const { assetPosition, asset, location } = props;

  const assigned =
    assetPosition.assetId !== undefined && assetPosition.assetId !== null;
  const deleted = assetPosition.status === Status.DELETED;
  const isGateway = assetPosition.assetPositionType === "gateway";
  const isWirelessCluster =
    assetPosition.assetPositionType === "wireless_cluster";
  const hasUseCase =
    assetPosition.assetPositionType &&
    [
      AssetType.CoolingMeter,
      AssetType.HeatMeter,
      AssetType.HeatingAndCoolingMeter,
    ].indexOf(assetPosition.assetPositionType) >= 0;

  const updateEntity = useUpdateEntity<AssetPosition>(
    "ASSET_POSITION",
    assetPosition.assetPositionId,
    props.refresh,
  );

  const [openModal, modal] = AssetTypeChangeForm(
    assetPosition,
    updateEntity,
    "Asset Position",
    true,
  );

  return (
    <>
      {modal}
      <ObjectDetailHeading
        leftColumn={{
          content: (
            <>
              <ObjectPropertyItem
                title={"Location Address"}
                type={"heading"}
                to={{ pathname: `/admin/location/${location?.locationId}` }}
              >
                {location?.address}
              </ObjectPropertyItem>

              {!isWirelessCluster && (
                <>
                  <HeadingEditorForm
                    update={updateEntity}
                    label={"Position Reference"}
                    values={assetPosition}
                    fieldName={"customerReference"}
                    title={"Reference"}
                    defaultValue={"No Position Reference"}
                    validator={assetPositionSchema.customerReference}
                    editActionUserType={"editor"}
                  />
                  <ObjectPropertyItem
                    title={"Current Asset Serial Number"}
                    type={"heading"}
                  >
                    {assigned ? (
                      asset && (
                        <CopyToClipboard
                          value={asset.serialNumber}
                          link={`/admin/asset/${asset.assetId}`}
                          className={"d-inline px-0"}
                          privacyMode={true}
                        />
                      )
                    ) : (
                      <span className={"text-warning"}>No asset assigned</span>
                    )}
                  </ObjectPropertyItem>
                </>
              )}
            </>
          ),
        }}
        rightColumn={{
          content: (
            <>
              {deleted && (
                <ObjectPropertyItem title={"Status"}>
                  <AssetRegisterStatusLabel status={assetPosition.status} />
                </ObjectPropertyItem>
              )}

              {!isWirelessCluster && (
                <ObjectPropertyItem
                  title={"Type"}
                  editActionUserType={"superadmin"}
                  editAction={openModal}
                >
                  {assetPosition && (
                    <span>
                      <AssetTypeHumanLabel asset={assetPosition} />
                    </span>
                  )}
                </ObjectPropertyItem>
              )}
              {hasUseCase && (
                <ObjectPropertyItem
                  title={"Use Case"}
                  editActionUserType={"admin"}
                  editAction={openModal}
                >
                  <AssetUseCaseHumanLabel
                    asset={assetPosition}
                    asBadge={false}
                  />
                </ObjectPropertyItem>
              )}

              {!isGateway && !isWirelessCluster && (
                <GatewayLink deviceAssetPosition={assetPosition} />
              )}

              <ObjectPropertyItem title={"Asset Position ID"}>
                <CopyToClipboard
                  value={assetPosition?.assetPositionId}
                  slice={8}
                />
              </ObjectPropertyItem>

              <ObjectPropertyItem title={"Created"}>
                {formatDate(assetPosition?.createdAt, undefined, false)}
              </ObjectPropertyItem>
              <ObjectPropertyItem title={"Updated"}>
                {formatDate(assetPosition?.lastUpdated, undefined, false)}
              </ObjectPropertyItem>
            </>
          ),
        }}
      />
    </>
  );
}
