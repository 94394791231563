import type { Dayjs } from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import type React from "react";
import type { TimeSeriesDataParams } from "../../model/deviceTimeSeries";

import { usePrivacyMode } from "reducers/privacy";
import HighchartsPrivacyModeFilter from "../Text/HighchartsPrivacyModeFilter";

import nodata from "highcharts/modules/no-data-to-display.js";
import { defaultChartColor } from "./ChartColors";
nodata(Highcharts);

declare global {
  interface Window {
    dayjs: any;
  }
}

export type DataPoint = [Dayjs, number | undefined];

function round(num?: number, decimalPlaces?: number) {
  if (decimalPlaces === undefined) {
    return num;
  } else if (num === undefined) {
    return "-";
  } else {
    const p = 10 ** decimalPlaces;
    const n = num * p * (1 + Number.EPSILON);
    return Math.round(n) / p;
  }
}

export default function SimpleLineChart(props: {
  data?: DataPoint[];
  range?: [Dayjs, number, number][];
  multipleData?: [string, DataPoint[], string?, string?][];
  unit?: string;
  name?: string;
  params?: TimeSeriesDataParams;
  type?: "line" | "area" | "areaspline";
  rounding?: number;
  displayExport?: boolean;
  yMin?: number;
  yMax?: number;
  clickEvent?: (e: any) => void;
  legend?: boolean;
  loading: boolean;
  privacyModeEnabled?: boolean;
}): React.ReactElement {
  const privacyMode = usePrivacyMode() && props.privacyModeEnabled;
  if (props.data && props.multipleData) {
    throw new Error("Can not define data and multiple data");
  } else if (!props.data && !props.multipleData) {
    throw new Error("At least one of data or multiple data must be specified");
  }

  const { type = "line", legend = false } = props;

  const dataMapper = (v: DataPoint): [number, number] => {
    return [v[0].valueOf(), Number.parseFloat(v[1] as unknown as string)];
  };

  const seriesDefinition = (
    data: DataPoint[],
    name?: string,
    userRef?: string,
    color?: string,
  ):
    | Highcharts.SeriesLineOptions
    | Highcharts.SeriesAreaOptions
    | Highcharts.SeriesAreasplineOptions => {
    return {
      id: userRef,
      data: data.map(dataMapper),
      type: type,
      name: name,
      color: color,
      animation: false,
      events: { click: props.clickEvent },
      tooltip: {
        pointFormatter: function () {
          return (
            `<tspan style='fill:${this.color}'>●</tspan>` +
            `<tspan style='${privacyMode && "filter: url(#blur)"}'> ${
              this.series.name
            } </tspan>` +
            `<tspan style='font-weight:bold'> ${round(this.y, props.rounding)}${
              props.unit
            } </tspan>`
          );
        },
      },
    };
  };

  const rangeDefiniton = (
    data: [Dayjs, number, number][],
    name?: string,
    userRef?: string,
    color?: string,
  ): Highcharts.SeriesArearangeOptions => {
    return {
      id: userRef,
      data: data.map((v) => [v[0].valueOf(), v[1], v[2]]),
      type: "arearange",
      name: name,
      color: color ? `var(--${color})` : undefined,
      animation: false,
      linkedTo: name,
    };
  };

  let series: (
    | Highcharts.SeriesLineOptions
    | Highcharts.SeriesAreaOptions
    | Highcharts.SeriesAreasplineOptions
    | Highcharts.SeriesArearangeOptions
  )[] = props.data
    ? [seriesDefinition(props.data, props.name, undefined, defaultChartColor)]
    : (props.multipleData as [string, DataPoint[], string?, string?][]).map(
        ([name, data, ref, color]) => {
          return seriesDefinition(data, name, ref, color);
        },
      );

  if (props.range) {
    series = [...series, rangeDefiniton(props.range, props.name)];
  }

  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    chart: {
      type: type,
    },
    xAxis: {
      type: "datetime",
      min: props.params?.startDatetime?.valueOf(),
      max: props.params?.endDatetime?.valueOf(),
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5,
        marker: {
          enabled: type === "line",
        },
        lineWidth: type === "area" ? 0 : undefined,
      },
      area: {
        fillOpacity: 0.5,
        marker: {
          enabled: type === "line",
        },
        lineWidth: type === "area" ? 0 : undefined,
      },
      line: {
        marker: {
          enabled: type === "line",
        },
      },
    },
    series: series,
    yAxis: [
      {
        labels: {
          format: `{value}${props.unit}`,
        },
        title: {
          text: props.name,
        },
        min: props.yMin,
        max: props.yMax,
      },
    ],
    legend: {
      enabled: legend,
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: props.loading ? "Loading Data " : "No data for this time period",
    },
    noData: {
      style: {
        fontWeight: "normal",
        fontSize: "15px",
        color: "black",
      },
    },
    tooltip: {
      shared: true,
      valueSuffix: props.unit,
      valueDecimals: props.rounding,
      // pointFormat: `{series.name} {point.y}${props.unit}`
    },
    exporting: {
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          enabled:
            props.displayExport !== undefined ? props.displayExport : true,
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadSVG",
          ],
        },
      },
    },
    time: {
      timezone: "Europe/London",
    },
  };

  return (
    <>
      <HighchartsPrivacyModeFilter />
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
}
