import SelectedScheme from "components/Scheme/SelectedScheme";
import SearchParamPage from "components/Search/SearchParamPage";
import { OneCardPage } from "components/utils/OneCardPage";
import AssetListFilterForm from "pages/assetRegister/assets/list/AssetListFilterForm";
import type React from "react";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";
import AssetRegisterList from "./AssetRegisterList";

const InnerAssetRegisterList = (props: any): React.ReactElement => {
  return (
    <SelectedScheme>
      <AssetRegisterList {...props} />
    </SelectedScheme>
  );
};

const AssetPositionListPage = (): React.ReactElement => {
  return (
    <OneCardPage
      headerTitle={"Asset Register"}
      breadcrumbs={[{ url: "/admin/asset_register", name: "Asset Register" }]}
      setPageTitle={schemeObjectPageTitle("Asset Register")}
    >
      <AssetListFilterForm
        includeNonCreateables={false}
        filterUnassignedField={"assetId"}
        includeTextSearch={true}
      />
      <SearchParamPage
        initialState={{
          assetType: undefined,
          assetId: undefined,
          textSearch: undefined,
          sort: "location.address",
        }}
      >
        <InnerAssetRegisterList />
      </SearchParamPage>
    </OneCardPage>
  );
};

export default AssetPositionListPage;
