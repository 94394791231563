import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import React from "react";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import { Button } from "../../../components/Theme/button";
import type { WirelessSurveyDataCollectorOut } from "../../../openapi/model/wirelessSurveyDataCollectorOut";

interface FormValues {
  samplePointName: string;
  startDateTime: string;
  endDateTime?: string;
}

interface ActionValues {
  samplePointName: string;
  startDateTime: Dayjs;
  endDateTime?: Dayjs;
}

function WirelessSurveySamplePointForm(props: {
  dataCollector: WirelessSurveyDataCollectorOut;
  refresh?: () => any;
  submit?: (values: ActionValues) => Promise<any>;
  onCancel?: () => any;
}) {
  const timeFormat = "yyyy-MM-DD[T]HH:mm";

  return (
    <Formik
      initialValues={
        {
          samplePointName: "",
          startDateTime: dayjs().endOf("minute").format(timeFormat),
          endDateTime: undefined,
        } as FormValues
      }
      onSubmit={(values) => {
        props.submit?.({
          ...values,
          startDateTime: dayjs(values.startDateTime, timeFormat),
          endDateTime: values.endDateTime
            ? dayjs(values.endDateTime, timeFormat)
            : undefined,
        });
      }}
      onReset={() => {
        props.onCancel?.();
      }}
    >
      {() => (
        <Form>
          <NewTextFormComponent
            fieldName={"samplePointName"}
            label={{ label: "Sample Point Name'" }}
          />
          <NewTextFormComponent
            fieldName={"startDateTime"}
            type={"datetime-local"}
            label={{ label: "Sample Point Start Date Time'" }}
          />
          <NewTextFormComponent
            fieldName={"endDateTime"}
            type={"datetime-local"}
            label={{ label: "Sample Point End Date Time'" }}
          />
          <Button color={"red"} type={"reset"}>
            Cancel
          </Button>
          <Button color={"brandLight"} type={"submit"}>
            Add
          </Button>
        </Form>
      )}
    </Formik>
  );
  /* TODO: button size */
}

export default WirelessSurveySamplePointForm;
