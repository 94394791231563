import { faLink } from "@fortawesome/free-solid-svg-icons";
import type React from "react";
import CreateButton from "../../../components/Buttons/CreateButton";
import type { Asset } from "../../../openapi/model/asset";
import type { AssetPosition } from "../../../openapi/model/assetPosition";
import type { AssetPositionAssetLinkIn } from "../../../openapi/model/assetPositionAssetLinkIn";

type Props = {
  assetPosition: AssetPosition;
  asset: Asset;
  refresh?: () => any;
};

const LinkAssetPositionFromAssetButton = (props: Props): React.ReactElement => {
  return (
    <CreateButton
      entityType={"LINK"}
      entity={
        {
          assetId: props.asset.assetId,
          assetPositionId: props.assetPosition.assetPositionId,
          companyId: props.asset.companyId,
        } as AssetPositionAssetLinkIn
      }
      color={"brandLight"}
      postCreate={props.refresh}
      text={"Link Asset"}
      icon={faLink}
    />
  );
};

export default LinkAssetPositionFromAssetButton;
