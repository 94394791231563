import { yupPassword } from "components/Forms/yupValidators";
import { Form, Formik, type FormikHelpers } from "formik";
import { CompanyRole } from "openapi/model/companyRole";
import { CompanyType } from "openapi/model/companyType";
import type { NewCompany } from "openapi/model/newCompany";
import type React from "react";
import * as Yup from "yup";
import AlertBanner from "../../components/Banner/AlertBanner";
import { ButtonGroupField } from "../../components/Forms/ButtonGroup";
import NewTextFormComponent from "../../components/Forms/NewTextFormComponent";
import { Button } from "../../components/Theme/button";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import type { Company } from "../../openapi/model/company";
import type { NewUser } from "../../openapi/model/newUser";
import { useSelectedCompany } from "../../reducers/company";

export interface CreateCompanyFormValues {
  companyName: string;
  companyId: string;
  emailAddress: string;
  createUser: boolean;
  companyType: CompanyType;
  password: string;
  userType: CompanyRole;
}

interface Props {
  onSubmit: (newCompany: NewCompany, newUser?: NewUser) => Promise<any>;
}

export default function CreateCompanyForm(props: Props): React.ReactElement {
  const selectedCompany = useSelectedCompany();

  const partner: Company | undefined =
    selectedCompany?.companyType === "PARTNER" ? selectedCompany : undefined;

  const submit = (
    values: CreateCompanyFormValues,
    { setSubmitting, resetForm }: FormikHelpers<CreateCompanyFormValues>,
  ): void => {
    setSubmitting(true);
    const newCompany = {
      name: values.companyName,
      companyId: values.companyId,
      companyType: values.companyType,
      partnerId:
        values.companyType === CompanyType.CLIENT
          ? selectedCompany?.companyId
          : undefined,
    };
    const newUser = values.createUser
      ? {
          emailAddress: values.emailAddress,
          fullname: values.emailAddress,
          password: values.password,
          userType: values.userType,
        }
      : undefined;
    props
      .onSubmit(newCompany, newUser)
      .then(() => resetForm())
      .finally(() => setSubmitting(false));
  };

  const initialValues = {
    companyName: "",
    emailAddress: "",
    password: "",
    createUser: true,
    companyType: CompanyType.CLIENT,
    companyId: "",
    userType: CompanyRole.Viewer,
  };
  return (
    <DisplayIfUserType userTypes={"admin"} companyType={"PARTNER"}>
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          companyName: Yup.string().required("Required"),
          companyId: Yup.string()
            .required()
            .min(5, "Id is too short -  should be 5 chars minimum")
            .max(50, "Id is too long - should be be 50 chars maximum")
            .matches(
              /[a-z0-9-]/,
              "Id can only contain letters a-z, numbers 0-9 or a `-`",
            ),
          emailAddress: Yup.string()
            .email()
            .when("createUser", {
              is: true,
              // biome-ignore lint/suspicious/noThenProperty: then is the yup name
              then: Yup.string().email().required("Required"),
            }),
          password: Yup.string().when("createUser", {
            is: true,
            // biome-ignore lint/suspicious/noThenProperty: then is the yup name
            then: yupPassword(),
          }),
        })}
      >
        {({ isValid, isSubmitting, values }): React.ReactElement => (
          <Form aria-label={"create-company-form"}>
            <h3 className="text-white mb-0">Add Company</h3>
            <NewTextFormComponent
              fieldName={"companyId"}
              label={{ label: "Id" }}
            />
            <NewTextFormComponent
              fieldName={"companyName"}
              label={{ label: "Name" }}
            />
            <DisplayIfUserType userTypes={"superadmin"}>
              <ButtonGroupField
                fieldName={"companyType"}
                label={{ label: "Company type" }}
                options={[
                  { label: "partner", value: "PARTNER" },
                  { label: "client", value: "CLIENT" },
                ]}
              />
            </DisplayIfUserType>{" "}
            <ButtonGroupField
              fieldName={"createUser"}
              label={{ label: "Create User" }}
              options={[
                { label: "yes", value: true },
                { label: "no", value: false },
              ]}
            />
            {values.createUser && (
              <>
                <NewTextFormComponent
                  fieldName={"emailAddress"}
                  label={{ label: "User email" }}
                  type={"email"}
                  disabled={!values.createUser}
                />
                <NewTextFormComponent
                  fieldName={"password"}
                  label={{ label: "User password" }}
                  type={"password"}
                  disabled={!values.createUser}
                />
                <ButtonGroupField
                  fieldName={"userType"}
                  label={{ label: "User type" }}
                  options={[
                    { label: "admin", value: "admin" },
                    { label: "editor", value: "editor" },
                    { label: "viewer", value: "viewer" },
                  ]}
                  disabled={!values.createUser}
                />
              </>
            )}
            {partner && (
              <AlertBanner className={"bg-brand-light-600"}>
                This new company will be created as a client of {partner.name}
              </AlertBanner>
            )}
            <Button
              type={"submit"}
              color={"brandLight"}
              disabled={isSubmitting || !isValid}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>
    </DisplayIfUserType>
  );
}
