import type React from "react";
import { ButtonGroupField } from "../../../components/Forms/ButtonGroup";

interface WirelessClusterConfigurationFormProps {
  namespace: string;
}

export default function WirelessClusterConfigurationForm(
  props: WirelessClusterConfigurationFormProps,
): React.ReactElement {
  const { namespace } = props;
  const withNamespace = (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  };

  return (
    <>
      {" "}
      <h4>Wireless Cluster</h4>
      <ButtonGroupField
        fieldName={withNamespace("autoOptimiseAssets")}
        label={{
          label:
            "Daily Auto Optimise Receiver Positions and Sync Gateway Device Lists",
        }}
        options={[
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ]}
      />
    </>
  );
}
