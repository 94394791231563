import React from "react";
import { Period } from "../../openapi/model/period";
import {
  type OptionsList,
  SelectComboField,
  type SelectComboFieldProps,
} from "../Forms/SelectCombo/SelectCombo";

type PeriodSelectComboProps = Omit<SelectComboFieldProps, "options">;

function getOptionsList(): OptionsList {
  return [
    { label: "Daily", value: Period.DAILY },
    { label: "Weekly", value: Period.WEEKLY },
    { label: "7 Day", value: Period.SEVENDAY },
    { label: "Monthly", value: Period.MONTHLY },
  ];
}

function PeriodSelectCombo(props: PeriodSelectComboProps) {
  return <SelectComboField {...props} options={getOptionsList()} />;
}

export default PeriodSelectCombo;
