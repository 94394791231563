import type React from "react";
import RequireScheme from "../../components/Scheme/RequireScheme";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import NewLocationForm from "../../pages/assetRegister/locations/NewLocationForm";
import { useRequiredSelectedCompanyId } from "../../reducers/company";
import { useSelectedScheme, useSelectedSchemeId } from "../../reducers/scheme";

const SchemeNewLocationPage = (): React.ReactElement => {
  const companyId = useRequiredSelectedCompanyId();
  const schemeId = useSelectedSchemeId();
  const scheme = useSelectedScheme();

  return (
    <RequireScheme>
      <OneCardPage
        headerTitle={`Add Location to ${scheme?.schemeName}`}
        breadcrumbs={[
          { url: "/admin/location", name: "Locations" },
          {
            url: `/admin/schemes/${schemeId}/location/create`,
            name: "Add Location",
          },
        ]}
        setPageTitle={schemeObjectPageTitle("Add Location")}
      >
        <NewLocationForm companyId={companyId} schemeId={schemeId as string} />
      </OneCardPage>
    </RequireScheme>
  );
};

export default SchemeNewLocationPage;
