import { Badge, type colors } from "components/Theme/badge";
import type React from "react";
import { RelayState } from "../../../../openapi/model/relayState";

const getColor = (state?: RelayState): keyof typeof colors => {
  switch (state) {
    case RelayState.ON:
      return "success";
    case RelayState.OFF:
      return "danger";
    case RelayState.SWITCHINGON:
      return "warning";
    case RelayState.SWITCHINGOFF:
      return "warning";
    case RelayState.SWITCHINGERROR:
      return "danger";
    default:
      return "warning";
  }
};
const RelayStateLabel = (
  props: {
    state?: RelayState;
    size?: "sm" | "md" | "lg";
  } & Omit<React.HTMLAttributes<HTMLSpanElement>, "color">,
): React.ReactElement => {
  const { size = "lg", state, ...rest } = props;
  return (
    <span className={`text-${size}`}>
      <Badge color={getColor(state)} {...rest}>
        {state || "UNKNOWN"}
      </Badge>
    </span>
  );
};

export default RelayStateLabel;
