import React from "react";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import EnergyBalancerView from "../../components/TimeSeries/EnergyBalanceView/EnergyBalancerView";
import { AssetType } from "../../openapi/model/assetType";
import { GroupBy } from "../../openapi/model/groupBy";
import type { HeatNetworkReportParams } from "./heatNetworkAnalysis/model";
import TimeSeriesStatsApiWrapper, {
  type AggregatedTimeSeriesStatsParams,
} from "./latestDataReport/TimeSeriesStatsApiWrapper";

interface Props {
  companyId?: string;
  schemeId?: string;
  query: HeatNetworkReportParams;
}

function EnergyBalanceReportPage(props: Props) {
  const [startDatetime, endDatetime] = [
    props.query.startDate,
    props.query.endDate,
  ];
  const params = (): AggregatedTimeSeriesStatsParams => {
    if (props.companyId && props.schemeId) {
      return {
        companyId: props.companyId,
        schemeId: props.schemeId,
        parameter: "Energy (Heating)",
        aggregation: "difference",
        assetTypes: [AssetType.HeatMeter, AssetType.HeatingAndCoolingMeter],
        startDatetime,
        endDatetime,
        period: "MONTHLY",
        groupBy: GroupBy.GROUPALL,
      };
    } else {
      throw new Error("Scheme or Company is not selected.");
    }
  };
  return (
    <>
      {props.companyId && props.schemeId && (
        <TimeSeriesStatsApiWrapper request={params()}>
          {({ data, loading }) => (
            <BlockSpinner loading={loading}>
              <EnergyBalancerView
                title={"Energy Balance"}
                data={data || {}}
                startDate={startDatetime}
                endDate={endDatetime}
              />
            </BlockSpinner>
          )}
        </TimeSeriesStatsApiWrapper>
      )}
    </>
  );
}

export default EnergyBalanceReportPage;
