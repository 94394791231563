import UserTypeLabel from "components/user/UserTypeLabel";
import { Form, Formik, type FormikHelpers } from "formik";
import type React from "react";
import { useState } from "react";
import DeleteButton from "../../components/Buttons/DeleteButton";
import EditButton from "../../components/Buttons/EditButton";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import { ButtonGroupField } from "../../components/Forms/ButtonGroup";
import PrivacyMode from "../../components/Text/PrivacyMode";
import { TableCell, TableRow } from "../../components/Theme/table";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import useUpdateEntity from "../../hooks/updateEntity";
import { Status } from "../../openapi/model/status";
import type { UserCompanyProperties } from "../../openapi/model/userCompanyProperties";
import EditUserTypeModel from "../users/EditUserTypeModel";
import type { CompanyUser } from "../users/users";

interface Props {
  userCompany: CompanyUser;
  showCompanyId?: boolean;
  showUserDetails?: boolean;
  profileAction?: boolean;
}

const UserCompanyListItemForm = (props: Props): React.ReactElement => {
  const {
    showCompanyId = false,
    showUserDetails = false,
    profileAction = true,
  } = props;

  const [error, setError] = useState<Error | undefined>(undefined);
  const [user, setUser] = useState<CompanyUser>(props.userCompany);

  const { updateEntity } = useUpdateEntity(
    "USER",
    props.userCompany.userId,
    undefined,
    profileAction
      ? `user/profile/company/${props.userCompany.companyId}/properties`
      : `user/${user.userId}/company/${user.companyId}`,
  );

  const initialValues: UserCompanyProperties = {
    receivesAlerts: user.properties?.receivesAlerts,
  };

  const postDelete = () => {
    setUser((s) => {
      return { ...s, status: "DELETED" };
    });
  };

  const submit = (
    values: UserCompanyProperties,
    {
      setSubmitting,
      resetForm,
      setStatus,
    }: FormikHelpers<UserCompanyProperties>,
  ) => {
    setSubmitting(true);
    setStatus("submitting");
    setError(undefined);

    (profileAction
      ? updateEntity(values)
      : updateEntity({
          userType: user.userType,
          companyId: user.companyId,
          properties: values,
        })
    )
      .then(() => {
        setStatus("success");
      })
      .catch((err: Error) => {
        resetForm();
        setStatus("error");
        setError(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const [openEditModal, editModal] = EditUserTypeModel(
    user,
    (updatedCompany: CompanyUser) => {
      setUser(updatedCompany);
    },
  );

  return (
    <TableRow data-cy={"list-item"} key={user.userId}>
      {editModal}
      {showCompanyId && (
        <TableCell data-cy={"user-company"}>{user.companyId}</TableCell>
      )}
      {showUserDetails && (
        <>
          <TableCell data-cy="user-fullname">
            <PrivacyMode>{user.fullname}</PrivacyMode>
          </TableCell>
          <TableCell data-cy="user-email_address">
            <PrivacyMode>{user.emailAddress}</PrivacyMode>
          </TableCell>
        </>
      )}
      <TableCell data-cy={"user-type"}>
        <UserTypeLabel user={user} />
      </TableCell>
      <TableCell data-cy={"user-alerts"}>
        {!user.apiUser && (
          <Formik initialValues={initialValues} onSubmit={submit}>
            {({ submitForm }): React.ReactElement => (
              <Form>
                <ButtonGroupField
                  fieldName={"receivesAlerts"}
                  label={{ margin: 0, size: "sm" }}
                  options={[
                    { label: "On", value: true },
                    { label: "Off", value: false },
                  ]}
                  onChange={(): void => {
                    submitForm();
                  }}
                  size={"sm"}
                />
                {error && <ErrorBanner error={error} />}
              </Form>
            )}
          </Formik>
        )}
      </TableCell>
      <TableCell>
        {!user.apiUser && (
          <DisplayIfUserType userTypes={"admin"}>
            <EditButton onClick={openEditModal} />
          </DisplayIfUserType>
        )}
        {user.apiUser && user.status !== Status.DELETED && (
          <DeleteButton
            entityType={"USER"}
            entityId={user.fullname}
            path={`user/api/${user.fullname}?company_id=${user.companyId}`}
            postDelete={postDelete}
            errorMessageBody={<b>Failed to delete user</b>}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default UserCompanyListItemForm;
