import { isArray } from "lodash-es";
import React, { useEffect } from "react";
import { type ValueContainerProps, components } from "react-select";
import UncontrolledTooltip from "../../Theme/tooltip";
import type { Option } from "./SelectCombo";
import { useHiddenOptionsData } from "./hooks";

export default function MultiValueContainer(
  props: ValueContainerProps<Option>,
) {
  const { children, ...other } = props;
  const [ref, setRef] = React.useState<HTMLDivElement | null>(null);
  const [isCounterShown, setIsCounterShown] = React.useState(false);

  const { overflowIndex, hiddenOptionsCount } = useHiddenOptionsData({
    isMultiline: false,
    ref,
    selectedOptionsCount: props.getValue().length,
    isCounterShown: isCounterShown,
  });

  useEffect(() => {
    setIsCounterShown(hiddenOptionsCount > 0);
  }, [hiddenOptionsCount]);

  if (!isArray(props.children) || props.children.length !== 2) {
    throw new Error("MultiValueContainer should be array and have 2 children");
  }

  const options = props.children[0];
  const placeholder = props.children[1];

  const hiddenContent =
    hiddenOptionsCount > 0 ? (
      <span className={"flex flex-wrap shrink gap-1 p-1"}>
        {[
          ...Array.from(options as Iterable<React.ReactNode>).slice(
            overflowIndex,
          ),
        ]}
      </span>
    ) : null;

  return (
    <components.ValueContainer {...other}>
      <div
        ref={(newRef) => setRef(newRef)}
        className={
          "flex flex-wrap overflow-y-clip w-100 h-100 gap-1 h-[28px] pl-4"
        }
      >
        {options}
        {placeholder}
      </div>
      {isCounterShown && (
        <UncontrolledTooltip
          message={hiddenContent}
          placement={"bottom"}
          width={ref ? ref.clientWidth - 100 : undefined}
          text={null}
          defaultStyles={false}
          className={"shadow-lg"}
          background={"zinc"}
        >
          <div
            className="w-[28px] h-[28px] rounded-full flex justify-center items-center
            text-sm/6 text-zinc-700
          bg-gray-200"
          >
            <p> +{hiddenOptionsCount}</p>
          </div>
        </UncontrolledTooltip>
      )}
    </components.ValueContainer>
  );
}
