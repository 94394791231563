import type React from "react";
import { useSelectedScheme } from "../../reducers/scheme";
import { Heading } from "../Theme/heading";

const RequireScheme = (props: {
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <>
      {useSelectedScheme() ? (
        props.children
      ) : (
        <Heading>Please select a scheme.</Heading>
      )}
    </>
  );
};

export default RequireScheme;
