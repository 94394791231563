import { Button } from "components/Theme/button";
import { Form, Formik } from "formik";
import type React from "react";
import { ErrorBanner } from "../../../../components/Error/ErrorBanner";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import useUpdateEntity from "../../../../hooks/updateEntity";
import type { AssetPosition } from "../../../../openapi/model/assetPosition";
import type { AssetPositionConfiguration } from "../../../../openapi/model/assetPositionConfiguration";
import DataProcessingConfigurationForm from "../DataProcessingConfigurationForm";
import WirelessClusterConfigurationForm from "../WirelessClusterConfigurationForm";

interface AssetPositionConfigurationTabProps {
  assetPosition: AssetPosition;
  refresh?: () => any;
}

interface FormValues {
  dataProcessing?: { autoCreateNewAssets: string };
  wirelessCluster?: { autoOptimiseAssets: string };
}

function assetPositionToFormValues(assetPosition: AssetPosition): FormValues {
  return {
    dataProcessing: {
      autoCreateNewAssets:
        assetPosition.configuration?.dataProcessing?.autoCreateNewAssets?.toString() ||
        "true",
    },
    wirelessCluster: {
      autoOptimiseAssets:
        assetPosition.configuration?.wirelessCluster?.autoOptimiseAssets?.toString() ||
        "false",
    },
  };
}

function formValuesToConfiguration(wirelessCluster = false) {
  return (values: FormValues): AssetPositionConfiguration => {
    return {
      dataProcessing: {
        autoCreateNewAssets:
          values.dataProcessing?.autoCreateNewAssets !== "false",
      },
      wirelessCluster: wirelessCluster
        ? {
            autoOptimiseAssets:
              values.wirelessCluster?.autoOptimiseAssets === "true",
          }
        : undefined,
    };
  };
}

function AssetPositionConfigurationTab(
  props: AssetPositionConfigurationTabProps,
): React.ReactElement {
  const wirelessCluster =
    props.assetPosition.assetPositionType === "wireless_cluster";

  const { updateEntity, updating, error } = useUpdateEntity<AssetPosition>(
    "ASSET_POSITION",
    props.assetPosition.assetPositionId,
    () => {
      props.refresh?.();
    },
  );
  return (
    <BlockSpinner loading={updating}>
      <Formik
        initialValues={assetPositionToFormValues(props.assetPosition)}
        onSubmit={(v, { setSubmitting }) => {
          setSubmitting(true);
          updateEntity({
            ...props.assetPosition,
            configuration: formValuesToConfiguration(wirelessCluster)(v),
          }).then(() => {
            setSubmitting(false);
          });
        }}
        enableReinitialize={true}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <DataProcessingConfigurationForm namespace={"dataProcessing"} />
            {wirelessCluster && (
              <WirelessClusterConfigurationForm namespace={"wirelessCluster"} />
            )}
            <Button
              type={"submit"}
              color={"brandLight"}
              disabled={isSubmitting || !isValid || !dirty}
            >
              Update
            </Button>
            <ErrorBanner error={error} />
          </Form>
        )}
      </Formik>
    </BlockSpinner>
  );
}

export default AssetPositionConfigurationTab;
