import React from "react";
import GetListPaginatedTable from "../../components/Pagination/GetListPaginatedTable";
import PrivacyMode from "../../components/Text/PrivacyMode";
import {
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/Theme/table";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import type { Account } from "../../openapi/model/account";

function AccountList(props: {
  companyId: string;
  schemeId?: string;
  locationId?: string;
}) {
  const showLocationCol = props.locationId === undefined;

  function row(account: Account) {
    return (
      <TableRow>
        <TableCell>{account.accountReference} </TableCell>
        {showLocationCol && (
          <TableCell>
            <CompanyNavLink
              className={"p-0 d-inline"}
              to={{
                pathname: `/admin/location/${account.locationId}/accounts`,
              }}
              dataCy={"locationId"}
              inline={true}
            >
              <PrivacyMode>
                {account.locationReference
                  ? `${account.locationAddress}(${account.locationReference})`
                  : account.locationAddress}
              </PrivacyMode>
            </CompanyNavLink>
          </TableCell>
        )}
        <TableCell>{account.openingDate}</TableCell>
        <TableCell>{account.closingDate || "-"}</TableCell>
      </TableRow>
    );
  }

  return (
    <GetListPaginatedTable
      row={row}
      header={[
        <TableHead key={0}>
          <TableHeader>
            <h5>Account Ref</h5>
          </TableHeader>
          {showLocationCol && (
            <TableHeader>
              <h5>Location</h5>
            </TableHeader>
          )}
          <TableHeader>
            <h5> Opening Date</h5>
          </TableHeader>
          <TableHeader>
            <h5>Closing Date</h5>
          </TableHeader>
        </TableHead>,
        showLocationCol ? 4 : 3,
      ]}
      entityType={"ACCOUNT"}
      params={props}
      cursor={{ maxItems: 100, direction: "desc" }}
      tableClassName={"list-group-flush"}
      showPagination={true}
    />
  );
}

export default AccountList;
