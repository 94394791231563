import classNames from "classnames";
import clsx from "clsx";
import { Form, Formik, useField } from "formik";
import React, { useState } from "react";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import ApiPaginatedTable from "../../../components/Pagination/NewApiPaginatedTable";
import { Button } from "../../../components/Theme/button";
import {
  TableCell,
  TableHeader,
  TableRow,
} from "../../../components/Theme/table";
import useGetList from "../../../hooks/getList";
import type { Asset } from "../../../openapi/model/asset";
import type { AssetType } from "../../../openapi/model/assetType";

interface SearchFormValues {
  serialNumber?: string;
}

function AssetSelectFormField(props: {
  name: string;
  assetType: AssetType;
  companyId: string;
  unassignedOnly?: boolean;
}) {
  const [field, _meta, { setValue }] = useField(props.name);

  const [searchValues, setSearchValues] = useState<SearchFormValues>({});

  const { unassignedOnly = true } = props;

  const [{ data, loading, error }] = useGetList("ASSET", {
    companyId: props.companyId,
    assetType: props.assetType,
    assetPositionId: unassignedOnly ? "NONE" : undefined,
    ...searchValues,
  });

  function onClick(assetId: string) {
    setValue(assetId);
  }

  return (
    <>
      <Formik
        initialValues={{} as SearchFormValues}
        onSubmit={(values) => setSearchValues(() => values)}
      >
        {() => {
          return (
            <Form>
              <NewTextFormComponent
                fieldName={"serialNumber"}
                label={{ label: "Serial Number" }}
              />
              <Button
                className={"float-right"}
                type={"submit"}
                color={"brandLight"}
              >
                Search
              </Button>
            </Form>
          );
        }}
      </Formik>

      <ApiPaginatedTable
        tableClassName={"table-sm"}
        loading={loading}
        error={error}
        cursor={data?.cursor}
        data={data}
        header={[
          <TableRow key={1}>
            <TableHeader>Serial Number</TableHeader>
            <TableHeader>Manufacturer</TableHeader>
            <TableHeader>&nbsp;</TableHeader>
          </TableRow>,
          3,
        ]}
        row={(asset: Asset, _) => (
          <TableRow
            key={asset.assetId}
            className={clsx(
              field.value === asset.assetId && "bg-brand-light-200",
            )}
          >
            <TableCell>{asset.serialNumber}</TableCell>
            <TableCell>{asset.manufacturer}</TableCell>
            <TableCell>
              <Button
                color={"brandLight"}
                onClick={() => onClick(asset.assetId)}
                size={"sm"}
              >
                Select
              </Button>
            </TableCell>
          </TableRow>
        )}
      />
    </>
  );
  /* TODO: button size small */
}

export default AssetSelectFormField;
