import type { Dayjs } from "dayjs";
import type React from "react";
import useGetData from "../../../../hooks/getData";
import {
  type DeviceTimeSeriesData,
  convertTimesToDayjs,
  extendDataToTimeRange,
  mapColumns,
} from "../../../../model/deviceTimeSeries";
import type { DeviceAsset } from "../../../../openapi/model/deviceAsset";
import type { GatewayAsset } from "../../../../openapi/model/gatewayAsset";
import type { ParsedTimeSeriesResponse } from "../../../../openapi/model/parsedTimeSeriesResponse";
import type { RawTimeSeriesResponse } from "../../../../openapi/model/rawTimeSeriesResponse";
import type { AssetTimeSeriesRequestParams } from "./AssetDataSelectionForm";
import type {
  DeviceTimeSeriesDisplayComponent,
  DeviceTimeSeriesDisplayComponentProps,
} from "./model";

interface Props {
  device: DeviceAsset | GatewayAsset;
  searchParams: AssetTimeSeriesRequestParams;
  render: DeviceTimeSeriesDisplayComponent;
}

const AssetTimeSeriesDataGetter = (props: Props): React.ReactElement => {
  const { device, searchParams } = props;
  const { assetId, companyId, schemeId } = device;
  const { startDatetime, endDatetime } = searchParams;

  const viewStyle = searchParams.projection === "Raw" ? "Raw" : "Standard";

  const [{ data, loading, error }] = useGetData<
    RawTimeSeriesResponse | ParsedTimeSeriesResponse
  >({
    path: `data/asset/${assetId}/time_series`,
    companyId,
    schemeId,
    params: {
      startDatetime: searchParams.startDatetime.format(),
      endDatetime: searchParams.endDatetime.format(),
      projection: searchParams.projection === "Raw" ? "raw" : "parsed",
    },
    singletonApi: true,
  });

  const timeseries: DeviceTimeSeriesData | undefined =
    data &&
    extendDataToTimeRange(
      data.columns,
      data.data,
      data.errors,
      "deviceErrors" in data ? data.deviceErrors : undefined,
      convertTimesToDayjs(data.times),
      startDatetime,
      endDatetime,
    );

  const displayProps = (): DeviceTimeSeriesDisplayComponentProps => {
    if (data && timeseries) {
      return {
        mappedTimeSeriesColumns: mapColumns(timeseries, viewStyle === "Raw"),
        times: timeseries.times as Dayjs[],
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    } else {
      return {
        mappedTimeSeriesColumns: {},
        times: [],
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    }
  };

  return <>{props.render(displayProps())}</>;
};

export default AssetTimeSeriesDataGetter;
