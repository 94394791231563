import type React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";
import { DayOfWeek } from "../../../openapi/model/dayOfWeek";

export default function DaySelect(
  props: Omit<SelectComboFieldProps, "option">,
): React.ReactElement {
  const options: Option[] = [
    { label: "Monday", value: DayOfWeek.MONDAY },
    { label: "Tuesday", value: DayOfWeek.TUESDAY },
    { label: "Wednesday", value: DayOfWeek.WEDNESDAY },
    { label: "Thursday", value: DayOfWeek.THURSDAY },
    { label: "Friday", value: DayOfWeek.FRIDAY },
    { label: "Saturday", value: DayOfWeek.SATURDAY },
    { label: "Sunday", value: DayOfWeek.SUNDAY },
  ];

  return <SelectComboField options={options} {...props} />;
}
