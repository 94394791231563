import type { AxiosPromise, AxiosRequestConfig } from "axios";
import type { RefetchOptions } from "axios-hooks";
import { isArray } from "lodash-es";
import type React from "react";
import useGetEntity, { type GetEntityProps } from "../../hooks/getEntity";

export interface GetEntityResultProps<T> {
  data?: T;
  error?: Error;
  loading: boolean;
  refresh: (
    config?: AxiosRequestConfig,
    options?: RefetchOptions,
  ) => AxiosPromise<T>;
  cancel: () => void;
}

interface Props extends GetEntityProps {
  children: (props: GetEntityResultProps<any>) => React.ReactElement;
  error: (props: { error: Error }) => React.ReactElement;
  placeholder: () => React.ReactElement;
}

const GetEntity = (props: Props): React.ReactElement => {
  const { children, ...rest } = props;
  const [{ data, error, loading }, refresh, cancel] = useGetEntity<any>(rest);

  const apiError = error !== null && error !== undefined;

  const notFoundError = data === undefined;

  const actualError = apiError
    ? isArray(error)
      ? error[0]
      : error
    : notFoundError && Error("Not found.");

  return (
    <>
      {loading
        ? props.placeholder()
        : actualError
          ? props.error({ error: actualError })
          : !notFoundError &&
            children({
              data,
              error: error !== null ? error : undefined,
              loading,
              refresh,
              cancel,
            })}
    </>
  );
};

export default GetEntity;
