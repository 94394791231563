import PrivacyMode from "components/Text/PrivacyMode";
import {
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/Theme/table";
import type React from "react";
import BulkUpdate from "../../../components/BulkUpdate/BulkUpdate";
import NumberOfAssetPositionsDisplayWidget from "../../../components/Common/NumberOfAssetPositionsDisplayWidget";
import LocationIdLink from "../../../components/Location/LocationIdLink";
import GetListPaginatedTable from "../../../components/Pagination/GetListPaginatedTable";
import { Button } from "../../../components/Theme/button";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";
import PaginatedApiToCSVExportButton from "../../../components/utils/CSVExport/PaginatedApiToCSVExportButton";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import type { Location } from "../../../openapi/model/location";

interface Props {
  companyId: string;
  schemeId?: string;
}

export default function LocationsList(props: Props): React.ReactElement {
  const { companyId, schemeId } = props;

  const params = {
    companyId,
    schemeId,
  };

  const header = (
    <TableHead>
      <TableRow>
        <TableHeader>Location ID</TableHeader>
        <TableHeader>Scheme ID</TableHeader>
        <TableHeader>Address</TableHeader>
        <TableHeader>Reference</TableHeader>
        <TableHeader>Asset Positions</TableHeader>
        <DisplayIfUserType userTypes={"superadmin"}>
          <TableHeader>Group</TableHeader>
        </DisplayIfUserType>
      </TableRow>
    </TableHead>
  );

  const row = (location: Location): React.ReactElement => {
    return (
      <TableRow data-cy="locations-list-item" key={location.locationId}>
        <TableCell data-cy={"location-id"}>
          <LocationIdLink location={location} />
        </TableCell>
        <TableCell data-cy="location-name">
          <PrivacyMode>{location.schemeId}</PrivacyMode>
        </TableCell>
        <TableCell data-cy="location-location">
          <PrivacyMode>{location.address}</PrivacyMode>
        </TableCell>
        <TableCell data-cy="location-reference">
          <PrivacyMode>{location.customerReference || "-"}</PrivacyMode>
        </TableCell>
        <TableCell data-cy={"location-asset-positions"}>
          <NumberOfAssetPositionsDisplayWidget
            positions={location.numberOfAssetPositions}
            index={location.locationId}
          />
        </TableCell>
        <DisplayIfUserType userTypes={"superadmin"}>
          <TableCell>
            <PrivacyMode>{location.group || "-"}</PrivacyMode>
          </TableCell>
        </DisplayIfUserType>
      </TableRow>
    );
  };
  const extraButtons = (
    <div className={"flex gap-2"}>
      <PaginatedApiToCSVExportButton entityType={"LOCATION"} params={params} />
      {schemeId && (
        <>
          <CompanyNavLink
            to={{ pathname: "/admin/location/create" }}
            button={{
              color: "brandLight",
            }}
          >
            Add Location
          </CompanyNavLink>
          <BulkUpdate
            companyId={companyId}
            schemeId={schemeId}
            objectType={"location"}
          />
          <CompanyNavLink
            to={{ pathname: "/admin/location/updates" }}
            button={{
              color: "brandLight",
            }}
          >
            Previous Uploads
          </CompanyNavLink>
        </>
      )}
    </div>
  );

  return (
    <GetListPaginatedTable
      entityType={"LOCATION"}
      params={{ companyId, schemeId }}
      cursor={{ maxItems: 10 }}
      data-cy={"location-list"}
      header={[header, 4]}
      row={row}
      additionalFunctionButtons={extraButtons}
    />
  );
}
