import type React from "react";
import { Button } from "../Theme/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "../Theme/dialog";

interface Props {
  buttonLabel: string;
  closeLabel?: string;
  title: string;
  body: string | React.ReactElement;
  className?: string;
  show: boolean;
  disabled?: boolean;
  handleClose: () => void;
  handleAction?: () => void;
}

const ConfirmModal = (props: Props): React.ReactElement => {
  const active: boolean = props.disabled === undefined ? true : props.disabled;

  return (
    <>
      <Dialog
        open={props.show}
        // toggle={props.handleClose}
        className={props.className}
        onClose={props.handleClose}
      >
        <DialogTitle
        // toggle={props.handleClose}
        >
          {props.title}
        </DialogTitle>
        <DialogBody>{props.body}</DialogBody>
        <DialogActions>
          {props.handleAction && (
            <Button
              color={"brandLight"}
              onClick={props.handleAction}
              disabled={!active}
            >
              {props.buttonLabel}
            </Button>
          )}
          <Button color="red" onClick={props.handleClose}>
            {props.closeLabel ? props.closeLabel : "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmModal;
