import AssetRegisterList from "pages/assetRegister/AssetRegisterListPage/AssetRegisterList";
import React from "react";
import ObjectPropertyItem from "../../../components/Headers/ObjectPropertyItem";
import AssetRegisterStatusLabel from "../../../components/Labels/AssetRegisterStatusLabel";
import ObjectDetailHeading from "../../../components/Layout/ObjectDetailHeading";
import RoutedTabGroupInterface from "../../../components/TabGroup/RoutedTabGroupInterface";
import PrivacyMode from "../../../components/Text/PrivacyMode";
import { Heading } from "../../../components/Theme/heading";
import { assetPositionToBreadcrumb } from "../../../components/navigation/breadcrumbs/Breadcrumbs";
import CopyToClipboard from "../../../components/utils/CopyToClipboard";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import EventList from "../../../containers/event/EventList";
import NoteList from "../../../containers/notes/NotesList";
import type { AssetRegisterEntitySuccessProps } from "../../../core/action/GetAssetRegisterEntitiesCombination";
import { Status } from "../../../openapi/model/status";
import AssetPositionDeleteTab from "../assetPositions/tabs/AssetPositionDeleteTab";
import GatewayFilelist from "../assets/gateway/GatewayFileList/GatewayFilelist";

export default function IntegrationHomePage(props: {
  integration: AssetRegisterEntitySuccessProps;
  deleteAssetPosition: () => Promise<any>;
}) {
  const { assetPosition, location, loading } = props.integration;
  const { deleteAssetPosition } = props;

  const deleted = assetPosition?.status === Status.DELETED;

  return (
    <OneCardPage
      headerTitle={"Integration Homepage"}
      breadcrumbs={
        assetPosition && location && [assetPositionToBreadcrumb(assetPosition)]
      }
      loading={loading}
      setPageTitle={() =>
        `Integration${location ? `: ${location.address}` : ""}`
      }
    >
      {assetPosition && location && (
        <>
          <ObjectDetailHeading
            leftColumn={{
              content: (
                <>
                  <ObjectPropertyItem
                    title={"Integration Title"}
                    type={"heading"}
                  >
                    <PrivacyMode>{location?.address}</PrivacyMode>
                  </ObjectPropertyItem>
                  <ObjectPropertyItem
                    title={"Integration Type"}
                    type={"heading"}
                  >
                    <PrivacyMode>
                      {
                        assetPosition?.configuration?.integration
                          ?.integrationType
                      }
                    </PrivacyMode>
                  </ObjectPropertyItem>
                </>
              ),
            }}
            rightColumn={{
              content: (
                <>
                  {deleted && (
                    <ObjectPropertyItem title={"Status"}>
                      <AssetRegisterStatusLabel status={assetPosition.status} />
                    </ObjectPropertyItem>
                  )}

                  <ObjectPropertyItem title={"Asset Position ID"}>
                    <CopyToClipboard
                      value={assetPosition?.assetPositionId}
                      slice={8}
                    />
                  </ObjectPropertyItem>

                  <ObjectPropertyItem title={"Created"}>
                    {formatDate(assetPosition?.createdAt, undefined, false)}
                  </ObjectPropertyItem>
                  <ObjectPropertyItem title={"Updated"}>
                    {formatDate(assetPosition?.lastUpdated, undefined, false)}
                  </ObjectPropertyItem>
                </>
              ),
            }}
          />
          <RoutedTabGroupInterface
            tabs={[
              {
                tabName: "Files",
                tabContent: <GatewayFilelist assetPosition={assetPosition} />,
                tabPath: "files",
                keepSearchString: false,
              },
              {
                tabName: "Owned Asset Positions",
                tabPath: "positions",
                tabContent: (
                  <>
                    <Heading>Integration Owned Asset Positions</Heading>
                    <AssetRegisterList
                      parentAssetPositionId={assetPosition.assetPositionId}
                      companyId={assetPosition.companyId}
                      schemeId={assetPosition.schemeId}
                      createAssetTags={false}
                    />
                  </>
                ),
              },
              {
                tabName: "Notes",
                tabPath: "note",
                tabContent: (
                  <>
                    <Heading>Notes</Heading>
                    <NoteList assetPosition={assetPosition} />
                  </>
                ),
                disabled: false,
              },
              {
                tabName: "Events",
                tabPath: "events",
                tabContent: (
                  <>
                    <Heading>Asset Position Events</Heading>
                    <EventList
                      assetPositionId={assetPosition.assetPositionId}
                      companyId={assetPosition.companyId}
                    />
                  </>
                ),
                disabled: false,
              },
              {
                tabName: "Delete",
                tabPath: "delete",
                tabContent: (
                  <AssetPositionDeleteTab
                    assetPosition={assetPosition}
                    // refresh={loadAssetPosition}
                    delete={deleteAssetPosition}
                    title={"Asset Position"}
                  />
                ),
                userTypes: "admin",
                disabled: false,
              },
            ]}
            topLevelPath={`/admin/asset_register/asset_position/${assetPosition.assetPositionId}`}
          />
        </>
      )}
    </OneCardPage>
  );
}
