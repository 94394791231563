import {
  faCaretDown,
  faCaretRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorBanner } from "components/Error/ErrorBanner";
import { formatDate } from "components/utils/HighlightNullAndOldDates";
import { isNil } from "lodash-es";
import React, { useState } from "react";
import NewApiPaginatedList from "../../components/Pagination/NewApiPaginatedList";
import { Button } from "../../components/Theme/button";
import Collapse from "../../components/Theme/collapse";
import { StackedListItem } from "../../components/Theme/stacked-list";
import useGetList, { type PaginatedListResult } from "../../hooks/getList";
import type { Event } from "../../openapi/model/event";
import type { Note } from "../../openapi/model/note";

export default function NoteHistoryAccordian(props: {
  note: Note;
  lastUpdate: Date;
}): React.ReactElement {
  const [visible, setVisible] = useState(false);

  const [{ data, error }, refresh] = useGetList<PaginatedListResult<Event>>(
    "EVENT",
    {
      noteId: props.note.noteId,
      companyId: props.note.companyId,
      schemeId: props.note.schemeId,
    },
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies(props.lastUpdate): this is the refresh trigger
  React.useEffect(() => {
    refresh();
  }, [props.lastUpdate, refresh]);

  return (
    <>
      <Button onClick={() => setVisible(!visible)} className={"btn-link px-0"}>
        <h5 className={"mb-0"}>
          <FontAwesomeIcon
            icon={visible ? faCaretDown : faCaretRight}
            fixedWidth
          />
          History (
          {data ? (
            data.data.length
          ) : (
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              fixedWidth
              className={"ml-0"}
            />
          )}{" "}
          {data ? (data.data.length === 1 ? "entry" : "entries") : "entries"}
          ).
        </h5>
      </Button>
      <Collapse isOpen={visible}>
        <>
          <NewApiPaginatedList
            data={data}
            row={(item: Event, index: number): React.ReactElement => {
              return (
                <StackedListItem key={index.toString()}>
                  {item.eventType} on {formatDate(item.eventDateTime)} by{" "}
                  {item.user.userName}
                </StackedListItem>
              );
            }}
            showPagination={
              !isNil(data?.cursor.after) || !isNil(data?.cursor.before)
            }
          />
          {error && <ErrorBanner error={error} />}
        </>
      </Collapse>
    </>
  );
}
