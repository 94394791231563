import type { Dayjs } from "dayjs";
import type React from "react";
import type {
  ColumnMap,
  TimeSeriesDataParams,
} from "../../../../../model/deviceTimeSeries";
import {
  type ErrorBand,
  identifyBestDurationForErrorBands,
  identifyErrorBands,
  removeValueErrorsFromData,
} from "../../../../../time_series/asset/data/valueErrors";
import GenericCharts from "../GenericCharts";
import HeatMeterFlowAndTemperatureChart from "./HeatMeterFlowAndTemperatureChart";

const HeatMeterCharts = (props: {
  mappedTimeSeriesColumns: ColumnMap;
  times: Dayjs[];
  params: TimeSeriesDataParams;
  loading: boolean;
  error?: Error;
}): React.ReactElement => {
  if (props.params.projection === "Raw") {
    return <GenericCharts {...props} />;
  } else {
    const combinedHeatAndFlowCharts: ColumnMap = {};
    let errorBands: Array<ErrorBand> = [];
    let genericCharts: ColumnMap = {};

    if (props.loading) {
      combinedHeatAndFlowCharts.Flow = {
        name: "Flow Temperature",
        unit: "deg C",
        data: [],
        type: "instant",
      };
      combinedHeatAndFlowCharts["Return Temperature"] = {
        name: "Flow Temperature",
        unit: "deg C",
        data: [],
        type: "instant",
      };
      combinedHeatAndFlowCharts["Flow Temperature"] = {
        name: "Flow Temperature",
        unit: "deg C",
        data: [],
        type: "instant",
      };
      combinedHeatAndFlowCharts.Power = {
        name: "Flow Temperature",
        unit: "deg C",
        data: [],
        type: "instant",
      };
    } else {
      const errorFreeData = removeValueErrorsFromData(
        props.mappedTimeSeriesColumns,
      );
      errorBands = identifyErrorBands(
        props.mappedTimeSeriesColumns,
        props.times,
        identifyBestDurationForErrorBands(props.times),
      );
      genericCharts = Object.entries(errorFreeData).reduce(
        (accumulator, [name, value]) => {
          if (
            ["Flow", "Flow Temperature", "Return Temperature", "Power"].indexOf(
              name,
            ) !== -1
          ) {
            combinedHeatAndFlowCharts[name] = value;
            return accumulator;
          } else {
            return { ...accumulator, [name]: value };
          }
        },
        {},
      );
    }

    return (
      <>
        <HeatMeterFlowAndTemperatureChart
          flowRateData={combinedHeatAndFlowCharts.Flow}
          flowTemperatureData={combinedHeatAndFlowCharts["Flow Temperature"]}
          returnTemperatureData={
            combinedHeatAndFlowCharts["Return Temperature"]
          }
          powerData={combinedHeatAndFlowCharts.Power}
          errorBands={errorBands}
          times={props.times}
          params={props.params}
          loading={props.loading}
          error={props.error}
        />
        <GenericCharts
          mappedTimeSeriesColumns={genericCharts}
          times={props.times}
          params={props.params}
          loading={props.loading}
          error={props.error}
        />
      </>
    );
  }
};

export default HeatMeterCharts;
