import {
  loadFileFromApi,
  loadFileFromApiToMemory,
} from "../../../../actions/helpers/presigned_s3";
import type { AssetFile as GatewayFile } from "../../../../openapi/model/assetFile";
import type { IntegrationFile } from "../../../../openapi/model/integrationFile";

export function isGatewayFile(
  file: GatewayFile | IntegrationFile,
): file is GatewayFile {
  return (file as GatewayFile).assetId !== undefined;
}

export function isIntegrationFile(
  file: GatewayFile | IntegrationFile,
): file is IntegrationFile {
  return (file as IntegrationFile).assetPositionId !== undefined;
}

export function fileUrl(file: GatewayFile | IntegrationFile): string {
  if (isGatewayFile(file)) {
    return `asset/${file.assetId}/files/${file.fileId}`;
  }
  if (isIntegrationFile(file)) {
    return `asset_position/${file.assetPositionId}/files/${file.fileId}`;
  }
  throw new Error("Unidentified file type");
}

export const getGatewayFileFromApi = async (
  file: GatewayFile | IntegrationFile,
): Promise<void> => {
  if (isGatewayFile(file)) {
    return loadFileFromApi(`/asset/${file.assetId}/files/${file.fileId}`);
  }
  if (isIntegrationFile(file)) {
    return loadFileFromApi(
      `/asset_position/${file.assetPositionId}/files/${file.fileId}`,
    );
  }
};

export const getGatewayFileFromApiToMemory = async (
  file: GatewayFile | IntegrationFile,
): Promise<any> => {
  if (isGatewayFile(file)) {
    return loadFileFromApiToMemory(
      `/asset/${file.assetId}/files/${file.fileId}`,
    );
  }
  if (isIntegrationFile(file)) {
    return loadFileFromApiToMemory(
      `/asset_position/${file.assetPositionId}/files/${file.fileId}`,
    );
  }
};
