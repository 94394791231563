import { Form, Formik } from "formik";
import type React from "react";
import { useState } from "react";
import { ButtonGroupField } from "../../../components/Forms/ButtonGroup";
import type { AggregatedReportRequest } from "../../../openapi/model/aggregatedReportRequest";
import type { AssetRegisterAggregatedTimeSeriesResponse } from "../../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import { AssetType } from "../../../openapi/model/assetType";
import AggregatedTimeSeriesApiWrapper from "../aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper";
import AllPeriodReportTable from "../allPeriodReportTable/NewAllPeriodReportTable";
import type { HeatNetworkReportParams } from "./model";

interface Props {
  companyId?: string;
  schemeId?: string;
  query: HeatNetworkReportParams;
}

export const SYNTHETIC_ENERGY_REPORT_AGGREGATIONS = [
  "sum_estimated_net_energy",
  "sum_estimated_forward_net_energy",
  "sum_estimated_forward_heating_energy",
  "sum_estimated_forward_cooling_energy",
  "sum_estimated_reverse_net_energy",
  "sum_estimated_reverse_heating_energy",
  "sum_estimated_reverse_cooling_energy",
];

const ENERGY_REPORT_COLS = [
  {
    parameter: "syntheticEnergy",
    aggregation: "sumEstimatedNetEnergy",
    name: "Estimated Energy Net Change",
    omitCollapsed: false,
  },
  {
    parameter: "syntheticEnergy",
    aggregation: "sumEstimatedForwardNetEnergy",
    name: "Estimated Forward Net Energy Change",
    omitCollapsed: false,
  },
  {
    parameter: "syntheticEnergy",
    aggregation: "sumEstimatedForwardHeatingEnergy",
    name: "Estimated Forward Heating Energy Change",
    omitCollapsed: true,
  },
  {
    parameter: "syntheticEnergy",
    aggregation: "sumEstimatedForwardCoolingEnergy",
    name: "Estimated Forward Cooling Energy Change",
    omitCollapsed: true,
  },
  {
    parameter: "syntheticEnergy",
    aggregation: "sumEstimatedReverseNetEnergy",
    name: "Estimated Reverse Net Energy Change",
    omitCollapsed: false,
  },
  {
    parameter: "syntheticEnergy",
    aggregation: "sumEstimatedReverseHeatingEnergy",
    name: "Estimated Reverse Heating Energy Change",
    omitCollapsed: true,
  },
  {
    parameter: "syntheticEnergy",
    aggregation: "sumEstimatedReverseCoolingEnergy",
    name: "Estimated Reverse Cooling Energy Change",
    omitCollapsed: true,
  },
  {
    parameter: "energyHeating",
    aggregation: "difference",
    name: "Recorded Energy Change",
    omitCollapsed: false,
  },
  {
    parameter: "syntheticEnergy",
    aggregation: "difference",
    name: "Difference",
    omitCollapsed: false,
  },
  {
    parameter: "syntheticEnergy",
    aggregation: "differencePercentage",
    name: "Difference (%)",
    omitCollapsed: false,
  },
];

const addDifferenceValues = (
  data?: AssetRegisterAggregatedTimeSeriesResponse,
): AssetRegisterAggregatedTimeSeriesResponse | undefined => {
  if (data) {
    return {
      times: data.times,
      data: data.data.map((value) => {
        const { aggregations, ...asset } = value;

        const synthetic_total =
          (aggregations.syntheticEnergy.sumEstimatedNetEnergy[0] as number) ||
          0;
        const recorded_total =
          (aggregations.energyHeating.difference[0] as number) || 0;
        const difference = recorded_total - synthetic_total;
        const percentage =
          recorded_total !== 0
            ? Math.abs(difference / recorded_total) * 100
            : undefined;

        return {
          ...asset,
          aggregations: {
            syntheticEnergy: {
              ...aggregations.syntheticEnergy,
              difference: [difference],
              differencePercentage: [percentage as number],
            },
            energyHeating: aggregations.energyHeating,
          },
        };
      }),
    };
  }
};

const SyntheticEnergyReportPage = (props: Props): React.ReactElement => {
  const [startDatetime, endDatetime] = [
    props.query.startDate,
    props.query.endDate,
  ];

  const [expandedTable, setExpandedTable] = useState(false);

  const params = (): AggregatedReportRequest => {
    if (props.companyId && props.schemeId) {
      return {
        companyId: props.companyId,
        schemeId: props.schemeId,
        aggregations: [
          ...SYNTHETIC_ENERGY_REPORT_AGGREGATIONS.map((value) => {
            return {
              parameter: "Synthetic Energy",
              aggregation: value,
            };
          }),
          {
            parameter: "Energy (Heating)",
            aggregation: "difference",
          },
        ],
        assetTypes: [AssetType.HeatMeter, AssetType.HeatingAndCoolingMeter],
        startTime: startDatetime.toDate(),
        endTime: endDatetime.toDate(),
        period: "ALL",
        useCase: props.query.useCases,
        group: props.query.group,
      };
    } else {
      throw new Error("Scheme or Company is not selected.");
    }
  };

  const energy_report_cols = ENERGY_REPORT_COLS.filter((value) => {
    return expandedTable ? true : !value.omitCollapsed;
  });

  return (
    <>
      <Formik
        initialValues={{ expanded: false }}
        onSubmit={(x): void => {
          setExpandedTable(x.expanded);
        }}
      >
        {({ submitForm }): React.ReactElement => (
          <Form>
            <ButtonGroupField
              fieldName={"expanded"}
              label={{ label: "Expanded" }}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              onChange={async (): Promise<void> => {
                await submitForm();
              }}
            />
          </Form>
        )}
      </Formik>
      {props.companyId && props.schemeId && (
        <AggregatedTimeSeriesApiWrapper request={params()}>
          {({ data }) => {
            return (
              <AllPeriodReportTable
                data={addDifferenceValues(data)}
                columnDefinitions={energy_report_cols}
                startDatetime={startDatetime}
                endDatetime={endDatetime}
                downloadFilename={`${props.schemeId}_synthetic_energy_report`}
              />
            );
          }}
        </AggregatedTimeSeriesApiWrapper>
      )}
    </>
  );
};

export default SyntheticEnergyReportPage;
