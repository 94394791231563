import type React from "react";
import { useState } from "react";
import type { RequireExactlyOne } from "type-fest";
import { errorToTitleAndText } from "../../../components/Error/errorMessages";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import useCreateEntity from "../../../hooks/createEntity";
import type { Asset } from "../../../openapi/model/asset";
import type { AssetAction } from "../../../openapi/model/assetAction";
import type { AssetPosition } from "../../../openapi/model/assetPosition";
import type { AssetPositionAction } from "../../../openapi/model/assetPositionAction";

interface Props {
  close: () => void;
  show: boolean;
  asset?: Asset;
  assetPosition?: AssetPosition;
  action?: AssetAction;
  assetPositionAction?: AssetPositionAction;
  description: string;
  onComplete?: () => void;
  onClose?: () => void;
}

type PProps = RequireExactlyOne<Props, "asset" | "assetPosition">;

const AssetActionConfirmModal = (props: PProps): React.ReactElement => {
  const [started, setStarted] = useState(false);
  const [completed, setCompleted] = useState(false);

  const { createEntity, error } = useCreateEntity(
    props.asset ? "ASSET" : "ASSET_POSITION",
    props.asset
      ? `asset/${props.asset.assetId}/action`
      : `asset_position/${props.assetPosition.assetPositionId}/action`,
  );

  const handleConfirm = (): void => {
    setStarted(true);
    createEntity(
      props.asset
        ? {
            ...props.action,
            companyId:
              props.asset.companyId === "NONE"
                ? undefined
                : props.asset.companyId,
          }
        : {
            ...props.assetPositionAction,
            companyId: props.assetPosition.companyId,
          },
    )
      .then(() => {
        setCompleted(true);
        if (props.onComplete) {
          props.onComplete();
        }
      })
      .catch(() => {
        setCompleted(true);
      });
  };

  const handleClose = (): void => {
    if (props.onClose) {
      props.onClose();
    }
    props.close();
    setTimeout(() => {
      setStarted(false);
      setCompleted(false);
    }, 200);
  };

  return (
    <ConfirmModal
      buttonLabel={"OK"}
      title={
        completed
          ? error
            ? ` Error${
                errorToTitleAndText(error).title
                  ? `: ${errorToTitleAndText(error).title}`
                  : ""
              }`
            : "Action Started"
          : "Are you sure?"
      }
      body={
        completed ? (
          error ? (
            <p>
              There was an error starting your action
              {errorToTitleAndText(error).text}
            </p>
          ) : (
            "Your action has been started"
          )
        ) : (
          `Do you really want to ${props.description}?`
        )
      }
      show={props.show}
      handleAction={!completed ? handleConfirm : undefined}
      disabled={!started}
      closeLabel={completed ? "Close" : "Cancel"}
      handleClose={handleClose}
    />
  );
};

export default AssetActionConfirmModal;
