import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import nodata from "highcharts/modules/no-data-to-display.js";
import { camelCase, round } from "lodash-es";
import type React from "react";
import type { AssetRegisterAggregatedTimeSeriesResponse } from "../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import type { AssetRegisterItemAggregatedTimeSeries } from "../../openapi/model/assetRegisterItemAggregatedTimeSeries";
import { usePrivacyMode } from "../../reducers/privacy";
import HighchartsPrivacyModeFilter from "../Text/HighchartsPrivacyModeFilter";
import {
  type AggregatedChartsAmendedOptions,
  type AggregatedDataItemClickHandlerProps,
  type AssetRegisterItemScatterColumn,
  type AssetRegisterItemScatterPoint,
  aggregationDataItemClickHandler,
} from "./AggregationChartFunctions";

nodata(Highcharts);

declare global {
  interface Window {
    dayjs: any;
  }
}

export default function AggregationScatterChart(
  props: {
    data?: AssetRegisterAggregatedTimeSeriesResponse;
    xAggregation: string;
    xParameter: string;
    yAggregation: string;
    yParameter: string;
    loading: boolean;
    name?: string;
    height?: string;
    xUnit: string;
    yUnit: string;
    xLabel: string;
    yLabel: string;
  } & AggregatedDataItemClickHandlerProps,
): React.ReactElement {
  const { height = "400px" } = props;
  const privacyMode = usePrivacyMode();

  const xAggregation = camelCase(props.xAggregation);
  const xParameter = camelCase(props.xParameter);
  const yAggregation = camelCase(props.yAggregation);
  const yParameter = camelCase(props.yParameter);

  const dataMapper = (
    v: AssetRegisterItemAggregatedTimeSeries,
  ): AssetRegisterItemScatterColumn => {
    return {
      assetPositionId: v.assetPositionId,
      assetPositionReference: v.assetPositionReference,
      locationAddress: v.locationAddress,
      assetId: v.assetId,
      x: v.aggregations[xParameter]?.[xAggregation]?.[0],
      y: v.aggregations[yParameter]?.[yAggregation]?.[0],
    };
  };

  const seriesDefinition = (
    data: AssetRegisterAggregatedTimeSeriesResponse,
    name?: string,
    userRef?: string,
    color?: string,
  ): Highcharts.SeriesScatterOptions & AggregatedChartsAmendedOptions => {
    return {
      id: userRef,
      data: data.data.map(dataMapper),
      type: "scatter",
      color: color ? `var(--${color})` : undefined,
      animation: false,
      events: {
        click: aggregationDataItemClickHandler({
          startTime: props.startTime,
          endTime: props.endTime,
          privacyMode: privacyMode,
          companyId: props.companyId,
          schemeId: props.schemeId,
        }),
      },
      turboThreshold: 0,
      tooltip: {
        pointFormatter: function (this: AssetRegisterItemScatterPoint) {
          return (
            `<tspan style='fill:${this.color}'>●</tspan>` +
            `<tspan style='${privacyMode && "filter: url(#blur)"}'> 
${this.locationAddress} - ${this.assetPositionReference || "No Ref"}
             </tspan>` +
            `<tspan style='font-weight:bold'> ${round(this.y as number, 1)}${
              props.yUnit
            } ${round(this.x as number, 1)}${props.xUnit}</tspan>`
          );
        },
      },
    };
  };

  const series: Highcharts.SeriesScatterOptions[] = props.data
    ? [seriesDefinition(props.data)]
    : [];

  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    chart: {
      type: "scatter",
      height: height,
    },
    xAxis: props.data
      ? [
          {
            labels: {
              format: `{value}${props.xUnit}`,
            },
            title: {
              text: props.xLabel,
            },
          },
        ]
      : [],
    // xAxis: {
    //   type: "datetime",
    //   min: props.params?.startDatetime?.valueOf(),
    //   max: props.params?.endDatetime?.valueOf(),
    // },
    series: series,
    yAxis: props.data
      ? [
          {
            labels: {
              format: `{value}${props.yUnit}`,
            },
            title: {
              text: props.yLabel,
            },
          },
        ]
      : [],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: props.loading ? "Loading Data " : "No data for this time period",
    },
    noData: {
      style: {
        fontWeight: "normal",
        fontSize: "15px",
        color: "black",
      },
    },
    tooltip: {
      shared: true,
      // valueSuffix: props.unit,
      // valueDecimals: props.rounding,
      // pointFormat: `{series.name} {point.y}${props.unit}`
    },
    exporting: {
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          // enabled:
          //   props.displayExport !== undefined ? props.displayExport : true,
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadSVG",
          ],
        },
      },
    },
    time: {
      timezone: "Europe/London",
    },
  };

  return (
    <>
      <HighchartsPrivacyModeFilter />
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
}
