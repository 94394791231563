import type { Dayjs } from "dayjs";
import type Highcharts from "highcharts";
import { sum } from "lodash-es";
import React from "react";
import type { AggregatedStatsResponse } from "../../../containers/report/latestDataReport/TimeSeriesStatsApiWrapper";
import type { AggregatedTimeSeriesStatsItem } from "../../../openapi/model/aggregatedTimeSeriesStatsItem";
import { useSelectedScheme } from "../../../reducers/scheme";
import SankeyChart from "../../charts/SankeyChart";
import CSVExportButton from "../../utils/CSVExport/CSVExportButton";
import NetworkEnergyConsumptionBarChart from "./NetworkEnergyConsumptionBarChart";
import {
  type DateValueMap,
  type DeliveredEnergy,
  type DeliveredEnergyByGroup,
  EnergyDataToCSV,
  type UnaccountedEnergyByGroup,
} from "./model";

interface EnergyBalanceViewProps {
  title: string;
  data: AggregatedStatsResponse;
  startDate: Dayjs;
  endDate: Dayjs;
}

function EnergyBalancerView(props: EnergyBalanceViewProps) {
  const { data, nodes, deliveredEnergy, unaccountedEnergy } =
    convertDataToSankeyPoints(props.data, 1000000);
  const csv = EnergyDataToCSV(deliveredEnergy, unaccountedEnergy);
  const selectedScheme = useSelectedScheme();
  return (
    <>
      <div className={"d-flex justify-content-between"}>
        <h3>Energy Delivered vs Unaccounted (periodic)</h3>
        <div>
          <CSVExportButton
            /* TODO: button size */
            // size={"sm"}
            data={csv}
            columnOrder={["group", "useCase", "type"]}
            filename={`${
              selectedScheme?.schemeId
            }_energy_use_MWh_${props.startDate.format(
              "YYYY-MM-DD",
            )}_to_${props.endDate.format("YYYY-MM-DD")}.csv`}
          />
        </div>
      </div>
      <NetworkEnergyConsumptionBarChart
        deliveredEnergyData={deliveredEnergy}
        unaccountedEnergyData={unaccountedEnergy}
        unit={"MWh"}
      />
      <h3>Energy Delivered vs Unaccounted (by group)</h3>
      <SankeyChart unit={"MWh"} data={data} nodes={nodes} />
    </>
  );
}

function convertDataToSankeyPoints(
  data: AggregatedStatsResponse,
  scaling = 1,
): {
  data: Array<Highcharts.SeriesSankeyPointOptionsObject>;
  nodes: Array<Highcharts.SeriesSankeyNodesOptionsObject>;
  deliveredEnergy: DeliveredEnergyByGroup;
  unaccountedEnergy: UnaccountedEnergyByGroup;
} {
  const entries = Object.fromEntries(
    Object.entries(data).map(([group, d]) => [
      group,
      createSankeyItemsForGroup(group, d, Object.keys(data), scaling),
    ]),
  );

  return {
    data: Object.entries(entries)
      .flatMap(([_group, v]) => [v.thisLevel, v.unaccounted, v.endConsumption])
      .flat(),
    nodes: [
      ...Object.entries(entries)
        .flatMap(([_group, v]) => v.nodes)
        .flat(),

      { id: "Energy Centre", color: "#052e16" },
      { id: "DELIVERED", color: "#166534" },
      // {id: "DELIVERED", column: 5},
      // {id: "UNACCOUNTED", column: 4}
    ],
    deliveredEnergy: Object.fromEntries(
      Object.entries(entries).map(([group, v]) => [
        group,
        v.deliveredEnergyByPeriod,
      ]),
    ),
    unaccountedEnergy: Object.fromEntries(
      Object.entries(entries).map(([group, v]) => [
        group,
        v.unaccountedEnergyByPeriod,
      ]),
    ),
  };
}

function createSankeyItemsForGroup(
  group: string,
  d: {
    [useCase: string]: { [datetime: string]: AggregatedTimeSeriesStatsItem };
  },
  allGroups: string[],
  scaling = 1,
): {
  thisLevel: Highcharts.SeriesSankeyPointOptionsObject[];
  endConsumption: Highcharts.SeriesSankeyPointOptionsObject[];
  unaccounted: Highcharts.SeriesSankeyPointOptionsObject[];
  nodes: Highcharts.SeriesSankeyNodesOptionsObject[];
  deliveredEnergyByPeriod: DeliveredEnergy;
  unaccountedEnergyByPeriod: DateValueMap;
} {
  let thisLevel: Highcharts.SeriesSankeyPointOptionsObject[] = [];
  let unaccounted: Highcharts.SeriesSankeyPointOptionsObject[] = [];
  let endConsumption: Highcharts.SeriesSankeyPointOptionsObject[] = [];

  let deliveredEnergyByPeriod: DeliveredEnergy = {};
  let unaccountedEnergyByPeriod: DateValueMap = {};
  let nodes: Highcharts.SeriesSankeyNodesOptionsObject[] = [];

  const groupParts = group.split("/");

  const hasProcess = Object.keys(d).indexOf("PROCESS") !== -1;
  const hasChildren = allGroups.filter((v) => v.startsWith(group)).length > 1;
  const hasParent = groupParts.length > 1;
  const parent =
    groupParts.length > 1
      ? groupParts.slice(0, groupParts.length - 1).join("/")
      : "Energy Centre";

  for (const [useCase, useCaseData] of Object.entries(d)) {
    if (useCase !== "PROCESS" && !hasChildren) {
      endConsumption = [
        ...endConsumption,
        {
          to: "DELIVERED",
          from: `${group} ${useCase}`,
          weight: sumOverallEntriesForGroup(useCaseData) / scaling,
          color: "#15803d",
        },
      ];

      deliveredEnergyByPeriod = {
        ...deliveredEnergyByPeriod,
        [useCase]: Object.fromEntries(
          Object.entries(useCaseData).map(([d, v]) => [
            d,
            (v.sum || 0) / scaling,
          ]),
        ),
      };
    }
    nodes = [
      ...nodes,
      {
        id: `${group} ${useCase}`,
        color: "#14532d",
        // column: (groupParts.length - 1) * 2 + (useCase === "PROCESS" ? 0 : 1) + 1
      },
    ];

    thisLevel = [
      ...thisLevel,
      {
        to: `${group} ${useCase}`,
        from:
          useCase === "PROCESS"
            ? hasParent
              ? `${parent} ${useCase}`
              : parent
            : hasParent
              ? `${parent} ${useCase}`
              : hasProcess
                ? `${group} PROCESS`
                : "Energy Centre",
        weight: sumOverallEntriesForGroup(useCaseData) / scaling,
        color: "#15803d",
      },
    ];
  }

  if (hasProcess) {
    const process = sumOverallEntriesForGroup(d.PROCESS) / scaling;
    const delivered = sumNoneProcessEntries(d) / scaling;

    unaccountedEnergyByPeriod = Object.entries(d).reduce(
      (unaccounted: any, [useCase, useCaseData]) => {
        for (const [date, dateSum] of Object.entries(useCaseData)) {
          if (!unaccounted[date]) {
            unaccounted[date] = 0;
          }
          unaccounted[date] +=
            (useCase === "PROCESS" ? dateSum.sum || 0 : -(dateSum.sum || 0)) /
            scaling;
        }
        return unaccounted;
      },
      {},
    );

    nodes = [
      ...nodes,
      {
        id: `${group} UNACCOUNTED`,
        color: "#7f1d1d",
      },
    ];

    unaccounted = [
      ...unaccounted,
      //     {
      //     to: `UNACCOUNTED`,
      //     from: `${group} UNACCOUNTED`,
      //     weight: process - delivered,
      //     color: '#ff0000'
      // },
      {
        to: `${group} UNACCOUNTED`,
        from: `${group} PROCESS`,
        weight: process - delivered,
        color: "#b91c1c",
      },
    ];
  }

  return {
    thisLevel,
    endConsumption,
    unaccounted,
    nodes,
    deliveredEnergyByPeriod,
    unaccountedEnergyByPeriod,
  };
}

function sumNoneProcessEntries(d: {
  [useCase: string]: { [datetime: string]: AggregatedTimeSeriesStatsItem };
}): number {
  return sum(
    Object.entries(d).map(([useCase, useCaseData]) => {
      if (useCase === "PROCESS") {
        return 0;
      } else {
        return sumOverallEntriesForGroup(useCaseData);
      }
    }),
  );
}

function sumOverallEntriesForGroup(d: {
  [datetime: string]: AggregatedTimeSeriesStatsItem;
}): number {
  return sum(Object.entries(d).map((v) => v[1].sum || 0));
}

export class StatsGroup {
  name: string;
  stats: AggregatedTimeSeriesStatsItem;
  children: { [name: string]: StatsGroup };

  constructor(name: string, stats: AggregatedTimeSeriesStatsItem) {
    this.name = name;
    this.stats = stats;
    this.children = {};
  }

  addChild(child: StatsGroup) {
    if (!child.name.startsWith(`${this.name}/`)) {
      throw new Error(`${child.name} is not a child of ${this.name}`);
    }
    this.children = { ...this.children, [child.name]: child };
  }
}

export default EnergyBalancerView;
