import type React from "react";
import { StackedListTwoColumnItem } from "../../../components/Theme/stacked-list";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates";
import type { WirelessSurveyOut } from "../../../openapi/model/wirelessSurveyOut";

export default function WirelessSurveyListRowInner(props: {
  wirelessSurvey: WirelessSurveyOut;
}): React.ReactElement {
  const { wirelessSurvey } = props;

  return (
    <StackedListTwoColumnItem
      column1={
        <div className="min-w-0 flex-auto">
          <CompanyNavLink to={{ pathname: `view/${wirelessSurvey.surveyId}` }}>
            <h5 className={"mb-0"}>{wirelessSurvey.surveyName}</h5>
          </CompanyNavLink>
          <p className="text-sm mb-0 mt-1">{wirelessSurvey.surveyId}</p>
        </div>
      }
      column2={<div>{formatDate(wirelessSurvey.createdAt)}</div>}
      column1Size={"sm:w-100"}
      column2Size={"sm:w-0"}
    />
  );
}
