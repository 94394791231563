import dayjs, { type Dayjs } from "dayjs";
import type { ShortcutsItem } from "react-tailwindcss-datepicker/dist/types";

export interface PresetDay {
  text: string;
  day: Dayjs;
}

const startOfMonth = dayjs().startOf("month");
const startOfToday = dayjs().startOf("day");
const startOfWeek = dayjs().startOf("week");

export const LAST_CALENDAR_YEAR: ShortcutsItem = {
  text: "Last Calendar Year",
  period: {
    start: dayjs(startOfMonth).subtract(12, "month").toDate(),
    end: dayjs(startOfMonth).subtract(1, "second").toDate(),
  },
};

export const LAST_3_CALENDAR_MONTHS: ShortcutsItem = {
  text: "Last 3 Calendar Months",
  period: {
    start: dayjs(startOfMonth).subtract(3, "month").toDate(),
    end: dayjs(startOfMonth).subtract(1, "second").toDate(),
  },
};
export const LAST_CALENDAR_MONTH: ShortcutsItem = {
  text: "Last Calendar Month",
  period: {
    start: dayjs(startOfMonth).subtract(1, "month").toDate(),
    end: dayjs(startOfMonth).subtract(1, "second").toDate(),
  },
};

export const LAST_28_DAYS: ShortcutsItem = {
  text: "Last 28 Days",
  period: {
    start: dayjs(startOfToday).subtract(28, "days").toDate(),
    end: dayjs(startOfToday).subtract(1, "second").toDate(),
  },
};

export const LAST_CALENDAR_WEEK: ShortcutsItem = {
  text: "Last Calendar Week",
  period: {
    start: dayjs(startOfWeek).subtract(1, "week").toDate(),
    end: dayjs(startOfWeek).subtract(1, "second").toDate(),
  },
};

export const LAST_7_DAYS: ShortcutsItem = {
  text: "Last 7 days",
  period: {
    start: dayjs(startOfToday).subtract(6, "days").toDate(),
    end: dayjs(startOfToday).subtract(1, "second").toDate(),
  },
};

export const YESTERDAY = {
  text: "Yesterday",
  day: dayjs(startOfToday).subtract(1, "day"),
};

const endOfMonth = (number = 0): PresetDay => {
  const day = dayjs(startOfMonth).subtract(number, "month").subtract(1, "day");
  return {
    text: `End of ${day.format("MMMM")}`,
    day: day,
  };
};

const endOfYear = (number = 0): PresetDay => {
  const day = dayjs(startOfMonth).subtract(number, "year").subtract(1, "day");
  return {
    text: `End of ${day.format("YYYY")}`,
    day: day,
  };
};

export const END_OF_LAST_MONTH = endOfMonth();
export const END_OF_PREVIOUS_MONTH = endOfMonth(1);
export const END_OF_PREVIOUS_PREVIOUS_MONTH = endOfMonth(2);

export const END_OF_LAST_YEAR = endOfYear();

export const DEFAULT_PRESETS: ShortcutsItem[] = [
  LAST_CALENDAR_WEEK,
  LAST_28_DAYS,
  LAST_CALENDAR_MONTH,
  LAST_3_CALENDAR_MONTHS,
];

export const DEFAULT_DAY_PRESETS = [
  YESTERDAY,
  END_OF_LAST_MONTH,
  END_OF_PREVIOUS_MONTH,
  END_OF_PREVIOUS_PREVIOUS_MONTH,
  END_OF_LAST_YEAR,
];

export default DEFAULT_PRESETS;
