import type { AxiosError } from "axios";
import { range } from "lodash-es";
import type React from "react";
import AlertBanner from "../Banner/AlertBanner";
import BlockSpinner from "../Spinners/BlockSpinner";
import { Heading } from "../Theme/heading";
import { StackedList, StackedListItem } from "../Theme/stacked-list";

export interface ListProps {
  data?: Array<any>;
  loading?: boolean;
  header?: React.ReactElement;
  row: (item: any, index: number, refresh?: () => void) => React.ReactElement;
  "data-cy"?: string; // optional cypress label for the table
  tableClassName?: string;
  noDataRow?: React.ReactElement;
  error?: Error | null;
  refresh?: () => void;
  missingElements?: number;
}

function List(props: ListProps) {
  const error = props.error;
  const header = props.header;

  const displayError = (error: Error | AxiosError): React.ReactElement => {
    if (error && Object.prototype.hasOwnProperty.call(error, "isAxiosError")) {
      const err = error as AxiosError;
      const message = err.message;
      return errorRow(message, err.response?.status);
    } else {
      return errorRow(error.message, undefined);
    }
  };

  const errorRow = (message: string, code?: number): React.ReactElement => {
    const color = !code || code >= 500 ? "bg-danger" : "bg-warning";
    const serverError = code && code >= 500;
    return (
      <StackedListItem key={"list-error"}>
        <AlertBanner className={color}>
          <Heading level={5}>
            {serverError ? "Server Error" : "An error has occurred"}.
          </Heading>
          <p>
            The sever has responded: {message} {code && <>({code})</>}.
          </p>
        </AlertBanner>
      </StackedListItem>
    );
  };
  return (
    <BlockSpinner loading={props.loading || false} scrollX={false}>
      <StackedList data-cy={props["data-cy"]}>
        {header}

        {props.data?.map((value, index) => {
          return props.row(value, index, props.refresh);
        })}
        {props.loading &&
          range(props.missingElements || 0).map((i) => (
            <StackedListItem
              key={i.toString()}
              data-cy={props["data-cy"] && `${props["data-cy"]}-loading-item`}
            />
          ))}
        {!props.loading && props.data?.length === 0 && !error && (
          <StackedListItem key={"no-data"}>
            {props.noDataRow || "No Data Found"}
          </StackedListItem>
        )}
        {!props.loading && error && displayError(error)}
      </StackedList>
    </BlockSpinner>
  );
}

export default List;
