import * as Sentry from "@sentry/react";
import React, { createContext, useReducer } from "react";
import { signOut } from "../actions/helpers/auth";

interface AuthState {
  authenticating: boolean;
  authenticated: boolean;
  authError?: Error;
}

type AuthActions =
  | "AUTHENTICATING"
  | "AUTHENTICATING_SUCCESS"
  | "AUTHENTICATING_ERROR"
  | "LOGOUT";

export interface AuthAction {
  type: AuthActions;
  payload?: any;
}

const Reducer = (state: AuthState, action: AuthAction): AuthState => {
  console.log(action);
  switch (action.type) {
    case "AUTHENTICATING":
      return {
        ...state,
        authenticating: true,
        authenticated: false,
        authError: undefined,
      };

    case "AUTHENTICATING_SUCCESS":
      return {
        ...state,
        authenticating: false,
        authenticated: true,
        authError: undefined,
      };

    case "AUTHENTICATING_ERROR":
      return {
        ...state,
        authenticating: false,
        authenticated: false,
        authError: action.payload,
      };

    case "LOGOUT":
      return {
        ...state,
        authenticated: false,
        authError: undefined,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<{
  state: AuthState;
  dispatch: React.Dispatch<AuthAction>;
}>({
  state: { authenticating: true, authenticated: false },
  dispatch: () => null,
});

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(Reducer, {
    authenticating: true,
    authenticated: false,
  });
  console.log("consider auth");

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuthState(): AuthState {
  return React.useContext(AuthContext).state;
}

function logout(dispatch: React.Dispatch<AuthAction>) {
  Sentry.setUser(null);
  return signOut().then(() => dispatch({ type: "LOGOUT" }));
}

export { AuthProvider, AuthContext, useAuthState, logout };
