import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type React from "react";
import type { RequireExactlyOne } from "type-fest";
import useUpdateEntity, {
  type UpdateEntityProps,
} from "../../hooks/updateEntity";
import ErrorModal from "../Error/ErrorModal";
import { Button, type ButtonSizingProps, type styles } from "../Theme/button";

type UpdateButtonProps = RequireExactlyOne<
  ButtonSizingProps & {
    errorMessageBody?: React.ReactElement;
    updatedEntity: any;
    disabled?: boolean;
    icon?: IconProp;
    text?: string;
    color?: keyof typeof styles.colors;
    children?: React.ReactNode;
  },
  "children" | "text"
>;

const UpdateButton = (
  props: UpdateEntityProps & UpdateButtonProps,
): React.ReactElement => {
  const {
    entityType,
    entityId,
    postUpdate,
    path,
    errorMessageBody,
    updatedEntity,
    disabled,
    icon,
    children,
    text,
    ...buttonProps
  } = props;
  const { updating, updateEntity, error } = useUpdateEntity(
    entityType,
    entityId,
    postUpdate,
    path,
  );

  return (
    <>
      <ErrorModal error={error} body={errorMessageBody} />
      <Button
        onClick={() => updateEntity(updatedEntity)}
        disabled={updating || disabled || false}
        {...buttonProps}
      >
        <>
          {icon && (
            <FontAwesomeIcon
              icon={updating ? faSpinner : icon}
              fixedWidth={true}
              className={"mr-1"}
              spin={updating}
            />
          )}
          {children || text}
        </>
      </Button>
    </>
  );
};

export default UpdateButton;
