import dayjs, { type Dayjs } from "dayjs";
import type React from "react";
import {
  LAST_3_CALENDAR_MONTHS,
  LAST_7_DAYS,
  LAST_CALENDAR_WEEK,
  LAST_CALENDAR_YEAR,
} from "./DateRangePickerPresets";

import clsx from "clsx";
import { useField } from "formik";
import { isNil } from "lodash-es";
import Datepicker from "react-tailwindcss-datepicker";
import type { DateValueType } from "react-tailwindcss-datepicker/dist/types";
import NewLabelWrapper, { type LabelProps } from "../NewLabelWrapper";
import { allInputStyles, inputStyles } from "../styles";

import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import dateRangePickerStyles from "./DateRangePicker.module.css";

dayjs.extend(utc);
dayjs.extend(timezone);

export interface Common {
  minDate?: Dayjs;
  maxDate?: Dayjs;
  clearable?: boolean;
  minDays?: number;
  showPresets?: boolean;
  placeholder?: string;
  timeZone?: string;
  label?: LabelProps;
}

type PropsTypes = (
  | {
      startDateName?: never; // name of the form field for the startDate
      endDateName: string; // name of the form field for the endDate
      single?: true;
    }
  | {
      startDateName?: string; // name of the form field for the startDate
      endDateName: string; // name of the form field for the endDate
      single?: never;
    }
) &
  Common;

export default function DateRangePicker(props: PropsTypes): React.ReactElement {
  const startDate = useField(props.startDateName || "unused");
  const endDate = useField(props.endDateName);

  const useStartDate = props.startDateName !== undefined;
  const {
    timeZone = "Europe/London",
    showPresets = true,
    single = false,
  } = props;

  const handleSelect = async (value: DateValueType): Promise<any> => {
    if (isNil(value)) {
      const ed = endDate[2].setValue(undefined);
      return useStartDate
        ? startDate[2].setValue(undefined).then(() => {
            return ed;
          })
        : ed;
    } else {
      const sd = useStartDate
        ? value.startDate
          ? startDate[2].setValue(
              dayjs(value.startDate).tz(timeZone).startOf("day"),
            )
          : startDate[2].setValue(null)
        : Promise.resolve();
      const ed = value.endDate
        ? single
          ? endDate[2].setValue(
              dayjs(value.endDate).tz(timeZone).startOf("day"),
            )
          : endDate[2].setValue(dayjs(value.endDate).tz(timeZone).endOf("day"))
        : endDate[2].setValue(null);
      await sd;
      return ed;
    }
  };

  const disabledDates = [
    props.minDate && {
      endDate: props.minDate.toDate(),
      startDate: new Date(-8640000000000000),
    },
    props.maxDate && {
      startDate: props.maxDate.add(1, "second").toDate(),
      endDate: new Date(8640000000000000),
    },
  ].filter((v) => v !== undefined);

  return (
    <NewLabelWrapper {...props.label}>
      <Datepicker
        value={{
          startDate: useStartDate
            ? startDate[0].value?.toDate()
            : endDate[0].value?.toDate(),
          endDate: endDate[0].value?.toDate(),
        }}
        containerClassName={clsx(
          dateRangePickerStyles.tailwindDatepicker,
          "relative w-full ",
          inputStyles.text,
        )}
        inputClassName={clsx(
          allInputStyles,
          "relative pr-14 w-full " +
            "tracking-wide " +
            "focus:ring-1 focus:border-0 hover:ring-1 hover:border-0 hover:ring-gray-300 " +
            "disabled:cursor-not-allowed focus:ring-gray-300 ",
        )}
        startFrom={
          useStartDate
            ? startDate[0].value?.toDate()
            : endDate[0].value?.toDate()
        }
        primaryColor={"cyan"}
        onChange={handleSelect}
        readOnly={true}
        displayFormat={"DD/MM/YYYY"}
        showShortcuts={true}
        asSingle={single}
        placeholder={props.placeholder}
        configs={{
          shortcuts: showPresets
            ? {
                last7Days: LAST_7_DAYS,
                lastWeek: LAST_CALENDAR_WEEK,
                last28Days: LAST_CALENDAR_WEEK,
                lastCalendarMonth: LAST_CALENDAR_WEEK,
                last3Months: LAST_3_CALENDAR_MONTHS,
                last12Months: LAST_CALENDAR_YEAR,
              }
            : {},
        }}
        disabledDates={disabledDates}
      />
    </NewLabelWrapper>
  );
}
