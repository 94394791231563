import type { TariffInstanceOut } from "../../openapi/model/tariffInstanceOut";
import type { TariffInstanceRates } from "../../openapi/model/tariffInstanceRates";

type TariffServiceDescription = Omit<
  TariffInstanceRates,
  | "unitConsumptionRate"
  | "unitConsumptionVatRate"
  | "standingChargeRate"
  | "standingChargeVatRate"
>;
type TariffServiceDescriptionMap = Record<string, TariffServiceDescription>;

function getTariffServices(
  tariffInstances: Array<TariffInstanceOut>,
): TariffServiceDescriptionMap {
  let serviceMap = {};

  for (const instance of tariffInstances) {
    for (const rate of instance.rates) {
      if (!(rate.serviceName in serviceMap)) {
        serviceMap = { ...serviceMap, [rate.serviceName]: rate };
      }
    }
  }

  return serviceMap;
}

export type { TariffServiceDescription, TariffServiceDescriptionMap };
export { getTariffServices };
