import clsx from "clsx";
import type React from "react";

type HeadingProps = {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  className?: string;
} & React.ComponentPropsWithoutRef<"h1" | "h2" | "h3" | "h4" | "h5" | "h6">;

export function Heading({ className, level = 1, ...props }: HeadingProps) {
  const Element: `h${typeof level}` = `h${level}`;

  const sizes = {
    1: "text-2xl/8",
    2: "text-lg/8",
    3: "text-md/8",
    4: "text-md/8",
    5: "text-md/8",
    6: "text-md/8",
  };
  return (
    <Element
      {...props}
      className={clsx(
        className,
        sizes[level],
        "font-semibold text-zinc-950 sm:text-xl/8 dark:text-white",
      )}
    />
  );
}

export function SubHeading({ className, level = 2, ...props }: HeadingProps) {
  const Element: `h${typeof level}` = `h${level}`;

  return (
    <Element
      {...props}
      className={clsx(
        className,
        "text-base/7 font-semibold text-zinc-950 sm:text-sm/6 dark:text-white",
      )}
    />
  );
}

export function PageHeadingBlock(
  props: React.PropsWithChildren<{
    className?: string;
  }>,
) {
  return (
    <div
      className={clsx(
        "mt-2 md:flex md:items-center md:justify-between",
        props.className,
      )}
    >
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {props.children}
        </h2>
      </div>
      {/*<div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">*/}
      {/*  <button*/}
      {/*      type="button"*/}
      {/*      className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"*/}
      {/*  >*/}
      {/*    Edit*/}
      {/*  </button>*/}
      {/*  <button*/}
      {/*      type="button"*/}
      {/*      className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"*/}
      {/*  >*/}
      {/*    Publish*/}
      {/*  </button>*/}
      {/*</div>*/}
    </div>
  );
}
