import type React from "react";
import GetListPaginatedList from "../../../components/Pagination/GetListPaginatedList";
import { Button } from "../../../components/Theme/button";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { companyObjectPageTitle } from "../../../hooks/setPageTitle";
import { useSelectedCompany } from "../../../reducers/company";
import WirelessSurveyListRowInner from "./WirelessSurveyListRow";

export default function WirelessSurveyListPage(): React.ReactElement {
  const company = useSelectedCompany();

  return (
    <OneCardPage
      headerTitle={"Wireless Surveys"}
      breadcrumbs={[
        {
          url: "/admin/wireless_survey",
          name: "Wireless Surveys",
        },
      ]}
      setPageTitle={companyObjectPageTitle("Wireless Surveys")}
    >
      <GetListPaginatedList
        entityType={"WIRELESS_SURVEY"}
        params={{ companyId: company?.companyId }}
        cursor={{ maxItems: 25 }}
        data-cy={"wireless-survey-list"}
        row={(d) => <WirelessSurveyListRowInner wirelessSurvey={d} />}
        additionalFunctionButtons={
          <CompanyNavLink to={{ pathname: "./create" }}>
            <Button color={"brandLight"}>Create New Survey</Button>
          </CompanyNavLink>
        }
      />
    </OneCardPage>
  );
}
