import clsx from "clsx";
import type React from "react";

interface CardProps extends React.PropsWithChildren {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  iconBlock?: React.ReactNode;
  className?: string;
  py?: string;
}

export default function Card(props: CardProps): React.ReactElement {
  return (
    <div
      className={clsx(
        " overflow-hidden bg-white rounded-lg shadow",
        props.className,
      )}
    >
      <div className={clsx("flex", props.iconBlock && "justify-between")}>
        <div>
          {(props.title || props.subtitle) && (
            <div className="md:px-4 pt-5 px-6 ">
              <TitleBlock title={props.title} subtitle={props.subtitle} />
            </div>
          )}
        </div>
        {props.iconBlock && (
          <div className={"pt-4 md:px-5 px-6"}>{props.iconBlock}</div>
        )}
      </div>
      <div className={clsx("px-4", props.py || "py-2")}>{props.children}</div>
    </div>
  );
}

function TitleBlock(props: {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}): React.ReactElement {
  return (
    <>
      {props.title && (
        <div
          className={clsx(
            props.subtitle
              ? "text-gray-600 text-xs uppercase"
              : "text-brand-dark-900 font-bold",
          )}
        >
          {props.title}
        </div>
      )}
      {props.subtitle && (
        <div className={"text-brand-dark-900 font-bold"}>{props.subtitle}</div>
      )}
    </>
  );
}

const heights = {
  28: "max-h-28 h-28",
  32: "max-h-32 h-32",
  36: "max-h-36 h-36",
  40: "max-h-40 h-40",
  44: "max-h-44 h-44",
  48: "max-h-48 h-48",
  52: "max-h-52 h-52",
  56: "max-h-56 h-56",
  60: "max-h-60 h-60",
  64: "max-h-64 h-64",
  72: "max-h-72 h-72",
  80: "max-h-80 h-80",
  96: "max-h-84 h-96",
};

interface FixHeightCardBodyProps extends React.PropsWithChildren {
  height?: keyof typeof heights;
  className?: string;
  overflowScroll?: boolean;
}

export function FixHeightCardBody(
  props: FixHeightCardBodyProps,
): React.ReactElement {
  const { height = 60, overflowScroll = true } = props;
  return (
    <div
      className={clsx(
        heights[height],
        props.className,
        overflowScroll && "overflow-y-auto",
      )}
    >
      {props.children}
    </div>
  );
}
