import { isArray } from "lodash-es";
import type React from "react";
import { ErrorBanner } from "./ErrorBanner";

const ErrorOverlappingBanner = (props: {
  error?: Error | Error[];
  children: React.ReactNode;
}): React.ReactElement => {
  const { error, children } = props;
  return (
    <>
      {error && (isArray(error) ? error.length > 0 : true) ? (
        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div
            style={{
              boxSizing: "border-box",
              width: "90%",
              flex: "none",
              marginLeft: "5%",
              marginRight: "5%",
              zIndex: 1,
            }}
          >
            {isArray(error) ? (
              error.map((e, i) => <ErrorBanner error={e} key={e.name} />)
            ) : (
              <ErrorBanner error={error} />
            )}
          </div>
          <div
            style={{
              boxSizing: "border-box",
              width: "100%",
              flex: "none",
              marginLeft: "-100%",
            }}
          >
            {children}
          </div>
          :
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default ErrorOverlappingBanner;
