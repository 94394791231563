import dayjs from "dayjs";
import { DenseAssetRegisterListItem } from "pages/assetRegister/AssetRegisterListPage/AssetRegisterListItem";
import type React from "react";
import CompanyNavLink from "../../../../components/navigation/CompanyNavLink";
import HighlightNullAndOldDates from "../../../../components/utils/HighlightNullAndOldDates";
import { formatDataCollectionFrequency } from "../../../../model/assets/deviceData";
import type { Asset } from "../../../../openapi/model/asset";
import type { AssetRegisterItem } from "../../../../openapi/model/assetRegisterItem";
import type { GatewayAsset } from "../../../../openapi/model/gatewayAsset";
import { GatewaySettingsType } from "../../../../openapi/model/gatewaySettingsType";
import type { GatewayState } from "../../../../openapi/model/gatewayState";
import GatewayStateLabel from "./GatewayStateLabel";

const GatewayRows = (gateway: GatewayAsset): React.ReactElement => {
  return (
    <>
      <td data-cy={"gateway-asset-id"}>
        <CompanyNavLink to={{ pathname: `/admin/asset/${gateway?.assetId}` }}>
          ...{gateway?.assetId.slice(-8)}
        </CompanyNavLink>
      </td>
      <td data-cy={"gateway-serial-number"}>{gateway?.serialNumber}</td>
      <td data-cy={"gateway-last-contact"}>
        <HighlightNullAndOldDates ageHighlight={dayjs.duration(3, "days")}>
          {gateway?.lastContact}
        </HighlightNullAndOldDates>
      </td>
      <td data-cy={"gateway-expected-connectivity"}>
        {formatDataCollectionFrequency(
          gateway?.configuration?.collectionFrequency,
        )}
      </td>
      <td data-cy={"gateway-model"}>{gateway?.model}</td>
      <td data-cy={"gateway-ip-address"}>{gateway?.ipAddress}</td>
      <td data-cy={"gateway-manufacturer"}>{gateway?.manufacturer}</td>
      <td data-cy={"gateway-mac-address"}>{gateway?.macAddress}</td>
      <td data-cy={"gateway-firmware-version"}>{gateway?.firmwareVersion}</td>
    </>
  );
};

function ReportDetail(props: {
  title: string;
  value?: string;
}): React.ReactElement {
  return (
    <p className={"ml-2 text-nowrap text-gray-500 text-xs"}>
      <span className={"font-bold"}>{props.title}</span>:{" "}
      <span className={"text-muted"}>{props.value || "Unknown"}</span>
    </p>
  );
}

function gatewayStatusColumn(gateway?: GatewayAsset): React.ReactElement {
  if (!gateway) {
    return <>No gateway</>;
  }
  return (
    <div className={"min-w-0 flex-auto"}>
      <p data-cy={"gateway-last-contact"}>
        <HighlightNullAndOldDates ageHighlight={dayjs.duration(3, "days")}>
          {gateway?.lastContact}
        </HighlightNullAndOldDates>
      </p>
      <p>
        {gateway && (
          <GatewayStateLabel
            size={"sm"}
            short={true}
            state={(gateway.state as GatewayState).configurationState}
          />
        )}
      </p>
    </div>
  );
}

function gatewayDetailsColumn(
  gateway?: GatewayAsset,
  actions?: React.ReactNode,
): React.ReactElement {
  if (!gateway) {
    return <></>;
  }
  return (
    <div className={"flex grow"}>
      <div className={"hidden lg:block grow"}>
        <div className={"flex flex-wrap min-w-0 pb-2 "}>
          <div
            className={"ml-2 text-gray-500 text-xs"}
            data-cy={"gateway-expected-connectivity"}
          >
            <b>Target</b>:{" "}
            {formatDataCollectionFrequency(
              gateway?.configuration?.collectionFrequency,
              true,
            )}
          </div>
          <ReportDetail
            title={"Model"}
            value={`${gateway.manufacturer}/${gateway.model}`}
          />
        </div>
        <div className={"flex flex-wrap min-w-0"}>
          <ReportDetail title={"Firmware"} value={gateway.firmwareVersion} />
          <ReportDetail title={"SIM"} value={gateway.simCardIccid} />
          <ReportDetail title={"IP"} value={gateway.ipAddress} />
          <ReportDetail
            title={"Config"}
            value={
              (gateway.configuration.manufacturerSpecificSettings
                ?.mbusSettings || GatewaySettingsType.ELVACODEFAULT) ===
              GatewaySettingsType.ELVACODEFAULT
                ? "Default"
                : "CMi Wireless"
            }
          />
        </div>
      </div>
      {actions && <div className={"grow-0"}>{actions}</div>}
    </div>
  );
}

export default function GatewayConnectivityReportDataRow(props: {
  assetRegisterItem: AssetRegisterItem;
  showScheme?: boolean;
  actionButtons?: (props: {
    assetRegisterItem: AssetRegisterItem;
  }) => React.ReactNode;
}): React.ReactElement {
  const { assetRegisterItem, showScheme = true } = props;
  return (
    <DenseAssetRegisterListItem
      assetRegisterItem={assetRegisterItem}
      showScheme={showScheme}
      showType={false}
      column2={({ assetRegisterItem }) => {
        return (
          <>
            {gatewayStatusColumn(
              assetRegisterItem.asset as GatewayAsset | undefined,
            )}
          </>
        );
      }}
      column3={({ assetRegisterItem }) => {
        return (
          <div className={"hidden md:block"}>
            {gatewayDetailsColumn(
              assetRegisterItem.asset as GatewayAsset | undefined,
              props.actionButtons?.({ assetRegisterItem }),
            )}
          </div>
        );
      }}
    />
  );
}

export const UnassignedGatewayConnectivityReportDataRow = (value: Asset) => {
  return <tr>{GatewayRows(value as GatewayAsset)}</tr>;
};
