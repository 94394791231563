import type React from "react";
import "./PageSpinner.css";

export default function PageSpinner(props: {
  children: React.ReactNode;
  spin: boolean;
}): React.ReactElement {
  return (
    <>
      {props.spin && (
        <div className="spinner" data-cy="page-spinner">
          <ul className="bokeh">
            <li />
            <li />
            <li />
            <li />
          </ul>
        </div>
      )}
      {props.children}
    </>
  );
}
