import React from "react";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { Heading } from "../../../components/Theme/heading";
import type { WirelessSurveyDataCollectorOut } from "../../../openapi/model/wirelessSurveyDataCollectorOut";
import type { WirelessSurveyOut } from "../../../openapi/model/wirelessSurveyOut";
import WirelessSurveyDataCollectorCreateForm from "./WirelessSurveyDataCollectorCreateForm";
import WirelessSurveyDataCollectorsList from "./WirelessSurveyDataCollectorsList";

function WirelessSurveyDataCollectorsTab(props: {
  survey: WirelessSurveyOut;
  dataCollectors?: WirelessSurveyDataCollectorOut[];
  loading: boolean;
  refresh?: () => any;
}) {
  const { dataCollectors, loading, survey } = props;
  return (
    <>
      <BlockSpinner loading={loading}>
        {!loading && (
          <>
            <Heading>Data Collectors</Heading>
            <WirelessSurveyDataCollectorsList
              dataCollectors={dataCollectors || []}
              refresh={props.refresh}
            />

            <Heading>Add New Data Collector</Heading>
            <WirelessSurveyDataCollectorCreateForm
              survey={survey}
              refresh={props.refresh}
            />
          </>
        )}
      </BlockSpinner>
    </>
  );
}

export default WirelessSurveyDataCollectorsTab;
