import clsx from "clsx";
import { Form, Formik, type FormikProps } from "formik";
import { isArray, isString } from "lodash-es";
import { Status } from "openapi/model/status";
import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AssetUseCaseSelect from "../../../../components/Asset/AssetUseCaseSelect";
import { ButtonGroupField } from "../../../../components/Forms/ButtonGroup";
import NewTextFormComponent from "../../../../components/Forms/NewTextFormComponent";
import LocationGroupSelect from "../../../../components/LocationGroup/LocationGroupSelect";
import { Button } from "../../../../components/Theme/button";
import { Col, Container } from "../../../../components/Theme/grid";
import {
  convertStringUndefined,
  createSearchString,
  parseSearchString,
} from "../../../../components/navigation/SearchString";
import useSetFormValueOnSchemeChange from "../../../../hooks/setFormValueOnSchemeChange";
import { AssetUseCase } from "../../../../openapi/model/assetUseCase";
import AssetTypeSelect from "../AssetTypeSelect";

type Props = {
  includeNonCreateables?: boolean;
  filterUnassignedField?: string;
  includeTextSearch?: boolean;
  includeGroup?: boolean;
  includeUseCase?: boolean;
  includeStatus?: boolean;
};

type State = {
  assetType?: string | string[];
  useCase?: AssetUseCase | AssetUseCase[];
  assetPositionId?: string;
  assetId?: string;
  textSearch?: string;
  group?: string;
  status?: Status;
  ready: boolean;
};

export default function AssetListFilterForm(props: Props): React.ReactElement {
  const [state, setState] = useState<State>({ ready: false });

  const location = useLocation();
  const navigate = useNavigate();
  const formikRef = useRef<FormikProps<Record<string, unknown>>>(null);

  const {
    filterUnassignedField = "assetPositionId",
    includeNonCreateables = true,
    includeTextSearch = false,
    includeGroup = true,
    includeUseCase = true,
    includeStatus = true,
  } = props;

  const setFiltersToRoute = useCallback(
    (values: any): void => {
      const newSearchString = createSearchString(
        convertStringUndefined({ ...values }),
      );

      if (
        location.search === ""
          ? newSearchString !== ""
          : `?${newSearchString}` !== location.search
      ) {
        console.log("update search", newSearchString);
        navigate({
          ...location,
          search: newSearchString,
        });
      }
    },
    [location, navigate],
  );

  useEffect(() => {
    const queryParams = parseSearchString(location.search);
    const query = {
      assetType: (
        isString(queryParams.assetType)
          ? queryParams.assetType
          : isArray(queryParams.assetType)
      )
        ? (queryParams.assetType as string[])
        : "undefined",
      useCase: isString(queryParams.useCase)
        ? (queryParams.useCase as AssetUseCase)
        : isArray(queryParams.useCase)
          ? (queryParams.useCase as AssetUseCase[])
          : [
              AssetUseCase.NONE,
              AssetUseCase.DOMESTIC,
              AssetUseCase.PROCESS,
              AssetUseCase.COMMERCIAL,
            ],
      [filterUnassignedField]: isString(queryParams[filterUnassignedField])
        ? (queryParams[filterUnassignedField] as string)
        : "undefined",
      textSearch:
        includeTextSearch && isString(queryParams.textSearch)
          ? queryParams.textSearch
          : undefined,
      group:
        includeGroup && isString(queryParams.group)
          ? queryParams.group
          : "undefined",
      status: includeStatus
        ? isString(queryParams.status)
          ? (queryParams.status as Status)
          : Status.ACTIVE
        : undefined,
    };
    setState({ ...query, ready: true });
    setFiltersToRoute(query);
  }, [
    setFiltersToRoute,
    location.search,
    filterUnassignedField,
    includeGroup,
    includeTextSearch,
    includeStatus,
  ]);

  useSetFormValueOnSchemeChange(formikRef, "group", "undefined", true);

  return (
    <>
      {state.ready && (
        <Formik
          innerRef={formikRef}
          initialValues={state}
          onSubmit={(x): void => {
            setFiltersToRoute(x);
          }}
        >
          {(): React.ReactElement => (
            <Form>
              <Container gap={3} className={"grid-"}>
                {includeTextSearch && (
                  <Col sm={12} md={6}>
                    <NewTextFormComponent
                      label={{ label: "Search", size: "sm" }}
                      fieldName={"textSearch"}
                    />
                  </Col>
                )}
                <Col sm={12} md={6}>
                  <AssetTypeSelect
                    fieldName={"assetType"}
                    label={{ label: "Asset Type", size: "sm" }}
                    allowUnknown={true}
                    includeNonCreateables={includeNonCreateables}
                    isMulti={true}
                  />
                </Col>
                {includeUseCase && (
                  <Col sm={12} md={includeGroup && includeTextSearch ? 3 : 6}>
                    <AssetUseCaseSelect
                      fieldName={"useCase"}
                      label={{ label: "Use Case", size: "sm" }}
                      includeAll={false}
                      isMulti={true}
                      includeNone={true}
                    />
                  </Col>
                )}
                {includeGroup && (
                  <Col sm={12} md={includeUseCase && includeTextSearch ? 3 : 6}>
                    <LocationGroupSelect
                      fieldName={"group"}
                      label={{ label: "Group", size: "sm" }}
                    />
                  </Col>
                )}
                <Col
                  sm={12}
                  md={includeStatus ? 2 : 4}
                  className={clsx(
                    !includeUseCase && !includeGroup && "md:col-start-7",
                  )}
                >
                  <ButtonGroupField
                    fieldName={filterUnassignedField}
                    label={{ label: "Filter Unassigned", size: "sm" }}
                    options={[
                      { label: "Yes", value: "NONE" },
                      {
                        label: "No",
                        value: "undefined",
                      },
                    ]}
                    fullWidth={true}
                  />
                </Col>
                {includeStatus && (
                  <Col sm={12} md={2}>
                    <ButtonGroupField
                      fieldName={"status"}
                      label={{ label: "Status", size: "sm" }}
                      options={[
                        { label: "Active", value: "ACTIVE" },
                        {
                          label: "Deleted",
                          value: "DELETED",
                        },
                      ]}
                      fullWidth={true}
                    />
                  </Col>
                )}
                <Col sm={12} md={2} className={"justify-end mt-auto"}>
                  <Button type={"submit"} className={"w-full md:h-[38px]"}>
                    Filter
                  </Button>
                </Col>
              </Container>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
