import type React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";
import { Frequency } from "../../../openapi/model/frequency";

export default function FrequencySelect(
  props: Omit<SelectComboFieldProps, "options">,
): React.ReactElement {
  const options: Option[] = [
    { label: "Daily", value: Frequency.DAILY },
    { label: "Weekly", value: Frequency.WEEKLY },
    { label: "Monthly", value: Frequency.MONTHLY },
  ];

  return <SelectComboField options={options} {...props} />;
}
