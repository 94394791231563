import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik, type FormikHelpers } from "formik";
import React from "react";
import * as Yup from "yup";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import NewTextFormComponent from "../../components/Forms/NewTextFormComponent";
import SingleDatePicker from "../../components/Forms/SingleDatePicker";
import { yupRequiredString } from "../../components/Forms/yupValidators";
import { Button } from "../../components/Theme/button";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import type { CreateEntity } from "../../hooks/createEntity";
import type { Account } from "../../openapi/model/account";
import type { Location } from "../../openapi/model/location";
import type { NewAccount } from "../../openapi/model/newAccount";

interface FormValues
  extends Omit<
    NewAccount,
    "companyId" | "schemeId" | "locationId" | "openingDate"
  > {
  openingDate: Dayjs;
}

const NewAccountSchema = Yup.object().shape({
  accountReference: yupRequiredString,
  openingDate: Yup.date().required(),
});

function NewAccountForm(props: {
  location: Location;
  create: CreateEntity<Account, NewAccount>;
}) {
  const { create, location } = props;

  const initialValues: FormValues = {
    accountReference: "",
    openingDate: dayjs().startOf("day"),
  };
  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    create
      .createEntity({
        ...values,
        openingDate: values.openingDate.format().slice(0, 10),
        companyId: location.companyId,
        schemeId: location.schemeId,
        locationId: location.locationId,
      })
      .finally(() => {
        setSubmitting(false);
        resetForm();
      });
  };
  return (
    <DisplayIfUserType userTypes={"superadmin"}>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={NewAccountSchema}
      >
        {({ submitForm, isSubmitting, isValid }) => (
          <Form>
            <NewTextFormComponent
              fieldName={"accountReference"}
              label={{ label: "Account Reference" }}
            />
            <SingleDatePicker
              dateName={"openingDate"}
              showPresets={false}
              label={{ label: "Opening Date" }}
              timeZone={"Europe/London"}
            />
            <Button
              color={"brandLight"}
              onClick={submitForm}
              disabled={isSubmitting || !isValid}
            >
              Create Account
            </Button>
          </Form>
        )}
      </Formik>
      <ErrorBanner error={create.error} />
    </DisplayIfUserType>
  );
}

export default NewAccountForm;
