import dayjs from "dayjs";
import {
  isNil,
  isNaN as loIsNan,
  toString as loIsString,
  toNumber,
} from "lodash-es";
import type React from "react";
import {
  type AssetRegisterDataItem,
  AssetRow,
  DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
} from "../../../components/Table/AssetRegisterData";
import type { PrivacyModeTableColumn } from "../../../components/Text/PrivacyModeDataTableCell";
import HighlightNullAndOldDates from "../../../components/utils/HighlightNullAndOldDates";
import AssetRegisterReportTable from "../allPeriodReportTable/AssetRegisterReportTable";
import type { LatestTimeSeriesResponse } from "./LatestTimeSeriesApiWrapper";

export interface AssetRegisterLatestTimeSeriesData
  extends AssetRegisterDataItem {
  time: Date;
}

const mapData = (
  data?: LatestTimeSeriesResponse,
): [Array<AssetRegisterLatestTimeSeriesData>, Array<string>] => {
  const keys: Set<string> = new Set<string>();
  const mappedData = (data ? data : []).map((value) => {
    const { values = [], time, ...asset } = value;
    const thisAssetMappedData = values
      ? Object.fromEntries(
          values.map((data) => {
            keys.add(data.name as string);
            return [data.name, data.value];
          }),
        )
      : {};

    return {
      ...asset,
      time: time as Date,
      ...thisAssetMappedData,
    };
  });

  return [mappedData, Array.from(keys.values())];
};

const LatestDataReportTable = (props: {
  data?: LatestTimeSeriesResponse;
  companyId: string;
  schemeId: string;
}): React.ReactElement => {
  const columns = (
    keys: Array<string>,
  ): PrivacyModeTableColumn<AssetRegisterDataItem>[] => {
    return [
      {
        name: "Gateway",
        selector: (row) => row.gatewayAssetId || "-",
        sortable: true,
        compact: true,
        width: "150px",
        cell: AssetRow(undefined, undefined, true),
      },
      {
        name: "Gateway Last Contact",
        sortable: true,
        grow: 2,
        width: "225px",
        cell: function DataCell(row): React.ReactElement {
          return (
            <HighlightNullAndOldDates ageHighlight={dayjs.duration(3, "days")}>
              {row.gatewayLastContactTime}
            </HighlightNullAndOldDates>
          );
        },
        selector: (row) =>
          row.gatewayLastContactTime
            ? (row.gatewayLastContactTime as unknown as string)
            : "-",
      },
      {
        name: "Time",
        sortable: true,
        width: "225px",
        cell: function DataCell(row): React.ReactElement {
          return (
            <HighlightNullAndOldDates ageHighlight={dayjs.duration(3, "days")}>
              {row.time as Date}
            </HighlightNullAndOldDates>
          );
        },
        selector: (row) => (row.time ? (row.time as unknown as string) : "-"),
      },
      ...keys.map((value) => {
        return {
          name: (
            <span
              style={{
                textAlign: "right",
                whiteSpace: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </span>
          ),
          minWidth: "100px",
          selector: (row: AssetRegisterDataItem) =>
            Number(row[value]) || Number.NEGATIVE_INFINITY,
          sortable: true,
          right: true,
          compact: true,
          format: (row: AssetRegisterDataItem) => {
            const data = row[value];
            if (isNil(data) || loIsNan(data)) {
              return "-";
            } else {
              return loIsString(
                (Math.round(toNumber(data) * 100) / 100).toFixed(1),
              );
            }
          },
        };
      }),
    ];
  };

  const [mappedData, keys] = mapData(props.data);

  return (
    <AssetRegisterReportTable
      data={mappedData}
      columnDefinitions={columns(keys)}
      startDatetime={dayjs().endOf("day").subtract(15, "days")}
      endDatetime={dayjs().endOf("day")}
      csvColumnOrder={[
        ...DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
        "gatewayAssetId",
        "gatewayLastContactTime",
        "gatewaySerialNumber",
        "locationCustomerReference",
        "time",
        ...keys,
      ]}
      downloadFilename={`latest_data_${props.companyId}_${props.schemeId}`}
    />
  );
};

export default LatestDataReportTable;
