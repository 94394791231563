import type React from "react";
import { Period } from "../../../openapi/model/period";
import {
  ButtonGroupField,
  type ButtonGroupFieldProps,
  type Option,
} from "../ButtonGroup";

interface CommonProps extends Omit<ButtonGroupFieldProps, "options"> {
  includeWeekly?: boolean;
}

export default function PeriodPickerToggle(
  props: CommonProps,
): React.ReactElement {
  const { includeWeekly = false, ...rest } = props;

  const options: Option[] = [
    { label: "D", value: Period.DAILY },
    includeWeekly && { label: "W", value: Period.WEEKLY },
    { label: "7D", value: Period.SEVENDAY },
    { label: "M", value: Period.MONTHLY },
  ].filter((v) => v !== false);

  return <ButtonGroupField {...rest} options={options} />;
}
