import * as Sentry from "@sentry/react";
import useAxios, { type UseAxiosResult } from "axios-hooks";
import { isArray, isEqual } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import Config from "../Config";
import {
  objectKeysToCamelCase,
  objectKeysToSnakeCase,
} from "../actions/helpers/apiService";
import { type Entities, EntitiesPathMap } from "../constants/entities";
import type { Cursor } from "../openapi/model/cursor";
import { removeMapBlanks } from "../utils/object";

export interface PaginatedData<T> {
  data: Array<T>;
  cursor: Cursor;
  setCursor: (cursor?: Cursor) => any;
}

export type PaginatedListResult<T> = [
  ...UseAxiosResult<PaginatedData<T>>,
  (item: T, index: number) => void,
];

export default function useGetList<T>(
  entityType: Entities,
  params?: Record<string, string | string[] | boolean | undefined>,
  cursor?: Cursor,
  path?: string,
): PaginatedListResult<T> {
  const [requestCursor, setRequestCursor] = useState(cursor);
  const [_0, setRequestParams] = useState(params);
  const [data, setData] = useState<PaginatedData<T> | undefined>(undefined);

  const activeSpan = Sentry.getActiveSpan();
  const rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : undefined;

  const sentryTraceHeader = rootSpan
    ? Sentry.spanToTraceHeader(rootSpan)
    : undefined;

  const sentryBaggageHeader = rootSpan
    ? Sentry.spanToBaggageHeader(rootSpan)
    : undefined;

  useEffect(() => {
    setRequestParams((requestParams) => {
      if (isEqual(requestParams, params)) {
        return requestParams;
      } else {
        setRequestCursor(cursor);
        return params;
      }
    });
  }, [params, cursor]);

  const setCursor = useCallback((newCursor?: Cursor) => {
    setRequestCursor(newCursor);
  }, []);

  const [result, refresh, cancel] = useAxios<PaginatedData<T>>({
    url: `${Config.apiGateway.URL}/${path ? path : EntitiesPathMap[entityType]}`,
    params: objectKeysToSnakeCase({ ...params, ...requestCursor }),
    headers: removeMapBlanks({
      baggage: sentryBaggageHeader,
      "sentry-trace": sentryTraceHeader,
    }),
    transformResponse: useCallback(
      (data: any, headers: any) => {
        try {
          setData({
            data: convertKeys(JSON.parse(data)).map(removeMapBlanks),
            cursor:
              headers?.["x-cursor"] &&
              objectKeysToCamelCase(JSON.parse(headers["x-cursor"])),
            setCursor,
          });
        } catch {
          return { data };
        }
      },
      [setCursor],
    ),
  });

  return [
    { ...result, data: data },
    refresh,
    cancel,
    (item, index) => {
      data &&
        setData({
          data: [
            ...data.data.slice(0, index),
            item,
            ...data.data.slice(index + 1),
          ],
          cursor: data.cursor ? data.cursor : {},
          setCursor: data.setCursor ? data.setCursor : () => null,
        });
    },
  ];
}

export function convertKeys(data: any, convertSnakeCaseOnly = false) {
  if (isArray(data)) {
    return data.map((d) => objectKeysToCamelCase(d, [], convertSnakeCaseOnly));
  } else {
    return objectKeysToCamelCase(data, [], convertSnakeCaseOnly);
  }
}
