import clsx from "clsx";
import type React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SelectCombo from "../Forms/SelectCombo/SelectCombo";
import CompanyNavLink from "../navigation/CompanyNavLink";
import DisplayIfUserType from "../utils/DisplayIfUserType/DisplayIfUserType";
import type { Tab, TabGroupInterfaceProps } from "./model";

export default function TabSwitchButtonGroup(
  props: TabGroupInterfaceProps,
): React.ReactElement {
  const { tabs, liveTab } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const tabMap: Map<string, Tab> = new Map(
    Object.entries(
      tabs.reduce((accumulator, tab) => {
        return { ...accumulator, [tab.tabPath]: tab };
      }, {}),
    ),
  );

  return (
    <div data-cy={"tab-interface"}>
      <div className="md:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <SelectCombo
          options={tabs.map((v) => {
            return { value: v.tabPath, label: v.tabName };
          })}
          name={"tab"}
          onChange={(v) => {
            const tab = tabMap.get(v as string) as Tab;

            props.switchTab
              ? props.switchTab(tab)
              : navigate({
                  pathname: tab.tabPath,
                  search: tab.keepSearchString ? location.search : undefined,
                });
          }}
          value={liveTab?.tabPath || tabs[0].tabPath}
        />
      </div>
      <div className="hidden md:block pb-3">
        <div className="border-b border-gray-200">
          <nav
            aria-label="Tabs"
            className="-mb-px flex space-x-4 lg:space-x-8 "
          >
            {tabs.map((tab) => (
              <DisplayIfUserType
                userTypes={tab.userTypes || "viewer"}
                key={tab.tabPath}
              >
                <CompanyNavLink
                  to={
                    props.switchTab
                      ? { hash: "#" }
                      : {
                          pathname: tab.tabPath,
                          search: tab.keepSearchString
                            ? location.search
                            : undefined,
                        }
                  }
                  aria-current={
                    tab.tabPath === liveTab?.tabPath ? "page" : undefined
                  }
                  className={clsx(
                    tab.tabPath === liveTab?.tabPath
                      ? "border-brand-dark-700 text-brand-dark-700"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium",
                  )}
                  onClick={() => {
                    props.switchTab?.(tab);
                  }}
                  dataCy={`tab-${tab.tabName}`}
                >
                  {tab.tabName}
                </CompanyNavLink>
              </DisplayIfUserType>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
