import AssetRegisterList from "pages/assetRegister/AssetRegisterListPage/AssetRegisterList";
import type React from "react";
import RoutedTabGroupInterface from "../../../../components/TabGroup/RoutedTabGroupInterface";
import type { Tab } from "../../../../components/TabGroup/model";
import { Heading } from "../../../../components/Theme/heading";
import EventList from "../../../../containers/event/EventList";
import type { GatewayAsset } from "../../../../openapi/model/gatewayAsset";
import AssetPositionAssetList from "../../assetPositions/assetPositionAssetLinks/AssetPositionAssetList";
import AssetConfigurationPage from "../AssetConfigurationPage";
import MeterTimeSeriesCharts from "../meters/MeterTimeSeriesCharts";
import GatewayConfigurationTab from "./GatewayConfigurationTab";
import GatewayFilelist from "./GatewayFileList/GatewayFilelist";
import GatewayJobTab from "./GatewayJobTab";
import StreamingGatewayData from "./data/StreamingGatewayData";

interface Props {
  gateway: GatewayAsset;
}

export default function GatewayHomepage(props: Props): React.ReactElement {
  const assetId = props.gateway.assetId;

  const tabs: (Tab | undefined)[] = [
    {
      tabName: "Statistics",
      tabContent: <MeterTimeSeriesCharts device={props.gateway} />,
      tabPath: "statistics",
      userTypes: "superadmin",
    },
    {
      tabName: "Devices",
      tabContent: (
        <>
          <AssetRegisterList
            companyId={props.gateway.companyId}
            parentAssetPositionId={props.gateway.assetPositionId}
            createAssetTags={false}
          />
        </>
      ),
      tabPath: "devices",
      keepSearchString: false,
    },
    {
      tabName: "Files",
      tabContent: <GatewayFilelist asset={props.gateway} />,
      tabPath: "files",
      keepSearchString: false,
    },
    props.gateway.manufacturer === "lansen"
      ? {
          tabName: "LiveData",
          tabContent: <StreamingGatewayData gateway={props.gateway} />,
          tabPath: "live_data",
          userTypes: "super_admin",
        }
      : undefined,
    {
      tabName: "Events",
      tabContent: (
        <EventList assetId={assetId} companyId={props.gateway.companyId} />
      ),
      tabPath: "events",
      keepSearchString: false,
    },
    {
      tabName: "Configuration",
      tabContent: <GatewayConfigurationTab gateway={props.gateway} />,
      tabPath: "configuration",
      keepSearchString: false,
    },
    {
      tabName: "Configuration (detailed)",
      tabContent: <AssetConfigurationPage asset_id={props.gateway.assetId} />,
      tabPath: "detailed",
      keepSearchString: false,
      userTypes: "superadmin",
    },
    {
      tabName: "Asset Position History",
      tabPath: "link",
      tabContent: (
        <>
          <Heading>Asset Position Asset History</Heading>
          <AssetPositionAssetList
            assetId={assetId}
            view={"assetPosition"}
            companyId={props.gateway.companyId}
            schemeId={props.gateway.schemeId}
          />
        </>
      ),
      userTypes: "superadmin",
      keepSearchString: false,
    },
    {
      tabName: "Jobs",
      tabContent: <GatewayJobTab gateway={props.gateway} />,
      tabPath: "job",
      keepSearchString: false,
    },
  ];

  return (
    <RoutedTabGroupInterface
      tabs={tabs}
      topLevelPath={`/admin/asset_register/asset/${assetId}`}
    />
  );
}
