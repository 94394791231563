import { isEmpty } from "lodash-es";
import type React from "react";
import { Heading } from "../../../components/Theme/heading";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import type { AssetPosition } from "../../../openapi/model/assetPosition";
import type { DeviceAsset } from "../../../openapi/model/deviceAsset";
import VirtualAssetCreateForm from "./virtual_assets/VirtualAssetCreateForm";
import VirtualAssetSummaryList from "./virtual_assets/VirtualAssetSummaryList";

const VirtualAssetTab = (props: {
  asset: DeviceAsset;
  assetPosition: AssetPosition;
}): React.ReactElement => {
  const { asset, assetPosition } = props;
  return (
    <>
      {!isEmpty(assetPosition.childAssetPositions) && (
        <>
          <Heading>Current Virtual Assets</Heading>
          <VirtualAssetSummaryList parentAssetPosition={assetPosition} />
        </>
      )}
      <DisplayIfUserType userTypes={"admin"}>
        <Heading>Create New Virtual Asset</Heading>
        <VirtualAssetCreateForm
          parentAsset={asset}
          parentAssetPosition={assetPosition}
        />
      </DisplayIfUserType>
    </>
  );
};

export default VirtualAssetTab;
