import { faSpinner, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorBanner } from "components/Error/ErrorBanner";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import AlertBanner from "../../../components/Banner/AlertBanner";
import { Button } from "../../../components/Theme/button";
import { Heading } from "../../../components/Theme/heading";
import type { AssetPosition } from "../../../openapi/model/assetPosition";
import type { Location } from "../../../openapi/model/location";

interface DeletionState {
  deleting: boolean;
  success?: boolean;
  error?: Error;
  refreshTimeout?: NodeJS.Timeout;
}

const AssetPositionDeleteButton = (props: {
  assetPosition: AssetPosition | Location;
  delete: () => Promise<any>;
  refresh?: () => any;
  disabled?: boolean;
  title: string;
}): React.ReactElement => {
  const [state, setState] = useState<DeletionState>({ deleting: false });

  const { refreshTimeout } = state;
  const cancelTimeout = useCallback(() => {
    refreshTimeout && clearTimeout(refreshTimeout);
    setState((s) => {
      return { ...s, refreshTimeout: undefined };
    });
  }, [refreshTimeout]);

  useEffect(() => {
    return cancelTimeout;
  }, [cancelTimeout]);

  const update = async (): Promise<void> => {
    cancelTimeout();
    setState({ deleting: true, success: false, error: undefined });
    return props
      .delete()
      .then()
      .then(() => {
        props.refresh &&
          setState({
            ...state,
            refreshTimeout: setTimeout(() => {
              props.refresh?.();
            }, 2000),
          });
        setState({ deleting: false, success: true });
      })
      .catch((error) => {
        setState({ deleting: false, success: false, error });
      });
  };

  return (
    <>
      <Button
        color="red"
        onClick={update}
        disabled={state.deleting || props.disabled || state.success}
      >
        <FontAwesomeIcon
          icon={state.deleting ? faSpinner : faTrashAlt}
          fixedWidth
          spin={state.deleting}
          className={"mr-2"}
        />
        Delete
      </Button>
      {state.success && (
        <AlertBanner className={"bg-green-700"}>
          <Heading>Deleted</Heading>
          <p>{props.title} has been successfully deleted.</p>
        </AlertBanner>
      )}
      <ErrorBanner error={state.error} />
    </>
  );
};

export default AssetPositionDeleteButton;
