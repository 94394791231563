import type React from "react";
import DateRangePicker, {
  type Common,
} from "./DateRangePicker/DateRangePicker";

interface PropTypes {
  dateName: string; // name of the form field for the date
}

export default function SingleDatePicker(
  props: Common & PropTypes,
): React.ReactElement {
  const { dateName, ...rest } = props;

  return <DateRangePicker single={true} endDateName={dateName} {...rest} />;
}
