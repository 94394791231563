import { Auth } from "aws-amplify";
import axios from "axios";
import { getApiUrl, getWebsocketBaseUrl } from "../../Config";

type Nullable<T> = T | null | undefined;

const local = import.meta.env?.VITE__LOCAL_LOGIN === "true";
let localTestUser: Nullable<string>;

export const signIn = (
  username: string,
  password: string,
  localOverride?: boolean,
  localOverrideUrl?: string,
): Promise<any> => {
  if (local || localOverride) {
    const url = localOverrideUrl || getApiUrl();
    /*
    axios instance to avoid using default interceptors
     */
    return axios
      .create()
      .get(`${url}/get_test_user`, {
        params: { email_address: username },
      })
      .then((resp) => {
        window.localStorage.setItem("localUser", JSON.stringify(resp.data));
        localTestUser = resp.data;
      });
  } else {
    return Auth.signIn(username, password);
  }
};

export const currentUser = (): Promise<any> => {
  if (local) {
    return new Promise((resolve, reject) => {
      if (localTestUser) {
        resolve(localTestUser);
      } else {
        const localUser = window.localStorage.getItem("localUser");

        if (localUser) {
          try {
            localTestUser = JSON.parse(localUser);
            resolve(localTestUser);
          } catch {
            reject("Could not parse current user");
          }
        }
        reject("No current user");
      }
    });
  } else {
    return Auth.currentAuthenticatedUser();
  }
};

export const getAuthHeader = async (): Promise<any> => {
  if (local) {
    return new Promise((resolve, reject) => {
      if (localTestUser) {
        resolve({ "X-Test-User": localTestUser });
      } else {
        reject("No current user");
      }
    });
  } else {
    return {
      Authorization: `Bearer ${await getAccessJWT()}`,
    };
  }
};

export const getWebSocketURL = async (): Promise<string> => {
  return `${getWebsocketBaseUrl()}?authToken=${await getAccessJWT()}`;
};
export const getAccessJWT = async (): Promise<any> => {
  return (await Auth.currentSession()).getAccessToken().getJwtToken();
};

export const signOut = (): Promise<any> => {
  if (local) {
    window.localStorage.removeItem("localUser");

    return new Promise((resolve) => {
      localTestUser = undefined;
      resolve(null);
    });
  } else {
    return Auth.signOut();
  }
};
