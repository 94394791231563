import React, { useState } from "react";
import { loadFileFromApi } from "../../../actions/helpers/presigned_s3";
import DownloadFileTooltip from "../../../components/downloadFileTooltip";
import type { ReportExecutionOut } from "../../../openapi/model/reportExecutionOut";

async function getArtifactsFileFromUrl(
  execution: ReportExecutionOut,
): Promise<void> {
  return loadFileFromApi(
    `/report/${execution.reportId}/artifacts?company_id=${execution.companyId}`,
  );
}

interface Props {
  execution: ReportExecutionOut;
}

function ReportExecutionArtifactsDownloadButton(props: Props) {
  const { execution } = props;

  const [loading, setLoading] = useState(false);
  const downloadable = execution.status === "COMPLETED";
  const failed = execution.status === "FAILED";

  const handleClick = async (): Promise<undefined> => {
    if (!loading) {
      setLoading(true);

      getArtifactsFileFromUrl(execution).finally(() => setLoading(false));
    }
    return;
  };

  return (
    <DownloadFileTooltip
      downloadable={downloadable}
      loading={loading}
      fileDescription={"Download report"}
      unavailableDescription={
        failed ? "This report failed" : "This report is calculating"
      }
      clickHandler={handleClick}
    />
  );
}

export default ReportExecutionArtifactsDownloadButton;
