import type React from "react";
import DisplayIfUserType from "../utils/DisplayIfUserType/DisplayIfUserType";
import TabSwitchButtonGroup from "./TabSwitchButtonGroup";
import type { TabGroupInterfaceProps } from "./model";

const TabGroupInterface = (
  props: TabGroupInterfaceProps,
): React.ReactElement => {
  return (
    <>
      <TabSwitchButtonGroup {...props} />
      <DisplayIfUserType userTypes={props.liveTab?.userTypes || "viewer"}>
        {props.liveTab?.tabContent}
      </DisplayIfUserType>
    </>
  );
};

export default TabGroupInterface;
