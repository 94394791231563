import type { Dayjs } from "dayjs";
import Highcharts, { type SeriesLineOptions } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import type React from "react";
import { usePrivacyMode } from "reducers/privacy";
import HighchartsPrivacyModeFilter from "../Text/HighchartsPrivacyModeFilter";
import type { SingleAggregationTimeAssetData } from "../TimeSeries/model";
import { createSearchString } from "../navigation/SearchString";
import { getUrlHash } from "../navigation/URL";
import { formatDate } from "../utils/HighlightNullAndOldDates";

import exporting from "highcharts/modules/exporting";
import offline from "highcharts/modules/offline-exporting";

exporting(Highcharts);
offline(Highcharts);

const convertStringToNumber = (val: any): number | null => {
  try {
    return Number.parseFloat(val);
  } catch {
    return null;
  }
};

const assetRegisterItemToSeriesName = (
  item: SingleAggregationTimeAssetData,
): string => {
  const positionRef = item.assetPositionReference
    ? item.assetPositionReference
    : item.assetPositionId.slice(28);
  const assetRef = item.assetId !== undefined ? item.serialNumber : "";
  return `${positionRef} (${assetRef})`;
};

export default function AssetRegisterDataChart(props: {
  xaxis: Dayjs[];
  data: Array<SingleAggregationTimeAssetData>;
  startTime: Dayjs;
  endTime: Dayjs;
}): React.ReactElement {
  const categories = props.xaxis;
  const privacyMode = usePrivacyMode();

  const openClickedSeries = (e: any): void => {
    const { assetId, companyId, schemeId } = e.point.series.userOptions;

    const url = `/admin/asset/${assetId}?${createSearchString({
      startDatetime: props.startTime?.format(),
      endDatetime: props.endTime?.format(),
    })}#${getUrlHash(companyId, schemeId, privacyMode)}`;
    window.open(url, "_blank");
  };
  const series = props.data.map((data): SeriesLineOptions => {
    const tsdata: Record<string, string | number | undefined> = data.data;
    return {
      ...data,
      name: assetRegisterItemToSeriesName(data),
      data: Object.values(tsdata).map(convertStringToNumber),
      events: { click: openClickedSeries },
      animation: false,
      type: "line",
      tooltip: {
        pointFormatter: function () {
          return (
            `<tspan style='fill:${this.color}'></tspan>` +
            `<tspan style='${privacyMode && "filter: url(#blur)"}'> ${
              this.series.name
            } </tspan>` +
            `<tspan style='font-weight:bold'> ${this.y} </tspan>`
          );
        },
      },
    };
  });

  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      categories: categories.map((d) => formatDate(d, "L")),
    },
    series: series,
    chart: {
      height: "50%",
      animation: false,
    },
    legend: {
      enabled: true,
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
      labelFormatter: function () {
        return `<tspan style="${privacyMode ? "filter: url(#blur)" : ""}">${
          this.name
        }</tspan>`;
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadSVG",
          ],
        },
      },
    },
  };

  return (
    <>
      <HighchartsPrivacyModeFilter />
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        immutable={true}
      />
    </>
  );
}
