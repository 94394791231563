import type * as React from "react";
import type { Company } from "../../openapi/model/company";
import type { CompanyType } from "../../openapi/model/companyType";

import {
  BellAlertIcon,
  BuildingOfficeIcon,
  CalculatorIcon,
  ChartBarSquareIcon,
  CloudArrowDownIcon,
  CogIcon,
  HomeIcon,
  ListBulletIcon,
  PencilIcon,
  QueueListIcon,
  RectangleGroupIcon,
  ServerStackIcon,
  UserGroupIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import type { User } from "../../openapi/model/user";

const getCompanyType = (company?: Company): CompanyType | undefined => {
  return company?.companyType;
};

export const filterRoutes = (
  company: Company | undefined,
  user: User | undefined,
  routes: RouteTypes[],
): RouteTypes[] => {
  const companyType = getCompanyType(company);
  const companyTypeFilter = companyType ? companyType : "NONE";

  const filteredRoutes = routes.filter(
    (route: RouteTypes) =>
      route.layout === "/admin" &&
      route.companyType.includes(companyTypeFilter),
  );
  return filteredRoutes.map((v) => {
    if (Object.hasOwnProperty.call(v, "views")) {
      const collapseRoute = v as CollapseRoute;
      return {
        ...collapseRoute,
        views: filterRoutes(company, user, collapseRoute.views),
      } as CollapseRoute;
    } else {
      return v;
    }
  });
};

interface BaseRoute {
  name: string;
  layout: string;
  icon?: string;
  actualIcon?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  userGroup?: string;
  companyType: (CompanyType | "NONE")[];
  invisible?: boolean;
}

export interface PageRoute extends BaseRoute {
  path: string;
  beta?: boolean;
}

export interface CollapseRoute extends BaseRoute {
  collapse: boolean;
  views: PageRoute[];
}

export type RouteTypes = PageRoute | CollapseRoute;

export function isPageRoute(route: RouteTypes): route is PageRoute {
  return (route as PageRoute).path !== undefined;
}

export const allRoutes: RouteTypes[] = [
  {
    path: "/dashboard",
    name: "Dashboard",
    actualIcon: HomeIcon,
    layout: "/admin",
    companyType: ["CLIENT"],
  },
  {
    path: "/schemes",
    name: "Schemes",
    actualIcon: RectangleGroupIcon,
    layout: "/admin",
    companyType: ["CLIENT"],
  },
  {
    path: "/asset",
    name: "Assets",
    actualIcon: CogIcon,
    layout: "/admin",
    userGroup: "viewer",
    companyType: ["PARTNER", "NONE"],
  },
  {
    path: "/report/gateway",
    name: "Gateway Status",
    actualIcon: ServerStackIcon,
    layout: "/admin",
    userGroup: "viewer",
    companyType: ["PARTNER", "NONE"],
  },
  {
    name: "Assets",
    actualIcon: CogIcon,
    collapse: true,
    companyType: ["CLIENT"],

    views: [
      {
        path: "/asset_register",
        name: "Asset Register",
        layout: "/admin",
        companyType: ["CLIENT"],
      },
      {
        path: "/asset_register/location",
        name: "Locations",
        layout: "/admin",
        userGroup: "editor",
        companyType: ["CLIENT"],
      },
      {
        path: "/asset_register/asset",
        name: "Assets",
        layout: "/admin",
        userGroup: "admin",
        companyType: ["CLIENT"],
      },
      {
        name: "Asset Positions",
        path: "/asset_register/asset_position",
        layout: "/admin",
        userGroup: "editor",
        companyType: ["CLIENT"],
      },
      {
        path: "/report/gateway",
        name: "Gateway Status",
        layout: "/admin",
        companyType: ["CLIENT"],
      },
      {
        path: "/asset_register/wireless_cluster",
        name: "Wireless Clusters",
        layout: "/admin",
        companyType: ["CLIENT"],
      },
      {
        path: "/asset_register/wireless_survey",
        name: "Wireless Survey",
        layout: "/admin",
        companyType: ["CLIENT"],
        userGroup: "superadmin",
      },
      {
        path: "/asset_register/integrations",
        name: "Integrations",
        layout: "/admin",
        companyType: ["CLIENT"],
        userGroup: "viewer",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/alarm",
    name: "Alarms",
    actualIcon: BellAlertIcon,
    layout: "/admin",
    companyType: ["CLIENT"],
  },
  {
    name: "Billing",
    actualIcon: CalculatorIcon,
    collapse: true,
    companyType: ["CLIENT"],
    views: [
      {
        path: "/report/billing/data",
        name: "Data",
        layout: "/admin",
        companyType: ["CLIENT"],
      },
      {
        path: "/billing/tariff",
        name: "Tariffs",
        layout: "/admin",
        companyType: ["CLIENT"],
        userGroup: "superadmin",
      },
      {
        path: "/billing/account",
        name: "Accounts",
        layout: "/admin",
        companyType: ["CLIENT"],
        userGroup: "superadmin",
      },
      {
        path: "/billing/report",
        name: "Reports",
        layout: "/admin",
        companyType: ["CLIENT"],
        userGroup: "superadmin",
      },
    ],
    layout: "/admin",
  },

  {
    name: "Performance",
    actualIcon: ChartBarSquareIcon,
    collapse: true,
    companyType: ["CLIENT"],
    views: [
      {
        path: "/report/time_series/latest",
        name: "Latest Data",
        layout: "/admin",
        companyType: ["CLIENT"],
      },
      {
        path: "/report/time_series/aggregated",
        name: "Data Explorer",
        layout: "/admin",
        companyType: ["CLIENT"],
      },
      {
        path: "/report/time_series/stats",
        name: "Stats Explorer",
        layout: "/admin",
        beta: true,
        companyType: ["CLIENT"],
        userGroup: "viewer",
      },
      {
        path: "/report/heat_network",
        name: "Heat Network",
        layout: "/admin",
        beta: true,
        companyType: ["CLIENT"],
      },
      {
        path: "/report/row_data_quality",
        name: "Data Quality",
        layout: "/admin",
        companyType: ["CLIENT"],
      },
    ],
    layout: "/admin",
  },
  {
    path: "/export",
    name: "Export",
    actualIcon: CloudArrowDownIcon,
    layout: "/admin",
    companyType: ["CLIENT"],
  },
  {
    path: "/notes",
    name: "Notes",
    actualIcon: PencilIcon,
    layout: "/admin",
    companyType: ["CLIENT"],
  },
  {
    path: "/job",
    name: "Jobs",
    actualIcon: QueueListIcon,
    layout: "/admin",
    companyType: ["CLIENT"],
  },
  {
    path: "/event",
    name: "Events",
    actualIcon: ListBulletIcon,
    layout: "/admin",
    companyType: ["CLIENT"],
  },
  {
    path: "/company",
    name: "Companies",
    actualIcon: BuildingOfficeIcon,
    layout: "/admin",
    companyType: ["NONE", "PARTNER"],
  },
  {
    path: "/users",
    name: "Users",
    actualIcon: UserGroupIcon,
    layout: "/admin",
    userGroup: "admin",
    companyType: ["CLIENT", "PARTNER"],
  },
  {
    path: "/configuration",
    name: "Configuration",
    actualIcon: WrenchIcon,
    layout: "/admin",
    userGroup: "admin",
    companyType: ["CLIENT"],
  },
];
