import type React from "react";
import type { RequireExactlyOne } from "type-fest";
import GetListPaginatedTable from "../../../../../components/Pagination/GetListPaginatedTable";
import { TableHeader, TableRow } from "../../../../../components/Theme/table";
import type { Asset } from "../../../../../openapi/model/asset";
import type { AssetFile as GatewayFile } from "../../../../../openapi/model/assetFile";
import type { AssetPosition } from "../../../../../openapi/model/assetPosition";
import GatewayFileListFilterForm, {
  useGetParsedGatewayFileListFilterParams,
} from "./GatewayFileListFilterForm";
import GatewayFileListRow from "./GatewayFileListRow";

type Props = RequireExactlyOne<
  {
    asset?: Asset;
    assetPosition?: AssetPosition;
  },
  "asset" | "assetPosition"
>;

const GatewayFilelist = ({
  asset,
  assetPosition,
}: Props): React.ReactElement => {
  const useAsset = asset !== undefined;

  const { companyId } = useAsset ? asset : (assetPosition as AssetPosition);

  const path = useAsset
    ? `asset/${asset.assetId}/files`
    : `asset_position/${assetPosition?.assetPositionId}/files`;
  const { params } = useGetParsedGatewayFileListFilterParams();
  const header = (
    <TableRow>
      <TableHeader>&nbsp;</TableHeader>
      <TableHeader>Filename</TableHeader>
      <TableHeader>Date received</TableHeader>
      <TableHeader>File size</TableHeader>
      <TableHeader>Status</TableHeader>
      <TableHeader>&nbsp;</TableHeader>
    </TableRow>
  );
  const row = (value: GatewayFile): React.ReactElement => (
    <GatewayFileListRow file={value} key={value.fileId} index={value.fileId} />
  );

  return (
    <>
      <GatewayFileListFilterForm />
      <GetListPaginatedTable
        entityType={"GATEWAY_FILE"}
        params={{
          companyId,
          startDatetime: params.startDatetime?.format(),
          endDatetime: params.endDatetime?.format(),
        }}
        cursor={{ maxItems: 50, direction: "desc" }}
        path={path}
        header={[header, 5]}
        row={row}
      />
    </>
  );
};

export default GatewayFilelist;
