import clsx from "clsx";
import type React from "react";

export interface StandardProps extends React.PropsWithChildren {
  className?: string;
}

interface GapDict {
  0: string;
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
  7: string;
  8: string;
  9: string;
  10: string;
}

const gaps: { [key: string]: GapDict } = {
  none: {
    0: "gap-0",
    1: "gap-1",
    2: "gap-2",
    3: "gap-3",
    4: "gap-4",
    5: "gap-5",
    6: "gap-6",
    7: "gap-7",
    8: "gap-8",
    9: "gap-9",
    10: "gap-10",
  },
  sm: {
    0: "gap-0 sm:gap-0",
    1: "gap-0 sm:gap-1",
    2: "gap-0 sm:gap-2",
    3: "gap-0 sm:gap-3",
    4: "gap-0 sm:gap-4",
    5: "gap-0 sm:gap-5",
    6: "gap-0 sm:gap-6",
    7: "gap-0 sm:gap-7",
    8: "gap-0 sm:gap-8",
    9: "gap-0 sm:gap-9",
    10: "gap-0 sm:gap-10",
  },
  md: {
    0: 'gap-0 md"gap-0',
    1: 'gap-0 md"gap-1',
    2: 'gap-0 md"gap-2',
    3: 'gap-0 md"gap-3',
    4: 'gap-0 md"gap-4',
    5: 'gap-0 md"gap-5',
    6: 'gap-0 md"gap-6',
    7: 'gap-0 md"gap-7',
    8: 'gap-0 md"gap-8',
    9: 'gap-0 md"gap-9',
    10: 'gap-0 md"gap-10',
  },
  lg: {
    0: "gap-0 lg:gap-0",
    1: "gap-0 lg:gap-1",
    2: "gap-0 lg:gap-2",
    3: "gap-0 lg:gap-3",
    4: "gap-0 lg:gap-4",
    5: "gap-0 lg:gap-5",
    6: "gap-0 lg:gap-6",
    7: "gap-0 lg:gap-7",
    8: "gap-0 lg:gap-8",
    9: "gap-0 lg:gap-9",
    10: "gap-0 lg:gap-10",
  },
  xl: {
    0: "gap-0 xl:gap-0",
    1: "gap-0 xl:gap-1",
    2: "gap-0 xl:gap-2",
    3: "gap-0 xl:gap-3",
    4: "gap-0 xl:gap-4",
    5: "gap-0 xl:gap-5",
    6: "gap-0 xl:gap-6",
    7: "gap-0 xl:gap-7",
    8: "gap-0 xl:gap-8",
    9: "gap-0 xl:gap-9",
    10: "gap-0 xl:gap-10",
  },
};

export function Container(
  props: {
    gap?: keyof GapDict;
    gapBreakpoint?: keyof typeof gaps;
  } & StandardProps,
) {
  const { gap = 4, gapBreakpoint = "none" } = props;
  return (
    <div
      className={clsx(
        "grid grid-cols-12",
        gaps[gapBreakpoint][gap],
        props.className,
      )}
    >
      {props.children}
    </div>
  );
}

interface ColProps extends StandardProps {
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

const classes: { [size: string]: { [cols: number]: string } } = {
  sm: {
    0: "col-span-0",
    1: "col-span-1",
    2: "col-span-2",
    3: "col-span-3",
    4: "col-span-4",
    5: "col-span-5",
    6: "col-span-6",
    7: "col-span-7",
    8: "col-span-8",
    9: "col-span-9",
    10: "col-span-10",
    11: "col-span-11",
    12: "col-span-12",
  },
  md: {
    0: "md:col-span-0",
    1: "md:col-span-1",
    2: "md:col-span-2",
    3: "md:col-span-3",
    4: "md:col-span-4",
    5: "md:col-span-5",
    6: "md:col-span-6",
    7: "md:col-span-7",
    8: "md:col-span-8",
    9: "md:col-span-9",
    10: "md:col-span-10",
    11: "md:col-span-11",
    12: "md:col-span-12",
  },
  lg: {
    0: "lg:col-span-0",
    1: "lg:col-span-1",
    2: "lg:col-span-2",
    3: "lg:col-span-3",
    4: "lg:col-span-4",
    5: "lg:col-span-5",
    6: "lg:col-span-6",
    7: "lg:col-span-7",
    8: "lg:col-span-8",
    9: "lg:col-span-9",
    10: "lg:col-span-10",
    11: "lg:col-span-11",
    12: "lg:col-span-12",
  },
  xl: {
    0: "xl:col-span-0",
    1: "xl:col-span-1",
    2: "xl:col-span-2",
    3: "xl:col-span-3",
    4: "xl:col-span-4",
    5: "xl:col-span-5",
    6: "xl:col-span-6",
    7: "xl:col-span-7",
    8: "xl:col-span-8",
    9: "xl:col-span-9",
    10: "xl:col-span-10",
    11: "xl:col-span-11",
    12: "xl:col-span-12",
  },
};

export function Col(props: ColProps) {
  const { sm = 12, md, lg, xl } = props;
  return (
    <div
      className={clsx(
        props.className,
        sm && classes.sm[sm],
        md && classes.md[md],
        lg && classes.lg[lg],
        xl && classes.xl[xl],
      )}
    >
      {props.children}
    </div>
  );
}
