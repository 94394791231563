import type React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";
import { LabellingCases } from "../../../openapi/model/labellingCases";

export default function HeaderLabelCaseSelect(
  props: Omit<SelectComboFieldProps, "options">,
): React.ReactElement {
  const options: Option[] = [
    { label: "Title Case", value: LabellingCases.TITLECASE },
    { label: "snake_case", value: LabellingCases.SNAKECASE },
    { label: "camelCase", value: LabellingCases.CAMELCASE },
  ];

  return <SelectComboField options={options} {...props} />;
}
