import { XMarkIcon } from "@heroicons/react/20/solid";
import type React from "react";
import { useState } from "react";
import { Button } from "../Theme/button";

export default function SuccessBanner(
  props: {
    title?: string;
  } & React.PropsWithChildren,
): React.ReactElement {
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);

  return (
    <>
      {visible && (
        <div
          className="pointer-events-auto flex items-center justify-between gap-x-6
        bg-green-800 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5 my-2"
        >
          <p className="text-sm leading-6 text-white">
            {props.title && (
              <span className={"text-base/7 font-semibold text-zinc-200 block"}>
                {props.title}
              </span>
            )}
            {props.children}
          </p>
          <Button plain onClick={onDismiss} onKeyDown={onDismiss}>
            <span className="sr-only">Dismiss</span>
            <div className={" text-zinc-200 "}>
              <XMarkIcon
                aria-hidden="true"
                className="h-5 w-5 text-zinc-200 "
              />
            </div>
          </Button>
        </div>
      )}
    </>
  );
}
