import type React from "react";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";

interface SftpReceiverIntegrationFormProps {
  namespace: string;
}

export default function SftpReceiverIntegrationForm(
  props: SftpReceiverIntegrationFormProps,
): React.ReactElement {
  const withNamespace = (field: string) => {
    return `${props.namespace}.${field}`;
  };

  return (
    <>
      <NewTextFormComponent
        fieldName={withNamespace("sftpCredentials.publicKey")}
        label={{ label: "Your Public SSH Key" }}
        type={"textarea"}
      />
    </>
  );
}
