import { Form, Formik } from "formik";
import { toInteger } from "lodash-es";
import type React from "react";
import { useState } from "react";
import AssetExpectedFrequencySwitch from "../../../../components/Asset/AssetExpectedFrequencySwitch";
import AssetGatewaySettingTypeSwitch from "../../../../components/Asset/AssetGatewaySettingTypeSwtich";
import AlertBanner from "../../../../components/Banner/AlertBanner";
import { ErrorBanner } from "../../../../components/Error/ErrorBanner";
import { ButtonGroupField } from "../../../../components/Forms/ButtonGroup";
import NewLabelWrapper from "../../../../components/Forms/NewLabelWrapper";
import { Button } from "../../../../components/Theme/button";
import useCreateEntity from "../../../../hooks/createEntity";
import type { GatewayAsset } from "../../../../openapi/model/gatewayAsset";
import { GatewaySettingsType } from "../../../../openapi/model/gatewaySettingsType";

interface Props {
  gateway: GatewayAsset;
}

const GatewaySettingForm = (props: Props): React.ReactElement => {
  const [state, setState] = useState<"error" | "completed" | undefined>(
    undefined,
  );

  const { createEntity, error } = useCreateEntity(
    "ASSET_CONFIGURATION",
    `asset/${props.gateway.assetId}`,
    true,
  );

  const isElvaco = props.gateway.manufacturer === "elvaco";

  const initialValues = {
    assetExpectedFrequency: props.gateway.configuration.collectionFrequency
      ? props.gateway.configuration.collectionFrequency.toString()
      : "15",
    assetSettingType:
      props.gateway.configuration.manufacturerSpecificSettings?.mbusSettings ||
      (isElvaco ? GatewaySettingsType.ELVACODEFAULT : undefined),
    assetPostFrequency: props.gateway.configuration.postFrequency
      ? props.gateway.configuration.postFrequency.toString()
      : "Default",
    postRawData:
      props.gateway.configuration.collectRawData ||
      (isElvaco ? false : undefined),
    autoSync: props.gateway.configuration.autoSync || false,
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }): void => {
        setState(undefined);
        createEntity({
          ...props.gateway,
          configuration: {
            ...props.gateway.configuration,
            autoSync: values.autoSync,
            collectionFrequency: toInteger(values.assetExpectedFrequency),
            manufacturerSpecificSettings: isElvaco
              ? {
                  mbusSettings: values.assetSettingType,
                }
              : undefined,
            postFrequency:
              values.assetPostFrequency === "Default"
                ? null
                : toInteger(values.assetPostFrequency),
            collectRawData: isElvaco ? values.postRawData : undefined,
          },
        })
          .then(() => setState("completed"))
          .finally(() => setSubmitting(false));
      }}
    >
      {({ isSubmitting, isValid }): React.ReactElement => (
        <Form>
          {isElvaco && (
            <AssetGatewaySettingTypeSwitch fieldName={"assetSettingType"} />
          )}
          <AssetExpectedFrequencySwitch
            fieldName={"assetExpectedFrequency"}
            title={"Data Collection Frequency"}
            abbreviate={true}
          />
          <AssetExpectedFrequencySwitch
            fieldName={"assetPostFrequency"}
            title={"Data Post Frequency"}
            abbreviate={true}
            include1Minute={false}
            includeDeviceNormal={true}
          />
          {isElvaco && (
            <ButtonGroupField
              fieldName={"postRawData"}
              label={{ label: "Post Raw MBus" }}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          )}
          <ButtonGroupField
            fieldName={"autoSync"}
            label={{ label: "Automatically Sync Configuration" }}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
          {props.gateway.configuration.customConfiguration && (
            <NewLabelWrapper label={"Custom Configuration"}>
              <ul>
                {Object.entries(
                  props.gateway.configuration.customConfiguration,
                ).map(([k, v]) => {
                  return (
                    <li key={k}>
                      <b>{k}</b>
                      {v}
                    </li>
                  );
                })}
              </ul>
            </NewLabelWrapper>
          )}

          <div className={"form-group row py-4"}>
            <span className={"float-right"}>
              <Button
                type={"submit"}
                color={"brandLight"}
                disabled={isSubmitting || !isValid}
              >
                Update
              </Button>
            </span>
          </div>
          {(state || error) && (
            <div className={"form-group row"}>
              {state === "completed" && (
                <AlertBanner className={"bg-success"}>
                  Settings successfully updated.
                </AlertBanner>
              )}
              {state === "error" && (
                <AlertBanner className={"bg-danger"}>
                  Error updating settings.{" "}
                  {error ? JSON.stringify(error) : "Unknown"}
                </AlertBanner>
              )}
              <ErrorBanner error={error} />
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default GatewaySettingForm;
