import { isNil } from "lodash-es";
import type React from "react";
import type { UserIdentifier } from "../../openapi/model/userIdentifier";

export default function UserReference(props: {
  userId?: string;
  user?: UserIdentifier;
}): React.ReactElement {
  const haveUserId = props.userId && props.userId.length === 36;
  const haveUserName = !isNil(props.user?.userName);

  if (haveUserName) {
    return <>{props.user?.userName}</>;
  }
  if (haveUserId) {
    return <>...{props.userId?.slice(24)}</>;
  }
  return <>{props.userId}</>;
}
