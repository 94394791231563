import clsx from "clsx";
import type React from "react";

interface StackedListCommon {
  "data-cy"?: string;
  key?: string;
}

export function StackedList(
  props: StackedListCommon & React.PropsWithChildren,
) {
  const { children, ...rest } = props;
  return (
    <ul className="divide-y divide-gray-100" {...rest}>
      {children}
    </ul>
  );
}

export function StackedListItem(
  props: StackedListCommon & React.PropsWithChildren<{ dense?: boolean }>,
) {
  const { children, dense = false, ...rest } = props;
  return (
    <li className={clsx("relative ", dense ? "py-2" : "py-5")} {...rest}>
      <div className={"flex sm:flex-none self-start"}>{children}</div>
    </li>
  );
}

export function StackedListTwoColumnItem(
  props: {
    column1: React.ReactElement;
    column2: React.ReactElement;
    column1Size?: string;
    column2Size?: string;
    dense?: boolean;
  } & StackedListCommon,
): React.ReactElement {
  const {
    column1,
    column2,
    column1Size = "w-1/2",
    column2Size = "w-1/2",
    dense = false,
    ...rest
  } = props;

  return (
    <li
      className={clsx("relative flex justify-between", dense ? "py-2" : "py-5")}
      {...rest}
    >
      <div
        className={`flex gap-x-4 pr-6 ${column1Size} sm:flex-none self-start`}
      >
        {column1}
      </div>
      <div
        className={`flex items-center justify-between gap-x-4 ${column2Size} sm:flex-none self-start`}
      >
        {column2}
      </div>
    </li>
  );
}

export function StackedListThreeColumnItem(
  props: {
    column1: React.ReactElement;
    column2: React.ReactElement;
    column3: React.ReactElement;
    column1Size?: string;
    column2Size?: string;
    column3Size?: string;
  } & StackedListCommon,
): React.ReactElement {
  const {
    column1,
    column2,
    column3,
    column1Size = "w-1/3",
    column2Size = "w-1/3",
    column3Size = "w-1/3",
    ...rest
  } = props;

  return (
    <li className="relative flex justify-between py-5" {...rest}>
      <div className={`flex gap-x-4 pr-6 ${column1Size}  self-start`}>
        {column1}
      </div>
      <div
        className={`flex items-center justify-between gap-x-4 ${column2Size}  self-start`}
      >
        {column2}
      </div>
      <div
        className={`flex items-center justify-between gap-x-4 ${column3Size}  self-start`}
      >
        {column3}
      </div>
    </li>
  );
}

export function StackedListHeadingContent(props: {
  title: string;
  className?: string;
}) {
  return (
    <div className={clsx("min-w-0 flex-auto", props.className)}>
      <div>
        <p className="text-sm font-semibold leading-6 text-gray-900">
          {props.title}
        </p>
      </div>
    </div>
  );
}
