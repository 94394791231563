import { ErrorBanner } from "components/Error/ErrorBanner";
import NewTextFormComponent from "components/Forms/NewTextFormComponent";
import BlockSpinner from "components/Spinners/BlockSpinner";
import { Form, Formik, type FormikHelpers } from "formik";
import type React from "react";
import * as Yup from "yup";
import { yupRequiredString } from "../../../components/Forms/yupValidators";
import { Button } from "../../../components/Theme/button";
import useCreateEntity from "../../../hooks/createEntity";
import type { AssetPosition } from "../../../openapi/model/assetPosition";
import { AssetType } from "../../../openapi/model/assetType";
import type { Location } from "../../../openapi/model/location";

interface Props {
  companyId: string;
  schemeId: string;
  postCreateActions?: () => any;
}

interface FormValues {
  address: "";
}

export default function AssetPositionCreateForm(
  props: Props,
): React.ReactElement {
  const createLocation = useCreateEntity<Location>("LOCATION");
  const createAssetPosition = useCreateEntity<AssetPosition>("ASSET_POSITION");

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    createLocation
      .createEntity({
        schemeId: props.schemeId,
        companyId: props.companyId,
        address: values.address,
      })
      .then(([location]) => {
        createAssetPosition.createEntity({
          schemeId: props.schemeId,
          companyId: props.companyId,
          locationId: location.locationId,
          assetPositionType: AssetType.WirelessCluster,
        });
      })
      .then(() => {
        resetForm();
        if (props.postCreateActions) {
          props.postCreateActions();
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues: FormValues = {
    address: "",
  };
  const creating = createLocation.creating || createAssetPosition.creating;

  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={Yup.object().shape({
        address: yupRequiredString,
      })}
    >
      {({ isValid, isSubmitting }): React.ReactElement => (
        <BlockSpinner loading={creating}>
          <Form data-cy={"wireless-cluster-create"}>
            <h3 className="text-white mb-0">Add Wireless Cluster</h3>
            <NewTextFormComponent
              fieldName={"address"}
              label={{ label: "Description" }}
            />
            <Button
              type={"submit"}
              color={"brandLight"}
              disabled={isSubmitting || !isValid}
            >
              Add Wireless Cluster
            </Button>
            <ErrorBanner
              error={createLocation.error || createAssetPosition.error}
            />
          </Form>
        </BlockSpinner>
      )}
    </Formik>
  );
}
