import { Badge } from "components/Theme/badge";
import type React from "react";
import type { JobStatus } from "../../openapi/model/jobStatus";
import type { Status } from "../../openapi/model/status";

export default function AssetRegisterStatusLabel(props: {
  status: Status | JobStatus;
  className?: string;
  displayActive?: boolean;
}): React.ReactElement {
  const { className, status, displayActive = false } = props;

  const activeElement = (
    <>
      {" "}
      {displayActive && (
        <Badge color={"success"} className={className}>
          Active
        </Badge>
      )}
    </>
  );
  switch (status) {
    case "ACTIVE":
      return activeElement;
    case "active":
      return activeElement;
    case "DELETED":
      return (
        <Badge color={"danger"} className={className}>
          Deleted
        </Badge>
      );
    case "disabled":
      return (
        <Badge color={"danger"} className={className}>
          Disabled
        </Badge>
      );
    default:
      return <></>;
  }
}
