import React from "react";
import { useLocation } from "react-router-dom";
import { selectPropertyFromLocationHash } from "../../components/navigation/URL";
import { SchemeContext } from "../../reducers/scheme";

const SchemeSelectInit = (props: {
  children: React.ReactNode;
}): React.ReactElement => {
  const location = useLocation();
  const [init, setInit] = React.useState(false);

  const { dispatch, state } = React.useContext(SchemeContext);

  // biome-ignore lint/correctness/useExhaustiveDependencies(state.schemes): want the trigger to occur when the scheme list changes
  React.useEffect(() => {
    const schemeId = selectPropertyFromLocationHash("scheme", location.hash);
    if (schemeId) {
      dispatch({ type: "SELECT_SCHEME_BY_ID", payload: schemeId });
    } else {
      dispatch({ type: "SELECT_SCHEME", payload: undefined });
    }
    setInit(true);
  }, [dispatch, location.hash, state.schemes]);

  return <>{init && props.children}</>;
};

export default SchemeSelectInit;
