import { Heading } from "components/Theme/heading";
import type React from "react";
import type { AssetPosition } from "../../../../openapi/model/assetPosition";
import type { Location } from "../../../../openapi/model/location";
import { Status } from "../../../../openapi/model/status";
import AssetPositionDeleteButton from "../AssetPositionDeleteButton";

const AssetPositionDeleteTab = (props: {
  assetPosition: AssetPosition | Location;
  title: string;
  refresh?: () => any;
  delete: () => Promise<any>;
}): React.ReactElement => {
  const deleted = props.assetPosition.status === Status.DELETED;
  return (
    <>
      <Heading>Delete {props.title}</Heading>
      <AssetPositionDeleteButton {...props} />
      {deleted && <p>{props.title} has already been deleted.</p>}
    </>
  );
};

export default AssetPositionDeleteTab;
