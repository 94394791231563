/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

class Login extends React.Component {
  render() {
    return (
      <>
        <div className="text-center text-brand-light-400 pt-5 text-lg font-light">
          © {new Date().getFullYear()}{" "}
          <a
            className="font-normal"
            href="https://meterpoint.io"
            target="_blank"
            rel="noreferrer"
          >
            Plan System Data Ltd
          </a>
        </div>
        <div
          className={
            "flex text-brand-light-400 justify-center gap-3 font-extralight"
          }
        >
          <div>
            <Link to="https://meterpoint.io" target="_blank">
              Plan System Data
            </Link>
          </div>
          <div>
            <Link to="https://meterpoint.io" target="_blank">
              About Us
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
