import type React from "react";
import type { Entities } from "../constants/entities";
import type { PaginatedListResultProps } from "../core/action/GetList";
import useCreateEntity from "../hooks/createEntity";
import useGetList from "../hooks/getList";
import type { Cursor } from "../openapi/model/cursor";

interface ChildProps<T = any> {
  create: (entity: T) => Promise<any>;
  creating: boolean;
  createError?: Error;
  list: PaginatedListResultProps<T>;
}

interface Props<T = any> {
  entityType: Entities;
  path?: string;
  put?: boolean;
  parseToJson?: boolean;
  listParams?: Record<string, string | string[] | boolean | undefined>;
  cursor?: Cursor;
  listPath?: string;
  children: (props: ChildProps<T>) => React.ReactElement;
}

function CreateAndGetListCombo<T = any>(props: Props<T>) {
  const list = useGetList<T>(
    props.entityType,
    props.listParams,
    props.cursor,
    props.listPath,
  );

  const create = useCreateEntity<T>(
    props.entityType,
    props.path,
    props.put,
    props.parseToJson,
  );

  function createFunction(entity: T): Promise<any> {
    const [_, refresh] = list;
    return create.createEntity(entity).then(() => refresh());
  }

  const [{ data, error, loading }, refresh, cancel, updateItem] = list;

  return (
    <>
      {props.children({
        create: createFunction,
        creating: create.creating,
        createError: create.error,
        list: {
          data: data,
          error: error !== null ? error : undefined,
          loading,
          refresh,
          cancel,
          updateItem,
        },
      })}
    </>
  );
}

export default CreateAndGetListCombo;
