import * as React from "react";
import RequireScheme from "../../../components/Scheme/RequireScheme";
import SelectedScheme from "../../../components/Scheme/SelectedScheme";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";
import { IntegrationCreateForm } from "./IntegrationCreateForm";

export function IntegrationCreatePage() {
  return (
    <SelectedScheme>
      {(schemeProps) => (
        <OneCardPage
          headerTitle={
            schemeProps.schemeId
              ? `Add an Integration to ${schemeProps.schemeId}`
              : "Add an Integration"
          }
          breadcrumbs={[
            {
              url: "/admin/integrations",
              name: "Integrations",
            },
            {
              url: "/admin/integrations/create",
              name: "Create",
            },
          ]}
          setPageTitle={schemeObjectPageTitle("Add Data Integration")}
        >
          <RequireScheme>
            <IntegrationCreateForm
              schemeId={schemeProps.schemeId as string}
              companyId={schemeProps.companyId}
            />
          </RequireScheme>
        </OneCardPage>
      )}
    </SelectedScheme>
  );
}
