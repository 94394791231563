import type React from "react";
import { Button, type styles } from "../../Theme/button";

interface ButtonProps extends React.PropsWithChildren {
  color?: keyof typeof styles.colors;
  onClick: () => any;
  disabled: boolean;
  className?: string;
}

function NewButton(props: ButtonProps) {
  return (
    <Button
      color={props.color || "brandLight"}
      onClick={() => {
        !props.disabled && props.onClick();
      }}
      disabled={props.disabled}
      className={props.className}
    >
      {props.children}
    </Button>
  );
}

export default NewButton;
