/*
Provides formatters to use with data tables for dealing with numeric and undefined values.
 */
import dayjs from "dayjs";
import {
  isNumber,
  isNaN as loIsNaN,
  toString as loToString,
  round,
  toNumber,
} from "lodash-es";
import type { TableRow } from "react-data-table-component";
import type { Primitive } from "type-fest";
import type { SingleAggregationTimeAssetData } from "../TimeSeries/model";

export const numberFormatter = (
  valueName: string,
  numberOfDigits?: number,
  undefinedString = "-",
) => {
  return (row: TableRow): string => {
    const data: Primitive | null | undefined = row[valueName] as
      | Primitive
      | null
      | undefined;
    if (data !== null && data !== undefined) {
      if (loIsNaN(data)) {
        return undefinedString;
      } else {
        return numberOfDigits !== undefined
          ? loToString(
              (Math.round(toNumber(data) * 100) / 100).toFixed(numberOfDigits),
            )
          : loToString(data);
      }
    } else {
      return undefinedString;
    }
  };
};

export const singleAggregationTimeAssetDataSelector = (date: Date) => {
  return (row: SingleAggregationTimeAssetData) => {
    const data = row.data[dayjs(date).format()];
    return data !== null && data !== undefined
      ? Number(data)
      : Number.NEGATIVE_INFINITY;
  };
};

export const singleAggregationTimeAssetDataFormatter = (
  date: Date,
  numberOfDigits?: number,
  undefinedString = "-",
) => {
  return (row: SingleAggregationTimeAssetData): string => {
    const data: Primitive | null | undefined = row.data[dayjs(date).format()];
    if (data !== null && data !== undefined) {
      if (loIsNaN(data)) {
        return undefinedString;
      } else {
        return numberOfDigits !== undefined
          ? loToString(
              (Math.round(toNumber(data) * 100) / 100).toFixed(numberOfDigits),
            )
          : loToString(data);
      }
    } else {
      return undefinedString;
    }
  };
};

export const percentageFormatter = (number?: string | number): string => {
  const value =
    isNumber(number) && !loIsNaN(number)
      ? round(toNumber(number) * 100, 1)
      : "-";

  return `${value} %`;
};

export default numberFormatter;
