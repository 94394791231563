import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, Formik, type FormikHelpers } from "formik";
import { range } from "lodash";
import type React from "react";
import { useState } from "react";
import * as Yup from "yup";
import SuccessBanner from "../../components/Banner/SuccessBanner";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import { ButtonGroupField } from "../../components/Forms/ButtonGroup";
import NewTextFormComponent from "../../components/Forms/NewTextFormComponent";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import { Button } from "../../components/Theme/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/Theme/table";
import useUpdateEntity from "../../hooks/updateEntity";
import type { StreamingConfiguration } from "../../openapi/model/streamingConfiguration";

export default function StreamingSettingsForm(props: {
  streamingConfiguration: StreamingConfiguration;
}): React.ReactElement {
  const [updated, setUpdated] = useState<boolean>(false);
  const [currentStreamingConfiguration, setCurrentStreamingConfiguration] =
    useState<StreamingConfiguration>(props.streamingConfiguration);
  const update = useUpdateEntity<StreamingConfiguration>(
    "STREAMING_CONFIGURATION",
    "",
    undefined,
    "streaming",
  );

  const submit = (
    values: StreamingConfiguration,
    { setSubmitting, resetForm }: FormikHelpers<StreamingConfiguration>,
  ) => {
    setUpdated(false);
    update
      .updateEntity(values)
      .then(() => {
        setUpdated(true);
        setCurrentStreamingConfiguration(values);
        resetForm();
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <Formik
        onSubmit={submit}
        initialValues={currentStreamingConfiguration}
        validationSchema={Yup.object().shape({
          enabled: Yup.boolean().required(),
          endpoints: Yup.array()
            .of(
              Yup.object().shape({
                url: Yup.string().required().url(),
                authentication: Yup.object().shape({
                  username: Yup.string().required(),
                  password: Yup.string().required(),
                }),
              }),
            )
            .max(3),
        })}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {({
          values,
          submitForm,
          isValid,
          isSubmitting,
          dirty,
        }): React.ReactElement => {
          return (
            <BlockSpinner loading={isSubmitting}>
              <div>
                <h4>Enable</h4>
                <p>
                  Turn off streaming without deleting your endpoint
                  configurations.{" "}
                </p>
                <ButtonGroupField
                  fieldName={"enabled"}
                  options={[
                    { label: "Streaming enabled", value: true },
                    { label: "Streaming disabled", value: false },
                  ]}
                  label={{}}
                />
                <h4>Endpoints</h4>
                <p>
                  Add upto 3 endpoints to receive the formatted data webhook.
                  Endpoints must be https (with a valid certificate) and be
                  secured with HTTP Basic Authentication.
                </p>

                <FieldArray
                  name={"endpoints"}
                  render={(arrayHelpers) => (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableHeader className={"px-1"}>URL</TableHeader>
                          <TableHeader className={"px-1"}>Username</TableHeader>
                          <TableHeader className={"px-1"}>Password</TableHeader>
                          <TableHeader>&nbsp;</TableHeader>
                        </TableRow>
                      </TableHead>
                      {values.endpoints && values.endpoints.length > 0 ? (
                        range(values.endpoints.length).map((index) => (
                          <TableBody key={index}>
                            <TableRow>
                              <TableCell className={"px-1"}>
                                <NewTextFormComponent
                                  fieldName={`endpoints[${index}].url`}
                                />
                              </TableCell>
                              <TableCell className={"px-1"}>
                                <NewTextFormComponent
                                  fieldName={`endpoints[${index}].authentication.username`}
                                />
                              </TableCell>
                              <TableCell className={"px-1"}>
                                <NewTextFormComponent
                                  fieldName={`endpoints[${index}].authentication.password`}
                                />
                              </TableCell>
                              <TableCell className={"px-1"}>
                                <Button
                                  color={"red"}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                                  Remove
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ))
                      ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={4}>No endpoints</TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                      {values.endpoints && values.endpoints.length < 3 && (
                        <tr>
                          <td colSpan={4} className={"text-center"}>
                            <Button
                              color="brandLight"
                              // size={"sm"}
                              onClick={() =>
                                arrayHelpers.push({
                                  url: "",
                                  authentication: {
                                    username: "",
                                    password: "",
                                  },
                                })
                              }
                            >
                              {/* TODO: small button */}
                              {/* show this when user has removed all friends from the list */}
                              Add an endpoint
                            </Button>
                          </td>
                        </tr>
                      )}
                    </Table>
                  )}
                />
                <div>
                  <Button
                    type={"submit"}
                    color={"brandLight"}
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </BlockSpinner>
          );
        }}
      </Formik>
      {!update.updating && update.error && <ErrorBanner error={update.error} />}

      {!update.updating && updated && (
        <SuccessBanner>
          Streaming configuration updated successfully.
        </SuccessBanner>
      )}
    </>
  );
}
