import GetList, { type GetListProps } from "core/action/GetList";
import type React from "react";
import NewApiPaginatedTable from "./NewApiPaginatedTable";

export interface FilterFormProps {
  refresh: () => void;
}

interface Props extends GetListProps {
  header: [React.ReactNode, number];
  row: (
    item: any,
    update: (updatedItem: any) => void,
  ) => React.ReactElement<{ key: string }>;
  "data-cy"?: string; // optional cypress label for the table
  tableClassName?: string;
  noDataRow?: React.ReactElement;
  showPagination?: boolean;
  additionalFunctionButtons?: React.ReactElement;
  filterForm?: ({ refresh }: FilterFormProps) => React.ReactElement;
}

const GetListPaginatedTable = (props: Props): React.ReactElement => {
  const { entityType, params, cursor, path, lastChange, ...rest } = props;

  return (
    <GetList
      entityType={entityType}
      params={params}
      cursor={cursor}
      path={path}
      lastChange={lastChange}
    >
      {({ data, loading, error, refresh, updateItem }) => (
        <>
          {props.filterForm?.({ refresh })}
          <NewApiPaginatedTable
            data={data}
            error={error}
            loading={loading}
            refresh={refresh}
            updateItem={updateItem}
            {...rest}
          />
        </>
      )}
    </GetList>
  );
};

export default GetListPaginatedTable;
