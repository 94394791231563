import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type React from "react";
import { useContext, useEffect } from "react";
import ErrorTriangle from "../../../components/Error/ErrorTriangle";
import { getData } from "../../../hooks/getData";
import useUpdateEntity from "../../../hooks/updateEntity";
import useUserHasRoles from "../../../hooks/user/getCurrentUserRole";
import {
  AssetPositionCacheContext,
  useGetCachedAssetPosition,
} from "../../../reducers/assetPositionCachingReducer";
import { useSelectedCompany } from "../../../reducers/company";

export default function ReceiverAssignmentButton(props: {
  assetPositionId: string;
  receiverPositionId: string;
  companyId: string;
  schemeId: string;
  initLoad?: boolean;
}): React.ReactElement {
  const company = useSelectedCompany();
  const { assetPositionId, companyId, receiverPositionId, schemeId } = props;
  const { dispatch } = useContext(AssetPositionCacheContext);
  const assetPositionState = useGetCachedAssetPosition(assetPositionId);

  const isUserAdmin = useUserHasRoles("admin");

  function refresh() {
    dispatch({
      type: "FETCHING_ITEM",
      assetPositionId: assetPositionId,
    });

    getData<Array<string>>({
      path: `asset_position/${assetPositionId}/wireless_receivers`,
      companyId,
      schemeId,
      params: { assetPositionId },
      post: false,
      singletonApi: true,
      transform: false,
    }).then((links) => {
      dispatch({
        type: "FETCHED_ITEM",
        assetPositionId: assetPositionId,
        payload: links,
      });
    });
  }

  useEffect(() => {
    if (assetPositionState === undefined && props.initLoad) {
      refresh();
    }
  });

  const { updateEntity, error: updateError } = useUpdateEntity(
    "ASSET_POSITION",
    assetPositionId,
    () => refresh(),
    `asset_position/${assetPositionId}/wireless_receivers?company_id=${company?.companyId}`,
  );

  const assigned =
    assetPositionState?.data &&
    (assetPositionState.data || []).includes(receiverPositionId);

  function removeReceiverFromPosition(
    receivers: string[],
    receiverPositionId: string,
  ): string[] {
    const index = receivers.indexOf(receiverPositionId, 0);
    if (index > -1) {
      receivers.splice(index, 1);
    }
    return receivers;
  }

  function toggleAssignment() {
    if (assetPositionState?.data && isUserAdmin) {
      const updatedReceivers = !assigned
        ? [...assetPositionState.data, receiverPositionId]
        : removeReceiverFromPosition(
            assetPositionState.data,
            receiverPositionId,
          );
      if (
        assetPositionState.data &&
        (assetPositionState.updating === false ||
          assetPositionState.updating === undefined)
      ) {
        dispatch({ type: "UPDATING_ITEM", assetPositionId });
        updateEntity(updatedReceivers).then(() => {
          dispatch({ type: "UPDATED_ITEM", assetPositionId });
        });
      }
    }
  }

  return (
    <div style={{ width: "20px" }} className={"align-middle"}>
      {(assetPositionState?.loading || assetPositionState?.updating) && (
        <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
      )}
      {!assetPositionState?.loading &&
        !assetPositionState?.updating &&
        assetPositionState?.data && (
          <FontAwesomeIcon
            icon={assigned ? faCheckSquare : faSquare}
            onClick={toggleAssignment}
            role={isUserAdmin ? "button" : undefined}
            fixedWidth
          />
        )}
      {!assetPositionState?.loading && (
        <ErrorTriangle error={assetPositionState?.error} />
      )}
      {!assetPositionState?.updating && <ErrorTriangle error={updateError} />}
    </div>
  );
}
