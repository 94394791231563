import Card from "components/Theme/card";
import type { Dayjs } from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import type React from "react";
import { columnToTimeNumberData } from "../../pages/assetRegister/assets/meters/heatMeters/HeatMeterFlowAndTemperatureChart";
import ErrorOverlappingBanner from "../Error/ErrorOverlappingBanner";
import BlockSpinner from "../Spinners/BlockSpinner";
import { pieChartColors } from "./ChartColors";

export interface DataQualityTimeSeriesAreaChartProps {
  times: Array<Dayjs>;
  missing: Array<number>;
  errors: Array<number>;
  valueErrors: Array<number>;
  incomplete: Array<number>;
  complete: Array<number>;
  expected: Array<number>;
  loading: boolean;
  error?: Error | Error[];
  size?: number;
}

export default function DataQualityTimeSeriesAreaChart(
  props: DataQualityTimeSeriesAreaChartProps,
): React.ReactElement {
  const {
    times,
    missing,
    errors,
    incomplete,
    expected,
    complete,
    valueErrors,
    size = 400,
  } = props;

  const series: (
    | Highcharts.SeriesAreaOptions
    | Highcharts.SeriesLineOptions
  )[] = [
    {
      data: columnToTimeNumberData(missing, times),
      name: "Rows Not Received",
      type: "area",
      color: pieChartColors.awful,
      animation: false,
      showInLegend: true,
    },
    {
      data: columnToTimeNumberData(valueErrors, times),
      name: "Rows With Value Error(s)",
      type: "area",
      color: pieChartColors.reallyReallyBad,
      animation: false,
      showInLegend: true,
    },
    {
      data: columnToTimeNumberData(errors, times),
      name: "Invalid Rows",
      type: "area",
      color: pieChartColors.reallyBad,
      animation: false,
      showInLegend: true,
    },
    {
      data: columnToTimeNumberData(incomplete, times),
      name: "Incomplete but Otherwise Valid Rows",
      type: "area",
      color: pieChartColors.bad,
      animation: false,
      showInLegend: true,
    },
    {
      data: columnToTimeNumberData(complete, times),
      name: "Complete Valid Rows",
      type: "area",
      color: pieChartColors.great,
      animation: false,
      showInLegend: true,
    },
    {
      data: columnToTimeNumberData(expected, times).map((val, index) => {
        return {
          x: val[0],
          y: val[1],
          dataLabels:
            index === expected.length - 2
              ? {
                  enabled: true,
                  format: "Expected: {y}",
                }
              : { enabled: false },
        };
      }),
      name: "Expected Rows",
      type: "line",
      color: "red",
      lineWidth: 2,
      dashStyle: "LongDash",
      animation: false,
      showInLegend: false,
      enableMouseTracking: false,
    },
  ];

  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
    },
    chart: {
      animation: false,
      height: size,
    },
    series: series,
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: props.loading ? "Loading Data" : "No data for this time period",
    },
    tooltip: {
      shared: true,
    },
    yAxis: [
      {
        // Primary yAxis
        title: {
          text: "Data Points",
        },
        min: 0,
        max: expected[0],
      },
    ],
    plotOptions: {
      area: {
        stacking: "normal",
        marker: {
          enabled: false,
        },
        lineWidth: 0,
      },
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    noData: {
      style: {
        fontWeight: "normal",
        fontSize: "15px",
        color: "black",
      },
    },
    exporting: {
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadSVG",
          ],
        },
      },
    },
  };

  return (
    <>
      <BlockSpinner loading={props.loading}>
        <ErrorOverlappingBanner error={props.error}>
          <Card title={"Quality"} subtitle={"Daily Data Quality Assessment"}>
            <div
              className="chart"
              style={{ minHeight: `${size}px`, height: `${size}px` }}
            >
              <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
          </Card>
        </ErrorOverlappingBanner>
      </BlockSpinner>
    </>
  );
}
