import type React from "react";
import type { Asset } from "../../../../openapi/model/asset";
import type { DeviceAsset } from "../../../../openapi/model/deviceAsset";
import type { GatewayAsset } from "../../../../openapi/model/gatewayAsset";
import GatewayListContent from "../gateway/GatewayListContent";
import MBusAssetListContext from "../meters/MBusAssetListContext";
import SimCardListContent from "../sim_cards/SimCardListContent";

export default function DeviceSpecificListContent(props: {
  asset: Asset;
  refreshElement: () => any;
}): React.ReactElement {
  switch (props.asset.assetType) {
    case "sim_card":
      return (
        <SimCardListContent
          asset={props.asset}
          refresh={props.refreshElement}
        />
      );
    case "gateway":
      return <GatewayListContent asset={props.asset as GatewayAsset} />;
    default:
      return <MBusAssetListContext asset={props.asset as DeviceAsset} />;
  }
}
