import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useField } from "formik";
import { lowerCase, upperFirst } from "lodash-es";
import type React from "react";
import { useState } from "react";
import * as Yup from "yup";
import NewCheckboxComponent from "../../../components/Forms/NewCheckboxComponent";
import NewCheckboxField from "../../../components/Forms/NewCheckboxField";
import NewLabelWrapper from "../../../components/Forms/NewLabelWrapper";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import { SelectComboField } from "../../../components/Forms/SelectCombo/SelectCombo";
import type { SubFormProps } from "../../../components/Forms/WithSubForm";
import Collapse from "../../../components/Theme/collapse";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/Theme/table";
import TimeFilterSelect from "../../../components/TimeSeries/TimeFilterSelect";
import type { AllDataReportConfiguration as AllDataReportConfigurationModel } from "../../../openapi/model/allDataReportConfiguration";
import { AssetType } from "../../../openapi/model/assetType";
import type { EquationContentDefinition } from "../../../openapi/model/equationContentDefinition";
import { LabellingCases } from "../../../openapi/model/labellingCases";
import type { SingleColumnContentDefinition } from "../../../openapi/model/singleColumnContentDefinition";
import { SpreadsheetFileType } from "../../../openapi/model/spreadsheetFileType";
import type { StandardSpreadsheetOptions } from "../../../openapi/model/standardSpreadsheetOptions";
import type { StaticContentDefinition } from "../../../openapi/model/staticContentDefinition";
import { TimeFilter } from "../../../openapi/model/timeFilter";
import HeaderLabelCaseSelect from "../Common/HeaderLabelCaseSelect";
import OutputFileTypeSelect from "../Common/OutputFileTypeSelect";

export interface AllDataReportConfigurationProps {
  fileName?: string;
  configuration: AllDataReportConfigurationModel;
}

export const AllDataReportConfigurationSubFormSchema = () => {
  return Yup.object().shape({
    configuration: Yup.object().shape({
      includedAssetTypes: Yup.array().required().min(1),
    }),
  });
};

export const AllDataReportConfigurationInitialValues: AllDataReportConfigurationProps =
  {
    fileName: "all_data_{company_id}_{scheme_id}_{date}.csv",
    configuration: {
      timeFilter: TimeFilter.ALL,
      fileType: SpreadsheetFileType.CSV,
      includedAssetTypes: [
        AssetType.HeatMeter,
        AssetType.CoolingMeter,
        AssetType.HeatingAndCoolingMeter,
        AssetType.ElectricityMeter,
        AssetType.GasMeter,
        AssetType.WaterMeter,
        AssetType.RoomSensor,
        AssetType.Co2RoomSensor,
      ],
      output: {
        outputType: "standard",
        headerLabels: LabellingCases.TITLECASE,
        includeUnits: false,
      } as StandardSpreadsheetOptions,
    },
  };

function customColumnContent(
  row:
    | SingleColumnContentDefinition
    | EquationContentDefinition
    | StaticContentDefinition,
): React.ReactElement {
  switch (row.outputType) {
    case "static":
      return <>{row.value}</>;
    case "equation":
      return <>{row.equation}</>;
    case "column":
      return (
        <>
          {row.column}
          {row.difference && " (difference)"}
        </>
      );
    default:
      return <></>;
  }
}

const AllDataReportConfigurationForm = ({
  namespace,
  disabled,
}: SubFormProps): React.ReactElement => {
  const withNamespace = (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  };

  const [{ value }] = useField(namespace);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const custom = value.configuration.output.outputType === "custom";
  return (
    <>
      <NewTextFormComponent
        fieldName={withNamespace("fileName")}
        label={{ label: "Filename'" }}
        placeholder={
          "Keywords for filename are: company_id, scheme_id and date"
        }
        disabled={disabled}
      />
      <h4>
        Advanced Configuration
        <FontAwesomeIcon
          icon={isOpen ? faMinusCircle : faPlusCircle}
          onClick={toggle}
          className={"mx-1"}
        />
      </h4>
      <Collapse isOpen={isOpen}>
        {custom ? (
          <>
            <NewLabelWrapper label={"This report has a custom output"}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeader>Header</TableHeader>
                    <TableHeader>Type</TableHeader>
                    <TableHeader>Content</TableHeader>
                    <TableHeader>Factor</TableHeader>
                    <TableHeader>Rounding</TableHeader>
                    <TableHeader>Format</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {value.configuration.output.columns.map(
                    (
                      v:
                        | SingleColumnContentDefinition
                        | EquationContentDefinition
                        | StaticContentDefinition,
                    ) => {
                      return (
                        <TableRow key={v.headerLabel}>
                          <TableCell>{v.headerLabel}</TableCell>
                          <TableCell>{v.outputType}</TableCell>
                          <TableCell>{customColumnContent(v)}</TableCell>
                          <TableCell>{v.factor || "-"}</TableCell>
                          <TableCell>{v.rounding || "-"}</TableCell>
                          <TableCell>{v.format || "-"}</TableCell>
                        </TableRow>
                      );
                    },
                  )}
                </TableBody>
              </Table>
            </NewLabelWrapper>
          </>
        ) : (
          <>
            <HeaderLabelCaseSelect
              fieldName={withNamespace("configuration.output.headerLabels")}
              label={{ label: "Headers Case" }}
              disabled={disabled}
            />
            <SelectComboField
              label={{ label: "Headers Case" }}
              fieldName={withNamespace("configuration.output.includeUnits")}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              disabled={disabled}
            />
          </>
        )}
        <TimeFilterSelect
          label={{ label: "Time Filter" }}
          fieldName={withNamespace("configuration.timeFilter")}
          disabled={disabled}
        />

        <OutputFileTypeSelect
          label={{ label: "File Type" }}
          fieldName={withNamespace("configuration.fileType")}
          disabled={disabled}
        />
        <NewCheckboxField
          name={withNamespace("configuration.includedAssetTypes")}
        >
          {(fieldProps) => (
            <NewCheckboxComponent
              label={{ label: "Asset Type Filter" }}
              options={Object.values([
                AssetType.HeatMeter,
                AssetType.HeatingAndCoolingMeter,
                AssetType.ElectricityMeter,
                AssetType.GasMeter,
                AssetType.WaterMeter,
                AssetType.CoolingMeter,
                AssetType.RoomSensor,
                AssetType.Co2RoomSensor,
                AssetType.Gateway,
              ]).map((key) => {
                return { label: upperFirst(lowerCase(key)), value: key };
              })}
              disabled={disabled}
              {...fieldProps}
            />
          )}
        </NewCheckboxField>
      </Collapse>
    </>
  );
};

export default AllDataReportConfigurationForm;
