import clsx from "clsx";
import { Col, Container } from "components/Theme/grid";
import type React from "react";
import MoreDetailCaret from "../Display/MoreDetailCaret";

const columnStyles = {
  reverse: {
    xs: "xs:flex-row-reverse",
    sm: "sm:flex-row-reverse",
    md: "md:flex-row-reverse",
    lg: "lg:flex-row-reverse",
    xl: "xl:flex-row-reverse",
    none: "",
  },
  text: {
    sm: "text-sm",
    none: "",
  },
};

interface ColumnProps {
  content: React.ReactNode;
  reverse?: keyof typeof columnStyles.reverse;
  text?: keyof typeof columnStyles.text;
}

interface ObjectDetailHeadingProps {
  leftColumn: ColumnProps;
  rightColumn: ColumnProps;
  lgBreakpoint?: number;
  rightColumnSmallScreenHide?: boolean;
}

function Column(
  props: Required<ColumnProps> & {
    lgBreakpoint: number;
    smallScreenHide?: boolean;
  },
) {
  const {
    lgBreakpoint,
    content,
    reverse,
    text,
    smallScreenHide = false,
  } = props;

  const inner = () => {
    return (
      <div
        className={clsx(
          "flex flex-wrap",
          columnStyles.reverse[reverse],
          columnStyles.text[text],
        )}
      >
        {content}
      </div>
    );
  };

  return (
    <Col sm={12} lg={lgBreakpoint}>
      <div className={"block lg:hidden"}>
        {smallScreenHide ? (
          <MoreDetailCaret label={"More detail"}>{inner()}</MoreDetailCaret>
        ) : (
          inner()
        )}
      </div>
      <div className={"hidden lg:block"}>{inner()}</div>
    </Col>
  );
}

export default function ObjectDetailHeading(
  props: ObjectDetailHeadingProps,
): React.ReactElement {
  const { lgBreakpoint = 7, rightColumnSmallScreenHide = true } = props;

  return (
    <Container gap={8} gapBreakpoint={"lg"} className={"py-0.5 lg:py-2"}>
      <Column
        {...{ reverse: "none", text: "none", ...props.leftColumn }}
        lgBreakpoint={lgBreakpoint}
      />
      <Column
        {...{ reverse: "lg", text: "sm", ...props.rightColumn }}
        lgBreakpoint={12 - lgBreakpoint}
        smallScreenHide={rightColumnSmallScreenHide}
      />
    </Container>
  );
}
