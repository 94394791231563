import clsx from "clsx";
import type React from "react";

const hiddenBreakpoints = {
  sm: "sm:hidden",
  md: "md:hidden",
  lg: "lg:hidden",
};

const inlineBreakpoints = {
  sm: "sm:inline-block",
  md: "md:inline-block",
  lg: "lg:inline-block",
};

function FormatTextAtSize(props: {
  breakpoint: "sm" | "md" | "lg";
  smallClassName?: string;
  largeClassName?: string;
  smallBrackets?: boolean;
  children: React.ReactNode;
}) {
  const { breakpoint, children, smallBrackets = false } = props;
  return (
    <>
      <span
        className={clsx(
          hiddenBreakpoints[breakpoint],
          "inline-block",
          props.smallClassName,
        )}
      >
        {smallBrackets && "("}
        {children}
        {smallBrackets && ")"}
      </span>
      <span
        className={clsx(
          inlineBreakpoints[breakpoint],
          "hidden",
          props.largeClassName,
        )}
      >
        {children}
      </span>
    </>
  );
}

export default FormatTextAtSize;
