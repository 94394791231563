import { ChevronDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import PrivacyMode from "components/Text/PrivacyMode";
import { lowerCase } from "lodash-es";
import type { Company } from "openapi/model/company";
import type React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CompanyContext } from "reducers/company";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../components/Theme/dropdown";
import { getUrlHash } from "../../components/navigation/URL";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { isUserPartnerAdmin, isUserSuperAdmin } from "../../model/user";
import { usePrivacyMode } from "../../reducers/privacy";
import { useProfile } from "../../reducers/profile";

export default function CompanySelect(): React.ReactElement {
  const { state } = useContext(CompanyContext);

  const privacy = usePrivacyMode();
  const { companies = [], selected } = state;
  const user = useProfile();

  const navigate = useNavigate();

  const setCompany = (company?: Company): void => {
    if (state.selected?.companyId !== company?.companyId) {
      navigate({
        hash: getUrlHash(company, undefined, privacy),
      });
    }
  };

  return (
    <>
      {(companies.length > 1 ||
        isUserSuperAdmin(user) ||
        isUserPartnerAdmin(user)) && (
        <Dropdown>
          <DropdownButton outline data-cy={"company-select"}>
            <span className={"hidden md:inline-block"}>Company: </span>
            <span
              className={
                "text-grey w-auto md:w-32 text-left overflow-clip text-xs md:text-sm text-nowrap"
              }
              data-cy={"company-select-selected"}
            >
              {selected ? <PrivacyMode>{selected.name}</PrivacyMode> : "None"}
            </span>

            <ChevronDownIcon />
          </DropdownButton>
          <DropdownMenu data-cy={"company-select-menu"}>
            <DisplayIfUserType userTypes={"superadmin"}>
              <DropdownItem key={"NONE"} onClick={() => setCompany(undefined)}>
                NONE
              </DropdownItem>
            </DisplayIfUserType>
            {companies
              .sort((c1, c2) => {
                if (lowerCase(c1.name) > lowerCase(c2.name)) {
                  return 1;
                }
                if (lowerCase(c1.name) < lowerCase(c2.name)) {
                  return -1;
                }
                return 0;
              })
              .map((company): React.ReactElement => {
                return (
                  <DropdownItem
                    data-cy={"company-select-item"}
                    key={company.companyId}
                    onClick={(): void => {
                      setCompany(company);
                    }}
                    className={classNames({
                      "bg-brand-light text-gray-100":
                        company.companyId === selected?.companyId,
                    })}
                  >
                    <PrivacyMode>{company.name}</PrivacyMode>
                  </DropdownItem>
                );
              })}
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  );
}
