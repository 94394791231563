import type { Scheme } from "openapi/model/scheme";
import type React from "react";
import { useContext, useEffect } from "react";
import useGetList from "../../hooks/getList";
import { useSelectedCompany } from "../../reducers/company";
import { SchemeContext } from "../../reducers/scheme";
import SchemeSelectInit from "./SchemeSelectInit";

const InnerSchemeListInit = (props: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { state, dispatch } = useContext(SchemeContext);
  const { stale } = state;
  const selectedCompany = useSelectedCompany();

  if (!selectedCompany) {
    throw new Error("Company must have been selected.");
  }

  const [{ data, loading, error }, refresh] = useGetList<Scheme>("SCHEME", {
    companyId: selectedCompany.companyId,
  });

  useEffect(() => {
    if (data) {
      dispatch({ type: "LOADING_SCHEMES_SUCCESS", payload: data.data });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (loading && !stale) {
      dispatch({ type: "LOADING_SCHEMES_STARTED" });
    }
  }, [loading, dispatch, stale]);

  useEffect(() => {
    if (error) {
      dispatch({ type: "LOADING_SCHEMES_ERROR", payload: error });
    }
  }, [error, dispatch]);
  useEffect(() => {
    if (stale) {
      refresh();
    }
  }, [stale, refresh]);

  return (
    <>
      <SchemeSelectInit>{props.children}</SchemeSelectInit>
    </>
  );
};

export default function SchemeListInit(props: {
  children: React.ReactNode;
}): React.ReactElement {
  const selectedCompany = useSelectedCompany();
  const { dispatch } = useContext(SchemeContext);

  useEffect(() => {
    if (!selectedCompany) {
      dispatch({ type: "LOADING_SCHEMES_SUCCESS", payload: [] });
    }
  }, [selectedCompany, dispatch]);

  return (
    <>
      {selectedCompany ? (
        <InnerSchemeListInit>{props.children}</InnerSchemeListInit>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
}
