import type React from "react";
import useGetEntity from "../../../hooks/getEntity";
import type { AssetRegisterItem } from "../../../openapi/model/assetRegisterItem";

interface ChildProps {
  assetRegisterItem: AssetRegisterItem;
  refresh: () => void;
}

interface Props {
  assetRegisterItem: AssetRegisterItem;
  children: (props: ChildProps) => React.ReactElement;
}

function AssetRegisterItemContainer(props: Props) {
  const [{ data }, refresh] = useGetEntity<AssetRegisterItem>({
    entityType: "ASSET_REGISTER",
    entityId: props.assetRegisterItem.assetPosition.assetPositionId,
    companyId: props.assetRegisterItem.assetPosition.companyId,
    schemeId: props.assetRegisterItem.assetPosition.schemeId,
    entityKey: "assetPositionId",
    manual: true,
  });

  return props.children({
    assetRegisterItem: data || props.assetRegisterItem,
    refresh,
  });
}

export default AssetRegisterItemContainer;
