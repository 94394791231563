import { meterOptions } from "components/Asset/AssetTypeSelectCombo";
import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import type { FormikHelpers } from "formik/dist/types";
import { isEqual } from "lodash-es";
import { Period } from "openapi/model/period";
import type React from "react";
import * as Yup from "yup";
import type timeSeriesTemplates from "../../../constants/timeSeriesTemplates.json";
import { ReportView } from "../../../openapi/model/reportView";
import DateRangePicker from "../../Forms/DateRangePicker/DateRangePicker";
import NewButton from "../../Forms/NewButton/NewButton";
import { Col } from "../../Theme/grid";
import type { AssetType, NewTimeSeriesRequestParams } from "../model";
import AggregatedTimeSeriesQuerySubForm, {
  type AggregatedTimeSeriesQuerySubFormProps,
  AggregatedTimeSeriesQuerySubFormSchema,
  type AggregatedTimeSeriesQuerySubFormValues,
} from "./AggregatedTimeSeriesQuerySubForm";

export interface AggregatedTimeSeriesQueryFormValues {
  query: AggregatedTimeSeriesQuerySubFormValues;
  startDate: Dayjs;
  endDate: Dayjs;
}

export function mapFormValuesToTimeSeriesParams(
  values: AggregatedTimeSeriesQueryFormValues,
  includeGroupBy = false,
  includeViewBy = true,
  includeAssetUseCase = false,
): NewTimeSeriesRequestParams {
  const _q: NewTimeSeriesRequestParams = {
    ...values.query,
    parameter: values.query.parameter as string,
    aggregation: values.query.aggregation as string,
    assetType: values.query
      .assetType as unknown as (keyof typeof timeSeriesTemplates)[],
    startDate: values.startDate,
    endDate: values.endDate,
  };
  if (!includeGroupBy) {
    _q.groupBy = undefined;
  }
  if (!includeViewBy) {
    _q.viewBy = undefined;
  }
  if (!includeAssetUseCase) {
    _q.useCase = undefined;
  }
  return _q;
}

export function mapTimeSeriesRequestParamsToFormValues(
  params: NewTimeSeriesRequestParams,
): AggregatedTimeSeriesQueryFormValues {
  const { startDate, endDate, ...query } = params;
  return {
    startDate,
    endDate,
    query: { ...query, viewBy: query.viewBy || ReportView.ASSETPOSITION },
  };
}

export interface AggregatedTimeSeriesQueryFormProps
  extends Omit<AggregatedTimeSeriesQuerySubFormProps, "namespace"> {
  onSubmit: (values: AggregatedTimeSeriesQueryFormValues) => Promise<any>;
  initialValues?: AggregatedTimeSeriesQueryFormValues;
}

export default function AggregatedTimeSeriesQueryForm(
  props: AggregatedTimeSeriesQueryFormProps,
) {
  const {
    initialValues = {
      query: {
        period: Period.DAILY,
        assetType: meterOptions as AssetType[],
        aggregation: "last",
        parameter: "Energy (Heating)",
        viewBy: ReportView.ASSETPOSITION,
      },
    } as AggregatedTimeSeriesQueryFormValues,
    onSubmit,
    ...rest
  } = props;
  const { wide } = rest;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        query: AggregatedTimeSeriesQuerySubFormSchema,
      })}
      onSubmit={(
        values: AggregatedTimeSeriesQueryFormValues,
        helpers: FormikHelpers<AggregatedTimeSeriesQueryFormValues>,
      ): Promise<any> => {
        helpers.setSubmitting(true);
        return onSubmit(values).finally(() => helpers.setSubmitting(false));
      }}
    >
      {({ values, submitForm }): React.ReactElement => {
        const isChanged = !isEqual(values, initialValues);

        return (
          <Form className={"mb-2"}>
            <AggregatedTimeSeriesQuerySubForm
              namespace={"query"}
              submitButton={
                <>
                  <Col sm={12} md={wide ? 9 : 12}>
                    <DateRangePicker
                      startDateName={"startDate"}
                      endDateName={"endDate"}
                      label={{ label: "Date Range", size: "sm" }}
                      maxDate={dayjs().startOf("day")}
                    />
                  </Col>
                  <Col sm={12} md={wide ? 3 : 12} className={"mt-auto"}>
                    <NewButton
                      onClick={submitForm}
                      disabled={!isChanged}
                      className={"w-full h-[38px]"}
                    >
                      Search
                    </NewButton>
                  </Col>
                </>
              }
              {...rest}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
