import { OneCardPage } from "components/utils/OneCardPage";
import dayjs from "dayjs";
import type { AssetType } from "openapi/model/assetType";
import type React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { inclusiveDatePeriod } from "../../../components/Forms/DateTimePicker";
import RequireScheme from "../../../components/Scheme/RequireScheme";
import { matchPeriod } from "../../../components/TimeSeries/model";
import {
  createSearchString,
  parseSearchString,
} from "../../../components/navigation/SearchString";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";
import type { AggregatedReportRequest } from "../../../openapi/model/aggregatedReportRequest";
import type { AggregationDefinition } from "../../../openapi/model/aggregationDefinition";
import type { CumulativeDataConfiguration } from "../../../openapi/model/cumulativeDataConfiguration";
import { useRequiredSelectedCompanyId } from "../../../reducers/company";
import { useSelectedSchemeId } from "../../../reducers/scheme";
import { BillingConfigurationInitialValues } from "../../jobs/FormatConfigurationForms/BillingConfigurationForm";
import AggregatedTimeSeriesApiWrapper from "../aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper";
import BillingReportSelectorForm, {
  type BillingReportRequestParams,
} from "./BillingReportSelectorForm";
import BillingReportView from "./BillingReportView";

const BillingReportPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const companyId = useRequiredSelectedCompanyId();
  const schemeId = useSelectedSchemeId();

  const searchStringToParams = (): Required<BillingReportRequestParams> => {
    const [startDate, endDate] = inclusiveDatePeriod(undefined, undefined, 7);
    const searchParams = parseSearchString(location.search);
    const startTime = searchParams.startTime
      ? dayjs(searchParams.startTime as string)
      : startDate;
    const endTime = searchParams.startTime
      ? dayjs(searchParams.endTime as string)
      : dayjs(+endDate);
    const period = matchPeriod(searchParams.period as string);
    console.log(searchParams);
    return {
      startTime,
      endTime,
      period,
      configuration: {
        ...BillingConfigurationInitialValues(false).configuration,
        ...(searchParams?.configuration as unknown as CumulativeDataConfiguration),
      },
    };
  };

  const selectData = (values: BillingReportRequestParams): void => {
    if (values.endTime) {
      const [startTime, endTime] = [
        values.startTime.format(),
        values.endTime.format(),
      ];
      navigate({
        search: createSearchString({
          startTime,
          endTime,
          period: values.period,
          configuration: values.configuration,
        }),
        hash: location.hash,
      });
    }
  };

  const { startTime, endTime, period, configuration } = searchStringToParams();
  const request: AggregatedReportRequest | undefined =
    companyId && schemeId
      ? {
          companyId: companyId,
          schemeId: schemeId,
          assetTypes: configuration.includedAssetTypes?.map((x) =>
            (x as string).toLowerCase(),
          ) as Array<unknown> as Array<AssetType>,
          aggregations: [
            "Energy (Heating)",
            "Energy (Cooling)",
            "Energy (Electrical Active Import)",
          ]
            .map((v): AggregationDefinition => {
              return {
                parameter: v,
                aggregation: "last",
                ...configuration.energy,
              };
            })
            .concat([
              {
                parameter: "Volume",
                aggregation: "last",
                ...configuration.volume,
              },
            ]),
          period,
          startTime: startTime.toDate(),
          endTime: endTime.toDate(),
          dataLabel: configuration.periodLabel,
        }
      : undefined;
  console.log(request);
  const queryParams = searchStringToParams();
  return (
    <OneCardPage
      headerTitle={"Billing Data Report"}
      breadcrumbs={[
        { url: "/admin/report/billing/data", name: "Billing Data" },
      ]}
      setPageTitle={schemeObjectPageTitle("Billing Data")}
    >
      <RequireScheme>
        <BillingReportSelectorForm {...queryParams} action={selectData} />
        {request && (
          <AggregatedTimeSeriesApiWrapper request={request}>
            {({ data }) => (
              <>
                {data && schemeId && (
                  <BillingReportView
                    schemeId={schemeId}
                    data={data}
                    query={queryParams}
                  />
                )}
              </>
            )}
          </AggregatedTimeSeriesApiWrapper>
        )}
      </RequireScheme>
    </OneCardPage>
  );
};

export default BillingReportPage;
