import { isArray, isNil, isString } from "lodash-es";
import { AssetUseCase } from "openapi/model/assetUseCase";
import type { ParsedQs } from "qs";
import type React from "react";
import { convertStringToOpenApiEnum } from "../../utils/enum";
import {
  type OptionsList,
  SelectComboField,
  type SelectComboFieldProps,
} from "../Forms/SelectCombo/SelectCombo";

type NewAssetUseCaseSelectProps = Omit<
  SelectComboFieldProps,
  "options" | "placeholder"
>;

export function matchStringsToAssetUseCaseEnum(
  lst: string | string[] | ParsedQs | ParsedQs[] | undefined,
): AssetUseCase[] | undefined {
  if (isNil(lst)) {
    return undefined;
  }
  if (isArray(lst)) {
    return lst
      .map((v) => {
        if (isString(v)) {
          return convertStringToOpenApiEnum<AssetUseCase>(
            v,
            AssetUseCase,
            true,
          );
        } else {
          return undefined;
        }
      })
      .filter((v) => !isNil(v)) as AssetUseCase[];
  }
  const val = isString(lst)
    ? convertStringToOpenApiEnum<AssetUseCase>(lst, AssetUseCase, true)
    : undefined;
  if (val) {
    return [val as AssetUseCase];
  }
}
export default function AssetUseCaseSelect(
  props: NewAssetUseCaseSelectProps,
): React.ReactElement {
  const options: OptionsList = [
    { label: "None", value: AssetUseCase.NONE },
    { label: "Domestic", value: AssetUseCase.DOMESTIC },
    { label: "Commercial", value: AssetUseCase.COMMERCIAL },
    { label: "Process", value: AssetUseCase.PROCESS },
  ].filter((v) => !isNil(v)) as OptionsList;

  return <SelectComboField options={options} {...props} placeholder={"All"} />;
}
