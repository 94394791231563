import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type React from "react";

export default function ErrorTriangle(props: {
  error?: Error | Error[] | null;
}): React.ReactElement {
  return (
    <>
      {props.error && (
        <FontAwesomeIcon icon={faTriangleExclamation} fixedWidth />
      )}
    </>
  );
}
