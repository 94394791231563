import { unflatten } from "flat";
import type { ParseResult } from "papaparse";
import type React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCreateEntity from "../../hooks/createEntity";
import type { BulkUpdateDetailed } from "../../openapi/model/bulkUpdateDetailed";
import {
  objectBlankStringsToUndefined,
  removeMapBlankObjects,
} from "../../utils/object";
import BulkUpdateCSVDropZone from "./BulkUpdateCSVDropZone";

interface Props {
  companyId: string;
  schemeId?: string;
  objectType: string;
}

export function unFlattenData(data: any[]): any[] {
  return data.map((d) => {
    return unflatten(d, { object: false });
  });
}

export const identifyUniqueArrayValues = (
  arrayOne: any[],
  arrayTwo: any[],
): any[] => {
  return arrayOne.reduce((accumulator, value) => {
    if (!arrayTwo.includes(value)) {
      return [...accumulator, value];
    }
    return accumulator;
  }, []);
};

export default function BulkUpdate(props: Props): React.ReactElement {
  const { createEntity } = useCreateEntity<BulkUpdateDetailed, any>("UPDATE");

  const navigate = useNavigate();
  const location = useLocation();

  const navigateToUpdate = (update: BulkUpdateDetailed): void => {
    navigate({
      pathname: `/admin/${props.objectType}/updates/${update.updateId}`,
      hash: location.hash,
    });
  };

  const parseComplete = async (
    inputData: Promise<ParseResult<any>>,
    filename: string,
  ): Promise<any> => {
    const data = await inputData;
    const objects = unFlattenData(data.data).map((v) =>
      removeMapBlankObjects(objectBlankStringsToUndefined(v)),
    );
    const [update] = await createEntity({
      objects: objects,
      companyId: props.companyId,
      schemeId: props.schemeId,
      objectType: props.objectType,
      filename: filename,
    });
    navigateToUpdate(update);
  };

  return <BulkUpdateCSVDropZone parseComplete={parseComplete} />;
}
