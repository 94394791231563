/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import type React from "react";
import { useEffect } from "react";
import AuthFooter from "../components/Footers/AuthFooter";
// react library for routing
// core components
import AuthNavbar from "../components/Navbars/AuthNavbar";
import AuthRoutes from "../routes/authRoutes";

const Auth = (): React.ReactElement => {
  useEffect(() => {
    document.body.classList.add("bg-brand-dark");
    return () => {
      document.body.classList.remove("bg-brand-dark");
    };
  }, []);

  return (
    <>
      <div className={"lg:max-w-md mx-auto"}>
        <AuthNavbar />
        <AuthRoutes />
        <AuthFooter />
      </div>
    </>
  );
};

export default Auth;
