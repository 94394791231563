import type React from "react";
import { useParams } from "react-router-dom";
import GetEntity from "../../core/action/GetEntity";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import { useRequiredSelectedCompanyId } from "../../reducers/company";
import { useSelectedSchemeId } from "../../reducers/scheme";
import { ErrorBanner } from "../Error/ErrorBanner";
import { OneCardPage } from "../utils/OneCardPage";
import GenericUpdatesDisplay, {
  type GenericUpdateProps,
} from "./GenericUpdatesDisplay";

export default function GenericUpdatesPage(
  props: GenericUpdateProps,
): React.ReactElement {
  const companyId = useRequiredSelectedCompanyId();
  const schemeId = useSelectedSchemeId();
  const { updateId } = useParams();
  if (!updateId) {
    throw new Error("SelectedUpdateId is required");
  }

  return (
    <GetEntity
      entityType={"UPDATE"}
      entityId={updateId}
      companyId={companyId}
      schemeId={schemeId}
      singletonApi={true}
      error={({ error }) => (
        <OneCardPage
          headerTitle={"Update"}
          breadcrumbs={[{ name: "Error" }]}
          setPageTitle={schemeObjectPageTitle("Bulk Updates Error")}
        >
          <ErrorBanner error={error} />
        </OneCardPage>
      )}
      placeholder={() => (
        <OneCardPage
          headerTitle={"Update"}
          loading={true}
          breadcrumbs={[{ name: "Loading" }]}
          setPageTitle={schemeObjectPageTitle("Bulk Updates Loading")}
        />
      )}
    >
      {(display) => <GenericUpdatesDisplay {...display} {...props} />}
    </GetEntity>
  );
}
