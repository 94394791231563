import type React from "react";
import type { TableColumn } from "react-data-table-component";
import type { AssetRegisterDataItem } from "../../../components/Table/AssetRegisterData";
import numberFormatter from "../../../components/Table/Formatters";
import sortNumbers from "../../../components/Table/Sort";

import { isNumber } from "lodash-es";
import type { AssetRegisterAggregatedTimeSeriesResponse } from "../../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import AssetRegisterReportTable, {
  type AssetRegisterReportTableProps,
} from "./AssetRegisterReportTable";

const mapAssetRegisterDataToFirstValues = (
  data: AssetRegisterAggregatedTimeSeriesResponse,
  columns: Array<DataColumnDefinition>,
): Array<AssetRegisterDataItem> => {
  return data.data.map((value) => {
    const { aggregations, ...asset } = value;

    return {
      ...asset,
      ...Object.fromEntries(
        columns.map(({ aggregation, parameter, factor = 1 }) => {
          return [
            `${parameter}_${aggregation}`,
            selectFirstValue(aggregations[parameter][aggregation], factor),
          ];
        }),
      ),
    };
  });
};

const selectFirstValue = (value: any[], factor = 1): number | undefined => {
  if (value && value.length > 0 && isNumber(value[0])) {
    return value[0] * factor;
  }
};

export interface DataColumnDefinition {
  name: string;
  parameter: string;
  aggregation: string;
  factor?: number;
}

interface AllPeriodReportTableProps
  extends Omit<AssetRegisterReportTableProps, "data" | "columnDefinitions"> {
  data?: AssetRegisterAggregatedTimeSeriesResponse;
  columnDefinitions: Array<DataColumnDefinition>;
  decimalPlaces?: number;
}

const AllPeriodReportTable = (
  props: AllPeriodReportTableProps,
): React.ReactElement => {
  const { columnDefinitions, decimalPlaces = 2, data, ...rest } = props;

  const columns = (): TableColumn<AssetRegisterDataItem>[] =>
    columnDefinitions.map(({ aggregation, parameter, name }) => {
      const key = `${parameter}_${aggregation}`;
      return {
        name: (
          <span
            style={{
              textAlign: "right",
              whiteSpace: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {" "}
            {name}
          </span>
        ),
        selector: (row: AssetRegisterDataItem): string => {
          return row[key] as string;
        },
        sortable: true,
        right: true,
        minWidth: "150px",
        sortFunction: sortNumbers(key),
        format: numberFormatter(key, decimalPlaces),
      };
    });

  return (
    <>
      {data && (
        <AssetRegisterReportTable
          columnDefinitions={columns()}
          data={mapAssetRegisterDataToFirstValues(data, columnDefinitions)}
          {...rest}
        />
      )}
    </>
  );
};

export default AllPeriodReportTable;
