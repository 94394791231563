import type { Dayjs } from "dayjs";
import type React from "react";
import { useState } from "react";
import ButtonGroup, {
  type ButtonGroupProps,
} from "../../../../components/Forms/ButtonGroup";
import Card from "../../../../components/Theme/card";
import { Col } from "../../../../components/Theme/grid";
import SimpleLineChart, {
  type DataPoint,
} from "../../../../components/charts/SimpleLineChart";
import SingleBarChart from "../../../../components/charts/SingleBarChart";
import type {
  ColumnDefinitionWithData,
  TimeSeriesDataParams,
} from "../../../../model/deviceTimeSeries";

function CumulativeDataButtonGroup(props: Omit<ButtonGroupProps, "options">) {
  return (
    <ButtonGroup
      {...props}
      options={[
        { label: <>&Sigma;</>, value: true },
        { label: <>&Delta;</>, value: false },
      ]}
    />
  );
}

const ChartForSingleTimeSeriesItem = (props: {
  column: ColumnDefinitionWithData;
  times: Dayjs[];
  params: TimeSeriesDataParams;
  loading: boolean;
}): React.ReactElement => {
  const [line, _switchLine] = useState(true);

  const column = props.column;
  const data = column.data.map(
    (tsValue: string | undefined, tsIndex: number) => {
      return [
        props.times[tsIndex],
        tsValue !== undefined
          ? Number.parseFloat(tsValue as string)
          : undefined,
      ] as DataPoint;
    },
  );

  return (
    <Col sm={12} xl={6}>
      <Card
        title={"Overview"}
        subtitle={column.name}
        iconBlock={
          <>
            {" "}
            {column.type === "cumulative" && (
              <CumulativeDataButtonGroup
                onChange={(v) => _switchLine(v as boolean)}
                label={{ size: "sm", margin: 0 }}
                size={"sm"}
                value={line}
              />
            )}
          </>
        }
      >
        <div className="chart">
          {line ? (
            <SimpleLineChart
              data={data}
              name={column.name}
              unit={column.unit}
              params={props.params}
              loading={props.loading}
            />
          ) : (
            <SingleBarChart
              data={data}
              definition={column}
              params={props.params}
              loading={props.loading}
            />
          )}
        </div>
      </Card>
    </Col>
  );
};

export default ChartForSingleTimeSeriesItem;
