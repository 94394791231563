import type React from "react";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import NotesList from "./NotesList";

export default function NotesListPage(): React.ReactElement {
  return (
    <OneCardPage
      headerTitle={"Notes List"}
      breadcrumbs={[{ url: "/admin/notes", name: "Notes" }]}
      setPageTitle={schemeObjectPageTitle("Notes")}
    >
      <NotesList />
    </OneCardPage>
  );
}
