import type React from "react";
import AlertBanner from "../Banner/AlertBanner";

interface Props {
  title?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export default function BetaAlert(props: Props): React.ReactElement {
  return (
    <AlertBanner margin={2}>
      <>
        <strong className="font-semibold block">
          Preview Feature{props.title && <>: {props.title}</>}
        </strong>
        <p>
          {" "}
          This feature is currently in preview. We might remove it or change it
          at any time.
        </p>
        <p>
          We&apos;d love your feedback on it, please let us know if it helped
          you or if we could make a change to make it better
        </p>
        {props.children}
      </>
    </AlertBanner>
  );
}
