import { camelCase, isString, toInteger } from "lodash-es";
import type React from "react";
import type { CSSProperties } from "react";
import type { TableColumn } from "react-data-table-component";
import {
  type AssetRegisterDataItem,
  DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
} from "../../../components/Table/AssetRegisterData";
import { percentageFormatter } from "../../../components/Table/Formatters";
import sortNumbers from "../../../components/Table/Sort";
import { toSnakeCaseWithParenthesis } from "../../../components/utils/TextUtils";
import { doesAssetHaveDataField } from "../../../model/assets/deviceData";
import AssetRegisterReportTable, {
  type AssetRegisterReportTableProps,
} from "../allPeriodReportTable/AssetRegisterReportTable";
import { type AssetDataQualityDataItem, QUALITY_AGGREGATIONS } from "./model";

type DataQualityCellFunction = (
  row: AssetRegisterDataItem,
  _index: number,
  column: TableColumn<AssetDataQualityDataItem>,
  _id: string | number,
) => React.ReactNode;

export const DataQualityCell = (total = false): DataQualityCellFunction => {
  const InnerDataQualityCellFunction = (
    row: AssetRegisterDataItem,
    _index: number,
    column: TableColumn<AssetDataQualityDataItem>,
    _id: string | number,
  ): React.ReactNode => {
    const id = isString(column.name) ? camelCase(column.name) : undefined;
    if (
      id === "total" ||
      (total && id && id in row && doesAssetHaveDataField(row.assetType, id))
    ) {
      const value = toInteger(row.receivedRowsComplete);
      const expected = toInteger(row.expectedRows);

      return (
        <>
          {value} /{" "}
          <small>
            {expected} ({percentageFormatter(value / expected)})
          </small>
        </>
      );
    } else if (id && id in row) {
      const value = toInteger(row[id]);
      return <>{value}</>;
    } else {
      return <></>;
    }
  };
  return InnerDataQualityCellFunction;
};

interface DataQualityReportTableProps
  extends Omit<AssetRegisterReportTableProps, "data" | "columnDefinitions"> {
  data: Array<AssetDataQualityDataItem>;
  decimalPlaces?: number;
  expanded?: boolean;
  withTotal?: boolean;
}

export default function DataQualityReportTable(
  props: DataQualityReportTableProps,
): React.ReactElement {
  const {
    data,
    startDatetime,
    endDatetime,
    expanded = true,
    withTotal = true,
  } = props;

  const noWrapStyle: CSSProperties = {
    whiteSpace: "nowrap",
  };

  const columns = (): TableColumn<AssetRegisterDataItem>[] => {
    return [
      ...["Total", ...QUALITY_AGGREGATIONS, "missing_rows"].map(
        (value: string) => {
          const name = camelCase(value);

          return {
            name: toSnakeCaseWithParenthesis(value),
            selector: (row: AssetRegisterDataItem) =>
              (row[name] as string | number) || "-",
            sortable: true,
            right: true,
            sortFunction: sortNumbers(
              name === "total" ? "receivedRowsComplete" : name,
              true,
              name === "total" ? "expectedRows" : undefined,
            ),
            cell: DataQualityCell(withTotal),
            grow: 2,
            wrap: false,
            style: noWrapStyle,
            omit: !expanded && !(value === "Total"),
          };
        },
      ),
    ];
  };

  return (
    <>
      {data && (
        <AssetRegisterReportTable
          {...{ data, startDatetime, endDatetime }}
          columnDefinitions={columns()}
          csvColumnOrder={[
            ...DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
            "gatewayAssetId",
            "gatewaySerialNumber",
            "gatewayAssetPositionId",
            "gatewayAssetPositionReference",
            "gatewayLastContactTime",
            "gatewayLocationAddress",
            "gatewayLocationId",
            "Collection Frequency",
            "total",
            ...QUALITY_AGGREGATIONS.map((value) => camelCase(value)),
          ]}
        />
      )}
    </>
  );
}
