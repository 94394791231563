import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type React from "react";
import type { RequireExactlyOne } from "type-fest";
import useCreateEntity, {
  type CreateEntityProps,
} from "../../hooks/createEntity";
import ErrorModal from "../Error/ErrorModal";
import { Button, type styles } from "../Theme/button";

type CreateButtonProps<T> = RequireExactlyOne<
  Omit<React.ComponentProps<typeof Button>, "onClick"> & {
    errorMessageBody?: React.ReactElement;
    entity: T;
    color?: keyof typeof styles.colors;
    showErrorModal?: boolean;
    icon?: IconProp;
    text?: string;
  },
  "children" | "text"
>;

const CreateButton = <T,>(
  props: CreateEntityProps & CreateButtonProps<T>,
): React.ReactElement => {
  const { entityType, path, parseToJson = true, put = false } = props;
  const { creating, createEntity, error } = useCreateEntity<T>(
    entityType,
    path,
    put,
    parseToJson,
  );

  const { text = "", icon, color, showErrorModal = true } = props;

  const click = () => {
    createEntity(props.entity).then(([data, headers]) => {
      props.postCreate?.(data, headers);
    });
  };

  return (
    <>
      {showErrorModal && (
        <ErrorModal error={error} body={props.errorMessageBody} />
      )}
      <Button color={color} onClick={click} disabled={creating}>
        {icon && (
          <FontAwesomeIcon
            icon={creating ? faSpinner : icon}
            fixedWidth={true}
            className={"mr-1"}
            spin={creating}
          />
        )}
        {text}
      </Button>
    </>
  );
};

export default CreateButton;
