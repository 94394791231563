import { range } from "lodash-es";
import React, { Fragment } from "react";
import NewTextFormComponent from "../../components/Forms/NewTextFormComponent";
import { Button } from "../../components/Theme/button";
import { TableCell, TableRow } from "../../components/Theme/table";
import type { TariffServiceDescription } from "./model";

export default function NewTariffInstanceRatesForm(props: {
  services: TariffServiceDescription[];
}) {
  return (
    <TableRow>
      <TableCell>
        <NewTextFormComponent fieldName={"startDate"} />
      </TableCell>
      {range(props.services.length).map((v) => (
        <Fragment key={v}>
          <TableCell>
            <NewTextFormComponent
              fieldName={`rates.${v}.unitConsumptionRate`}
            />
          </TableCell>
          <TableCell>
            <NewTextFormComponent
              fieldName={`rates.${v}.unitConsumptionVatRate`}
            />
          </TableCell>
          <TableCell>
            <NewTextFormComponent fieldName={`rates.${v}.standingChargeRate`} />
          </TableCell>
          <TableCell>
            <NewTextFormComponent
              fieldName={`rates.${v}.standingChargeVatRate`}
            />
          </TableCell>
        </Fragment>
      ))}
      <TableCell>
        <Button type={"submit"} color={"brandLight"} className={"btn-sm"}>
          Add Instance
        </Button>
      </TableCell>
    </TableRow>
  );
}
