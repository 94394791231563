import type React from "react";
import type { Dispatch, SetStateAction } from "react";

import { Bars3Icon } from "@heroicons/react/24/outline";
import SchemeSelect from "components/Scheme/SchemeSelect";
import CompanySelect from "../../containers/company/CompanySelect";
import ProfileDropdown from "./ProfileDropdown";

interface NavbarProps {
  sidebarState: [boolean, Dispatch<SetStateAction<boolean>>];
}

export default function Navbar(props: NavbarProps): React.ReactNode {
  const [_, setSidebarOpen] = props.sidebarState;

  return (
    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-1 md:gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button
        type="button"
        onClick={() => setSidebarOpen(true)}
        className="-m-2.5 p-2.5 text-gray-700 xl:hidden"
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
      </button>

      {/* Separator */}
      <div aria-hidden="true" className="h-6 w-px bg-gray-900/10 xl:hidden" />

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 justify-end">
        {/*<form*/}
        {/*  action="#"*/}
        {/*  method="GET"*/}
        {/*  className="relative md:flex flex-1 hidden "*/}
        {/*>*/}
        {/*  <label htmlFor="search-field" className="sr-only">*/}
        {/*    Search*/}
        {/*  </label>*/}
        {/*  <MagnifyingGlassIcon*/}
        {/*    aria-hidden="true"*/}
        {/*    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"*/}
        {/*  />*/}
        {/*  <input*/}
        {/*    id="search-field"*/}
        {/*    name="search"*/}
        {/*    type="search"*/}
        {/*    placeholder="Search..."*/}
        {/*    className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"*/}
        {/*  />*/}
        {/*</form>*/}
        <div className="flex items-center gap-x-1 md:gap-x-3 lg:gap-x-4">
          <CompanySelect />
          <SchemeSelect />

          {/* Separator */}
          <div
            aria-hidden="true"
            className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
          />

          {/* Profile dropdown */}
          <ProfileDropdown />
        </div>
      </div>
    </div>
  );
}
