import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import type { Path } from "react-router-dom";
import PrivacyMode from "../Text/PrivacyMode";
import CompanyNavLink from "../navigation/CompanyNavLink";
import DisplayIfUserType from "../utils/DisplayIfUserType/DisplayIfUserType";

interface Props {
  title: string;
  children: React.ReactNode | string;
  to?: Partial<Path>;
  privacyMode?: boolean;
  type?: "heading" | "detail";
  editAction?: () => void;
  editActionUserType?: "viewer" | "editor" | "admin" | "superadmin";
}

export default function ObjectPropertyItem(props: Props): React.ReactElement {
  const {
    title,
    children,
    type = "detail",
    editAction,
    editActionUserType = "admin",
  } = props;

  const detail = (
    <>
      {React.isValidElement(children) ? (
        React.cloneElement(children)
      ) : props.to ? (
        <CompanyNavLink to={props.to}>
          <PrivacyMode usePrivacyMode={props.privacyMode}>
            {children as string}
          </PrivacyMode>
        </CompanyNavLink>
      ) : (
        <PrivacyMode usePrivacyMode={props.privacyMode}>
          {children as string}
        </PrivacyMode>
      )}
    </>
  );

  return (
    <div
      className={
        type === "detail" ? "ml-2 mr-md-0 lg:mr-2 my-0.5" : "mr-4 my-1"
      }
      data-cy={`heading-${title}`}
    >
      <span className={"uppercase mb-0 text-gray-500 text-xs block"}>
        {title}
        {editAction && (
          <DisplayIfUserType userTypes={editActionUserType}>
            <FontAwesomeIcon
              icon={faPen}
              data-cy={"edit"}
              className={"ml-1"}
              size={"xs"}
              onClick={() => editAction()}
            />
          </DisplayIfUserType>
        )}
      </span>

      {type === "detail" ? (
        <span className={"block text-sm"}>{detail}</span>
      ) : (
        <span className={"block text-xl"}>{detail}</span>
      )}
    </div>
  );
}
