import dayjs from "dayjs";
import type React from "react";
import { useParams } from "react-router-dom";
import BetaAlert from "../../../components/Beta/BetaAlert";
import RequireScheme from "../../../components/Scheme/RequireScheme";
import RoutedTabGroupInterface from "../../../components/TabGroup/RoutedTabGroupInterface";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";
import { AssetUseCase } from "../../../openapi/model/assetUseCase";
import { useRequiredSelectedCompanyId } from "../../../reducers/company";
import { useSelectedSchemeId } from "../../../reducers/scheme";
import EnergyBalanceReportPage from "../EnergyBalanceReportPage";
import { VWADTReportContainer } from "../vwadtReport/VWADTReportContainer";
import VWARTReportContainer from "../vwartReport/VWARTReportContainer";
import HeatNetworkQueryUrlWrapper from "./HeatNetworkQueryUrlWrapper";
import HeatNetworkReportQueryForm from "./HeatNetworkReportQueryForm";
import RunTimeReportPage from "./RunTimeReportPage";
import SyntheticEnergyReportPage from "./SyntheticEnergyReportPage";
import SyntheticVolumeReportPage from "./SyntheticVolumeReportPage";
import type { HeatNetworkReportParams } from "./model";

const HeatNetworkAnalysisReportPage = (): React.ReactElement => {
  const params = useParams();
  const companyId = useRequiredSelectedCompanyId();
  const scheme = useSelectedSchemeId();

  const props = { companyId, schemeId: scheme };

  const tab: string = params.tab ? params.tab : "vwart";

  return (
    <OneCardPage
      headerTitle={"Heat Network Analysis"}
      breadcrumbs={[
        { url: "/admin/report/heat_network", name: "Heat Network Analysis" },
      ]}
      setPageTitle={schemeObjectPageTitle("Heat Network Analysis")}
    >
      <RequireScheme>
        <BetaAlert />
        <HeatNetworkQueryUrlWrapper
          defaultValues={{
            startDate: dayjs().startOf("month").subtract(12, "months"),
            endDate: dayjs().startOf("month").subtract(1, "seconds"),
            useCases: [AssetUseCase.DOMESTIC, AssetUseCase.NONE],
          }}
        >
          {(
            params: HeatNetworkReportParams,
            onChange: (v: HeatNetworkReportParams) => void,
          ) => {
            return (
              <>
                <HeatNetworkReportQueryForm
                  onSubmit={(v) => {
                    onChange(v);
                    return Promise.resolve();
                  }}
                  initialValues={params}
                  dateOnly={tab === "energy_balance"}
                />
                <RoutedTabGroupInterface
                  tabs={[
                    {
                      tabName: "VWART",
                      tabPath: "vwart",
                      tabContent: (
                        <VWARTReportContainer query={params} {...props} />
                      ),
                      keepSearchString: true,
                    },
                    {
                      tabName: "VWADT",
                      tabPath: "vwadt",
                      tabContent: (
                        <VWADTReportContainer query={params} {...props} />
                      ),
                      keepSearchString: true,
                    },
                    {
                      tabName: "Energy Balance",
                      tabPath: "energy_balance",
                      tabContent: (
                        <EnergyBalanceReportPage query={params} {...props} />
                      ),
                      keepSearchString: true,
                    },
                    {
                      tabName: "Volume",
                      tabPath: "volume",
                      tabContent: (
                        <SyntheticVolumeReportPage query={params} {...props} />
                      ),
                      keepSearchString: true,
                    },
                    {
                      tabName: "Energy",
                      tabPath: "energy",
                      tabContent: (
                        <SyntheticEnergyReportPage query={params} {...props} />
                      ),
                      keepSearchString: true,
                    },
                    {
                      tabName: "Runtime",
                      tabPath: "runtime",
                      tabContent: (
                        <RunTimeReportPage query={params} {...props} />
                      ),
                      keepSearchString: true,
                    },
                  ]}
                  topLevelPath={"/admin/report/heat_network"}
                />
              </>
            );
          }}
        </HeatNetworkQueryUrlWrapper>
      </RequireScheme>
    </OneCardPage>
  );
};

export default HeatNetworkAnalysisReportPage;
