import type React from "react";
import SelectedScheme from "../../components/Scheme/SelectedScheme";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import EventList from "./EventList";

const EventListPage = (): React.ReactElement => {
  return (
    <OneCardPage
      headerTitle={"Event List"}
      breadcrumbs={[
        { url: "/admin/event", name: "Events" },
        { url: "/admin/event", name: "List" },
      ]}
      setPageTitle={schemeObjectPageTitle("Events")}
    >
      <SelectedScheme>{(props) => <EventList {...props} />}</SelectedScheme>
    </OneCardPage>
  );
};

export default EventListPage;
