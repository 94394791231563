import dayjs, { type Dayjs } from "dayjs";

export const inclusiveDatePeriod = (
  startDate?: Dayjs,
  endDate?: Dayjs,
  numberOfDays = 14,
  defaultEndIsToday = false,
): [Dayjs, Dayjs] => {
  const now = dayjs();

  const actualEndDate = endDate
    ? endOfDay(endDate)
    : defaultEndIsToday
      ? endOfDay(now)
      : endOfDay(now).subtract(1, "day");

  const actualStartDate = startDate
    ? startDate.startOf("day")
    : dayjs(
        +actualEndDate +
          +dayjs.duration(1, "millisecond") +
          +dayjs.duration(-numberOfDays, "days"),
      );
  return [actualStartDate, actualEndDate];
};

export const endOfDay = (date: Dayjs): Dayjs => {
  return dayjs(+date.endOf("day"));
};

export default inclusiveDatePeriod;
