import React from "react";
import PrivacyMode from "../../components/Text/PrivacyMode";
import type { TariffOut } from "../../openapi/model/tariffOut";
import TariffInstanceList from "./TariffInstanceList";

function TariffHomepage(props: { tariff: TariffOut }) {
  const { tariff } = props;

  return (
    <>
      <div className={"d-flex flex-wrap"}>
        {/* TODO: switch to object heading */}
        <div className={"mr-4"}>
          <h6 style={{ textTransform: "uppercase", marginBottom: 0 }}>
            Tariff Name
          </h6>
          <h2>
            <PrivacyMode>{tariff.tariffName}</PrivacyMode>
          </h2>
        </div>
      </div>
      <div style={{ display: "flex", flexWrap: "nowrap", overflowX: "auto" }}>
        <div style={{ width: "100vw" }}>
          <TariffInstanceList tariff={tariff} />
        </div>
      </div>
    </>
  );
}

export default TariffHomepage;
