import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type React from "react";

interface SpinnerProps {
  spin?: boolean;
  alt?: IconProp;
  className?: string;
}

export default function Spinner({
  spin = false,
  alt,
  className,
}: SpinnerProps): React.ReactElement {
  return (
    <>
      {spin ? (
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          fixedWidth
          className={className}
        />
      ) : (
        <>
          {alt && (
            <FontAwesomeIcon icon={alt} fixedWidth className={className} />
          )}
        </>
      )}
    </>
  );
}
