import type { Dayjs } from "dayjs";
import type {
  FormatterCallbackFunction,
  Point,
  PointOptionsObject,
  Series,
  SeriesClickEventObject,
  SeriesEventsOptionsObject,
  SeriesTooltipOptionsObject,
} from "highcharts";
import { createSearchString } from "../navigation/SearchString";
import { getUrlHash } from "../navigation/URL";

export interface AssetRegisterItemScatterColumn extends PointOptionsObject {
  locationAddress?: string;
  assetPositionReference?: string;
  assetPositionId?: string;
  assetId?: string;
  x: number;
  y: number;
}

export interface AssetRegisterItemScatterPoint extends Point {
  locationAddress?: string;
  assetPositionReference?: string;
  assetPositionId?: string;
  assetId?: string;
  x: number;
  y?: number;
}

export interface AssetRegisterItemColumn extends PointOptionsObject {
  locationAddress?: string;
  assetPositionReference?: string;
  assetPositionId?: string;
  assetId?: string;
  y: number;
}

export interface AssetRegisterItemPoint extends Point {
  locationAddress?: string;
  assetPositionReference?: string;
  assetPositionId?: string;
  assetId?: string;
  y?: number;
}

export interface AggregatedSeriesClickEventObject
  extends SeriesClickEventObject {
  point: AssetRegisterItemScatterPoint | AssetRegisterItemPoint;
}

export interface AggregatedChartsAmendedOptions {
  tooltip: SeriesTooltipOptionsObject & {
    pointFormatter: FormatterCallbackFunction<
      AssetRegisterItemScatterPoint | AssetRegisterItemPoint
    >;
  };
  events?: SeriesEventsOptionsObject & {
    click?: (this: Series, event: AggregatedSeriesClickEventObject) => void;
  };
}

export interface AggregatedDataItemClickHandlerProps {
  startTime: Dayjs;
  endTime: Dayjs;
  companyId: string;
  schemeId: string;
}

export function aggregationDataItemClickHandler(
  props: AggregatedDataItemClickHandlerProps & { privacyMode: boolean },
) {
  return function (this: Series, event: AggregatedSeriesClickEventObject) {
    const { assetId } = event.point;

    const url = `/admin/asset/${assetId}?${createSearchString({
      startDatetime: props.startTime?.format(),
      endDatetime: props.endTime?.format(),
    })}${getUrlHash(props.companyId, props.schemeId, props.privacyMode)}`;
    window.open(url, "_blank");
  };
}
