import React from "react";
import ObjectPropertyItem from "../../../components/Headers/ObjectPropertyItem";
import ObjectDetailHeading from "../../../components/Layout/ObjectDetailHeading";
import PrivacyMode from "../../../components/Text/PrivacyMode";
import CopyToClipboard from "../../../components/utils/CopyToClipboard";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates";
import type { WirelessSurveyOut } from "../../../openapi/model/wirelessSurveyOut";

export default function WirelessSurveyHeading(props: {
  survey: WirelessSurveyOut;
}) {
  const { survey } = props;
  return (
    <ObjectDetailHeading
      leftColumn={{
        content: (
          <ObjectPropertyItem title={"Survey Name"} type={"heading"}>
            <PrivacyMode>{survey.surveyName}</PrivacyMode>
          </ObjectPropertyItem>
        ),
      }}
      rightColumn={{
        content: (
          <>
            <ObjectPropertyItem title={"Survey ID"}>
              <CopyToClipboard value={survey.surveyId} slice={8} />
            </ObjectPropertyItem>
            <ObjectPropertyItem title={"Created"}>
              {formatDate(survey.createdAt, undefined, false)}
            </ObjectPropertyItem>
            <ObjectPropertyItem title={"Updated"}>
              {formatDate(survey.lastUpdated, undefined, false)}
            </ObjectPropertyItem>
          </>
        ),
      }}
    />
  );
}
