import GetListPaginatedList from "components/Pagination/GetListPaginatedList";
import PaginatedApiToCSVExportButton from "components/utils/CSVExport/PaginatedApiToCSVExportButton";
import type { AssetRegisterItem } from "openapi/model/assetRegisterItem";
import type { AssetRegisterSort } from "openapi/model/assetRegisterSort";
import type { DeviceAsset } from "openapi/model/deviceAsset";
import type { GatewayAsset } from "openapi/model/gatewayAsset";
import type { Status } from "openapi/model/status";
import React from "react";
import AssetRegisterListItem, {
  AssetRegisterListHeading,
} from "./AssetRegisterListItem";
import AssetRegisterToAsseTagButton from "./AssetRegisterToAssetTagButton";

interface Props {
  companyId: string;
  schemeId?: string;
  locationId?: string;
  assetType?: string;
  assetId?: string;
  textSearch?: string;
  parentAssetPositionId?: string;
  status?: Status[];
  sort?: AssetRegisterSort;
  row?: (props: {
    showScheme?: boolean;
    assetRegisterItem: AssetRegisterItem;
    // index: number;
    refresh?: () => void;
  }) => React.ReactElement;
  header?: React.ReactElement;
  download?: boolean;
  createAssetTags?: boolean;
  showLocation?: boolean;
  lastChange?: Date;
  additionalFunctionButtons?: React.ReactElement;
}

const assetToAssetRegisterExportDict = (
  asset: GatewayAsset | DeviceAsset,
): any => {
  const {
    assetId,
    serialNumber,
    manufacturer,
    model,
    type,
    parentAssetId,
    version,
    lastUpdated: _0,
    createdAt: _1,
    assetType: _2,
    assetPositionId: _3,
    schemeId: _4,
    companyId: _5 /* pull this row out so not exported*/,
    ...rest
  } = asset;

  if ("credentials" in rest) {
    (rest as any).credentials = undefined;
  }

  return {
    assetId,
    serialNumber,
    manufacturer,
    model,
    type,
    parentAssetId,
    version,
    assetProperties: rest,
  };
};

const mapAssetRegisterItemForCSV = (
  assetRegisterItem: AssetRegisterItem,
): any => {
  const { location, assetPosition, asset } = assetRegisterItem;

  const { companyId, schemeId, address, locationId } = location;
  const {
    assetPositionType,
    customerReference,
    parentAssetPositionId,
    assetPositionId,
  } = assetPosition;
  const assetProperties = asset ? assetToAssetRegisterExportDict(asset) : {};
  return {
    companyId,
    schemeId,
    address,
    locationId,
    assetPositionType,
    customerReference,
    parentAssetPositionId,
    assetPositionId,
    ...assetProperties,
  };
};

export default function AssetRegisterList(props: Props): React.ReactElement {
  const {
    row,
    header,
    download = true,
    createAssetTags = true,
    showLocation = true,
    additionalFunctionButtons,
    ...params
  } = props;
  const showScheme = props.schemeId === undefined;
  const rowFunction = (
    item: AssetRegisterItem,
    // index: number,
    // refresh?: () => void
  ): React.ReactElement => {
    const Row = row ? row : AssetRegisterListItem;
    return (
      <Row
        assetRegisterItem={item}
        showScheme={showScheme}
        showLocation={showLocation}
      />
    );
  };

  const Header = header ? header : <AssetRegisterListHeading />;
  const filtered = true;

  const filename =
    `${props.companyId}_${props.schemeId ? `${props.schemeId}_` : ""}` +
    `${filtered ? "filtered_" : ""}asset_register`;
  return (
    <GetListPaginatedList
      entityType={"ASSET_REGISTER"}
      params={params as unknown as Record<string, string>}
      lastChange={props.lastChange}
      cursor={{ maxItems: 25 }}
      header={Header}
      row={rowFunction}
      tableClassName={"list-group-flush"}
      data-cy={"asset-register-list"}
      additionalFunctionButtons={
        <div className={"flex gap-2"}>
          {download && (
            <PaginatedApiToCSVExportButton
              entityType={"ASSET_REGISTER"}
              params={params as unknown as Record<string, string>}
              mapFunction={mapAssetRegisterItemForCSV}
              filename={filename}
            />
          )}
          {props.schemeId && createAssetTags && (
            <AssetRegisterToAsseTagButton
              companyId={props.companyId}
              schemeId={props.schemeId}
            />
          )}
          {additionalFunctionButtons &&
            React.cloneElement(additionalFunctionButtons)}
        </div>
      }
    />
  );
}
