import dayjs from "dayjs";
import type React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import inclusiveDatePeriod from "../../../../components/Forms/DateTimePicker";
import { parseSearchString } from "../../../../components/navigation/SearchString";
import { doesDeviceHaveParsedData } from "../../../../model/assets/deviceData";
import type { DeviceAsset } from "../../../../openapi/model/deviceAsset";
import type { GatewayAsset } from "../../../../openapi/model/gatewayAsset";
import type { AssetTimeSeriesRequestParams } from "./AssetDataSelectionForm";

interface Props {
  children: ({
    searchParams,
  }: {
    searchParams: AssetTimeSeriesRequestParams;
  }) => React.ReactElement;
  device: DeviceAsset | GatewayAsset;
}

const AssetDataSearchStringParser = (props: Props): React.ReactElement => {
  const [searchParams, setSearchParams] = useState<
    AssetTimeSeriesRequestParams | undefined
  >(undefined);

  const location = useLocation();

  useEffect(() => {
    const [startDate, endDate] = inclusiveDatePeriod(
      undefined,
      undefined,
      undefined,
      true,
    );
    const searchParams = parseSearchString(location.search);

    const endDatetime = searchParams.startDatetime
      ? dayjs(searchParams.endDatetime as string)
      : dayjs(+endDate);

    const startDatetime = searchParams.startDatetime
      ? dayjs(searchParams.startDatetime as string)
      : startDate;

    const style: string = (
      searchParams.projection
        ? searchParams.projection
        : doesDeviceHaveParsedData(props.device)
          ? "Standard"
          : "Raw"
    ) as string;

    setSearchParams({
      startDatetime,
      endDatetime,
      projection: style,
    });
  }, [location.search, props.device]);

  return <>{searchParams && props.children({ searchParams })}</>;
};

export default AssetDataSearchStringParser;
