import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Theme/button";
import type React from "react";
import useDeleteEntity, {
  type DeleteEntityProps,
} from "../../hooks/deleteEntity";
import ErrorModal from "../Error/ErrorModal";
import DisplayIfUserType from "../utils/DisplayIfUserType/DisplayIfUserType";

interface DeleteButtonProps {
  errorMessageBody?: React.ReactElement;
}

export default function DeleteButton(
  props: DeleteEntityProps & DeleteButtonProps,
): React.ReactElement {
  const { deleting, deleteEntity, error } = useDeleteEntity(
    props.entityType,
    props.entityId,
    props.postDelete,
    props.path,
  );

  return (
    <DisplayIfUserType userTypes={["admin"]}>
      <ErrorModal
        error={error}
        title={error?.name}
        body={props.errorMessageBody}
      />
      <Button onClick={deleteEntity} disabled={deleting} color={"red"}>
        <FontAwesomeIcon
          icon={deleting ? faSpinner : faTrashAlt}
          spin={deleting}
          fixedWidth={true}
        />
      </Button>
    </DisplayIfUserType>
  );
}
