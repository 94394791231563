import dayjs from "dayjs";
import type React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type timeSeriesTemplates from "../../../constants/timeSeriesTemplates.json";
import { AssetUseCase } from "../../../openapi/model/assetUseCase";
import type { GroupBy } from "../../../openapi/model/groupBy";
import type { ReportView } from "../../../openapi/model/reportView";
import { matchStringsToAssetTypeEnum } from "../../../pages/assetRegister/assets/AssetTypeSelect";
import { matchStringsToEnum } from "../../../utils/enum";
import { removeMapBlanks } from "../../../utils/object";
import {
  convertStringUndefined,
  createSearchString,
  parseSearchString,
} from "../../navigation/SearchString";
import { type NewTimeSeriesRequestParams, matchPeriod } from "../model";

interface TimeSeriesQueryUrlWrapperProps {
  defaultValues: NewTimeSeriesRequestParams;
  children: (
    params: NewTimeSeriesRequestParams,
    onChange: (v: NewTimeSeriesRequestParams) => void,
  ) => React.ReactNode;
}

function TimeSeriesQueryUrlWrapper(props: TimeSeriesQueryUrlWrapperProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [init, setInit] = useState<boolean>(false);

  const searchParams = parseSearchString(location.search);
  const currentParams: Partial<NewTimeSeriesRequestParams> = {
    startDate: searchParams.startDate
      ? dayjs(searchParams.startDate as string)
      : undefined,
    endDate: searchParams.endDate
      ? dayjs(searchParams.endDate as string)
      : undefined,
    parameter: searchParams.parameter
      ? (searchParams.parameter as string)
      : undefined,
    aggregation: searchParams.aggregation
      ? (searchParams.aggregation as string)
      : undefined,
    group: searchParams.group ? (searchParams.group as string) : undefined,
    viewBy: searchParams.viewBy
      ? (searchParams.viewBy as ReportView)
      : undefined,
    groupBy: searchParams.groupBy
      ? (searchParams.groupBy as GroupBy)
      : undefined,
    period: searchParams.period
      ? matchPeriod(searchParams.period as string)
      : undefined,
    assetType: matchStringsToAssetTypeEnum(
      searchParams.assetType as string[],
    ) as unknown as (keyof typeof timeSeriesTemplates)[],
    useCase: matchStringsToEnum<AssetUseCase>(
      searchParams.useCase as string[],
      AssetUseCase,
      true,
    ),
  };

  const setLocationWithParams = (values: NewTimeSeriesRequestParams): void => {
    const [startDatetime, endDatetime] = [
      values.startDate?.format(),
      values.endDate?.format(),
    ];
    const newSearchString = createSearchString(
      convertStringUndefined({
        ...values,
        startDatetime,
        endDatetime,
      }),
    );
    if (
      location.search === ""
        ? newSearchString !== ""
        : `?${newSearchString}` !== location.search
    ) {
      console.log("update search", newSearchString);
      navigate({
        ...location,
        search: newSearchString,
      });
    }
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: shouldnt change, we only want to run once
  useEffect(() => {
    setLocationWithParams({
      ...removeMapBlanks(currentParams),
      ...props.defaultValues,
    });
    setInit(true);
  }, []);

  return (
    <>
      {init &&
        props.children(
          { ...props.defaultValues, ...removeMapBlanks(currentParams) },
          setLocationWithParams,
        )}
    </>
  );
}

export default TimeSeriesQueryUrlWrapper;
