import { OneCardPage } from "components/utils/OneCardPage";
import type React from "react";
import SchemesList from "./SchemesList";

export const SchemesListPage = (): React.ReactElement => {
  return (
    <OneCardPage
      headerTitle={"Schemes List"}
      breadcrumbs={[{ url: "/admin/schemes", name: "Schemes" }]}
      showSchemeInBreadcrumbs={false}
    >
      <SchemesList />
    </OneCardPage>
  );
};
