import type React from "react";
import type { AssetRegisterDataItem } from "../../../components/Table/AssetRegisterData";
import type { AssetRegisterDataItemBase } from "../../../openapi/model/assetRegisterDataItemBase";
import ReceiverAssignmentButton from "./ReceiverAssignmentButton";

export function getReceiverAssignmentTableValue(
  receiver: AssetRegisterDataItemBase,
) {
  return (d: AssetRegisterDataItem): number => {
    if (receiver.assetId) {
      const data = d[receiver.assetId] as unknown as number[] | undefined;
      return data ? data[0] : Number.NEGATIVE_INFINITY;
    }
    return Number.NEGATIVE_INFINITY;
  };
}

export default function ReceiverAssignmentTableCell(
  receiver: AssetRegisterDataItemBase,
  initLoad = false,
) {
  return function Cell(row: AssetRegisterDataItem): React.ReactElement {
    const value = getReceiverAssignmentTableValue(receiver)(row);
    return (
      <>
        <ReceiverAssignmentButton
          assetPositionId={row.assetPositionId as string}
          receiverPositionId={receiver.assetPositionId}
          companyId={receiver.companyId}
          schemeId={receiver.schemeId}
          initLoad={initLoad}
        />
        <span
          style={{ width: "50px", textAlign: "right", paddingRight: "2px" }}
        >
          {value === Number.NEGATIVE_INFINITY ? "-" : value.toFixed(1)}
        </span>
      </>
    );
  };
}
