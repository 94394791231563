import type React from "react";

import { Button, type styles } from "components/Theme/button";

export interface ActionProps extends React.PropsWithChildren {
  onClick: () => any;
  color?: keyof typeof styles.colors;
}

export const Action = ({ onClick, color, children }: ActionProps) => {
  return (
    <Button onClick={onClick} color={color}>
      {children}
    </Button>
  );
};

export default Action;
