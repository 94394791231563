import type { Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import type React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DateRangePicker from "../../../components/Forms/DateRangePicker/DateRangePicker";
import { Button } from "../../../components/Theme/button";
import { Col, Container } from "../../../components/Theme/grid";
import { createSearchString } from "../../../components/navigation/SearchString";

export interface DateRangeParams {
  startDatetime?: Dayjs;
  endDatetime?: Dayjs;
}

const DateRangeOnlySelectorForm = (
  props: Required<DateRangeParams>,
): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialValues = (): Required<DateRangeParams> => {
    return props;
  };

  const selectData = (values: Required<DateRangeParams>): void => {
    const [startDatetime, endDatetime] = [
      values.startDatetime.format(),
      values.endDatetime.format(),
    ];
    navigate({
      search: createSearchString({
        startDatetime,
        endDatetime,
      }),
      hash: location.hash,
    });
  };

  return (
    <Formik
      initialValues={initialValues()}
      onSubmit={selectData}
      enableReinitialize={true}
    >
      {(): React.ReactElement => {
        return (
          <Form>
            <Container>
              <Col sm={12} md={8} lg={10} xl={10} className={"px-3 pr-sm-1"}>
                <DateRangePicker
                  startDateName={"startDatetime"}
                  endDateName={"endDatetime"}
                />
              </Col>
              <Col sm={12} md={4} lg={2} xl={2} className={"py-1 px-3 pl-sm-1"}>
                <Button
                  type={"submit"}
                  color={"brandLight"}
                  className={"w-100"}
                >
                  Search
                </Button>
              </Col>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DateRangeOnlySelectorForm;
