import type React from "react";
import AssetIdLink from "../../../../components/Asset/AssetIdLink";
import AssetTypeHumanLabel from "../../../../components/Asset/AssetTypeHumanLabel";
import AssetPositionIdLink from "../../../../components/AssetPosition/AssetPositionIdLink";
import PrivacyMode from "../../../../components/Text/PrivacyMode";
import { TableCell, TableRow } from "../../../../components/Theme/table";
import type { Asset } from "../../../../openapi/model/asset";
import type { AssetPosition } from "../../../../openapi/model/assetPosition";
import LinkAssetPositionFromAssetButton from "../../assetPositions/LinkAssetPositionFromAssetButton";
import DeviceSpecificListContent from "./DeviceSpecficListContent";

const AssetListGenericRowsContent = (props: {
  asset: Asset;
  assignAssetPosition?: AssetPosition;
  showPosition?: boolean;
  showScheme?: boolean;
  refreshElement: () => any;
  refreshList?: () => any;
}): React.ReactElement => {
  const showPosition =
    props.showPosition !== undefined ? props.showPosition : true;
  const showScheme = props.showScheme !== undefined ? props.showScheme : true;

  return (
    <TableRow data-cy={"list-item"}>
      <TableCell className="text-left" data-cy={"asset-id"}>
        <AssetIdLink asset={props.asset} />
      </TableCell>
      {showScheme && (
        <TableCell className={"text-left"}>
          <PrivacyMode>{props.asset.schemeId}</PrivacyMode>
        </TableCell>
      )}

      <TableCell className="text-left">
        <PrivacyMode>{props.asset.serialNumber}</PrivacyMode>
      </TableCell>
      <TableCell className="text-left">
        <AssetTypeHumanLabel asset={props.asset} />
      </TableCell>
      <TableCell className="text-left">{props.asset.manufacturer}</TableCell>
      {showPosition ? (
        <>
          <TableCell className={"text-left"}>
            {props.asset.assetPositionId && (
              <AssetPositionIdLink
                assetPositionId={props.asset.assetPositionId}
              />
            )}
          </TableCell>
          <TableCell className="text-left">
            <DeviceSpecificListContent
              asset={props.asset}
              refreshElement={props.refreshElement}
            />
          </TableCell>
        </>
      ) : (
        <TableCell className={"text-left"}>
          {props.assignAssetPosition && (
            <LinkAssetPositionFromAssetButton
              asset={props.asset}
              assetPosition={props.assignAssetPosition}
              refresh={props.refreshList}
            />
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

export default AssetListGenericRowsContent;
