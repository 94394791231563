import {
  faCog,
  faExclamationCircle,
  faSlash,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import dayjs from "dayjs";
import { lowerCase, startCase } from "lodash-es";
import type React from "react";
import { Link } from "react-router-dom";
import PrivacyMode from "../../components/Text/PrivacyMode";
import { StackedListTwoColumnItem } from "../../components/Theme/stacked-list";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import {
  formatDate,
  formatDuration,
} from "../../components/utils/HighlightNullAndOldDates";
import type { AssetAlarmEvent } from "../../openapi/model/assetAlarmEvent";
import type { Event } from "../../openapi/model/event";
import type { GatewayAsset } from "../../openapi/model/gatewayAsset";

const AlarmIcon = (props: { alarm: AssetAlarmEvent }) => {
  const closed = !(props.alarm.closedDateTime === undefined);
  switch (props.alarm.alarmType) {
    case "GATEWAY_OFFLINE":
      return (
        <span
          className={clsx(
            "fa-layers",
            " ml-0",
            " mr-2",
            closed ? "text-success" : "text-danger",
          )}
        >
          <>
            {!closed && <FontAwesomeIcon icon={faSlash} fixedWidth />}
            <FontAwesomeIcon icon={faWifi} fixedWidth />
          </>
        </span>
      );
    case "GATEWAY_CONFIGURATION_ERROR_UPDATING":
      return (
        <span
          className={clsx(
            "fa-layers",
            " ml-0",
            " mr-2",
            closed ? "text-success" : "text-danger",
          )}
        >
          <FontAwesomeIcon icon={faCog} fixedWidth />
        </span>
      );
    default:
      return <FontAwesomeIcon icon={faExclamationCircle} />;
  }
};

const getAlarmHeading = (alarm: AssetAlarmEvent): React.ReactElement => {
  const closed = !(alarm.closedDateTime === undefined);
  switch (alarm.alarmType) {
    case "GATEWAY_OFFLINE":
      return (
        <CompanyNavLink
          to={{
            pathname: `/admin/asset/${alarm.affectedObjects.asset?.assetId}`,
          }}
          colorClassName={closed ? "text-success" : "text-danger"}
        >
          Gateway{" "}
          <PrivacyMode>{alarm.affectedObjects.asset?.serialNumber}</PrivacyMode>{" "}
          offline
          {closed && (
            <>
              {" "}
              (closed{" "}
              {formatDuration(
                dayjs.duration(dayjs().diff(alarm.closedDateTime)),
              )}
              )
            </>
          )}
        </CompanyNavLink>
      );
    case "GATEWAY_CONFIGURATION_ERROR_UPDATING":
      return (
        <CompanyNavLink
          to={{
            pathname: `/admin/asset/${alarm.affectedObjects.asset?.assetId}`,
          }}
          colorClassName={closed ? "text-success" : "text-danger"}
        >
          Gateway{" "}
          <PrivacyMode>{alarm.affectedObjects.asset?.serialNumber}</PrivacyMode>{" "}
          configuration error
          {closed && (
            <>
              {" "}
              (closed{" "}
              {formatDuration(
                dayjs.duration(dayjs().diff(alarm.closedDateTime)),
              )}
              )
            </>
          )}
        </CompanyNavLink>
      );

    default:
      return <></>;
  }
};

const AlarmMessage = (props: {
  alarm: AssetAlarmEvent;
}): React.ReactElement => {
  const { alarm } = props;
  const closed = !(props.alarm.closedDateTime === undefined);
  const asset = alarm.affectedObjects.asset as GatewayAsset;
  switch (alarm.alarmType) {
    case "GATEWAY_OFFLINE":
      return (
        <>
          <span className={"d-inline-block"}>
            {alarm.eventData?.lastContact ? (
              <>
                Data was last received at&nbsp;
                {formatDate(alarm.eventData.lastContact, "LT")} on{" "}
                {formatDate(alarm.eventData.lastContact, "L")}.
              </>
            ) : closed ? (
              <>Data was not received within the expected period.</>
            ) : (
              <>
                Data was last received at&nbsp;
                {formatDate(asset.lastContact, "LT")} on{" "}
                {formatDate(asset?.lastContact, "L")}.
              </>
            )}
          </span>
          {alarm.affectedObjects.location && (
            <span className={"d-inline-block px-1"}>
              Address:{" "}
              <CompanyNavLink
                to={{
                  pathname: `/admin/location/${alarm.affectedObjects.location.locationId}`,
                }}
              >
                <PrivacyMode>
                  {alarm.affectedObjects.location.address}.
                </PrivacyMode>
              </CompanyNavLink>
              {alarm.affectedObjects.assetPosition?.customerReference && (
                <>
                  &nbsp;Reference:&nbsp;
                  <CompanyNavLink
                    to={{
                      pathname: `/admin/asset_position/${alarm.affectedObjects.assetPosition.assetPositionId}`,
                    }}
                  >
                    <PrivacyMode>
                      {alarm.affectedObjects.assetPosition.customerReference}
                    </PrivacyMode>
                  </CompanyNavLink>
                </>
              )}
            </span>
          )}
        </>
      );
    case "GATEWAY_CONFIGURATION_ERROR_UPDATING":
      return (
        <>
          <span className={"d-inline-block"}>
            Updating device ({lowerCase(startCase(alarm.eventData.actionType))})
            failed at {formatDate(alarm.eventData.lastUpdate, "LT")} on{" "}
            {formatDate(alarm.eventData.lastUpdate, "L")}.
          </span>
          {alarm.affectedObjects.location && (
            <span className={"d-inline-block px-1"}>
              Address:{" "}
              <CompanyNavLink
                to={{
                  pathname: `/admin/location/${alarm.affectedObjects.location.locationId}`,
                }}
              >
                <PrivacyMode>
                  {alarm.affectedObjects.location.address}.
                </PrivacyMode>
              </CompanyNavLink>
              {alarm.affectedObjects.assetPosition?.customerReference && (
                <>
                  &nbsp;Reference:&nbsp;
                  <CompanyNavLink
                    to={{
                      pathname: `/admin/asset_position/${alarm.affectedObjects.assetPosition.assetPositionId}`,
                    }}
                  >
                    <PrivacyMode>
                      {alarm.affectedObjects.assetPosition.customerReference}
                    </PrivacyMode>
                  </CompanyNavLink>
                </>
              )}
            </span>
          )}
        </>
      );
    default:
      return <></>;
  }
};

const AlarmListItemGroup = (props: {
  icon: React.ReactElement;
  heading: React.ReactElement;
  age: string | React.ReactNode;
  description: React.ReactElement;
  dense?: boolean;
}): React.ReactElement => {
  const { icon, heading, age, description } = props;
  return (
    <StackedListTwoColumnItem
      column1={
        <div className="min-w-0 flex-auto">
          {icon}
          <span className={"mb-0"}>{heading}</span>
          <p className="text-sm mb-0 mt-1">{description}</p>
        </div>
      }
      column2={
        <div className={"min-w-0 flex-auto text-right"}>
          <small>{age}</small>
        </div>
      }
      column1Size={"w-5/6"}
      column2Size={"w-1/6"}
      dense={props.dense}
    />
  );
};

const AlarmWidgetRow = (
  value: Event | AssetAlarmEvent,
  index: number,
  dense?: boolean,
): React.ReactElement => {
  if (Object.hasOwnProperty.call(value, "alarmType")) {
    const alarm = value as AssetAlarmEvent;
    return (
      <AlarmListItemGroup
        icon={<AlarmIcon alarm={alarm} />}
        heading={getAlarmHeading(alarm)}
        age={formatDuration(dayjs.duration(dayjs().diff(alarm.eventDateTime)))}
        description={<AlarmMessage alarm={alarm} />}
        key={index}
        dense={dense}
      />
    );
  } else {
    return <></>;
  }
};

export default AlarmWidgetRow;
