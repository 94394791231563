import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import type React from "react";
import Spinner from "../../action/Spinner";

const DownloadButtonContent = ({
  loading = false,
}: {
  loading?: boolean;
}): React.ReactElement => {
  return (
    <>
      <Spinner alt={faCloudDownloadAlt} spin={loading} className={"mr-2"} />
      Download
    </>
  );
};

export default DownloadButtonContent;
