import {
  faCheck,
  faCog,
  faRotate,
  faSkull,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import type React from "react";
import { useState } from "react";
import { Button } from "../../../../../components/Theme/button";
import UncontrolledTooltip from "../../../../../components/Theme/tooltip";
import useCreateEntity from "../../../../../hooks/createEntity";
import type { AssetFile } from "../../../../../openapi/model/assetFile";
import { FileProcessingStatus } from "../../../../../openapi/model/fileProcessingStatus";
import type { IntegrationFile } from "../../../../../openapi/model/integrationFile";
import { fileUrl, isIntegrationFile } from "../model";

interface ResentDataFileButtonProps {
  file: IntegrationFile | AssetFile;
  id: string;
  removeQuarantine: boolean;
}

export default function ResendDataFileButton(
  props: ResentDataFileButtonProps,
): React.ReactElement {
  const { file, id, removeQuarantine } = props;
  const [resent, setResent] = useState(false);

  const resendFile = useCreateEntity("GATEWAY_FILE", fileUrl(file));

  const handleResendFile = async (): Promise<any> => {
    return resendFile
      .createEntity({ companyId: file.companyId, removeQuarantine })
      .then(() => {
        setResent(true);
      });
  };

  const iconFa = resent
    ? faCheck
    : resendFile.error
      ? faXmark
      : resendFile.creating
        ? faCog
        : removeQuarantine
          ? faSkull
          : faRotate;

  const disabled = iconFa === faCog || iconFa === faSkull;

  return (
    <>
      {isIntegrationFile(file) &&
        (file.status === FileProcessingStatus.QUARANTINED ||
        !removeQuarantine ? (
          <Button
            plain
            id={`tooltip-file-resend-${id}`}
            onClick={async (): Promise<any> => {
              return await handleResendFile();
            }}
            size={"sm"}
            disabled={!disabled}
          >
            {removeQuarantine ? (
              <UncontrolledTooltip
                message={
                  resendFile.error
                    ? `Error removing quarantine from file ${resendFile.error.message}`
                    : "Remove file quarantine"
                }
              >
                <FontAwesomeIcon
                  className={"text-brand-dark-700"}
                  fixedWidth={true}
                  size={"sm"}
                  icon={iconFa}
                  spin={resendFile.creating}
                />
              </UncontrolledTooltip>
            ) : (
              <UncontrolledTooltip
                message={
                  resendFile.error
                    ? `Error resending file ${resendFile.error.message}`
                    : "Resend file"
                }
              >
                <FontAwesomeIcon
                  className={"text-brand-dark-700"}
                  fixedWidth={true}
                  size={"sm"}
                  icon={iconFa}
                  spin={resendFile.creating}
                />
              </UncontrolledTooltip>
            )}
          </Button>
        ) : (
          <span className={"fa fa-fw fa-sm"} />
        ))}
    </>
  );
}
