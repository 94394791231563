import { Button } from "components/Theme/button";
import { Form, Formik, type FormikHelpers } from "formik";
import React from "react";
import * as Yup from "yup";
import NewTextFormComponent from "../../components/Forms/NewTextFormComponent";
import PrivacyMode from "../../components/Text/PrivacyMode";
import { Heading } from "../../components/Theme/heading";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import type { CreateEntity } from "../../hooks/createEntity";
import { nameRegEx } from "../../model/utils/schema";
import type { Scheme } from "../../openapi/model/scheme";
import type { TariffIn } from "../../openapi/model/tariffIn";
import type { TariffOut } from "../../openapi/model/tariffOut";

const TariffSchema = Yup.object().shape({
  tariffId: Yup.string()
    .required()
    .matches(...nameRegEx),
  tariffName: Yup.string().required(),
});

type FormValues = Omit<TariffOut, "companyId" | "schemeId">;

const TariffCreateForm = (props: {
  scheme: Scheme;
  create: CreateEntity<TariffOut, TariffIn>;
}) => {
  const { scheme, create } = props;

  const initialValues: FormValues = {
    tariffId: scheme.schemeId,
    tariffName: scheme.schemeName,
  };

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    create
      .createEntity({
        ...values,
        companyId: scheme.companyId,
        schemeId: scheme.schemeId,
      })
      .finally(() => {
        setSubmitting(false);
        resetForm();
      });
  };

  return (
    <>
      <Heading>
        Billing has not been enabled for{" "}
        <PrivacyMode>{props.scheme.schemeName}</PrivacyMode>. Please contact
        support.
      </Heading>
      <DisplayIfUserType userTypes={"superadmin"}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={TariffSchema}
        >
          {({ submitForm, isSubmitting, isValid }) => (
            <Form>
              <NewTextFormComponent
                fieldName={"tariffId"}
                label={{ label: "Tariff Id" }}
              />
              <NewTextFormComponent
                fieldName={"tariffName"}
                label={{ label: "Tariff Name" }}
              />
              <Button
                color={"brandLight"}
                onClick={submitForm}
                disabled={isSubmitting || !isValid}
              >
                Create Tariff
              </Button>
            </Form>
          )}
        </Formik>
      </DisplayIfUserType>
    </>
  );
};

export default TariffCreateForm;
