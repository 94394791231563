import type React from "react";
import type { AssetPosition } from "../../openapi/model/assetPosition";
import CompanyNavLink from "../navigation/CompanyNavLink";

const AssetPositionIdLink = (props: {
  assetPosition?: AssetPosition;
  assetPositionId?: string;
  full?: boolean;
  text?: string;
}): React.ReactElement => {
  const { assetPosition, assetPositionId } = props;
  const actualAssetPositionId = assetPosition
    ? assetPosition.assetPositionId
    : assetPositionId;

  if (actualAssetPositionId) {
    if (actualAssetPositionId !== "NONE") {
      const displayAssetPositionId = props.full
        ? actualAssetPositionId
        : `...${actualAssetPositionId.slice(24)}`;
      return (
        <CompanyNavLink
          to={{ pathname: `/admin/asset_position/${actualAssetPositionId}` }}
        >
          {props.text || displayAssetPositionId}
        </CompanyNavLink>
      );
    } else {
      return <>-</>;
    }
  }
  throw new Error("One of assetPosition or assetPositionId must be set");
};

export default AssetPositionIdLink;
