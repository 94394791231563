import type React from "react";
import { useState } from "react";
import type { Event } from "../../openapi/model/event";

import { isEmpty } from "lodash-es";
import { loadFileFromApi } from "../../actions/helpers/presigned_s3";
import DownloadFileTooltip from "../../components/downloadFileTooltip";

const getArtifactsFileFromUrl = async (event: Event): Promise<void> => {
  const linked_jobs = event.affectedObjects.filter((e) => e.type === "job");
  const the_job = linked_jobs.length === 1 ? linked_jobs[0].id : undefined;

  return loadFileFromApi(
    `/job/artifacts?job_id=${the_job}&event_id=${event.eventId}&company_id=${event.companyId}`,
  );
};

const EventJobCompleteArtifactsDownload = (props: {
  event: Event;
  index: string;
}): React.ReactElement => {
  const { event } = { ...props };

  const [loading, setLoading] = useState(false);

  const downloadable = event.eventData?.artifactsFile !== undefined;

  const handleClick = async (): Promise<undefined> => {
    if (downloadable && !loading) {
      setLoading(true);

      getArtifactsFileFromUrl(event).finally(() => setLoading(false));
    }
    return;
  };

  return (
    <>
      {!isEmpty(event.eventData) &&
        (event.eventType === "JOB_COMPLETED" ||
          event.eventType === "REPORT_COMPLETED") && (
          <DownloadFileTooltip
            loading={loading}
            downloadable={downloadable}
            fileDescription={"Download job results"}
            clickHandler={handleClick}
          />
        )}
    </>
  );
};

export default EventJobCompleteArtifactsDownload;
