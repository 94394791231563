import type React from "react";
import type { AssetPosition } from "../../../../../openapi/model/assetPosition";
import type { DeviceAsset } from "../../../../../openapi/model/deviceAsset";
import type { GatewayAsset } from "../../../../../openapi/model/gatewayAsset";
import AssetDataSearchStringParser from "../AssetDataSearchStringParser";
import AssetRFChart from "./AssetRFChart";
import AssetTimeSeriesRFGetter from "./AssetTimeSeriesRFGetter";

interface Props {
  device: DeviceAsset | GatewayAsset;
  assetPosition: AssetPosition;
}

export default function AssetRFAssessmentContainer(
  props: Props,
): React.ReactElement {
  return (
    <AssetDataSearchStringParser device={props.device}>
      {({ searchParams }) => {
        return (
          <AssetTimeSeriesRFGetter
            device={props.device}
            searchParams={searchParams}
            render={(props) => <AssetRFChart {...props} />}
          />
        );
      }}
    </AssetDataSearchStringParser>
  );
}
