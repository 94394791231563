import { Auth } from "aws-amplify";
import { Form, Formik, type FormikHelpers } from "formik";
import React from "react";
import * as Yup from "yup";
import AlertBanner from "../../../components/Banner/AlertBanner";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import { Button } from "../../../components/Theme/button";
import { AuthContext, logout } from "../../../reducers/auth";

interface State {
  success: boolean;
  error: boolean;
  errorMessage: string;
}

interface FormValues {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export default function ChangePasswordForm() {
  const [state, setState] = React.useState<State>({
    success: false,
    error: false,
    errorMessage: "",
  });
  const dispatch = React.useContext(AuthContext).dispatch;

  const submit = (
    { currentPassword, newPassword }: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, currentPassword, newPassword);
      })
      .then(() => {
        resetForm();
        setState({ success: true, error: false, errorMessage: "" });
        logout(dispatch);
      })
      .catch((err) => {
        setState({
          success: false,
          error: true,
          errorMessage: err.message,
        });
      });
    setSubmitting(false);
  };

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string().required(
            "Current password is required.",
          ),
          newPassword: Yup.string().required("New Password is required"),
          newPasswordConfirm: Yup.string()
            .required("Confirmation of your new password is required")
            .oneOf(
              [Yup.ref("newPassword"), null],
              "Must match your new password",
            ),
        })}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <NewTextFormComponent
              fieldName={"currentPassword"}
              label={{ label: "Current password" }}
              type="password"
            />
            <NewTextFormComponent
              fieldName={"newPassword"}
              label={{ label: "New password" }}
              type="password"
            />
            <NewTextFormComponent
              fieldName={"newPasswordConfirm"}
              label={{ label: "Confirm new password" }}
              type="password"
            />
            <Button
              color="brandLight"
              disabled={!isValid || isSubmitting}
              type="submit"
            >
              Save password
            </Button>
          </Form>
        )}
      </Formik>
      {state.success ? (
        <AlertBanner className={"mt-3 bg-success"}>
          Password successfully changed.
        </AlertBanner>
      ) : (
        <></>
      )}
      {state.error ? (
        <AlertBanner className={"mt-3 bg-red"}>
          Password change failed with error: {state.errorMessage}
        </AlertBanner>
      ) : (
        <></>
      )}
    </>
  );
}
