import inclusiveDatePeriod from "components/Forms/DateTimePicker";
import type { Dayjs } from "dayjs";
import type React from "react";
import ErrorOverlappingBanner from "../../../components/Error/ErrorOverlappingBanner";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import useGetData from "../../../hooks/getData";
import type { AggregatedStatsRequest } from "../../../openapi/model/aggregatedStatsRequest";
import type { AggregatedTimeSeriesStatsItem } from "../../../openapi/model/aggregatedTimeSeriesStatsItem";
import { useSelectedCompany } from "../../../reducers/company";
import { useSelectedScheme } from "../../../reducers/scheme";

export type AggregatedStatsResponse = {
  [group: string]: {
    [use_case: string]: { [datetime: string]: AggregatedTimeSeriesStatsItem };
  };
};

export interface LatestTimeSeriesReportProps {
  error?: Error;
  data?: AggregatedStatsResponse;
  loading: boolean;
}

export interface AggregatedTimeSeriesStatsParams
  extends Omit<AggregatedStatsRequest, "startDatetime" | "endDatetime"> {
  startDatetime?: Dayjs;
  endDatetime?: Dayjs;
}

export default function TimeSeriesStatsApiWrapper(props: {
  request: AggregatedTimeSeriesStatsParams;
  children: (props: LatestTimeSeriesReportProps) => React.ReactNode;
}): React.ReactElement {
  const companyId = useSelectedCompany()?.companyId as string;
  const schemeId = useSelectedScheme()?.schemeId;
  const { request } = props;
  const [actualStartDatetime, actualEndDatetime] = inclusiveDatePeriod(
    request.startDatetime,
    request.endDatetime,
  );

  const [{ data, loading, error }] = useGetData<AggregatedStatsResponse>({
    path: "report/time_series/stats",
    companyId,
    schemeId,
    params: {
      ...props.request,
      startDatetime: actualStartDatetime.toDate(),
      endDatetime: actualEndDatetime.toDate(),
    },
    singletonApi: true,
    post: true,
    convertSnakeCaseKeysOnlyToCamelCase: true,
  });

  return (
    <>
      <ErrorOverlappingBanner error={error || undefined}>
        <BlockSpinner loading={loading}>
          {props.children({ error: error || undefined, data, loading })}
        </BlockSpinner>
      </ErrorOverlappingBanner>
    </>
  );
}
