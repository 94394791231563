import type React from "react";
import { useState } from "react";
import type { Event } from "../../openapi/model/event";

import "react-json-pretty/themes/monikai.css";

import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash-es";
import JSONPretty from "react-json-pretty";

const EventMoreDetailCaret = (props: { event: Event }): React.ReactElement => {
  const [display, setDisplay] = useState(false);
  const event = props.event;

  function flip() {
    setDisplay((v) => !v);
  }
  return (
    <>
      {!isEmpty(event.eventData) && event.eventType !== "JOB_COMPLETED" && (
        <>
          <span onClick={flip} onKeyDown={flip} className={"text-xs"}>
            <FontAwesomeIcon
              icon={display ? faCaretDown : faCaretRight}
              fixedWidth
            />
            {display ? "Hide" : "More"}
          </span>
          {display && (
            <div style={{ maxWidth: "35rem" }}>
              <JSONPretty data={event.eventData} className={"text-xs"} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EventMoreDetailCaret;
