import { Badge } from "components/Theme/badge";
import type React from "react";
import { Status } from "../../openapi/model/status";

export default function DeletedLabel(props: {
  object: { status: Status };
}): React.ReactElement {
  /* TODO: small size badge */
  if (props.object.status === Status.DELETED) {
    return (
      <Badge color={"danger"} className={"mx-1"}>
        Deleted
      </Badge>
    );
  } else {
    return <></>;
  }
}
