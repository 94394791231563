import { faLinkSlash } from "@fortawesome/free-solid-svg-icons";
import type React from "react";
import UpdateButton from "../../../components/Buttons/UpdateButton";
import type { AssetPosition } from "../../../openapi/model/assetPosition";

export default function WirelessClusterGatewayLeaveButton(props: {
  gatewayPosition: AssetPosition;
  refresh?: () => void;
}): React.ReactElement {
  const { gatewayPosition } = props;

  return (
    <UpdateButton
      updatedEntity={{
        ...gatewayPosition,
        parentAssetPositionId: undefined,
      }}
      entityId={gatewayPosition.assetPositionId}
      entityType={"ASSET_POSITION"}
      text={"Leave"}
      icon={faLinkSlash}
      color={"brandLight"}
      /* TODO: small button */
      postUpdate={props.refresh}
    />
  );
}
