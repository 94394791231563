import { Field, useField } from "formik";
import type React from "react";

import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { usePrivacyMode } from "../../reducers/privacy";
import CopyToClipboard from "../utils/CopyToClipboard";
import NewLabelWrapper, { type LabelProps } from "./NewLabelWrapper";

interface Props {
  fieldName: string;
  disabled?: boolean;
  placeholder?: string;
  infoText?: string;
  clipboard?: boolean;
  staticValue?: boolean;
  privacyModeDisabled?: boolean;
  dataCy?: string;
  type?: string;
  className?: string;
  label?: LabelProps;
}

export default function NewTextFormComponent(props: Props): React.ReactElement {
  const {
    disabled = false,
    clipboard = false,
    staticValue = false,
    privacyModeDisabled = false,
  } = props;
  const [_field, meta, _helpers] = useField(props.fieldName);
  const privacyMode = usePrivacyMode();

  return (
    <>
      <NewLabelWrapper {...props.label} for={props.fieldName}>
        <div className="relative">
          <Field
            id={props.fieldName}
            name={props.fieldName}
            aria-invalid="true"
            aria-describedby="email-error"
            className={clsx(
              "block w-full rounded-lg border-0 py-[7px] sm:text-sm sm:leading-6 ring-1",
              clipboard && "pr-10",
              meta.error && meta.touched
                ? "pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                : "text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-brand-light-600",
              staticValue && "static",
              privacyMode && !privacyModeDisabled && "blurry-text",
              props.className,
            )}
            type={props.type || "text"}
            as={props.type === "textarea" && "textarea"}
            disabled={disabled || staticValue}
            data-cy={props.dataCy || props.fieldName}
            placeholder={props.placeholder}
          />
          {meta.error && meta.touched && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                aria-hidden="true"
                className="h-5 w-5 text-red-500"
              />
            </div>
          )}
          {clipboard && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <CopyToClipboard value={_field.value}>
                <FontAwesomeIcon
                  icon={faClipboard}
                  aria-hidden="true"
                  className="h-5 w-5"
                />
              </CopyToClipboard>
            </div>
          )}
        </div>
        {meta.error && meta.touched && (
          <p id="email-error" className="mt-2 text-sm text-red-600">
            {meta.error}
          </p>
        )}
      </NewLabelWrapper>
    </>
  );
}
