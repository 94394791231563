import { MenuItem } from "@headlessui/react";
import type React from "react";

interface ProfileDropdownMenuItemProps {
  name: string;
  href?: string;
  action?: () => any;
  "data-cy"?: string;
}

export default function ProfileDropdownMenuItem(
  props: ProfileDropdownMenuItemProps,
): React.ReactElement {
  const { name, href, action } = props;
  return (
    <MenuItem key={name}>
      <a
        href={href}
        onClick={action && action}
        className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
        data-cy={props["data-cy"]}
      >
        {name}
      </a>
    </MenuItem>
  );
}
