import { TableHeader, TableRow } from "components/Theme/table";
import type React from "react";

const AssetListGenericRowsHeader = (
  showPosition = true,
  showScheme = true,
): [React.ReactElement, number] => {
  return [
    <TableRow key={1}>
      <TableHeader className="text-left">Device ID</TableHeader>
      {showScheme && <TableHeader className={"text-left"}>Scheme</TableHeader>}
      <TableHeader className="text-left">Serial Number</TableHeader>
      <TableHeader className="text-left">Type</TableHeader>
      <TableHeader className="text-left">Manufacturer</TableHeader>
      {showPosition && (
        <TableHeader className="text-left">Position</TableHeader>
      )}
      <TableHeader className="text-left">&nbsp;</TableHeader>
    </TableRow>,
    5 + (showPosition ? 1 : 0) + (showScheme ? 1 : 0),
  ];
};

export default AssetListGenericRowsHeader;
