import React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../components/Forms/SelectCombo/SelectCombo";
import { CumulativeValueTimeSeriesField } from "../../openapi/model/cumulativeValueTimeSeriesField";

function CumulativeDataSelect(props: Omit<SelectComboFieldProps, "options">) {
  const values: Array<Option> = [
    {
      value: CumulativeValueTimeSeriesField.EnergyCooling,
      label: "Energy (Cooling)",
    },
    {
      value: CumulativeValueTimeSeriesField.EnergyHeating,
      label: "Energy (Heating)",
    },
    {
      value: CumulativeValueTimeSeriesField.EnergyElectricalActiveImport,
      label: "Energy (Electrical Active Import)",
    },
    {
      value: CumulativeValueTimeSeriesField.EnergyElectricalActiveExport,
      label: "Energy (Electrical Active Export)",
    },
    {
      value: CumulativeValueTimeSeriesField.EnergyElectricalReactiveImport,
      label: "Energy (Electrical Reactive Import)",
    },
    {
      value: CumulativeValueTimeSeriesField.EnergyElectricalReactiveExport,
      label: "Energy (Electrical Reactive Export)",
    },
    { value: CumulativeValueTimeSeriesField.Volume, label: "Volume" },
  ];
  return <SelectComboField options={values} {...props} />;
}

export default CumulativeDataSelect;
