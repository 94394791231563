import type { Dayjs } from "dayjs";
import Highcharts, { type Dictionary, type SVGElement } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import annotations from "highcharts/modules/annotations";
import nodata from "highcharts/modules/no-data-to-display.js";
import { isArray } from "lodash-es";
import type React from "react";
import { useRef } from "react";
import ErrorOverlappingBanner from "../../../../../components/Error/ErrorOverlappingBanner";
import BlockSpinner from "../../../../../components/Spinners/BlockSpinner";
import Card from "../../../../../components/Theme/card";
import { Col } from "../../../../../components/Theme/grid";
import { heatMeterChartColors } from "../../../../../components/charts/ChartColors";
import type {
  ColumnDefinitionWithData,
  TimeSeriesDataParams,
} from "../../../../../model/deviceTimeSeries";
import type { ErrorBand } from "../../../../../time_series/asset/data/valueErrors";
nodata(Highcharts);
annotations(Highcharts);

export const columnToNumberData = (
  column: ColumnDefinitionWithData | undefined,
): (number | null)[] => {
  if (column) {
    return (column.data as string[]).map((v) =>
      v === null ? null : Number.parseFloat(v),
    );
  } else {
    return [];
  }
};

export const columnToTimeNumberData = (
  column: ColumnDefinitionWithData<any> | Array<number> | undefined,
  times: Dayjs[],
): [number, number | null][] => {
  if (isArray(column) && times) {
    return column.map((data, i) => {
      return [times[i].valueOf(), data];
    });
  } else if ((column as ColumnDefinitionWithData<any>)?.data && times) {
    return ((column as ColumnDefinitionWithData<any>).data as string[]).map(
      (v, index) => {
        return [
          times[index].valueOf(),
          v === null ? null : Number.parseFloat(v),
        ];
      },
    );
  } else {
    return [];
  }
};

const removeNulls = (array: (number | null)[]): number[] => {
  return array.filter((v) => v !== null) as number[];
};

const HeatMeterFlowAndTemperatureChart = (props: {
  flowRateData: ColumnDefinitionWithData;
  flowTemperatureData: ColumnDefinitionWithData;
  returnTemperatureData: ColumnDefinitionWithData;
  powerData: ColumnDefinitionWithData;
  errorBands?: Array<ErrorBand>;
  times: Dayjs[];
  params: TimeSeriesDataParams;
  loading: boolean;
  error?: Error;
}): React.ReactElement => {
  const series: Highcharts.SeriesLineOptions[] = [
    {
      data: columnToTimeNumberData(props.flowTemperatureData, props.times),
      type: "line",
      animation: false,
      color: heatMeterChartColors.flowTemperature,
      name: "Flow Temperature",
      yAxis: 0,
    },
    {
      data: columnToTimeNumberData(props.returnTemperatureData, props.times),
      type: "line",
      animation: false,
      color: heatMeterChartColors.returnTemperature,
      name: "Return Temperature",
      yAxis: 0,
    },
    {
      data: columnToTimeNumberData(props.flowRateData, props.times),
      type: "line",
      animation: false,
      color: heatMeterChartColors.flowRate,
      name: "Flow Rate",
      yAxis: 1,
    },
    {
      data: columnToTimeNumberData(props.powerData, props.times),
      type: "line",
      animation: false,
      color: heatMeterChartColors.power,
      name: "Power",
      yAxis: 2,
    },
  ];

  const minTemperature = Math.min(
    ...[
      ...removeNulls(columnToNumberData(props.flowTemperatureData)),
      ...removeNulls(columnToNumberData(props.returnTemperatureData)),
      0,
    ],
  );
  const maxTemperature = Math.max(
    ...[
      ...removeNulls(columnToNumberData(props.flowTemperatureData)),
      ...removeNulls(columnToNumberData(props.returnTemperatureData)),
      60,
    ],
  );
  const chartEl = useRef<HighchartsReact.RefObject>(null);

  let tooltip: SVGElement | undefined = undefined;
  const ticksAboveZero = Math.ceil(maxTemperature / 20);
  const ticksBelowZero = Math.floor(minTemperature / 20);
  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      min: props.params.startDatetime?.valueOf(),
      max: props.params.endDatetime?.valueOf(),
      plotBands: props.errorBands?.flatMap((v) => {
        return {
          from: v.start.valueOf(),
          to: v.end.valueOf(),
          color: "lightgrey",
          events: {
            mouseout: () => {
              if (tooltip) {
                tooltip.destroy();
                tooltip = undefined;
              }
            },
            mouseover: (e: Event | Dictionary<any> | undefined) => {
              if (e && chartEl.current) {
                const chart = chartEl.current.chart;

                const [x, y] = [
                  (e as MouseEvent).offsetX + 20,
                  (e as MouseEvent).offsetY + 20,
                ];

                tooltip = chart.renderer
                  .label(
                    `<div style='border: 1px solid red'><p class='py-0 my-0' style='font-size: 10px'>Value Errors Removed</p><p class='py-0 my-0' style='font-size: 12px'>${Object.keys(
                      v.errors,
                    ).join(",")}</p> </div>`,
                    x,
                    y,
                    "callout",
                    undefined,
                    undefined,
                    true,
                  )
                  .css({
                    color: "#333333",
                    width: 200,
                  })
                  .attr({
                    fill: "rgba(247, 247, 247, 0.85)",
                    padding: 0,
                    zIndex: 20,
                    r: 5,
                  })
                  .add();
              }
            },
          },
        };
      }),
    },
    series: series,
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: props.loading ? "Loading Data" : "No data for this time period",
    },
    tooltip: {
      shared: true,
    },
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: "{value}°C",
        },
        title: {
          text: "Temperature",
        },
        min: ticksBelowZero * 20,
        max: ticksAboveZero * 20,
        tickAmount: ticksAboveZero + ticksBelowZero + 1,
      },
      {
        // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: "Flow Rate",
        },
        labels: {
          format: "{value}m3/hr",
        },
        min: Math.min(
          ...[...removeNulls(columnToNumberData(props.flowRateData)), 0],
        ),
        max: Math.max(
          ...[...removeNulls(columnToNumberData(props.flowRateData)), 1],
        ),
        opposite: true,
      },
      {
        // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: "Power",
        },
        labels: {
          format: "{value}W",
        },
        min: Math.min(
          ...[...removeNulls(columnToNumberData(props.powerData)), 10000],
        ),
        max: Math.max(
          ...[...removeNulls(columnToNumberData(props.powerData)), 1],
        ),
        opposite: true,
      },
    ],
    noData: {
      style: {
        fontWeight: "normal",
        fontSize: "15px",
        color: "black",
      },
    },
    exporting: {
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadSVG",
          ],
        },
      },
    },
  };

  return (
    <Col>
      <BlockSpinner loading={props.loading}>
        <ErrorOverlappingBanner error={props.error}>
          <Card title={"Overview"} subtitle={"Flow Diagnostics"}>
            <div className="chart">
              <HighchartsReact
                allowChartUpdate={true}
                highcharts={Highcharts}
                options={options}
                ref={chartEl}
              />
            </div>
          </Card>
        </ErrorOverlappingBanner>
      </BlockSpinner>
    </Col>
  );
};

export default HeatMeterFlowAndTemperatureChart;
