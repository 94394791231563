import type React from "react";

import "./PrivacyMode.css";
import { usePrivacyMode } from "../../reducers/privacy";

const PrivacyMode = (props: {
  children?: string | string[];
  usePrivacyMode?: boolean;
}): React.ReactElement => {
  const privacyMode = usePrivacyMode() && (props.usePrivacyMode || true);
  return (
    <span className={privacyMode ? "blurry-text" : ""}>{props.children}</span>
  );
};

export default PrivacyMode;
