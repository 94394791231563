import { faFolderClosed } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import UpdateButton from "../../../components/Buttons/UpdateButton";
import type { WirelessSurveyDataCollectorOut } from "../../../openapi/model/wirelessSurveyDataCollectorOut";

function WirelessSurveyDataCollectorCloseButton(props: {
  dataCollector: WirelessSurveyDataCollectorOut;
  postUpdate?: () => any;
}) {
  const { dataCollector } = props;
  return (
    <UpdateButton
      entityType={"WIRELESS_SURVEY_DATA_COLLECTOR"}
      entityId={props.dataCollector.dataCollectorId}
      updatedEntity={{
        ...dataCollector,
        closed: true,
      }}
      path={`wireless_survey/${dataCollector.surveyId}/data_collector/${dataCollector.dataCollectorId}`}
      color={"amber"}
      text={"Close"}
      icon={faFolderClosed}
      postUpdate={props.postUpdate}
    />
  );
}

export default WirelessSurveyDataCollectorCloseButton;
