import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type React from "react";
import { Button } from "../../components/Theme/button";
import type { AssetPosition } from "../../openapi/model/assetPosition";
import NoteModal from "./NoteModal";

interface Props {
  assetPosition: AssetPosition;
  onCreate?: () => void;
}

export default function CreateNoteModalButton(
  props: Props,
): React.ReactElement {
  return (
    <>
      <NoteModal
        onCreate={props.onCreate}
        assetPosition={props.assetPosition}
        creating={true}
      >
        {({ openModal, isOpen }) => (
          <Button onClick={openModal} disabled={isOpen} color={"brandLight"}>
            <FontAwesomeIcon icon={faPen} className={"px-0 mx-2"} fixedWidth />
            <span>Add Note</span>
          </Button>
        )}
      </NoteModal>
    </>
  );
}
