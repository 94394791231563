import React from "react";
import RequireScheme from "../../components/Scheme/RequireScheme";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import { useSelectedScheme } from "../../reducers/scheme";
import AccountList from "./AccountList";

function AccountListPage() {
  const scheme = useSelectedScheme();

  return (
    <OneCardPage
      headerTitle={"Account list"}
      setPageTitle={schemeObjectPageTitle("Accounts")}
    >
      <RequireScheme>
        {scheme && (
          <AccountList
            companyId={scheme.companyId}
            schemeId={scheme.schemeId}
          />
        )}
      </RequireScheme>
    </OneCardPage>
  );
}

export default AccountListPage;
