import * as Yup from "yup";

export const yupPassword = (length = 8) => {
  return Yup.string()
    .required("Required")
    .min(length, `Password is too short - should be ${length} chars minimum.`)
    .matches(
      /[a-zA-Z0-9]/,
      "Password can only letters a-z, A-Z or numbers 0-9.",
    );
};
export const yupMatchPassword = (
  passwordFieldName = "password",
): Yup.StringSchema => {
  return Yup.string()
    .required("Confirmation of your new password is required")
    .oneOf([Yup.ref(passwordFieldName), null], "Must match your new password");
};

export const yupString = Yup.string();
export const yupRequiredString = Yup.string().required("Required");
