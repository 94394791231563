import type { FormikProps } from "formik";
import type React from "react";
import AssetPositionAssetLinkDateSelect from "./AssetPositionAssetLinkDateSelect";

import type { EditableAssetPositionAssetLinkOut } from "./model";

function AssetPositionAssetLinkForm(
  props: FormikProps<EditableAssetPositionAssetLinkOut>,
): React.ReactElement {
  return (
    <>
      <AssetPositionAssetLinkDateSelect
        serialNumber={props.values.serialNumber}
        customerReference={props.values.assetPositionCustomerReference}
      />
    </>
  );
}

export default AssetPositionAssetLinkForm;
