import useUserHasRoles from "hooks/user/getCurrentUserRole";
import type React from "react";
import * as Yup from "yup";
import NewTextFormComponent from "../../../../../components/Forms/NewTextFormComponent";
import { SelectComboField } from "../../../../../components/Forms/SelectCombo/SelectCombo";

export const WirelessReceiverConfigurationFormSchema = Yup.object().shape({
  ageLimit: Yup.number().min(1).max(9999),
  mbusBaudRate: Yup.number(),
});

export default function WirelessReceiverConfigurationForm({
  namespace,
}: {
  namespace: string;
}): React.ReactElement {
  const allowEditing = useUserHasRoles("admin");

  const withNamespace = (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  };

  return (
    <>
      <h4>Wireless Receiver Configuration</h4>
      <SelectComboField
        label={{ label: "Baud Rate" }}
        options={[
          { label: 2400, value: 2400 },
          { label: 9600, value: 9600 },
        ]}
        fieldName={withNamespace("mbusBaudRate")}
        disabled={!allowEditing}
      />
      <NewTextFormComponent
        fieldName={withNamespace("ageLimit")}
        label={{ label: "Data Age Limit (minutes)" }}
        type={"number"}
        disabled={!allowEditing}
      />
    </>
  );
}
