import { startCase } from "lodash-es";
import type React from "react";
import type { AssetPosition } from "../../openapi/model/assetPosition";
import { AssetUseCase } from "../../openapi/model/assetUseCase";
import { Badge } from "../Theme/badge";

export default function AssetUseCaseHumanLabel(props: {
  asset: AssetPosition;
  asBadge?: boolean;
}): React.ReactElement {
  const { asset, asBadge = true } = props;
  const useCase: AssetUseCase = asset.useCase || AssetUseCase.NONE;

  if (asBadge) {
    return <Badge>{useCase}</Badge>;
  } else {
    return <>{startCase(useCase.toLowerCase())}</>;
  }
}
