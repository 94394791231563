import classNames from "classnames";
import { useField } from "formik";
import React from "react";
import ApiPaginatedTable from "../../../components/Pagination/NewApiPaginatedTable";
import { Button } from "../../../components/Theme/button";
import {
  TableCell,
  TableHeader,
  TableRow,
} from "../../../components/Theme/table";
import useGetList from "../../../hooks/getList";
import type { Asset } from "../../../openapi/model/asset";
import type { AssetType } from "../../../openapi/model/assetType";

function AssetSelectFormField(props: {
  name: string;
  assetType: AssetType;
  companyId: string;
  unassignedOnly?: boolean;
}) {
  const [field, _meta, { setValue }] = useField(props.name);

  const { unassignedOnly = true } = props;

  const [{ data, loading, error }] = useGetList("ASSET", {
    companyId: props.companyId,
    assetType: props.assetType,
    assetPositionId: unassignedOnly ? "NONE" : undefined,
  });

  function onClick(assetId: string) {
    setValue(assetId);
  }
  return (
    <ApiPaginatedTable
      loading={loading}
      error={error}
      cursor={data?.cursor}
      data={data}
      header={[
        <TableRow key={1}>
          <TableHeader>Serial Number</TableHeader>
          <TableHeader>Manufacturer</TableHeader>
        </TableRow>,
        2,
      ]}
      row={(asset: Asset, _, key) => (
        <TableRow
          key={key}
          className={classNames({
            "table-info": field.value === asset.assetId,
          })}
        >
          <TableCell>{asset.serialNumber}</TableCell>
          <TableCell>{asset.manufacturer}</TableCell>
          <TableCell>
            <Button color={"brandLight"} onClick={() => onClick(asset.assetId)}>
              Select
            </Button>
          </TableCell>
        </TableRow>
      )}
    />
  );
}

export default AssetSelectFormField;
