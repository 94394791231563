import { isNil } from "lodash-es";
import type React from "react";
import type { LatestTimeSeriesReportProps } from "../../containers/report/latestDataReport/TimeSeriesStatsApiWrapper";
import type { Company } from "../../openapi/model/company";
import { GroupBy } from "../../openapi/model/groupBy";
import type { LocationGroup } from "../../openapi/model/locationGroup";
import type { Scheme } from "../../openapi/model/scheme";
import { useSelectedCompany } from "../../reducers/company";
import { usePrivacyMode } from "../../reducers/privacy";
import {
  useLocationGroups,
  useSchemes,
  useSelectedScheme,
} from "../../reducers/scheme";
import BlockSpinner from "../Spinners/BlockSpinner";
import { Heading } from "../Theme/heading";
import {
  aggregatedStatsCountToPercentage,
  aggregatedStatsSelector,
  filterGroupList,
  mapLocationGroupData,
  mapSchemesData,
} from "../TimeSeries/model";
import { getUrlHash } from "../navigation/URL";
import SimpleLineChart, { type DataPoint } from "./SimpleLineChart";

interface AggregatedStatsChartDefinitionProps {
  groupBy?: GroupBy;
  clickUrl?: string;
  showCountAsPercentage?: boolean;
  seriesName: string;
  units: string;
  groupFilter?: string;
}

function getData(
  groupBy: GroupBy,
  companyId: string,
  schemeId?: string,
  data?: any,
  schemes?: Scheme[],
  locationGroups?: LocationGroup[],
  statsCount = false,
  groupFilter?: string,
): any {
  if (isNil(data)) {
    return [[], undefined];
  }
  switch (groupBy) {
    case "GROUP_ALL":
      return schemeId
        ? [
            undefined,
            !isNil(locationGroups)
              ? mapLocationGroupData(
                  data,
                  filterGroupList(
                    locationGroups as LocationGroup[],
                    groupFilter,
                  ),
                  (stats, groupId) =>
                    statsCount
                      ? (aggregatedStatsCountToPercentage(stats)(
                          groupId,
                        ) as DataPoint[])
                      : aggregatedStatsSelector("mean")(
                          stats,
                          groupId as string,
                        ),
                )
              : [],
          ]
        : [
            aggregatedStatsSelector("mean")(data, schemeId as string),
            undefined,
          ];
    case "SCHEME":
      return schemeId || isNil(schemes)
        ? [[], undefined]
        : [
            undefined,
            mapSchemesData(data, schemes, (data, schemeId) =>
              statsCount
                ? aggregatedStatsCountToPercentage(data)(schemeId)
                : aggregatedStatsSelector("mean")(data, schemeId),
            ),
          ];

    case "NONE":
      return [
        statsCount
          ? aggregatedStatsCountToPercentage(data)(
              schemeId ? schemeId : companyId,
            )
          : aggregatedStatsSelector("mean")(
              data,
              schemeId ? schemeId : companyId,
            ),
        undefined,
      ];
  }
}

export default function AggregatedStatsChart(
  props: LatestTimeSeriesReportProps & AggregatedStatsChartDefinitionProps,
): React.ReactElement {
  const allSchemes = useSchemes() || [];
  const privacyMode = usePrivacyMode();
  const selectedCompany = useSelectedCompany() as Company;
  const locationGroups = useLocationGroups();
  const selectedScheme = useSelectedScheme();

  const graphClickHandler = (e: any, companyId: string): void => {
    const schemeId: string = e.point.series.userOptions.id;
    const url = `${props.clickUrl}#${getUrlHash(
      companyId,
      schemeId,
      privacyMode,
    )}`;
    window.open(url, "_blank");
  };

  const {
    data,
    loading,
    groupBy = GroupBy.NONE,
    showCountAsPercentage = false,
  } = props;

  const [singleData, multipleData] = getData(
    groupBy as GroupBy,
    selectedCompany.companyId,
    selectedScheme?.schemeId,
    data,
    allSchemes,
    locationGroups,
    showCountAsPercentage,
    props.groupFilter,
  );

  return (
    <BlockSpinner loading={loading}>
      {loading && <Heading>LOADING</Heading>}
      <SimpleLineChart
        data={singleData}
        multipleData={multipleData}
        name={props.seriesName}
        unit={props.units}
        displayExport={false}
        yMin={showCountAsPercentage ? 0 : undefined}
        yMax={showCountAsPercentage ? 100 : undefined}
        rounding={1}
        loading={loading}
        privacyModeEnabled={true}
        clickEvent={(e) =>
          graphClickHandler(e, selectedCompany.companyId as string)
        }
      />
    </BlockSpinner>
  );
}
