import type { Asset } from "openapi/model/asset";
import type React from "react";
import type { GatewayState } from "../../../openapi/model/gatewayState";
import type { InputOutputModuleState } from "../../../openapi/model/inputOutputModuleState";
import GatewayStateLabel from "./gateway/GatewayStateLabel";
import RelayStateLabel from "./input_output_module/RelayStateLabel";

const AssetStateLabel = (
  props: {
    asset?: Asset;
    size?: "sm" | "md" | "lg";
  } & React.HTMLAttributes<HTMLSpanElement>,
): React.ReactElement => {
  const { asset, ...rest } = props;
  const relay = asset?.assetType === "input_output_module";
  const gateway = asset?.assetType === "gateway";
  const receiver = asset?.assetType === "wireless_receiver";

  if (asset?.state) {
    if (relay) {
      return (
        <RelayStateLabel
          state={(asset.state as InputOutputModuleState).relayState}
          {...rest}
        />
      );
    } else if (gateway || receiver) {
      return (
        <GatewayStateLabel
          state={(asset.state as GatewayState).configurationState}
          {...rest}
        />
      );
    }
  }
  return <></>;
};

export default AssetStateLabel;
