import type { UseAxiosResult } from "axios-hooks";
import RoutedTabGroupInterface from "components/TabGroup/RoutedTabGroupInterface";
import {
  DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
  DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER_ASSET_POSITION_ONLY,
} from "components/Table/AssetRegisterData";
import AggregatedTimeSeriesQueryForm, {
  type AggregatedTimeSeriesQueryFormValues,
  mapTimeSeriesRequestParamsToFormValues,
} from "components/TimeSeries/AggregatedTimeSeriesQueryForm/AggregatedTimeSeriesQueryForm";
import type { NewTimeSeriesRequestParams } from "components/TimeSeries/model";
import CSVExportButton from "components/utils/CSVExport/CSVExportButton";
import TimeSeriesReportGraph from "containers/report/aggregatedTimeSeriesReport/TimeSeriesReportGraph";
import TimeSeriesReportTable from "containers/report/aggregatedTimeSeriesReport/TimeSeriesReportTable";
import { VOLUME_WEIGHTED_AVERAGE_AGGREGATIONS } from "containers/report/vwartReport/VWARTReportView";
import dayjs from "dayjs";
import { camelCase } from "lodash-es";
import type { AssetType } from "openapi/model/assetType";
import { ReportView } from "openapi/model/reportView";
import type React from "react";
import type { TableRow } from "react-data-table-component";
import BetaAlert from "../../../components/Beta/BetaAlert";
import type { AssetRegisterAggregatedTimeSeriesResponse } from "../../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import { ErrorBanner } from "../../Error/ErrorBanner";
import BlockSpinner from "../../Spinners/BlockSpinner";

interface AggregatedTimeSeriesDataReportViewProps {
  onSubmit: (v: AggregatedTimeSeriesQueryFormValues) => Promise<any>;
  request: NewTimeSeriesRequestParams;
  data: UseAxiosResult<AssetRegisterAggregatedTimeSeriesResponse>;
  companyId: string;
  schemeId: string;
}

export interface SingleAggregationTimeAssetData extends TableRow {
  companyId: string;
  companyName: string;
  schemeName: string;
  schemeId: string;
  assetId?: string;
  serialNumber?: string;
  assetType: AssetType;
  assetPositionId: string;
  assetPositionReference?: string;
  locationId: string;
  locationAddress?: string;
  locationCustomerReference?: string;

  data: { [key: string]: number | undefined | string };
}

const mapReportData = (
  data: AssetRegisterAggregatedTimeSeriesResponse,
  parameter: string,
  aggregation: string,
  includeAssetDetails = true,
): Array<SingleAggregationTimeAssetData> => {
  return data.data.map((assetData) => {
    const { aggregations, ...asset } = assetData;
    const assetOnlyDetails = { ...asset };
    assetOnlyDetails.assetId = undefined;
    assetOnlyDetails.serialNumber = undefined;
    assetOnlyDetails.installDate = undefined;
    assetOnlyDetails.uninstallDate = undefined;
    return {
      ...(includeAssetDetails ? asset : assetOnlyDetails),
      data: Object.fromEntries(
        data.times.map((e, i) => {
          const aggregationParameter =
            (aggregations[parameter] ?? {
              [aggregation]: [],
            })[aggregation] ?? [];
          return [dayjs(e).format(), aggregationParameter[i] ?? undefined];
        }),
      ),
    };
  });
};

const csvExport = (
  data?: Array<SingleAggregationTimeAssetData>,
): (() => Promise<any>) => {
  return () =>
    new Promise((resolve, reject) => {
      if (data) {
        resolve(
          data.map((value) => {
            const { data, ...asset } = value;
            return {
              ...asset,
              ...data,
            };
          }),
        );
      } else {
        reject(new Error("No Data"));
      }
    });
};

export default function AggregatedTimeSeriesDataReportView(
  props: AggregatedTimeSeriesDataReportViewProps,
): React.ReactElement {
  const { aggregation, parameter, startDate, endDate } = props.request;
  const beta = VOLUME_WEIGHTED_AVERAGE_AGGREGATIONS.includes(
    aggregation as string,
  );

  const [{ data, loading, error }] = props.data;
  const showAssets = props.request.viewBy !== ReportView.ASSETPOSITION;

  return (
    <BlockSpinner loading={loading}>
      <AggregatedTimeSeriesQueryForm
        onSubmit={props.onSubmit}
        initialValues={mapTimeSeriesRequestParamsToFormValues(props.request)}
        wide={true}
        showErrors={false}
        showAssetUseCase={true}
      />
      <div className={"w-full text-right"}>
        <CSVExportButton
          download={csvExport(
            data
              ? mapReportData(
                  data,
                  camelCase(parameter),
                  camelCase(aggregation),
                  showAssets,
                )
              : undefined,
          )}
          disabled={data === undefined}
          columnOrder={
            showAssets
              ? DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER
              : DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER_ASSET_POSITION_ONLY
          }
          filename={`${props.schemeId}_aggregated_${parameter}_${aggregation}`}
        />
      </div>
      {beta && <BetaAlert title={"Volume Weighted Aggregations"} />}
      {error && <ErrorBanner error={error} />}
      <RoutedTabGroupInterface
        tabs={[
          {
            tabContent: (
              <>
                {data && (
                  <TimeSeriesReportTable
                    reportData={mapReportData(
                      data,
                      camelCase(parameter),
                      camelCase(aggregation),
                    )}
                    times={data.times}
                    startTime={startDate}
                    endTime={endDate}
                    showAsset={showAssets}
                  />
                )}
              </>
            ),
            tabName: "Table",
            tabPath: "table",
          },
          {
            tabContent: (
              <>
                {data && (
                  <TimeSeriesReportGraph
                    times={data.times}
                    data={mapReportData(
                      data,
                      camelCase(parameter),
                      camelCase(aggregation),
                    )}
                    startTime={startDate}
                    endTime={endDate}
                  />
                )}
              </>
            ),
            tabName: "Graph",
            tabPath: "graph",
          },
        ]}
        topLevelPath={"/admin/report/time_series/aggregated"}
      />
    </BlockSpinner>
  );
}
