import { Auth } from "aws-amplify";
import PageSpinner from "components/Spinners/PageSpinner";
import { Form, Formik, type FormikHelpers } from "formik";
import type React from "react";
import { useState } from "react";
import * as Yup from "yup";
import AlertBanner from "../../../components/Banner/AlertBanner";
import { Button } from "../../../components/Theme/button";
import AuthField from "../../../layouts/AuthField";

interface Props {
  handleEmail: (emailAddress: string) => any;
}

interface State {
  success: boolean;
  error: boolean;
  errorMessage: string;
}

interface FormValues {
  emailAddress: string;
}

const ForgottenPasswordForm = (props: Props): React.ReactElement => {
  const [state, setState] = useState<State>({
    success: false,
    error: false,
    errorMessage: "",
  });

  const submit = (
    { emailAddress }: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    Auth.forgotPassword(emailAddress)
      .then(() => {
        resetForm();
        setState({ success: true, error: false, errorMessage: "" });
        props.handleEmail(emailAddress);
      })
      .catch((err) => {
        setState({
          success: false,
          error: true,
          errorMessage: err.message,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    emailAddress: "",
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={Yup.object().shape({
          emailAddress: Yup.string().email().required("Required."),
        })}
        validateOnMount={true}
      >
        {({ isValid, isSubmitting }): React.ReactElement => (
          <Form>
            <PageSpinner spin={isSubmitting}>
              <AuthField
                name={"emailAddress"}
                label={"Enter email address"}
                type={"text"}
              />
              <div className={"flex py-3 justify-end gap-4"}>
                <Button
                  type="submit"
                  color="brandLight"
                  disabled={isSubmitting || !isValid}
                >
                  Reset password
                </Button>
              </div>
            </PageSpinner>
          </Form>
        )}
      </Formik>
      {state.success && (
        <AlertBanner className={"mt-3 bg-green"}>
          A reset token has been sent to your email address.
        </AlertBanner>
      )}
      {state.error && (
        <AlertBanner className={"mt-3 bg-red"}>
          Password reset failed with error: {state.errorMessage}
        </AlertBanner>
      )}
    </>
  );
};

export default ForgottenPasswordForm;
