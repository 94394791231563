import type React from "react";
import { useEffect, useState } from "react";
import { Button } from "../Theme/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "../Theme/dialog";
import { errorToTitleAndText } from "./errorMessages";

interface Props {
  title?: string;
  body?: React.ReactElement;
  error?: Error;
}

export default function ErrorModal(props: Props): React.ReactElement {
  const { title, body = "", error } = props;

  const [open, setOpen] = useState(error !== undefined);

  useEffect(() => {
    if (error !== undefined) {
      setOpen(true);
    }
  }, [error]);

  if (error) {
    const errorMessage = errorToTitleAndText(error);
    return (
      <Dialog open={open} onClose={() => setOpen(!open)}>
        <DialogTitle>
          <div className={"text-red-700"}>
            {title ? title : "Error"}
            {errorMessage.title && <>: {errorMessage.title}</>}
          </div>
        </DialogTitle>
        <DialogBody>
          {body}
          {errorMessage.text}
        </DialogBody>
        <DialogActions>
          <Button color="brandLight" onClick={() => setOpen(false)}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return <></>;
  }
}
