import Navbar from "components/Navbar/Navbar";
import Sidebar from "components/Sidebar/Sidebar";
/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import type React from "react";
import { useState } from "react";
import { CompanyProvider } from "../../reducers/company";
import { PrivacyProvider } from "../../reducers/privacy";
import { SchemeProvider } from "../../reducers/scheme";
import AdminRoutes from "../../routes/adminRoutes";
import CompanyListInit from "./CompanyListInit";
import PrivacyModeInit from "./PrivacyModeInit";
import SchemeGroupListInit from "./SchemeGroupListInit";
import SchemeListInit from "./SchemeListInit";

const Admin = (): React.ReactElement => {
  const sidebarState = useState(false);

  return (
    <CompanyProvider>
      <CompanyListInit>
        <SchemeProvider>
          <PrivacyProvider>
            <SchemeListInit>
              <SchemeGroupListInit>
                <PrivacyModeInit>
                  <div>
                    <Sidebar sidebarState={sidebarState} />
                    <div className="xl:pl-72">
                      <Navbar sidebarState={sidebarState} />
                      <main className="py-4 max-w-screen-2xl mx-auto">
                        <div className="px-4 sm:px-6 lg:px-8">
                          <AdminRoutes />
                        </div>
                      </main>
                    </div>
                  </div>
                </PrivacyModeInit>
              </SchemeGroupListInit>
            </SchemeListInit>
          </PrivacyProvider>
        </SchemeProvider>
      </CompanyListInit>
    </CompanyProvider>
  );
};

export default Admin;
