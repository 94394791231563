import { Menu, MenuButton, MenuItems, MenuSeparator } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import type React from "react";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext, logout } from "../../reducers/auth";
import { CompanyContext } from "../../reducers/company";
import { PrivacyContext } from "../../reducers/privacy";
import { ProfileContext, useProfile } from "../../reducers/profile";
import { AppContext } from "../../reducers/reactStore";
import { SchemeContext } from "../../reducers/scheme";
import DisplayIfUserType from "../utils/DisplayIfUserType/DisplayIfUserType";
import ProfileDropdownMenuItem from "./ProfileDropdownMenuItem";

async function sha256(message: string): Promise<string> {
  const msgBuffer = new TextEncoder().encode(message);

  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
}

function ProfileDropdown(): React.ReactElement {
  const user = useProfile();
  const navigate = useNavigate();

  const { dispatch } = useContext(AppContext);
  const schemeContext = useContext(SchemeContext);
  const companyContext = useContext(CompanyContext);
  const privacyContext = useContext(PrivacyContext);
  const authContext = useContext(AuthContext);
  const profileContext = useContext(ProfileContext);

  const location = useLocation();

  const handleLogout = async (): Promise<void> => {
    dispatch({ type: "CLEAR_REFERER" });
    companyContext.dispatch({ type: "LOGOUT" });
    schemeContext.dispatch({ type: "LOGOUT" });
    privacyContext.dispatch({ type: "LOGOUT" });
    logout(authContext.dispatch).finally(() => {
      authContext.dispatch({ type: "LOGOUT" });
      profileContext.dispatch({ type: "LOGOUT" });
      navigate("/auth/login");
    });
  };

  const switchViewerMode = () => {
    profileContext.dispatch({
      type: "SWITCH_TO_VIEWER_TYPE",
      payload: !profileContext.state.forceViewerMode,
    });
  };

  const switchPrivacyMode = () => {
    if (privacyContext.state.privacyMode) {
      privacyContext.dispatch({ type: "TURN_PRIVACY_MODE_OFF" });
    } else {
      privacyContext.dispatch({ type: "TURN_PRIVACY_MODE_ON" });
    }
  };

  const [hash, setHash] = useState<string | undefined>(undefined);

  useEffect(() => {
    sha256(user?.emailAddress as string).then((data) => {
      setHash(data);
    });
  }, [user]);

  return (
    <Menu as="div" className="relative">
      <MenuButton
        className="-m-1.5 flex items-center p-1.5"
        data-cy={"navbar-profile"}
      >
        <span className="sr-only">Open user menu</span>
        <img
          alt="..."
          src={`https://www.gravatar.com/avatar/${hash}?d=identicon`}
          className="h-8 w-8 rounded-full bg-gray-50"
        />
        <span className="hidden lg:flex lg:items-center">
          <span
            aria-hidden="true"
            className="ml-4 text-sm font-semibold leading-6 text-gray-900"
          >
            {user?.fullname}
          </span>
          <ChevronDownIcon
            aria-hidden="true"
            className="ml-2 h-5 w-5 text-gray-400"
          />
        </span>
      </MenuButton>
      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <ProfileDropdownMenuItem
          name={"Your profile"}
          href={`/admin/profile${location.hash}`}
        />
        <DisplayIfUserType
          userTypes={"superadmin"}
          ignoreViewerModeSwitch={true}
        >
          <ProfileDropdownMenuItem
            name={`Switch to ${profileContext.state.forceViewerMode ? "Admin" : "Viewer"} Mode`}
            action={switchViewerMode}
          />
        </DisplayIfUserType>
        <ProfileDropdownMenuItem
          name={`Switch Privacy Mode ${privacyContext.state.privacyMode ? "off" : "on"}`}
          action={switchPrivacyMode}
        />
        <MenuSeparator
          className={
            "col-span-full mx-3.5 my-1 h-px border-0 bg-zinc-950/5 sm:mx-3 dark:bg-white/10"
          }
        />
        <ProfileDropdownMenuItem
          name={"Sign out"}
          action={handleLogout}
          data-cy={"navbar-logout"}
        />
      </MenuItems>
    </Menu>
  );
}

export default ProfileDropdown;
