import { startCase } from "lodash-es";
import type { Asset } from "../../../openapi/model/asset";
import type { AssetPosition } from "../../../openapi/model/assetPosition";
import type { Location } from "../../../openapi/model/location";

export interface PageDefinition {
  name: string;
  url?: string;
  privacyMode?: boolean;
}

export const assetToBreadcrumb = (asset: Asset): PageDefinition => {
  return {
    name: asset.serialNumber,
    url: `/admin/asset/${asset.assetId}`,
    privacyMode: true,
  };
};

export const assetPositionToBreadcrumb = (
  assetPosition: AssetPosition,
): PageDefinition => {
  const id = assetPosition.customerReference
    ? assetPosition.customerReference
    : `...${assetPosition.assetPositionId.slice(26)}`;

  return {
    name: `${id} (${startCase(assetPosition.assetPositionType)})`,
    url: `/admin/asset_position/${assetPosition.assetPositionId}`,
    privacyMode: true,
  };
};

export const locationToBreadcrumb = (location: Location): PageDefinition => {
  return {
    name: location.address,
    url: `/admin/location/${location.locationId}`,
    privacyMode: true,
  };
};
