import type React from "react";
import AggregationScatterChart from "../../../components/charts/AggregationsScatterChart";
import type { AggregatedReportRequest } from "../../../openapi/model/aggregatedReportRequest";
import { AssetType } from "../../../openapi/model/assetType";
import AggregatedTimeSeriesApiWrapper from "../aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper";
import type { HeatNetworkReportParams } from "../heatNetworkAnalysis/model";
import VWARTReportView, {
  VOLUME_WEIGHTED_AVERAGE_AGGREGATIONS,
} from "./VWARTReportView";

interface Props {
  companyId?: string;
  schemeId?: string;
  query: HeatNetworkReportParams;
}

const VWARTReportContainer = (props: Props): React.ReactElement => {
  const [startDatetime, endDatetime] = [
    props.query.startDate,
    props.query.endDate,
  ];

  const params = (): AggregatedReportRequest => {
    if (props.companyId && props.schemeId) {
      return {
        companyId: props.companyId,
        schemeId: props.schemeId,
        aggregations: [
          ...VOLUME_WEIGHTED_AVERAGE_AGGREGATIONS.map((value) => {
            return {
              parameter: "Return Temperature",
              aggregation: value,
            };
          }),
          { parameter: "Volume", aggregation: "difference" },
        ],
        assetTypes: [AssetType.HeatMeter, AssetType.HeatingAndCoolingMeter],
        startTime: startDatetime.toDate(),
        endTime: endDatetime.toDate(),
        period: "ALL",
        useCase: props.query.useCases,
        group: props.query.group,
      };
    } else {
      throw new Error("Scheme or Company is not selected.");
    }
  };

  return (
    <>
      {props.companyId && props.schemeId && (
        <AggregatedTimeSeriesApiWrapper request={params()}>
          {({ data, loading }) => (
            <>
              <AggregationScatterChart
                data={data}
                name={"VWART"}
                xAggregation={"difference"}
                xParameter={"volume"}
                yAggregation={"volumeWeightedAverage"}
                yParameter={"returnTemperature"}
                loading={loading}
                xLabel={"Volume"}
                xUnit={"m3"}
                yLabel={"VWART"}
                yUnit={"degC"}
                height={"400px"}
                companyId={props.companyId as string}
                schemeId={props.schemeId as string}
                startTime={startDatetime}
                endTime={endDatetime}
              />
              <VWARTReportView
                data={data}
                schemeId={props.schemeId}
                startDatetime={startDatetime}
                endDatetime={endDatetime}
              />
            </>
          )}
        </AggregatedTimeSeriesApiWrapper>
      )}
    </>
  );
};

export default VWARTReportContainer;
