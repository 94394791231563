import { faTag } from "@fortawesome/free-solid-svg-icons";
import CreateButton from "components/Buttons/CreateButton";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import { getFilenameFromHeaders } from "components/utils/FileDownloads";
import { saveAs } from "file-saver";
import type React from "react";

export default function AssetRegisterToAsseTagButton(props: {
  companyId: string;
  schemeId: string;
}): React.ReactElement {
  const { companyId, schemeId } = props;
  return (
    <DisplayIfUserType userTypes={"superadmin"}>
      <CreateButton
        entityType={"ASSET_REGISTER"}
        entity={{ companyId, schemeId }}
        path={"asset_register/labels"}
        text={"Create Asset Tags"}
        size={"md"}
        color={"brandLight"}
        icon={faTag}
        parseToJson={false}
        postCreate={(response, headers) => {
          console.log(response);
          saveAs(response, getFilenameFromHeaders(headers));
        }}
      />
    </DisplayIfUserType>
  );
}
