import { useCallback, useEffect, useState } from "react";
import type { RequireExactlyOne } from "type-fest";
import type { Asset } from "../openapi/model/asset";
import type { AssetPosition } from "../openapi/model/assetPosition";
import type { Location } from "../openapi/model/location";
import { getEntity } from "./getEntity";

export interface AssetRegisterEntityResult {
  loading: boolean;
  assetPosition?: AssetPosition;
  location?: Location;
  asset?: Asset;
  error?: Error | Error[] | null;
}

export type GetAssetRegisterEntitiesProps = RequireExactlyOne<
  {
    companyId: string;
    schemeId?: string;
    assetId?: string;
    assetPositionId?: string;
    locationId?: string;
  },
  "assetPositionId" | "assetId"
>;

const useGetAssetRegisterEntitiesCombination = ({
  assetId,
  assetPositionId,
  companyId,
  schemeId,
}: GetAssetRegisterEntitiesProps): [AssetRegisterEntityResult, () => void] => {
  const [state, setState] = useState<AssetRegisterEntityResult>({
    loading: false,
  });

  const load = useCallback(() => {
    if (assetPositionId) {
      setState({ loading: true });
      getAssetPosition(assetPositionId, companyId, schemeId)
        .then(({ assetPosition, location }) => {
          if (assetPosition.assetId) {
            getAsset(assetPosition.assetId, companyId, schemeId).then(
              (asset) => {
                setState({
                  loading: false,
                  assetPosition,
                  location,
                  asset: asset.data,
                });
              },
            );
          } else {
            setState({ loading: false, assetPosition, location });
          }
        })
        .catch((error) => {
          setState({ loading: false, error });
        });
    } else if (assetId) {
      setState({ loading: true });
      getAsset(assetId, companyId, schemeId)
        .then((asset) => {
          if (asset.data?.assetPositionId) {
            getAssetPosition(
              asset.data.assetPositionId,
              companyId,
              schemeId,
            ).then(({ assetPosition, location }) => {
              setState({
                loading: false,
                assetPosition,
                location,
                asset: asset.data,
              });
            });
          } else {
            setState({
              loading: false,
              asset: asset.data,
            });
          }
        })
        .catch((error) => {
          setState({ loading: false, error });
        });
    }
  }, [assetId, assetPositionId, companyId, schemeId]);

  useEffect(() => {
    load();
  }, [load]);

  return [state, load];
};

const getAssetPosition = (
  assetPositionId: string,
  companyId: string,
  schemeId?: string,
) => {
  return getEntity<AssetPosition>({
    entityType: "ASSET_POSITION",
    entityId: assetPositionId,
    companyId,
    schemeId,
  }).then((assetPosition) => {
    if (assetPosition.data?.locationId) {
      return getLocation(
        assetPosition.data?.locationId,
        companyId,
        schemeId,
      ).then((location) => {
        return {
          location: location.data as Location,
          assetPosition: assetPosition.data as AssetPosition,
        };
      });
    } else {
      throw new Error("Asset Position has no valid location.");
    }
  });
};

const getAsset = (assetId: string, companyId: string, schemeId?: string) => {
  return getEntity<Asset>({
    entityType: "ASSET",
    entityId: assetId,
    companyId,
    schemeId,
  });
};

const getLocation = (
  locationId: string,
  companyId: string,
  schemeId?: string,
) => {
  return getEntity<Location>({
    entityType: "LOCATION",
    entityId: locationId,
    companyId,
    schemeId,
  });
};

export default useGetAssetRegisterEntitiesCombination;
