import RequireScheme from "components/Scheme/RequireScheme";
import SelectedScheme from "components/Scheme/SelectedScheme";
import AggregatedTimeSeriesDataReport from "components/TimeSeries/AggregatedTimeSeriesDataReport/AggregatedTimeSeriesDataReport";
import { OneCardPage } from "components/utils/OneCardPage";
import type React from "react";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";

const SchemeAggregatedTimeSeriesDataReport = (): React.ReactElement => {
  return (
    <OneCardPage
      headerTitle={"Data Explorer"}
      breadcrumbs={[
        { url: "/admin/report/time_series/aggregate", name: "Data Explorer" },
      ]}
      setPageTitle={schemeObjectPageTitle("Data Explorer")}
    >
      <RequireScheme>
        <SelectedScheme>
          {(schemeProps) => {
            return (
              <AggregatedTimeSeriesDataReport
                companyId={schemeProps.companyId}
                schemeId={schemeProps.schemeId as string}
              />
            );
          }}
        </SelectedScheme>
      </RequireScheme>
    </OneCardPage>
  );
};

export default SchemeAggregatedTimeSeriesDataReport;
