import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import type React from "react";
import { useState } from "react";
import { ErrorBanner } from "../../../components/Error/ErrorBanner";
import AutoSubmit from "../../../components/Forms/AutoSubmit";
import { ButtonGroupField } from "../../../components/Forms/ButtonGroup";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/Theme/table";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates";
import useGetEntity from "../../../hooks/getEntity";
import type { DeviceConfigurationMap } from "../../../openapi/model/deviceConfigurationMap";
import { useSelectedCompany } from "../../../reducers/company";
import { useSelectedScheme } from "../../../reducers/scheme";

interface AssetConfigurationPageProps {
  asset_id: string;
}

interface FormFilterValues {
  show: "all" | "set" | "incorrect";
}

export default function AssetConfigurationPage(
  props: AssetConfigurationPageProps,
): React.ReactElement {
  const company = useSelectedCompany();
  const scheme = useSelectedScheme();

  const [filterValues, setFilterValues] = useState<FormFilterValues>({
    show: "all",
  });
  const [{ data, loading, error }] = useGetEntity<DeviceConfigurationMap>({
    entityType: "DETAILED_ASSET_CONFIGURATION",
    entityId: props.asset_id,
    companyId: company?.companyId,
    schemeId: scheme?.schemeId,
    singletonApi: true,
    path: `asset/${props.asset_id}/configuration/detailed`,
  });

  const total = data?.values.length;
  const set = data?.values.filter((v) => v.target !== null).length;
  const incorrect = data?.values.filter(
    (v) => v.target !== null && v.target !== v.current,
  ).length;

  return (
    <BlockSpinner loading={loading}>
      <div>
        <Formik
          initialValues={filterValues}
          onSubmit={(v) => {
            setFilterValues(v);
          }}
          enableReinitialize={true}
        >
          {() => {
            return (
              <>
                <AutoSubmit />
                <Form>
                  <ButtonGroupField
                    fieldName={"show"}
                    label={{}}
                    options={[
                      { label: `All (${total || "-"})`, value: "all" },
                      { label: `Set (${set || "-"})`, value: "set" },
                      {
                        label: `Incorrect (${incorrect || "-"})`,
                        value: "incorrect",
                      },
                    ]}
                  />
                </Form>
              </>
            );
          }}
        </Formik>
        <>
          {error && <ErrorBanner error={error} />}
          {data && (
            <Table dense={true} striped={true}>
              <TableHead>
                <TableRow>
                  <TableHeader>Key {filterValues.show}</TableHeader>
                  <TableHeader>Target</TableHeader>
                  <TableHeader>Current</TableHeader>
                  <TableHeader>Last change</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.values
                  .filter((value) => {
                    if (filterValues.show === "all") {
                      return true;
                    } else {
                      if (filterValues.show === "incorrect") {
                        return (
                          value.target !== null &&
                          value.target !== value.current
                        );
                      } else {
                        return value.target !== null;
                      }
                    }
                  })
                  .map((value) => (
                    <TableRow key={value.key}>
                      <TableCell>{value.key}</TableCell>
                      <TableCell>
                        <span style={{ whiteSpace: "pre-wrap" }}>
                          {value.target || "-"}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ whiteSpace: "pre-wrap" }}>
                          {value.current || "-"}
                        </span>
                        {value.target &&
                          value.current &&
                          (value.target === value.current ? (
                            <FontAwesomeIcon
                              icon={faCheck}
                              className={"px-2 text-success"}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faXmark}
                              className={"px-2 text-danger"}
                            />
                          ))}
                      </TableCell>
                      <TableCell>
                        <span className={"text-nowrap"}>
                          {formatDate(value.lastChanged)}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </>
      </div>
    </BlockSpinner>
  );
}
