import BlockSpinner from "components/Spinners/BlockSpinner";
import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik, type FormikHelpers } from "formik";
import type { JobExecutionInstruction } from "openapi/model/jobExecutionInstruction";
import type React from "react";
import { useState } from "react";
import AlertBanner from "../../components/Banner/AlertBanner";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import SingleDatePicker from "../../components/Forms/SingleDatePicker";
import { Button } from "../../components/Theme/button";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { formatDate } from "../../components/utils/HighlightNullAndOldDates";
import useCreateEntity from "../../hooks/createEntity";
import type { JobDefinitionOut } from "../../openapi/model/jobDefinitionOut";
import { JobStatus } from "../../openapi/model/jobStatus";

interface Props {
  job: JobDefinitionOut;
  refresh: () => void;
}

interface FormValues {
  date: Dayjs;
}

export default function ExecuteJobForm(props: Props): React.ReactElement {
  const { createEntity } =
    useCreateEntity<JobExecutionInstruction>("JOB_EXECUTE");

  const [error, setError] = useState<Error | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);

  const { job } = { ...props };
  const yesterday = dayjs().startOf("day").subtract(1, "second");

  const submit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    setError(undefined);
    setSuccess(undefined);
    const definition: JobExecutionInstruction = {
      companyId: job.companyId,
      jobId: job.jobId,
      endDate: values.date.format("YYYY-MM-DD"),
    };
    createEntity(definition)
      .then(() => {
        setSuccess("Job Scheduled.");
        props.refresh();
      })
      .catch((err) => setError(err))
      .finally(() => setSubmitting(false));
  };

  const initialValues: FormValues = { date: yesterday };

  return (
    <DisplayIfUserType userTypes={"admin"}>
      {job.status === JobStatus.Active ? (
        <Formik initialValues={initialValues} onSubmit={submit}>
          {({ isSubmitting, submitForm }) => (
            <BlockSpinner loading={isSubmitting}>
              <p>
                Job {job.name} ({job.jobId}) can be executed for any date before
                {formatDate(yesterday, "L")}.
              </p>
              <p>
                Select a date to run the report for below and press execute.
              </p>
              <p>Your output report may take a few minutes to be produced.</p>
              <Form>
                <SingleDatePicker dateName={"date"} maxDate={yesterday} />
                <div className={"form-group row float-right"}>
                  <Button
                    type={"submit"}
                    color={"brandLight"}
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    Execute
                  </Button>
                </div>
              </Form>
            </BlockSpinner>
          )}
        </Formik>
      ) : (
        <>
          Job {job.name} ({job.jobId}) is not active and can not be executed.
        </>
      )}
      {success && <AlertBanner className={"bg-success"}>{success}</AlertBanner>}
      {error && <ErrorBanner error={error} />}
    </DisplayIfUserType>
  );
}
