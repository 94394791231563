import * as colors from "tailwindcss/colors";

export const pieChartColors = {
  great: colors.green["700"],
  good: colors.green["500"],
  bad: colors.amber["500"],
  reallyBad: colors.amber["800"],
  reallyReallyBad: colors.red["500"],
  awful: colors.red["800"],
};

export const heatMeterChartColors = {
  flowTemperature: colors.red["500"],
  returnTemperature: colors.blue["500"],
  power: colors.yellow["300"],
  flowRate: colors.green["400"],
};

export const defaultChartColor = "#0c4780"; /* brand dark 800 */
