import { ErrorBanner } from "components/Error/ErrorBanner";
import BlockSpinner from "components/Spinners/BlockSpinner";
import { Form, Formik, type FormikHelpers, type FormikProps } from "formik";
import { isNil } from "lodash-es";
import React from "react";
import * as Yup from "yup";
import AssetUseCaseSelect from "../../../components/Asset/AssetUseCaseSelect";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import { Button } from "../../../components/Theme/button";
import useCreateEntity from "../../../hooks/createEntity";
import { assetPositionSchema } from "../../../model/assetPosition";
import type { AssetPosition } from "../../../openapi/model/assetPosition";
import { AssetType } from "../../../openapi/model/assetType";
import { AssetUseCase } from "../../../openapi/model/assetUseCase";
import type { Location } from "../../../openapi/model/location";
import AssetTypeSelect from "../assets/AssetTypeSelect";

interface Props {
  location: Location;
  postCreateActions?: () => any;
}

interface FormValues {
  assetPositionType: AssetType | "undefined";
  useCase: AssetUseCase;
  customerReference?: string;
  parentAssetPositionId?: string;
}

function InnerAssetPositionCreateForm(
  props: FormikProps<FormValues> & { error?: Error; creating: boolean },
): React.ReactElement {
  const { values, setFieldValue, isValid, isSubmitting, creating, error } =
    props;
  React.useEffect(() => {
    if (
      [
        AssetType.HeatMeter,
        AssetType.CoolingMeter,
        AssetType.HeatingAndCoolingMeter,
      ].indexOf(values.assetPositionType as AssetType) < 0
    ) {
      setFieldValue("useCase", AssetUseCase.NONE);
    }
  }, [values.assetPositionType, setFieldValue]);

  return (
    <BlockSpinner loading={creating}>
      <Form data-cy={"asset-position-create"}>
        <h3 className="text-white mb-0">Add Asset Position</h3>
        <AssetTypeSelect fieldName={"assetPositionType"} includeSelect={true} />
        <AssetUseCaseSelect
          fieldName={"useCase"}
          includeNone={true}
          disabled={
            isNil(values.assetPositionType) ||
            [
              AssetType.HeatMeter,
              AssetType.CoolingMeter,
              AssetType.HeatingAndCoolingMeter,
            ].indexOf(values.assetPositionType as AssetType) === -1
          }
        />
        <NewTextFormComponent
          fieldName={"customerReference"}
          label={{ label: "Customer Reference" }}
        />
        <NewTextFormComponent
          fieldName={"parentAssetPositionId"}
          label={{ label: "Parent Asset Position Id" }}
        />
        <Button
          type={"submit"}
          color={"brandLight"}
          disabled={isSubmitting || !isValid}
        >
          Add Position
        </Button>
        <ErrorBanner error={error} />
      </Form>
    </BlockSpinner>
  );
}

export default function AssetPositionCreateForm(
  props: Props,
): React.ReactElement {
  const { createEntity, creating, error } =
    useCreateEntity<AssetPosition>("ASSET_POSITION");

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    createEntity({
      ...values,
      assetPositionType: values.assetPositionType as AssetType,
      useCase: values.useCase,
      locationId: props.location.locationId,
      schemeId: props.location.schemeId,
      companyId: props.location.companyId,
    })
      .then(() => {
        resetForm();
        if (props.postCreateActions) {
          props.postCreateActions();
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues: FormValues = {
    assetPositionType: "undefined",
    useCase: AssetUseCase.NONE,
    customerReference: "",
    parentAssetPositionId: "",
  };
  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={Yup.object().shape(assetPositionSchema)}
    >
      {(formikProps): React.ReactElement => (
        <InnerAssetPositionCreateForm
          {...formikProps}
          error={error}
          creating={creating}
        />
      )}
    </Formik>
  );
}
