import React from "react";
import {
  SelectComboField,
  type SelectComboFieldProps,
} from "../../Forms/SelectCombo/SelectCombo";

function LabelSelect(props: Omit<SelectComboFieldProps, "options">) {
  return (
    <SelectComboField
      {...props}
      options={[
        { label: "Start", value: "left" },
        { label: "End", value: "right" },
      ]}
    />
  );
}

export default LabelSelect;
