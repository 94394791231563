import type { Scheme } from "openapi/model/scheme";
import React, { createContext, useReducer } from "react";
import type { LocationGroup } from "../openapi/model/locationGroup";

interface SchemeGroupState {
  loading: boolean;
  groups?: Array<LocationGroup>;
  error?: Error;
}

export interface SchemeState {
  loading: boolean;
  schemes?: Array<Scheme>;
  selected?: Scheme;
  error?: Error;
  selectionError?: any;
  stale?: boolean;
  groups: SchemeGroupState;
}

type SchemeActions =
  | "LOADING_SCHEMES_STARTED"
  | "LOADING_SCHEMES_SUCCESS"
  | "LOADING_SCHEMES_ERROR"
  | "LOADING_GROUPS_STARTED"
  | "LOADING_GROUPS_SUCCESS"
  | "LOADING_GROUPS_ERROR"
  | "SELECT_SCHEME"
  | "SELECT_SCHEME_BY_ID"
  | "SELECT_GROUP"
  | "SET_STALE"
  | "LOGOUT";

export interface SchemeAction {
  type: SchemeActions;
  payload?: any;
}

function findSchemeById(schemeId: string, state: SchemeState): Scheme {
  if (state.schemes !== undefined && state.schemes.length > 0) {
    const scheme = state.schemes.filter(
      (scheme) => scheme.schemeId === schemeId,
    );
    if (scheme.length === 1) {
      return scheme[0];
    }
  }
  throw new Error(`Can not find scheme ${schemeId}`);
}

const Reducer = (state: SchemeState, action: SchemeAction): SchemeState => {
  switch (action.type) {
    case "LOADING_SCHEMES_STARTED":
      return { loading: true, groups: { loading: false } };
    case "LOADING_SCHEMES_SUCCESS":
      return {
        loading: false,
        schemes: action.payload,
        stale: false,
        groups: { loading: false },
      };
    case "LOADING_SCHEMES_ERROR":
      return {
        loading: false,
        error: action.payload,
        groups: { loading: false },
      };
    case "LOADING_GROUPS_STARTED":
      return {
        ...state,
        groups: { loading: true },
      };
    case "LOADING_GROUPS_SUCCESS":
      return {
        ...state,
        groups: { loading: false, groups: action.payload },
      };
    case "LOADING_GROUPS_ERROR":
      return {
        ...state,
        groups: { loading: false, error: action.payload },
      };
    case "SELECT_SCHEME":
      return { ...state, selected: action.payload };
    case "SELECT_SCHEME_BY_ID":
      try {
        return {
          ...state,
          selected: findSchemeById(action.payload, state),
          selectionError: undefined,
        };
      } catch (err) {
        return { ...state, selected: undefined, selectionError: err };
      }
    case "SET_STALE":
      return { ...state, stale: true };
    case "LOGOUT":
      return { loading: false, groups: { loading: false } };

    default:
      return state;
  }
};

const SchemeContext = createContext<{
  state: SchemeState;
  dispatch: React.Dispatch<SchemeAction>;
}>({
  state: { loading: false, groups: { loading: false } },
  dispatch: () => null,
});

const SchemeProvider: React.FC<{
  children: React.ReactNode;
  initialState?: SchemeState;
}> = ({
  children,
  initialState = {
    loading: false,
    groups: { loading: false },
  },
}) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  console.log("consider scheme");

  return (
    <SchemeContext.Provider value={{ state, dispatch }}>
      {children}
    </SchemeContext.Provider>
  );
};

function useSelectedScheme(): Scheme | undefined {
  return React.useContext(SchemeContext).state.selected as Scheme;
}

function useSelectedSchemeId(): string | undefined {
  return useSelectedScheme()?.schemeId;
}

function useLocationGroups(): LocationGroup[] | undefined {
  return React.useContext(SchemeContext).state.groups.groups;
}

function useSchemes(): Array<Scheme> | undefined {
  return React.useContext(SchemeContext).state.schemes;
}

export {
  SchemeContext,
  SchemeProvider,
  useSelectedScheme,
  useSelectedSchemeId,
  useSchemes,
  useLocationGroups,
};
