import classNames from "classnames";
import { startCase } from "lodash-es";
import type React from "react";
import type { RequireExactlyOne } from "type-fest";
import type { Asset } from "../../openapi/model/asset";
import type { AssetPosition } from "../../openapi/model/assetPosition";
import type { AssetType } from "../../openapi/model/assetType";
import { Badge } from "../Theme/badge";

export const AssetTypeToHumanString = (assetType: AssetType): string => {
  return startCase(assetType);
};

const AssetTypeHumanLabel = (
  props: RequireExactlyOne<
    {
      asset?: Asset | AssetPosition;
      assetType?: AssetType;
      iconLocation?: "left" | "right";
      className?: string;
    },
    "asset" | "assetType"
  >,
): React.ReactElement => {
  const assetType: AssetType = props.assetType
    ? props.assetType
    : "assetType" in props.asset
      ? props.asset.assetType
      : props.asset.assetPositionType;
  const virtual: boolean | undefined = props.assetType
    ? false
    : "virtualAsset" in props.asset
      ? props.asset.virtualAsset
      : "virtualAssetPosition" in props.asset
        ? props.asset.virtualAssetPosition
        : false;
  const { iconLocation = "right" } = props;
  const icon = virtual && (
    <Badge
      color={"teal"}
      className={classNames({
        "px-2 text-white": true,
        "ml-2": iconLocation === "right",
        "mr-2": iconLocation === "left",
      })}
    >
      virtual
    </Badge>
  );
  return (
    <span className={props.className}>
      {iconLocation === "left" && icon}
      {AssetTypeToHumanString(assetType)}
      {iconLocation === "right" && icon}
    </span>
  );
};

export default AssetTypeHumanLabel;
