import type React from "react";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import SchemeTariffListDisplay from "./SchemeTariffListDisplay";
import SchemeTariffListWrapper from "./SchemeTariffListWrapper";

export default function SchemeTariffHomepage(): React.ReactElement {
  return (
    <OneCardPage
      headerTitle={"Tariff"}
      breadcrumbs={[
        { url: "/admin/tariff", name: "Tariff" },
        { url: "/admin/tariff", name: "Home" },
      ]}
      setPageTitle={schemeObjectPageTitle("Tariffs")}
    >
      <SchemeTariffListWrapper>
        {({ data }) => <SchemeTariffListDisplay data={data} />}
      </SchemeTariffListWrapper>
    </OneCardPage>
  );
}
