import type React from "react";
import { Route, Routes } from "react-router-dom";
import DebugLayout from "../components/navigation/DebugLayout";
import Login from "../containers/login/Login";
import ForgottenPasswordPage from "../containers/profile/ForgottenPasswordPage";

const AuthRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route element={<DebugLayout />}>
        <Route path={"/login"} element={<Login />} />
        <Route path={"/reset-password"} element={<ForgottenPasswordPage />} />
      </Route>
    </Routes>
  );
};

export default AuthRoutes;
