import { useFormikContext } from "formik";
import useUserHasRoles from "hooks/user/getCurrentUserRole";
import { isArray } from "lodash-es";
import type React from "react";
import type { MultiValue, SingleValue } from "react-select";
import * as Yup from "yup";
import NewTextFormComponent from "../../../../../components/Forms/NewTextFormComponent";
import {
  SelectComboField,
  type Value,
} from "../../../../../components/Forms/SelectCombo/SelectCombo";
import { yupRequiredString } from "../../../../../components/Forms/yupValidators";
import { MBusAddressingMode } from "../../../../../openapi/model/mBusAddressingMode";

export const MBusConfigurationFormSchema = Yup.object().shape({
  addressingMode: yupRequiredString,
  primaryAddress: Yup.number().when(
    ["addressingMode"],
    (addressingMode, schema) => {
      console.log(addressingMode);
      console.log(schema);
      console.log(addressingMode === MBusAddressingMode.Secondary);
      return addressingMode === MBusAddressingMode.Secondary
        ? schema.notRequired().nullable(true)
        : schema.min(0).max(255).integer().required();
    },
  ),
  encryptionKey: Yup.string().max(32).nullable(true),
  baudRate: Yup.number().required(),
});

export default function MbusConfigurationForm({
  namespace,
  addressingMode,
}: {
  namespace?: string;
  addressingMode?: MBusAddressingMode;
}): React.ReactElement {
  const allowComplexEditing = useUserHasRoles("superadmin");
  const allowEditing = useUserHasRoles("admin");
  const { setFieldValue } = useFormikContext();
  const primaryAddress = addressingMode === MBusAddressingMode.Primary;

  const withNamespace = (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  };

  return (
    <>
      <h4>MBus Configuration</h4>

      <SelectComboField
        label={{ label: "Addressing Mode" }}
        options={[
          { label: "Primary", value: MBusAddressingMode.Primary },
          { label: "Secondary", value: MBusAddressingMode.Secondary },
        ]}
        fieldName={withNamespace("addressingMode")}
        disabled={!allowComplexEditing}
        onChange={(value: SingleValue<Value> | MultiValue<Value>) => {
          if (isArray(value)) {
            throw new Error("MBus Addressing Mode should not be a list.");
          }
          if ((value as SingleValue<Value>) === MBusAddressingMode.Primary) {
            setFieldValue(withNamespace("primaryAddress"), 0);
          }
          if ((value as SingleValue<Value>) === MBusAddressingMode.Secondary) {
            setFieldValue(withNamespace("primaryAddress"), "");
          }
        }}
      />
      <NewTextFormComponent
        fieldName={withNamespace("primaryAddress")}
        label={{ label: "Primary Address" }}
        type={"number"}
        disabled={!allowComplexEditing || !primaryAddress}
      />
      <NewTextFormComponent
        fieldName={withNamespace("encryptionKey")}
        label={{ label: "Wireless Encryption Key" }}
        disabled={!allowEditing}
      />
      <SelectComboField
        label={{ label: "Baud Rate" }}
        options={[
          { label: 300, value: 300 },
          { label: 2400, value: 2400 },
          { label: 9600, value: 9600 },
        ]}
        fieldName={withNamespace("baudRate")}
        disabled={!allowEditing || !allowComplexEditing}
      />
    </>
  );
}
