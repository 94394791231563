import { Heading } from "components/Theme/heading";
import AssetRegisterList from "pages/assetRegister/AssetRegisterListPage/AssetRegisterList";
import type React from "react";
import RoutedTabGroupInterface from "../../../components/TabGroup/RoutedTabGroupInterface";
import type { Tab } from "../../../components/TabGroup/model";
import {
  assetPositionToBreadcrumb,
  locationToBreadcrumb,
} from "../../../components/navigation/breadcrumbs/Breadcrumbs";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import EventList from "../../../containers/event/EventList";
import NoteList from "../../../containers/notes/NotesList";
import type { AssetRegisterEntitySuccessProps } from "../../../core/action/GetAssetRegisterEntitiesCombination";
import useUpdateEntity from "../../../hooks/updateEntity";
import type { AssetPosition } from "../../../openapi/model/assetPosition";
import { AssetType } from "../../../openapi/model/assetType";
import { Status } from "../../../openapi/model/status";
import IntegrationHomePage from "../integrations/IntegrationHomePage";
import WirelessClusterGatewayAddList from "../wirelessCluster/WirelessClusterGatewayAddList";
import WirelessClusterGatewayList from "../wirelessCluster/WirelessClusterGatewayList";
import WirelessClusterReceiverAssignmentTab from "../wirelessCluster/WirelessClusterReceiverAssignmentTab";
import AssetPositionPreview from "./AssetPositionPreview";
import SimpleAssetRegisterUploadContainer from "./simpleAssetRegisterUpload/SimpleAssetRegisterUploadContainer";
import AssetPositionConfigurationTab from "./tabs/AssetPositionConfigurationTab";
import AssetPositionDeleteTab from "./tabs/AssetPositionDeleteTab";
import AssetPositionAssetHistoryTab from "./tabs/assetPositionAssetHistoryTab";

const notNoneNullOrUndefined = (obj: any): boolean => {
  return obj !== "NONE" && obj !== undefined && obj !== null;
};

const AssetPositionDisplay = ({
  assetPosition,
  location,
  asset,
  loading,
  refresh,
}: AssetRegisterEntitySuccessProps): React.ReactElement => {
  const deleted = assetPosition?.status === Status.DELETED;
  const gateway = assetPosition?.assetPositionType === AssetType.Gateway;
  const wireless_cluster =
    assetPosition?.assetPositionType === AssetType.WirelessCluster;

  if (assetPosition === undefined) {
    throw new Error("Asset Position Not Found");
  }

  const { updateEntity } = useUpdateEntity<AssetPosition>(
    "ASSET_POSITION",
    assetPosition.assetPositionId,
  );

  const deleteAssetPosition = (): Promise<any> => {
    if (assetPosition) {
      return updateEntity({
        ...assetPosition,
        status: Status.DELETED,
      });
    } else {
      throw new Error("Can not delete without an asset position");
    }
  };

  if (assetPosition?.assetPositionType === AssetType.ApiIntegration) {
    return (
      <IntegrationHomePage
        integration={{
          assetPosition,
          location,
          asset,
          loading,
          refresh,
        }}
        deleteAssetPosition={deleteAssetPosition}
      />
    );
  }
  const clusterGatewayTab = {
    tabName: "Cluster Gateways",
    tabPath: "gateways",
    tabContent: <WirelessClusterGatewayList clusterPosition={assetPosition} />,
  };
  const clusterReceiverAssignmentTab = {
    tabName: "Receiver Assignment",
    tabPath: "receiver_assignment",
    tabContent: (
      <WirelessClusterReceiverAssignmentTab clusterPosition={assetPosition} />
    ),
  };
  const clusterAddGatewaysTab = {
    tabName: "Add Cluster Gateways",
    tabPath: "add_gateways",
    tabContent: (
      <WirelessClusterGatewayAddList clusterPosition={assetPosition} />
    ),
    userTypes: "admin",
  };

  const ownedAssetPositionsTab = {
    tabName: "Owned Asset Positions",
    tabPath: "positions",
    tabContent: (
      <>
        <Heading>
          {gateway && "Gateway"}
          {wireless_cluster && "Cluster"} Owned Asset Positions
        </Heading>
        <AssetRegisterList
          parentAssetPositionId={assetPosition.assetPositionId}
          companyId={assetPosition.companyId}
          schemeId={assetPosition.schemeId}
          createAssetTags={false}
        />
      </>
    ),
  };
  const uploadAssetRegisterTab = {
    tabName: "Add Owned Asset Positions",
    tabPath: "add_positions",
    tabContent: (
      <>
        <Heading>Add Simple Asset Register</Heading>
        <SimpleAssetRegisterUploadContainer assetPosition={assetPosition} />
      </>
    ),
    userTypes: "admin",
    disabled: deleted,
  };
  const configuration = {
    tabName: "Configuration",
    tabPath: "configuration",
    tabContent: (
      <AssetPositionConfigurationTab
        assetPosition={assetPosition}
        refresh={refresh}
      />
    ),
    userTypes: "admin",
    disabled: !wireless_cluster && !gateway,
  };

  const assetHistoryTab = {
    tabName: "Asset History",
    tabPath: "link",
    tabContent: (
      <AssetPositionAssetHistoryTab
        assetPosition={assetPosition}
        refresh={refresh}
      />
    ),
    disabled: false,
  };
  const notesTab = {
    tabName: "Notes",
    tabPath: "note",
    tabContent: (
      <>
        <Heading>Notes</Heading>
        <NoteList assetPosition={assetPosition} />
      </>
    ),
    disabled: false,
  };
  const eventsTab = {
    tabName: "Events",
    tabPath: "events",
    tabContent: (
      <>
        <Heading>Asset Position Events</Heading>
        <EventList
          assetPositionId={assetPosition.assetPositionId}
          companyId={assetPosition.companyId}
        />
      </>
    ),
    disabled: false,
  };
  const deleteTab = {
    tabName: "Delete",
    tabPath: "delete",
    tabContent: (
      <AssetPositionDeleteTab
        assetPosition={assetPosition}
        // refresh={loadAssetPosition}
        delete={deleteAssetPosition}
        title={"Asset Position"}
      />
    ),
    userTypes: "admin",
    disabled: false,
  };
  const getTabs = (): Tab[] => {
    switch (assetPosition.assetPositionType) {
      case AssetType.Gateway:
        return assetPosition.assetId
          ? [
              ownedAssetPositionsTab,
              uploadAssetRegisterTab,
              configuration,
              assetHistoryTab,
              notesTab,
              eventsTab,
              deleteTab,
            ]
          : [
              assetHistoryTab,
              ownedAssetPositionsTab,
              uploadAssetRegisterTab,
              configuration,
              notesTab,
              eventsTab,
              deleteTab,
            ];
      case AssetType.WirelessCluster:
        return [
          clusterGatewayTab,
          clusterAddGatewaysTab,
          clusterReceiverAssignmentTab,
          ownedAssetPositionsTab,
          uploadAssetRegisterTab,
          configuration,
          notesTab,
          eventsTab,
          deleteTab,
        ];
      default:
        return [assetHistoryTab, notesTab, eventsTab, deleteTab];
    }
  };

  const niceRef =
    assetPosition.customerReference ||
    `${assetPosition.assetPositionId.slice(0, 8)}...`;

  return (
    <OneCardPage
      headerTitle={
        wireless_cluster ? "Wireless Cluster Homepage" : "Asset Position Detail"
      }
      breadcrumbs={
        assetPosition &&
        location && [
          locationToBreadcrumb(location),
          assetPositionToBreadcrumb(assetPosition),
        ]
      }
      loading={loading}
      setPageTitle={() => {
        return wireless_cluster
          ? `Wireless Cluster: ${niceRef}`
          : `Asset Position: ${niceRef}`;
      }}
    >
      {assetPosition && location && (
        <>
          <AssetPositionPreview
            assetPosition={assetPosition}
            asset={asset}
            location={location}
            refresh={refresh}
          />
          <RoutedTabGroupInterface
            tabs={getTabs()}
            topLevelPath={`/admin/asset_register/asset_position/${assetPosition.assetPositionId}`}
          />
        </>
      )}
    </OneCardPage>
  );
};

export default AssetPositionDisplay;
