import Card from "components/Theme/card";
import React from "react";
import { Heading } from "../../../components/Theme/heading";
import ChangePasswordForm from "./ChangePasswordForm";

export default class AuthenticationCard extends React.Component {
  render(): React.ReactElement {
    return (
      <Card title={"Authentication"} className={"my-4"}>
        <Heading>Password</Heading>
        <p>
          After a successful password update, you will be redirected to the
          login page where you can log in with your new password.
        </p>
        <ChangePasswordForm />
      </Card>
    );
  }
}
