import type { AxiosError } from "axios";
import { Table, TableBody, TableCell, TableRow } from "components/Theme/table";
import type { PaginatedData } from "hooks/getList";
import { range } from "lodash-es";
import type React from "react";
import { useEffect } from "react";
import type { Cursor } from "../../openapi/model/cursor";
import AlertBanner from "../Banner/AlertBanner";
import BlockSpinner from "../Spinners/BlockSpinner";
import ApiPagination from "./ApiPagination";

export default function ApiPaginatedTable(props: {
  cursor?: Cursor;
  error?: Error | null;
  data?: PaginatedData<any>;
  loading?: boolean;
  refresh?: () => any;
  updateItem?: (item: any, index: number) => void;
  header: [React.ReactNode, number];
  row: (
    item: any,
    update: (updatedItem: any) => void,
    key: number,
  ) => React.ReactElement<{ key: string }>;
  "data-cy"?: string; // optional cypress label for the table
  tableClassName?: string;
  noDataRow?: React.ReactElement;
  showPagination?: boolean;
  additionalFunctionButtons?: React.ReactElement;
  lastChange?: Date;
}): React.ReactElement {
  const { refresh, lastChange } = props;

  // biome-ignore lint/correctness/useExhaustiveDependencies(lastChange): changing lastChange is the refresh trigger
  useEffect(() => {
    refresh?.();
  }, [refresh, lastChange]);
  const errorRow = (
    message: string,
    code?: number,
    tableWidth = 1,
  ): React.ReactElement => {
    const color = !code || code >= 500 ? "bg-red-700" : "bg-amber-700";
    return (
      <TableBody key={"list-error"}>
        <TableRow>
          <TableCell colSpan={tableWidth} data-cy={"list-error"}>
            <AlertBanner className={color}>
              <h3 style={{ color: "black" }}>An error has occurred.</h3>
              <p style={{ color: "black" }}>
                The sever has responded: {message} {code && <>({code})</>}.
              </p>
            </AlertBanner>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const displayError = (
    error: Error | AxiosError,
    tableWidth = 1,
  ): React.ReactElement => {
    if (error && Object.prototype.hasOwnProperty.call(error, "isAxiosError")) {
      const err = error as AxiosError;
      const message = err.message;
      return errorRow(message, err.response?.status, tableWidth);
    } else {
      return errorRow(error.message, undefined, tableWidth);
    }
  };

  const requestedSize = (props.cursor as Cursor)?.maxItems || 10;
  const error = props.error;
  const [header, tableWidth] = props.header;

  const missingElements = Math.max(
    requestedSize - (props.data?.data.length || 0),
    0,
  );

  return (
    <>
      <BlockSpinner loading={props.loading || false} scrollX={true}>
        <Table
          className={`align-items-center table-flush ${props.tableClassName}`}
          data-cy={props["data-cy"]}
        >
          {header}
          {props.data && (
            <TableBody>
              {props.data?.data.map((value, index) =>
                props.row(
                  value,
                  (updateItem) => props.updateItem?.(updateItem, index),
                  index,
                ),
              )}
            </TableBody>
          )}
          {props.loading &&
            range(missingElements).map((i) => (
              <TableBody key={i}>
                <TableRow>
                  <TableCell colSpan={tableWidth}>&nbsp;</TableCell>
                </TableRow>
              </TableBody>
            ))}
          {!props.loading && props.data?.data.length === 0 && !error && (
            <TableBody key={"no-data"}>
              {props.noDataRow || (
                <TableRow>
                  <TableCell colSpan={tableWidth} data-cy={"no-data"}>
                    No data found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
          {!props.loading && error && displayError(error, tableWidth)}
        </Table>
      </BlockSpinner>

      {(props.showPagination === undefined || props.showPagination) &&
        props.data && (
          <ApiPagination
            cursor={props.data.cursor}
            newCursor={props.data.setCursor}
            refresh={props.refresh}
            // prevCursor={this.props.state.list.prevCursor}
            // newCursor={this.debouncedGetPage}
          />
        )}
      {props.additionalFunctionButtons}
    </>
  );
}
