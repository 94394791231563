import type React from "react";

const HighchartsPrivacyModeFilter = (): React.ReactElement => {
  return (
    <svg style={{ height: 0 }}>
      <title>Blur</title>
      <defs>
        <filter id="blur">
          <feGaussianBlur in="SourceGraphic" stdDeviation={2.5} />
        </filter>
      </defs>
    </svg>
  );
};

export default HighchartsPrivacyModeFilter;
