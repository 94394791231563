import React from "react";
import { userHasTypeForCompany } from "../../model/user";
import type { Company } from "../../openapi/model/company";
import type { CompanyType } from "../../openapi/model/companyType";
import type { User } from "../../openapi/model/user";
import { useSelectedCompany } from "../../reducers/company";
import { ProfileContext, useProfile } from "../../reducers/profile";

export default function useUserHasRoles(
  userTypes: string | string[],
  companyType?: CompanyType[],
  ignoreForceViewerMode = false,
): boolean {
  const user = useProfile();
  const company = useSelectedCompany();
  const forceViewerMode =
    React.useContext(ProfileContext).state.forceViewerMode;

  return userHasRole(
    user,
    company,
    userTypes,
    forceViewerMode && !ignoreForceViewerMode,
    companyType,
  );
}

export function userHasRole(
  user: User | undefined,
  company: Company | undefined,
  userTypes: string | string[],
  forceViewerMode = false,
  companyType?: CompanyType[],
): boolean {
  if (user) {
    if (user.userType === "superadmin") {
      if (forceViewerMode) {
        return userTypes.includes("viewer");
      } else {
        return true;
      }
    } else if (
      company &&
      (!companyType ||
        (company && companyType.includes(company.companyType))) &&
      userHasTypeForCompany(user, company.companyId, userTypes)
    ) {
      return true;
    }
  }
  return false;
}
