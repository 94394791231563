import type React from "react";
import GetListPaginatedList from "../../components/Pagination/GetListPaginatedList";
import {
  StackedListHeadingContent,
  StackedListTwoColumnItem,
} from "../../components/Theme/stacked-list";
import useUserHasRoles from "../../hooks/user/getCurrentUserRole";
import { AlarmTypes } from "../../openapi/model/alarmTypes";
import type { AssetAlarmEvent } from "../../openapi/model/assetAlarmEvent";
import type { Event } from "../../openapi/model/event";
import AlarmWidgetRow from "./AlarmWidgetRow";

interface Props {
  companyId: string;
  schemeId?: string;
  assetId?: string;
  assetPositionId?: string;
  locationId?: string;
  openAlarmsOnly?: boolean;
  list?: boolean;
  lastUpdated?: string;
  row?: (value: AssetAlarmEvent, index: number) => React.ReactElement;
  showPagination?: boolean;
  maxDisplay?: number;
  noDataRow?: React.ReactElement;
  showHeader?: boolean;
  dense?: boolean;
}

export default function AlarmList(props: Props): React.ReactElement {
  const { showHeader = true } = props;

  const filterAlarms = !useUserHasRoles("superadmin", [], false);

  const row = props.row
    ? props.row
    : (value: Event, index: number): React.ReactElement =>
        AlarmWidgetRow(value, index, props.dense);
  return (
    <GetListPaginatedList
      row={row}
      entityType={"ALARM"}
      params={{
        companyId: props.companyId,
        schemeId: props.schemeId,
        assetId: props.assetId,
        assetPositionId: props.assetPositionId,
        locationId: props.locationId,
        openAlarmsOnly: props.openAlarmsOnly || false,
        alarmTypes: filterAlarms ? [AlarmTypes.OFFLINE] : undefined,
      }}
      header={
        showHeader ? (
          <StackedListTwoColumnItem
            column1={<StackedListHeadingContent title={""} />}
            column2={
              <StackedListHeadingContent
                title={"Age"}
                className={"text-right"}
              />
            }
            column1Size={"w-5/6"}
            column2Size={"w-1/6"}
          />
        ) : (
          <></>
        )
      }
      cursor={{ maxItems: 100, direction: "desc" }}
      tableClassName={"list-group-flush"}
      showPagination={props.showPagination}
      noDataRow={props.noDataRow}
    />
  );
}
