import type React from "react";

import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import { isNil, snakeCase } from "lodash-es";
import { Link } from "react-router-dom";
import { TableCell, TableRow } from "../../components/Theme/table";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import UserReference from "../../components/user/UserReference";
import { formatDate } from "../../components/utils/HighlightNullAndOldDates";
import type { Event } from "../../openapi/model/event";
import EventJobCompleteArtifactsDownload from "./EventJobCompleteArtifactsDownload";
import EventMoreDetailCaret from "./EventMoreDetailCaret";
import type { EventListProps } from "./model";

export function findISODatesInString(text: string) {
  const re = RegExp(
    /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((\+(\d{2}):(\d{2})|Z)?)/g,
  );
  const match = [...text.matchAll(re)];
  return match.map((theMatch) => {
    const start = theMatch.index as number;
    return {
      text: theMatch[0],
      start: start,
      end: start + theMatch[0].length,
    };
  });
}

export function findLinkInString(text: string) {
  const re = RegExp(
    /(?<type>Job|Asset|Location|location|Asset Position) `(?<name>[a-zA-Z\d:\s*,-]*)` (\((?<id>[a-z\d-]{36})\)|with id (?<id2>[a-z\d-]{36}))|(?<type2>Asset Position) (?<id3>[a-z\d-]{36})/g,
  );
  const match = [...text.matchAll(re)];
  return match
    .map((theMatch) => {
      const start = theMatch.index as number;
      const type = theMatch[1] || theMatch[6];
      const id = theMatch[4] || theMatch[5] || theMatch[7];
      const name =
        theMatch[2] ||
        (type === "Asset Position"
          ? id && `Asset Position ...${id.slice(28)}`
          : `...${id?.slice(28)}`);
      return {
        type: type,
        id: id,
        name: name,
        text: theMatch[0],
        start: start,
        end: start + theMatch[0].length,
      };
    })
    .filter((v) => !isNil(v.id));
}

export function replaceAndFormatIsoDates(text: string): string {
  const dates = findISODatesInString(text);
  if (dates.length > 0) {
    let str = "";
    let position = 0;
    for (const date of dates) {
      str += text.slice(position, date.start);
      str += dayjs(date.text).format("lll");
      position = date.end;
    }
    return str;
  }
  return text;
}

export function convertMessageToLinkedString(text: string): React.ReactElement {
  const links = findLinkInString(text);

  if (links.length > 0) {
    const components = [];
    let position = 0;
    for (const link of links) {
      components.push(
        replaceAndFormatIsoDates(text.slice(position, link.start)),
      );
      if (link.type === "Job") {
        components.push("Job ");
      }
      components.push(
        <CompanyNavLink
          to={{ pathname: `/admin/${snakeCase(link.type)}/${link.id}` }}
          className={"darker-link"}
        >
          {link.name}
        </CompanyNavLink>,
      );
      position = link.end;
    }
    components.push(replaceAndFormatIsoDates(text.slice(position)));
    return <>{components}</>;
  }
  return <>{text}</>;
}

function getMessageForEvent(value: Event): React.ReactElement {
  try {
    return convertMessageToLinkedString(value.message);
  } catch (err) {
    Sentry.captureException(err, {
      contexts: { event: { id: value.eventId, message: value.message } },
    });
    try {
      return <>{replaceAndFormatIsoDates(value.message)}</>;
    } catch (err) {
      Sentry.captureException(err, {
        contexts: { event: { id: value.eventId, message: value.message } },
      });
      return <>{value.message}</>;
    }
  }
}
export default function EventListRow(props: {
  value: Event;
  key: string;
  eventListProps: EventListProps;
}): React.ReactElement {
  const { value, key } = props;

  return (
    <TableRow key={key}>
      <TableCell className={"align-top"}>
        <span className={"text-nowrap"}>{formatDate(value.eventDateTime)}</span>
      </TableCell>
      <TableCell className={"align-top"}>{value.eventType}</TableCell>
      <TableCell className={"align-top"}>
        <span className={"block text-wrap"}>{getMessageForEvent(value)}</span>
        <EventMoreDetailCaret event={value} />
      </TableCell>
      <TableCell className={"align-top"}>
        {value.userId && (
          <UserReference userId={value.userId} user={value.user} />
        )}
      </TableCell>
      <TableCell className={"align-top"}>
        <EventJobCompleteArtifactsDownload event={value} index={key} />
      </TableCell>
    </TableRow>
  );
}
