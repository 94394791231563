import type React from "react";
import * as Yup from "yup";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import type { SubFormProps } from "../../../components/Forms/WithSubForm";
import { yupRequiredString } from "../../../components/Forms/yupValidators";
import TimeFilterSelect from "../../../components/TimeSeries/TimeFilterSelect";
import type { ElvacoX109ReportConfiguration } from "../../../openapi/model/elvacoX109ReportConfiguration";
import { TimeFilter } from "../../../openapi/model/timeFilter";

export interface X109ConfigurationProps {
  fileName: string;
  configuration: ElvacoX109ReportConfiguration;
}

export const X109ConfigurationSubFormSchema = Yup.object().shape({
  fileName: yupRequiredString,
});

export const X109ConfigurationInitialValues: X109ConfigurationProps = {
  fileName: "{gatewaySerialNumber}_{meterSerialNumber}_{date}_2109.csv",
  configuration: {
    timeFilter: TimeFilter.ALL,
  },
};

export const X110ConfigurationInitialValues: X109ConfigurationProps = {
  fileName: "{gatewaySerialNumber}_{date}_2110.csv",
  configuration: {
    timeFilter: TimeFilter.ALL,
  },
};

const X109ConfigurationForm = ({
  namespace,
  disabled,
}: SubFormProps): React.ReactElement => {
  const withNamespace = (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  };

  return (
    <>
      <h4>X109 Report Configuration</h4>
      <NewTextFormComponent
        fieldName={withNamespace("fileName")}
        label={{ label: "Filename'" }}
        placeholder={
          "Keywords for filename are: gatewaySerialNumber, meterSerialNumber and date"
        }
        disabled={disabled}
      />
      <TimeFilterSelect
        fieldName={withNamespace("configuration.timeFilter")}
        label={{ label: "Time Filter" }}
        disabled={disabled}
      />
    </>
  );
};

export default X109ConfigurationForm;
