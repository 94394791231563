import { ErrorBanner } from "components/Error/ErrorBanner";
import { Formik } from "formik";
import { isNumber } from "lodash-es";
import type React from "react";
import { useState } from "react";
import * as Yup from "yup";
import SuccessBanner from "../../../../../components/Banner/SuccessBanner";
import BlockSpinner from "../../../../../components/Spinners/BlockSpinner";
import { Button } from "../../../../../components/Theme/button";
import useUpdateEntity from "../../../../../hooks/updateEntity";
import type { DeviceAsset } from "../../../../../openapi/model/deviceAsset";
import type { DeviceConfiguration } from "../../../../../openapi/model/deviceConfiguration";
import { objectBlankStringsToUndefined } from "../../../../../utils/object";
import MBusConfigurationSubForm, {
  MBusConfigurationFormSchema,
} from "./MbusConfigurationForm";

export default function DeviceConfigurationTab(props: {
  asset: DeviceAsset;
}): React.ReactElement {
  const configuration: DeviceConfiguration | undefined = props.asset
    .configuration as DeviceConfiguration | undefined;

  const [updated, setUpdated] = useState(false);
  const { updateEntity, updating, error } = useUpdateEntity(
    "ASSET",
    props.asset.assetId,
    () => {
      setUpdated(true);
    },
  );

  const initialValues: DeviceConfiguration = {
    mbus: {
      primaryAddress: isNumber(configuration?.mbus?.primaryAddress)
        ? configuration?.mbus?.primaryAddress
        : undefined,
      addressingMode: configuration?.mbus?.addressingMode || "secondary",
      encryptionKey: configuration?.mbus?.encryptionKey || "",
      baudRate: configuration?.mbus?.baudRate || 2400,
    },
  };

  function submit(values: DeviceConfiguration) {
    setUpdated(false);
    updateEntity({
      ...props.asset,
      configuration: {
        ...values,
        mbus: objectBlankStringsToUndefined(values.mbus),
      },
    });
  }

  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        mbus: MBusConfigurationFormSchema,
      })}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {({ submitForm, isValid, values }): React.ReactElement => {
        return (
          <BlockSpinner loading={updating}>
            <MBusConfigurationSubForm
              addressingMode={values?.mbus?.addressingMode}
              namespace={"mbus"}
            />
            <Button
              type={"submit"}
              color={"brandLight"}
              onClick={submitForm}
              disabled={updating || !isValid}
            >
              Update
            </Button>
            {updated && (
              <SuccessBanner>
                Configuration was updated successfully.
              </SuccessBanner>
            )}
            <ErrorBanner error={error} />
          </BlockSpinner>
        );
      }}
    </Formik>
  );
}
