import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface MoreDetailCaretProps extends React.PropsWithChildren {
  label: string;
}

export default function MoreDetailCaret(
  props: MoreDetailCaretProps,
): React.ReactElement {
  const [display, setDisplay] = React.useState(false);

  const flip = () => {
    setDisplay((v) => !v);
  };

  return (
    <>
      <span onClick={flip} onKeyDown={flip} className={"text-xs"}>
        <FontAwesomeIcon
          icon={display ? faCaretDown : faCaretRight}
          fixedWidth
        />
        {display ? "Hide" : props.label}
      </span>
      {display && props.children}
    </>
  );
}
