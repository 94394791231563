import { type Entities, EntitiesPathMap } from "../constants/entities";

import axios, { type AxiosError } from "axios";
import { useState } from "react";
import Config from "../Config";
import {
  objectKeysToCamelCase,
  objectKeysToSnakeCase,
} from "../actions/helpers/apiService";
import { convertKeys } from "./getList";

interface WholeListResult<T> {
  data?: Array<T>;
  error?: AxiosError;
  loading: boolean;
  execute: (params: Record<string, string | undefined>) => Promise<Array<T>>;
}

export interface GetWholeListParams {
  entityType: Entities;
  params?: Record<string, string | undefined>;
}

export default function useGetWholeList<T>(
  entityType: Entities,
): WholeListResult<T> {
  const execute = (
    params: Record<string, string | undefined>,
  ): Promise<Array<T>> => {
    setState((s) => {
      return { ...s, loading: true };
    });
    return iterate<T>(
      `${Config.apiGateway.URL}/${EntitiesPathMap[entityType]}`,
      params,
      100,
    )
      .then((data) => {
        setState((s) => {
          return { ...s, loading: false, data, error: undefined };
        });
        return data;
      })
      .catch((error) => {
        setState((s) => {
          return { ...s, loading: false, data: undefined, error };
        });
        throw error;
      });
  };

  const [state, setState] = useState<WholeListResult<T>>({
    loading: false,
    execute,
  });

  return state;
}

function iterate<T>(
  path: string,
  params: any,
  pagesLeft: number,
): Promise<Array<T>> {
  if (pagesLeft === 0) {
    throw new Error("Export too large.");
  }
  return axios
    .get(path, {
      params: objectKeysToSnakeCase({ ...params, maxItems: 100 }),
      transformResponse: (data, headers) => {
        return {
          data: convertKeys(JSON.parse(data)),
          cursor:
            headers && objectKeysToCamelCase(JSON.parse(headers["x-cursor"])),
        };
      },
    })
    .then(async (response) => {
      const { cursor, data } = response.data;
      if (cursor?.after) {
        const nextParams = {
          ...params,
          maxItems: cursor.maxItems,
          after: cursor.after,
          direction: cursor.direction,
        };
        const nextData = await iterate(path, nextParams, pagesLeft - 1);
        return [...data, ...nextData];
      } else {
        return data;
      }
    });
}
