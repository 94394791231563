import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { StackedListTwoColumnItem } from "../../../components/Theme/stacked-list";
import type { AllDataReportConfiguration } from "../../../openapi/model/allDataReportConfiguration";
import type { ElvacoX109ReportConfiguration } from "../../../openapi/model/elvacoX109ReportConfiguration";
import { OutputType } from "../../../openapi/model/outputType";
import type { ReportExecutionOut } from "../../../openapi/model/reportExecutionOut";
import ReportExecutionArtifactsDownloadButton from "./ReportExecutionArtifactsDownloadButton";
import ReportExecutionCalculatingBadge from "./ReportExecutionCalculatingBadge";

import advancedFormat from "dayjs/plugin/advancedFormat";
import { lowerCase, startCase } from "lodash-es";

dayjs.extend(advancedFormat);

export default function ReportExecutionListRow(props: {
  execution: ReportExecutionOut;
  refresh: () => void;
}) {
  const { execution, refresh } = props;
  const calculating = execution.status === "CALCULATING";
  const [wasCalculating, _0] = useState<boolean>(calculating);

  useEffect(() => {
    if (calculating) {
      const timer = setTimeout(refresh, 10000);
      return () => clearTimeout(timer);
    }
  }, [calculating, refresh]);

  function description(execution: ReportExecutionOut): string {
    switch (execution.reportFormat.format) {
      case OutputType.ELVACOX109:
        return `Time Filter: ${
          (
            execution.reportFormat
              .configuration as ElvacoX109ReportConfiguration
          ).timeFilter
        }`;
      case OutputType.ELVACOX110:
        return `Time Filter: ${
          (
            execution.reportFormat
              .configuration as ElvacoX109ReportConfiguration
          ).timeFilter
        }`;
      case OutputType.ALLDATA:
        return `Time Filter: ${
          (execution.reportFormat.configuration as AllDataReportConfiguration)
            .timeFilter
        }`;

      default:
        return "";
    }
  }

  const name = startCase(lowerCase(execution.reportFormat.format));
  const dates = `${
    execution.startDate
      ? dayjs(execution.startDate).format("Do MMM YYYY")
      : dayjs(execution.endDate).format("Do MMM YYYY")
  } - ${dayjs(execution.endDate).format("Do MMM YYYY")}`;

  return (
    <StackedListTwoColumnItem
      column1={
        <div className="min-w-0 flex-auto">
          <div className={"inline-block w-60"}>
            {name}
            <ReportExecutionCalculatingBadge
              execution={execution}
              wasCalculating={wasCalculating}
              className={"px-2 mx-2"}
            />
          </div>
          <div className={"inline-block w-60"}>{dates}</div>
          <div className={"inline-block w-48 "}>{description(execution)}</div>
          <div className={"inline-block italic text-gray-600"}>
            Created: {dayjs(execution.createdAt).format("Do MMM YYYY HH:mm")}
          </div>
        </div>
      }
      column2={<ReportExecutionArtifactsDownloadButton execution={execution} />}
      column1Size={"sm:w-11/12"}
      column2Size={"sm:w-1/12"}
    />
  );
}
