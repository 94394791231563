import CompanySelectDropdown from "containers/company/CompanySelectDropdown";
import { Form, Formik, type FormikHelpers } from "formik";
import type React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Button } from "../../../components/Theme/button";
import type { Asset } from "../../../openapi/model/asset";
import type { AssetAction } from "../../../openapi/model/assetAction";
import AssetActionConfirmModal from "../assetActions/AssetActionConfirmModal";

interface FormValues {
  companyId: string;
}

interface Props {
  asset: Asset;
}

const AssetCompanyAssignForm = (props: Props): React.ReactElement => {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState<AssetAction | undefined>(undefined);
  const [complete, setComplete] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const openModal = (): void => {
    setOpen(true);
  };

  const closeModal = (): void => {
    setOpen(false);
  };

  const submit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    setAction({
      actionType: "ASSIGN_TO_COMPANY",
      payload: { companyId: values.companyId },
    });
    openModal();
  };

  const onComplete = (): void => {
    navigate({ pathname: "/admin/asset", hash: location.hash });
  };

  const initialValues = {
    companyId: "",
  };
  return (
    <>
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={Yup.object().shape({
          companyId: Yup.string()
            .required("Required")
            .notOneOf(["undefined", ""]),
        })}
        render={({
          isValid,
          isSubmitting,
          resetForm,
          setSubmitting,
        }): React.ReactElement => (
          <Form data-cy={"assign-asset-form"}>
            <CompanySelectDropdown fieldName={"companyId"} allowNone={true} />
            <Button
              type={"submit"}
              color={"brandLight"}
              disabled={isSubmitting || !isValid}
            >
              Assign
            </Button>
            <AssetActionConfirmModal
              show={open}
              asset={props.asset}
              action={action}
              close={closeModal}
              description={"Assign Asset"}
              onComplete={(): void => {
                resetForm();
                setSubmitting(false);
                setComplete(true);
              }}
              onClose={(): void => {
                setSubmitting(false);
                if (complete) {
                  onComplete();
                }
              }}
            />
          </Form>
        )}
      />
    </>
  );
};

export default AssetCompanyAssignForm;
