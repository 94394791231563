import { faLink } from "@fortawesome/free-solid-svg-icons";
import type React from "react";
import UpdateButton from "../../../components/Buttons/UpdateButton";
import type { AssetPosition } from "../../../openapi/model/assetPosition";

export default function WirelessClusterGatewayAssignButton(props: {
  clusterPosition: AssetPosition;
  gatewayPosition: AssetPosition;
  refresh?: () => void;
}): React.ReactElement {
  const { clusterPosition, gatewayPosition } = props;
  console.log(clusterPosition, gatewayPosition);

  return (
    <UpdateButton
      updatedEntity={{
        ...gatewayPosition,
        parentAssetPositionId: clusterPosition.assetPositionId,
      }}
      entityId={gatewayPosition.assetPositionId}
      entityType={"ASSET_POSITION"}
      text={"Join"}
      icon={faLink}
      color={"brandLight"}
      /* TODO: small button */
      postUpdate={props.refresh}
    />
  );
}
