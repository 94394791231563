import type React from "react";
import type { AssetPosition } from "../../../../openapi/model/assetPosition";

import AssetTypeHumanLabel from "../../../../components/Asset/AssetTypeHumanLabel";
import AssetPositionIdLink from "../../../../components/AssetPosition/AssetPositionIdLink";
import GetListPaginatedTable from "../../../../components/Pagination/GetListPaginatedTable";
import type { AssetRegisterItem } from "../../../../openapi/model/assetRegisterItem";
import type { DeviceConfiguration } from "../../../../openapi/model/deviceConfiguration";

interface Props {
  parentAssetPosition: AssetPosition;
}

const VirtualAssetSummaryList = (props: Props): React.ReactElement => {
  const header = (
    <tr>
      <th className={"text-left"}>Position Id</th>
      <th className={"text-left"}>Reference</th>
      <th className="text-left">Type</th>
      <th className={"text-left"}>Asset Serial Number</th>
      <th className={"text-left"}>Data Field</th>
    </tr>
  );
  const colCount = 5;
  const row = (value: AssetRegisterItem): React.ReactElement => (
    <tr>
      <td className="text-left" data-cy={"assetPositionId"}>
        <AssetPositionIdLink assetPosition={value.assetPosition} />
      </td>
      <td className={"text-left"}>
        {value.assetPosition.customerReference
          ? value.assetPosition.customerReference
          : "-"}
      </td>
      <td className="text-left">
        <AssetTypeHumanLabel asset={value.assetPosition} />
      </td>
      <td className={"text-left"}>
        {value.asset ? value.asset.serialNumber : "-"}
      </td>
      <td className={"text-left"}>
        {value.asset
          ? (value.asset.configuration as DeviceConfiguration).virtual?.field ||
            "-"
          : "-"}
      </td>
    </tr>
  );

  return (
    <GetListPaginatedTable
      entityType={"ASSET_REGISTER"}
      params={{
        companyId: props.parentAssetPosition.companyId,
        schemeId: props.parentAssetPosition.schemeId,
        parentAssetPositionId: props.parentAssetPosition.assetPositionId,
      }}
      cursor={{ maxItems: 25 }}
      header={[header, colCount]}
      row={row}
    />
  );
};

export default VirtualAssetSummaryList;
