import type { NewCompany } from "openapi/model/newCompany";
import type { NewUser } from "openapi/model/newUser";
import type React from "react";
import { useContext } from "react";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import useCreateEntity from "../../hooks/createEntity";
import type { Company } from "../../openapi/model/company";
import type { RegisterCompany } from "../../openapi/model/registerCompany";
import { CompanyContext } from "../../reducers/company";
import CreateCompanyForm from "./CreateCompanyForm";

interface Props {
  onSubmit?: () => any;
}

export default function CreateCompanyContainer(
  props: Props,
): React.ReactElement {
  const { dispatch } = useContext(CompanyContext);
  const registerCompany = useCreateEntity<Company, RegisterCompany>(
    "COMPANY",
    "register",
  );
  const createCompany = useCreateEntity<Company, NewCompany>("COMPANY");

  const submit = (newCompany: NewCompany, newUser?: NewUser): Promise<any> => {
    const p = newUser
      ? registerCompany.createEntity({
          newCompany,
          newUser,
        })
      : createCompany.createEntity(newCompany);
    return p.then(() => {
      dispatch({ type: "SET_STALE" });
      props.onSubmit?.();
    });
  };

  return (
    <>
      <CreateCompanyForm onSubmit={submit} />
      <ErrorBanner error={registerCompany.error || createCompany.error} />
    </>
  );
}
