import getWholeList, { type GetWholeListParams } from "hooks/getWholeList";
import React from "react";
import CSVExportButton from "./CSVExportButton";

export interface ExportFileProps {
  filename?: string;
  columnOrder?: string[];
  sortColumns?: boolean;
  mapFunction?: (object: any) => any;
}

interface Props extends GetWholeListParams, ExportFileProps {}

export default function PaginatedApiToCSVExportButton(props: Props) {
  const { entityType, params, mapFunction, ...rest } = props;
  const { execute } = getWholeList(entityType);
  const download = (): Promise<Array<any>> => {
    return execute(params || {}).then((res: Array<any>) => {
      if (mapFunction) {
        return res.map(mapFunction);
      } else {
        return res;
      }
    });
  };
  return <CSVExportButton {...rest} download={download} />;
}
