import type React from "react";
import type { AssetPosition } from "../../../openapi/model/assetPosition";
import ReceiverAssignmentTable from "./ReceiverAssignmentTable";
import WirelessRFDataApiWrapper from "./WirelessRFDataApiWrapper";

export default function WirelessClusterReceiverAssignmentTab(props: {
  clusterPosition: AssetPosition;
}): React.ReactElement {
  const { clusterPosition } = props;
  return (
    <>
      <WirelessRFDataApiWrapper
        request={{
          companyId: clusterPosition.companyId,
          schemeId: clusterPosition.schemeId,
          period: "ALL",
          parentAssetPositionId: clusterPosition.assetPositionId,
        }}
      >
        {(props) => (
          <ReceiverAssignmentTable
            {...props}
            wirelessCluster={clusterPosition}
          />
        )}
      </WirelessRFDataApiWrapper>
    </>
  );
}
