import type { GatewayAsset } from "../../../../openapi/model/gatewayAsset";

import type React from "react";
import { Heading } from "../../../../components/Theme/heading";
import DisplayIfUserType from "../../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import JobCreateForm from "../../../../containers/jobs/JobCreateForm";
import JobList from "../../../../containers/jobs/JobList";

const GatewayJobTab = (props: {
  gateway: GatewayAsset;
}): React.ReactElement => {
  const gatewayPosition = props.gateway.assetPositionId;

  return (
    <>
      {gatewayPosition ? (
        <>
          <Heading>Scheduled Jobs</Heading>
          <JobList
            assetPositionId={gatewayPosition}
            companyId={props.gateway.companyId}
            schemeId={props.gateway.schemeId}
          />
          <DisplayIfUserType userTypes={"admin"}>
            <Heading>Add New Job</Heading>
            <JobCreateForm
              assetPositionId={gatewayPosition}
              companyId={props.gateway.companyId}
            />
          </DisplayIfUserType>
        </>
      ) : (
        <>
          <Heading>Scheduled Jobs</Heading>
          <p>
            This gateway is not assigned to a position. No jobs are supported.
          </p>
        </>
      )}
    </>
  );
};

export default GatewayJobTab;
