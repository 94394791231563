import type { UseAxiosResult } from "axios-hooks";
import type React from "react";
import useGetData from "../../../hooks/getData";
import type { AggregatedReportRequest } from "../../../openapi/model/aggregatedReportRequest";
import type { AssetRegisterAggregatedTimeSeriesResponse } from "../../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import type { NewTimeSeriesRequestParams } from "../model";

interface AggregatedTimeSeriesDataWrapperProps {
  companyId: string;
  schemeId: string;
  params: NewTimeSeriesRequestParams;
  children: (
    data: UseAxiosResult<AssetRegisterAggregatedTimeSeriesResponse>,
  ) => React.ReactNode;
}

function AggregatedTimeSeriesDataWrapper(
  props: AggregatedTimeSeriesDataWrapperProps,
) {
  const params = (): AggregatedReportRequest => {
    const {
      startDate,
      endDate,
      parameter,
      aggregation,
      period,
      group,
      assetType,
      viewBy,
      useCase,
    } = props.params;
    if (props.companyId && props.schemeId) {
      return {
        companyId: props.companyId,
        schemeId: props.schemeId,
        aggregations: [
          {
            parameter,
            aggregation,
          },
        ],
        startTime: startDate?.toDate(),
        endTime: endDate?.toDate(),
        period,
        group: group === "undefined" ? undefined : group,
        assetTypes: assetType,
        viewBy,
        useCase,
      };
    } else {
      throw new Error("Scheme or Company is not selected.");
    }
  };

  const data = useGetData<AssetRegisterAggregatedTimeSeriesResponse>({
    path: "report/time_series",
    companyId: props.companyId,
    schemeId: props.schemeId,
    params: params(),
    post: true,
    singletonApi: true,
  });

  return <>{props.children(data)}</>;
}

export default AggregatedTimeSeriesDataWrapper;
