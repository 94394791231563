import * as serviceWorker from "./serviceWorker";
import "./instrumentation";
// plugins styles from node_modules
// import "react-perfect-scrollbar/dist/css/styles.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";
// our styles
import "./index.css";
import "@fontsource/roboto";
import "@fontsource/roboto/100-italic.css";
import "@fontsource/roboto/300-italic.css";
import "@fontsource/roboto/400-italic.css";
import "@fontsource/roboto/500-italic.css";
import "@fontsource/roboto/700-italic.css";
import "@fontsource/roboto/900-italic.css";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";

import { Amplify } from "aws-amplify";
import axios, { type AxiosRequestConfig } from "axios";
import Qs from "qs";
import App from "./App";
import config from "./Config";
import { getAuthHeader } from "./actions/helpers/auth";
// plugins styles downloaded
// core styles

serviceWorker.register({});
export const configureAmplify = (): void => {
  const c = {
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,

      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
      authenticationFlowType: "USER_PASSWORD_AUTH",
    },
    API: {
      endpoints: [
        {
          name: "management",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION,
        },
      ],
    },
  };
  Amplify.configure(c);
};
export const requestInterceptor = async (config: AxiosRequestConfig) => {
  const headers = await getAuthHeader();
  return { ...config, headers: { ...config.headers, ...headers } };
};

export const paramsSerializer = (params: any) =>
  Qs.stringify(params, { arrayFormat: "repeat" });
axios.defaults.paramsSerializer = paramsSerializer;
axios.interceptors.request.use(requestInterceptor);

configureAmplify();
App();
