import type React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";
import { Rounding } from "../../../openapi/model/rounding";

const RoundingMethodSelect = (
  props: Omit<SelectComboFieldProps, "options">,
): React.ReactElement => {
  const options: Option[] = [
    { label: "None", value: Rounding.NONE },
    { label: "0 decimal places", value: Rounding.WHOLE },
    { label: "1 decimal place", value: Rounding.ONEDP },
    { label: "2 decimal places", value: Rounding.TWODP },
  ];

  return <SelectComboField {...props} options={options} />;
};

export default RoundingMethodSelect;
