import { startCase } from "lodash-es";
import type React from "react";
import {
  type Option,
  SelectComboField,
} from "../../components/Forms/SelectCombo/SelectCombo";
import { EventTypes } from "../../openapi/model/eventTypes";

export default function EventTypeSelect(props: {
  fieldName: string;
}): React.ReactElement {
  const { fieldName, ...rest } = props;

  const options: Option[] = Object.values(EventTypes).map(
    (v: string): Option => {
      return {
        value: v,
        label: startCase(v),
      };
    },
  );

  return (
    <SelectComboField
      options={options}
      fieldName={fieldName}
      {...rest}
      isMulti
    />
  );
}
