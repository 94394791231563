import { Auth } from "aws-amplify";
import PageSpinner from "components/Spinners/PageSpinner";
import { Form, Formik, type FormikHelpers } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import AlertBanner from "../../../components/Banner/AlertBanner";
import { Button } from "../../../components/Theme/button";
import AuthField from "../../../layouts/AuthField";
import { AuthContext, logout } from "../../../reducers/auth";

interface Props {
  emailAddress: string;
}

interface State {
  success: boolean;
  error: boolean;
  errorMessage: string;
}

interface FormValues {
  resetCode: string;
  newPassword: string;
  newPasswordConfirm: string;
}

const ResetCodeForm = (props: Props): React.ReactElement => {
  const [state, setState] = useState<State>({
    success: false,
    error: false,
    errorMessage: "",
  });

  const dispatch = React.useContext(AuthContext).dispatch;

  const submit = (
    { resetCode, newPassword }: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    Auth.forgotPasswordSubmit(props.emailAddress, resetCode, newPassword)
      .then(() => {
        resetForm();
        setState({ success: true, error: false, errorMessage: "" });
        logout(dispatch);
      })
      .catch((err) => {
        setState({ success: false, error: true, errorMessage: err.message });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    newPassword: "",
    resetCode: "",
    newPasswordConfirm: "",
  };

  return (
    <>
      {state.success ? (
        <AlertBanner className={"mt-3 bg-success"}>
          <p>Password successfully changed.</p>
          <p>
            Continue to <Link to={"/auth/login"}>login.</Link>
          </p>
        </AlertBanner>
      ) : (
        <>
          <AlertBanner className={"mt-3 bg-danger"}>
            A reset code has been sent to your email address. Please enter it
            and your new password below.
          </AlertBanner>

          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={Yup.object().shape({
              resetCode: Yup.string().required("Required."),
              newPassword: Yup.string().required("New Password is required"),
              newPasswordConfirm: Yup.string()
                .required("Confirmation of your new password is required")
                .oneOf(
                  [Yup.ref("newPassword"), null],
                  "Must match your new password",
                ),
            })}
            validateOnMount={true}
          >
            {({ isValid, isSubmitting }): React.ReactElement => (
              <Form>
                <PageSpinner spin={isSubmitting}>
                  <AuthField
                    name={"resetCode"}
                    label={"Enter reset code"}
                    type={"text"}
                  />
                  <AuthField
                    name={"newPassword"}
                    label={"New password"}
                    type={"password"}
                  />
                  <AuthField
                    name={"newPasswordConfirm"}
                    label={"Confirm new password"}
                    type={"password"}
                  />
                  <div className={"flex py-3 justify-end gap-4"}>
                    <Button
                      type="submit"
                      color="brandLight"
                      disabled={isSubmitting || !isValid}
                    >
                      Reset password
                    </Button>
                  </div>
                </PageSpinner>
              </Form>
            )}
          </Formik>
          {state.error && (
            <AlertBanner className={"mt-3 bg-red"}>
              Password change failed with error: {state.errorMessage}
            </AlertBanner>
          )}
        </>
      )}
    </>
  );
};

export default ResetCodeForm;
