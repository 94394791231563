import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GetList from "core/action/GetList";
import type { Cursor } from "openapi/model/cursor";
import type React from "react";
import ErrorOverlappingBanner from "../../components/Error/ErrorOverlappingBanner";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import Card, { FixHeightCardBody } from "../../components/Theme/card";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import useUserHasRoles from "../../hooks/user/getCurrentUserRole";
import { AlarmTypes } from "../../openapi/model/alarmTypes";
import type { AssetAlarmEvent } from "../../openapi/model/assetAlarmEvent";
import AlarmList from "./AlarmList";

const numberOfAlarms = (
  loading: boolean,
  error?: Error | null,
  list?: Array<AssetAlarmEvent>,
  cursor?: Cursor,
): string => {
  if (loading) {
    return "?";
  } else if (error) {
    return "-";
  } else if (cursor?.after) {
    return `${cursor.maxItems}+`;
  } else {
    return `${list?.length}`;
  }
};

const AlarmWidget = (props: {
  companyId: string;
  schemeId?: string;
}): React.ReactElement => {
  const filterAlarms = !useUserHasRoles("superadmin", [], false);
  const params = {
    ...props,
    alarmTypes: filterAlarms ? [AlarmTypes.OFFLINE] : undefined,
  };

  return (
    <GetList entityType={"ALARM"} params={params} cursor={{ maxItems: 25 }}>
      {({ data, error, loading }) => (
        <Card
          title={
            <>
              Open Alarms (
              {numberOfAlarms(loading, error, data?.data, data?.cursor)}){" "}
            </>
          }
          py={"py-2"}
        >
          <FixHeightCardBody height={36}>
            <BlockSpinner loading={false}>
              <ErrorOverlappingBanner>
                <AlarmList
                  companyId={props.companyId}
                  schemeId={props.schemeId}
                  openAlarmsOnly={true}
                  showHeader={false}
                  dense={true}
                  noDataRow={
                    <div className="w-full">
                      <div className={"text-center"}>
                        <FontAwesomeIcon
                          icon={faCheck}
                          className={"text-success my-2"}
                          size={"3x"}
                        />
                        <h4 className={"text-success"}>No open alarms</h4>
                      </div>
                    </div>
                  }
                  showPagination={false}
                  maxDisplay={5}
                />
                {data && data.data.length > 5 && (
                  <CompanyNavLink
                    to={{ pathname: "/admin/alarm", search: "?status=open" }}
                    className={"text-sm"}
                  >
                    More
                  </CompanyNavLink>
                )}
              </ErrorOverlappingBanner>
            </BlockSpinner>
          </FixHeightCardBody>
        </Card>
      )}
    </GetList>
  );
};

export default AlarmWidget;
