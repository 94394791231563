import dayjs from "dayjs";
import React from "react";
import { Period } from "../../../openapi/model/period";
import { ReportView } from "../../../openapi/model/reportView";
import { meterOptions } from "../../Asset/AssetTypeSelectCombo";
import AggregatedTimeSeriesDataReportView from "../AggregatedTimeSeriesDataReportView/AggregatedTImeSeriesDataReportView";
import AggregatedTimeSeriesDataWrapper from "../AggregatedTimeSeriesDataWrapper/AggregatedTimeSeriesDataWrapper";
import { mapFormValuesToTimeSeriesParams } from "../AggregatedTimeSeriesQueryForm/AggregatedTimeSeriesQueryForm";
import TimeSeriesQueryUrlWrapper from "../TimeSeriesQueryUrlWrapper/TimeSeriesQueryUrlWrapper";
import type { AssetType, NewTimeSeriesRequestParams } from "../model";

export interface AggregatedTimeSeriesDataReportProps {
  companyId: string;
  schemeId: string;
}

function AggregatedTimeSeriesDataReport(
  props: AggregatedTimeSeriesDataReportProps,
) {
  return (
    <TimeSeriesQueryUrlWrapper
      defaultValues={{
        assetType: meterOptions as AssetType[],
        parameter: "Energy (Heating)",
        aggregation: "last",
        period: Period.DAILY,
        startDate: dayjs().startOf("day").subtract(14, "days"),
        endDate: dayjs().endOf("day").subtract(1, "days"),
        viewBy: ReportView.ASSETPOSITION,
      }}
    >
      {(
        params: NewTimeSeriesRequestParams,
        onChange: (v: NewTimeSeriesRequestParams) => void,
      ) => (
        <AggregatedTimeSeriesDataWrapper
          companyId={props.companyId}
          schemeId={props.schemeId}
          params={params}
        >
          {(data) => (
            <AggregatedTimeSeriesDataReportView
              companyId={props.companyId}
              schemeId={props.schemeId}
              data={data}
              onSubmit={(v) => {
                onChange(mapFormValuesToTimeSeriesParams(v, false, true, true));
                return Promise.resolve();
              }}
              request={params}
            />
          )}
        </AggregatedTimeSeriesDataWrapper>
      )}
    </TimeSeriesQueryUrlWrapper>
  );
}

export default AggregatedTimeSeriesDataReport;
