import dayjs, { type Dayjs } from "dayjs";
import type React from "react";
import MultipleChart from "../../../components/charts/MultipleChart";

import type { SingleAggregationTimeAssetData } from "../../../components/TimeSeries/model";

const TimeSeriesReportGraph = (props: {
  times: Array<Date>;
  data: Array<SingleAggregationTimeAssetData>;
  startTime: Dayjs;
  endTime: Dayjs;
}): React.ReactElement => {
  return (
    <MultipleChart
      xaxis={props.times.map((d) => dayjs(d))}
      data={props.data}
      startTime={props.startTime}
      endTime={props.endTime}
    />
  );
};

export default TimeSeriesReportGraph;
