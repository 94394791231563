import type React from "react";
import { ButtonGroupField } from "../Forms/ButtonGroup";

const AssetExpectedFrequencySwitch = (props: {
  fieldName: string;
  submit?: any;
  title?: string;
  abbreviate?: boolean;
  includeDeviceNormal?: boolean;
  include1Minute?: boolean;
}): React.ReactElement => {
  const {
    fieldName,
    title = "Expected Data Frequency",
    abbreviate = false,
    includeDeviceNormal = false,
    include1Minute = true,
  } = props;

  const suffix = abbreviate ? "min" : "minute";
  return (
    <ButtonGroupField
      fieldName={fieldName}
      label={{ label: title }}
      options={(include1Minute ? [{ label: `1 ${suffix}`, value: "1" }] : [])
        .concat([
          { label: `5 ${suffix}s`, value: "5" },
          { label: `15 ${suffix}s`, value: "15" },
          { label: `30 ${suffix}s`, value: "30" },
          { label: `60 ${suffix}s`, value: "60" },
          { label: "Twice Daily", value: "720" },
          { label: "Daily", value: "1440" },
        ])
        .concat(
          includeDeviceNormal
            ? [{ label: "Device Default", value: "Default" }]
            : [],
        )}
      onChange={(): void => {
        if (props.submit) {
          props.submit();
        }
      }}
    />
  );
};

export default AssetExpectedFrequencySwitch;
