import type React from "react";
import BulkUpdate from "../../../../components/BulkUpdate/BulkUpdate";
import GetListPaginatedTable from "../../../../components/Pagination/GetListPaginatedTable";
import { Button } from "../../../../components/Theme/button";
import CompanyNavLink from "../../../../components/navigation/CompanyNavLink";
import PaginatedApiToCSVExportButton from "../../../../components/utils/CSVExport/PaginatedApiToCSVExportButton";
import type { Asset } from "../../../../openapi/model/asset";
import type { AssetPosition } from "../../../../openapi/model/assetPosition";
import { AssetSort } from "../../../../openapi/model/assetSort";
import AssetContainer, { type AssetChildProps } from "../AssetContainer";
import AssetListGenericRowsContent from "./AssetListGenericRowsContent";
import AssetListGenericRowsHeader from "./AssetListGenericRowsHeader";

interface Props {
  assetId?: string;
  assetType?: string;
  assetPositionId?: string;
  showPosition?: boolean;
  assignAssetPosition?: AssetPosition;
  textSearch?: string;
  showDownload?: boolean;
  showPagination?: boolean;
  headerRow?: [React.ReactElement, number];
  assetRow?: (asset: Asset) => React.ReactElement;
  className?: string;
  companyId: string;
  schemeId?: string;
  refresh?: () => any;
  sort?: AssetSort;
}

export default function AssetList(props: Props): React.ReactElement {
  const params = {
    parentAssetId: props.assetId,
    companyId: props.companyId,
    schemeId: props.schemeId,
    assetType: props.assetType,
    assetPositionId: props.assetPositionId,
    textSearch: props.textSearch,
    sort: props.sort || AssetSort.AssetId,
  };

  const headerRow = props.headerRow
    ? props.headerRow
    : AssetListGenericRowsHeader(
        props.showPosition,
        props.schemeId === undefined,
      );

  const assetRow = props.assetRow
    ? props.assetRow
    : (asset: Asset): React.ReactElement => {
        return (
          <AssetContainer asset={asset} key={asset.assetId}>
            {({ asset, refresh }: AssetChildProps) => (
              <AssetListGenericRowsContent
                asset={asset}
                assignAssetPosition={props.assignAssetPosition}
                showPosition={props.showPosition}
                showScheme={props.schemeId === undefined}
                refreshList={props.refresh}
                refreshElement={refresh}
              />
            )}
          </AssetContainer>
        );
      };

  return (
    <GetListPaginatedTable
      entityType={"ASSET"}
      params={params}
      cursor={{ maxItems: 25 }}
      data-cy={"asset-list"}
      header={headerRow}
      row={assetRow}
      tableClassName={props.className}
      showPagination={props.showPagination}
      additionalFunctionButtons={
        <div className={"flex gap-2"}>
          <PaginatedApiToCSVExportButton entityType={"ASSET"} params={params} />
          <BulkUpdate
            companyId={props.companyId}
            schemeId={props.schemeId}
            objectType={"asset"}
          />
          <CompanyNavLink
            to={{ pathname: "/admin/asset/updates" }}
            button={{
              color: "brandLight",
            }}
            className={"d-inline-block d-md-none"}
          >
            Previous Uploads
          </CompanyNavLink>
        </div>
      }
    />
  );
}
