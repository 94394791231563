import type React from "react";
import { useParams } from "react-router-dom";
import { ErrorBanner } from "../../../components/Error/ErrorBanner";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import GetEntity from "../../../core/action/GetEntity";
import { useRequiredSelectedCompanyId } from "../../../reducers/company";
import { useSelectedSchemeId } from "../../../reducers/scheme";
import LocationDisplay from "./LocationDisplay";

const LocationHomePage = (): React.ReactElement => {
  const companyId = useRequiredSelectedCompanyId();
  const schemeId = useSelectedSchemeId();

  const { locationId } = useParams<{ locationId: string }>();

  if (locationId === undefined) {
    throw new Error("LocationId must be specified.");
  }

  return (
    <>
      <GetEntity
        entityType={"LOCATION"}
        entityId={locationId}
        {...{ companyId, schemeId }}
        error={({ error }) => (
          <OneCardPage
            headerTitle={"Location Detail"}
            breadcrumbs={[{ name: "Error" }]}
            setPageTitle={() => "Location: Error"}
          >
            <ErrorBanner error={error} />
          </OneCardPage>
        )}
        placeholder={() => (
          <OneCardPage
            headerTitle={"Location Detail"}
            loading={true}
            breadcrumbs={[{ name: "Loading" }]}
            setPageTitle={() => "Location: Loading"}
          />
        )}
      >
        {(display) => <LocationDisplay {...display} />}
      </GetEntity>
    </>
  );
};

export default LocationHomePage;
