import { Heading } from "components/Theme/heading";
import type React from "react";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import StreamingSettingsFormPage from "./StreamingSettingsFormPage";

export default function ConfigurationHomePage(): React.ReactElement {
  return (
    <>
      <OneCardPage
        headerTitle={"Configuration"}
        breadcrumbs={[{ url: "/admin/configuration", name: "Configuration" }]}
        setPageTitle={schemeObjectPageTitle("Configuration")}
      >
        <Heading>Data Streaming</Heading>
        <p>
          Configure streaming of formatted and error checked meter data via
          HTTPS to up to 3 endpoints.
        </p>
        <StreamingSettingsFormPage />
      </OneCardPage>
    </>
  );
}
