import dayjs from "dayjs";
import { isUndefined } from "lodash-es";
import React from "react";
import AssetIdLink from "../../../../components/Asset/AssetIdLink";
import AssetTypeHumanLabel from "../../../../components/Asset/AssetTypeHumanLabel";
import AssetPositionIdLink from "../../../../components/AssetPosition/AssetPositionIdLink";
import PrivacyMode from "../../../../components/Text/PrivacyMode";
import { Badge } from "../../../../components/Theme/badge";
import { TableCell, TableRow } from "../../../../components/Theme/table";
import DisplayIfUserType from "../../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { formatDate } from "../../../../components/utils/HighlightNullAndOldDates";
import type { UpdateEntity } from "../../../../hooks/updateEntity";
import type { AssetPositionAssetLinkOut } from "../../../../openapi/model/assetPositionAssetLinkOut";
import UnlinkAssetPositionFromAssetButton from "../UnlinkAssetPositionFromAssetButton";
import AssetPositionAssetUpdateModal from "./AssetPositionAssetUpdateModal";

export interface AssetPositionAssetRowInnerProps {
  link: AssetPositionAssetLinkOut;
  updateEntity: UpdateEntity<AssetPositionAssetLinkOut>;
  refreshList?: () => void;
}
function AssetPositionAssetRow(
  props: AssetPositionAssetRowInnerProps & { view: "asset" | "assetPosition" },
) {
  const { link, refreshList, view } = props;

  const installedAlwaysText =
    !link.installDate && !link.uninstallDate ? "Forever" : "Not set";
  const canEdit = !link.cancelled || isUndefined(link.cancelled);
  return (
    <TableRow key={link.linkId} data-cy={"list-item"}>
      {view === "asset" ? (
        <>
          <TableCell>
            <AssetIdLink assetId={link.assetId} full={false} />
          </TableCell>
          <TableCell>{link.manufacturer}</TableCell>
          <TableCell>
            <PrivacyMode>{link.serialNumber}</PrivacyMode>
          </TableCell>
          <TableCell>
            <AssetTypeHumanLabel assetType={link.assetType} />
          </TableCell>
        </>
      ) : (
        <>
          <TableCell>
            <AssetPositionIdLink
              assetPositionId={link.assetPositionId}
              full={true}
            />
          </TableCell>
          <TableCell>
            <PrivacyMode>{link.assetPositionCustomerReference}</PrivacyMode>
          </TableCell>
          <TableCell>
            <AssetTypeHumanLabel assetType={link.assetPositionType} />
          </TableCell>
        </>
      )}
      <TableCell>
        {link.installDate ? formatDate(link.installDate) : installedAlwaysText}
        {canEdit && (
          <DisplayIfUserType userTypes={"superadmin"}>
            <AssetPositionAssetUpdateModal
              link={link}
              update={props.updateEntity}
              className={"mx-2"}
            />
          </DisplayIfUserType>
        )}
      </TableCell>
      <TableCell>
        {link.uninstallDate
          ? formatDate(dayjs(link.uninstallDate).subtract(1, "minutes"))
          : installedAlwaysText}
        {canEdit && (
          <DisplayIfUserType userTypes={"superadmin"}>
            <AssetPositionAssetUpdateModal
              link={link}
              update={props.updateEntity}
              className={"mx-2"}
            />
          </DisplayIfUserType>
        )}
      </TableCell>
      <TableCell>
        {link.cancelled ? (
          <Badge color={"zinc"}>cancelled</Badge>
        ) : (
          <Badge color={"success"} className={"mx-2"}>
            active
          </Badge>
        )}
      </TableCell>
      <DisplayIfUserType userTypes={"admin"}>
        <TableCell>
          {!link.cancelled && (
            <UnlinkAssetPositionFromAssetButton
              assetPositionAssetLink={link}
              refresh={refreshList}
            />
          )}
        </TableCell>
      </DisplayIfUserType>
    </TableRow>
  );
}

AssetPositionAssetRow.whyDidYouRender = true;
export default AssetPositionAssetRow;
