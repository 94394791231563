import type { UseAxiosResult } from "axios-hooks";
import { startCase } from "lodash-es";
import React from "react";
import type { AggregatedStatsResponse } from "../../../containers/report/latestDataReport/TimeSeriesStatsApiWrapper";
import type { AssetRegisterAggregatedTimeSeriesResponse } from "../../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import BetaAlert from "../../Beta/BetaAlert";
import BlockSpinner from "../../Spinners/BlockSpinner";
import AggregatedStatsChart from "../../charts/AggregatedStatsChart";
import AggregationDistributionChart from "../../charts/AggregationDistributionChart";
import AggregatedTimeSeriesQueryForm, {
  type AggregatedTimeSeriesQueryFormValues,
  mapTimeSeriesRequestParamsToFormValues,
} from "../AggregatedTimeSeriesQueryForm/AggregatedTimeSeriesQueryForm";
import {
  type NewTimeSeriesRequestParams,
  getUnitsForParameter,
} from "../model";

interface AggregatedTimeSeriesStatsReportViewProps {
  onSubmit: (v: AggregatedTimeSeriesQueryFormValues) => Promise<any>;
  request: NewTimeSeriesRequestParams;
  data: UseAxiosResult<AggregatedStatsResponse>;
  aggregatedData?: UseAxiosResult<AssetRegisterAggregatedTimeSeriesResponse>;
  companyId: string;
  schemeId?: string;
  showForm?: boolean;
}

function AggregatedTimeSeriesStatsReportView(
  props: AggregatedTimeSeriesStatsReportViewProps,
) {
  const [{ data, error, loading }] = props.data;
  const haveScheme = props.schemeId !== undefined;
  const { request, showForm = true } = props;
  return (
    <>
      {showForm && (
        <>
          <AggregatedTimeSeriesQueryForm
            onSubmit={props.onSubmit}
            initialValues={mapTimeSeriesRequestParamsToFormValues(request)}
            wide={true}
            showErrors={false}
            showGroup={true}
            showGroupByScheme={!haveScheme}
            showGroupByGroup={haveScheme}
            showAssetUseCase={true}
          />
          <BetaAlert />
        </>
      )}
      <AggregatedStatsChart
        error={error || undefined}
        data={data}
        loading={loading}
        seriesName={`Mean of ${startCase(request.aggregation)} of ${request.parameter}`}
        units={getUnitsForParameter(request.parameter)}
        groupBy={request.groupBy}
        groupFilter={request.group}
      />
      {props.schemeId && props.aggregatedData && (
        <BlockSpinner loading={props.aggregatedData[0].loading}>
          <AggregationDistributionChart
            data={props.aggregatedData[0].data}
            loading={props.aggregatedData[0].loading}
            parameter={request.parameter}
            aggregation={request.aggregation}
            unit={getUnitsForParameter(request.parameter)}
            companyId={props.companyId}
            schemeId={props.schemeId as string}
            startTime={request.startDate}
            endTime={request.endDate}
          />
        </BlockSpinner>
      )}
    </>
  );
}

export default AggregatedTimeSeriesStatsReportView;
