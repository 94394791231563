import { Button } from "components/Theme/button";
import type { Dayjs } from "dayjs";
import { Form, Formik, type FormikHelpers } from "formik";
import React from "react";
import * as yup from "yup";
import useCreateEntity from "../../../../hooks/createEntity";
import type { AssetPosition } from "../../../../openapi/model/assetPosition";
import type { AssetPositionAssetLinkIn } from "../../../../openapi/model/assetPositionAssetLinkIn";
import AssetSelectFormField from "../../assets/AssetSelectFormField";
import AssetPositionAssetLinkDateSelect from "./AssetPositionAssetLinkDateSelect";

interface FormValues {
  assetId?: string;
  installDate?: Dayjs;
  uninstallDate?: Dayjs;
}

export default function AssetPositionAssetLinkCreateForm(props: {
  assetPosition: AssetPosition;
  refresh?: () => any;
}) {
  const { createEntity } = useCreateEntity<AssetPositionAssetLinkIn>("LINK");
  const initialValues: FormValues = {};

  const handleSubmit = (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    helpers.setSubmitting(true);
    createEntity({
      companyId: assetPosition.companyId,
      installDate: values.installDate?.toDate(),
      uninstallDate: values.uninstallDate?.toDate(),
      cancelled: false,
      assetId: values.assetId,
      assetPositionId: assetPosition.assetPositionId,
    })
      .then(() => {
        helpers.resetForm();
        props.refresh?.();
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };
  const { assetPosition } = props;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnMount={true}
      validationSchema={yup.object().shape({
        assetId: yup.string().required(),
      })}
    >
      {({ isValid, isSubmitting }) => {
        return (
          <Form>
            <h4>Select Asset</h4>
            <AssetSelectFormField
              name={"assetId"}
              assetType={assetPosition.assetPositionType}
              companyId={assetPosition.companyId}
            />
            <h4>Dates</h4>
            <AssetPositionAssetLinkDateSelect newLink={true} />

            <Button
              type={"submit"}
              color={"brandLight"}
              disabled={!isValid || isSubmitting}
            >
              Assign
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
