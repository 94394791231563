import type React from "react";
import useUpdateEntity from "../../../../hooks/updateEntity";
import type { AssetPositionAssetLinkOut } from "../../../../openapi/model/assetPositionAssetLinkOut";
import type { AssetPositionAssetRowInnerProps } from "./AssetPositionAssetRowInner";

function AssetPositionAssetRowContainer(props: {
  link: AssetPositionAssetLinkOut;
  refreshList?: () => void;
  children: (props: AssetPositionAssetRowInnerProps) => React.ReactNode;
}): React.ReactElement {
  const { link, refreshList } = props;

  const updateEntity = useUpdateEntity<AssetPositionAssetLinkOut>(
    "LINK",
    link.linkId,
    props.refreshList,
  );

  return (
    <>
      {props.children({
        link,
        refreshList,
        updateEntity,
      })}
    </>
  );
}

export default AssetPositionAssetRowContainer;
