import { ChevronRightIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import type React from "react";
import { useLocation } from "react-router-dom";
import { useSelectedCompany } from "../../reducers/company";
import { useSelectedScheme } from "../../reducers/scheme";
import CompanyNavLink from "../navigation/CompanyNavLink";
import type { PageDefinition } from "../navigation/breadcrumbs/Breadcrumbs";
export interface BreadcrumbsProps {
  breadcrumbs: PageDefinition[];
  showCompanyInBreadcrumbs?: boolean;
  showSchemeInBreadcrumbs?: boolean;
}

function Crumb(definition: PageDefinition, index: number): React.ReactElement {
  const first = index === 0;
  const location = useLocation();
  return (
    <li key={definition.name}>
      <div className={clsx("flex", !first && "items-center")}>
        {!first && (
          <ChevronRightIcon
            aria-hidden="true"
            className="h-3 w-3 flex-shrink-0 text-gray-400"
          />
        )}
        <CompanyNavLink to={{ pathname: definition.url || location.pathname }}>
          <span
            className={clsx(
              "text-xs font-medium text-gray-500 hover:text-gray-700",
              !first && "ml-2",
            )}
          >
            {definition.name}
          </span>
        </CompanyNavLink>
      </div>
    </li>
  );
}

export default function Breadcrumbs(
  props: BreadcrumbsProps,
): React.ReactElement {
  const selectedCompany = useSelectedCompany();
  const selectedScheme = useSelectedScheme();
  const {
    breadcrumbs,
    showCompanyInBreadcrumbs = true,
    showSchemeInBreadcrumbs = true,
  } = props;

  const allBreadcrumbs: PageDefinition[] = [
    ...(showCompanyInBreadcrumbs && selectedCompany
      ? [{ name: selectedCompany.name }]
      : []),
    ...(showSchemeInBreadcrumbs && selectedScheme
      ? [{ name: selectedScheme.schemeName }]
      : []),
    ...breadcrumbs,
  ];
  return (
    <div className={"hidden md:block"}>
      <nav aria-label="Breadcrumb" className="hidden sm:flex">
        <ol className="flex items-center space-x-2">
          {allBreadcrumbs.map((crumb, i) => {
            return Crumb(crumb, i);
          })}
        </ol>
      </nav>
    </div>
  );
}
