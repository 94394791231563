import React from "react";
import ApiPaginatedList, {
  type ApiPaginatedListProps,
} from "../../../components/Pagination/NewApiPaginatedList";
import type { PaginatedListResultProps } from "../../../core/action/GetList";
import type { ReportExecutionOut } from "../../../openapi/model/reportExecutionOut";
import ReportExecutionContainer, {
  type ReportExecutionChildProps,
} from "./ReportExecutionContainer";
import ReportExecutionListRow from "./ReportExecutionListRow";

interface Props
  extends Omit<ApiPaginatedListProps, "cursor" | "data" | "loading" | "row"> {
  list: PaginatedListResultProps<ReportExecutionOut>;
}

export default function ReportExecutionList(props: Props) {
  const { list, ...rest } = props;
  const { loading, data } = list;
  return (
    <ApiPaginatedList
      cursor={data?.cursor}
      data={data}
      loading={loading}
      row={(v: ReportExecutionOut) => (
        <ReportExecutionContainer key={v.reportId} reportExecution={v}>
          {({ reportExecution, refresh }: ReportExecutionChildProps) => (
            <ReportExecutionListRow
              refresh={refresh}
              execution={reportExecution}
            />
          )}
        </ReportExecutionContainer>
      )}
      {...rest}
    />
  );
}
