import GetEntity from "core/action/GetEntity";
import dayjs from "dayjs";
import { startCase } from "lodash-es";
import type React from "react";
import { useParams } from "react-router-dom";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import ObjectPropertyItem from "../../components/Headers/ObjectPropertyItem";
import AssetRegisterStatusLabel from "../../components/Labels/AssetRegisterStatusLabel";
import ObjectDetailHeading from "../../components/Layout/ObjectDetailHeading";
import RoutedTabGroupInterface from "../../components/TabGroup/RoutedTabGroupInterface";
import { Heading } from "../../components/Theme/heading";
import type { PageDefinition } from "../../components/navigation/breadcrumbs/Breadcrumbs";
import CopyToClipboard from "../../components/utils/CopyToClipboard";
import HighlightNullAndOldDates from "../../components/utils/HighlightNullAndOldDates";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import type { JobDefinitionOut } from "../../openapi/model/jobDefinitionOut";
import { JobStatus } from "../../openapi/model/jobStatus";
import EventList from "../event/EventList";
import ReportExecutionListContainer from "../report/executions/ReportExecutionListContainer";
import ChangeJobStateButton from "./ChangeJobState";
import ExecuteJobForm from "./ExecuteJobForm";
import JobCreateForm from "./JobCreateForm";

const jobToBreadcrumb = (job: JobDefinitionOut): PageDefinition[] => {
  return [
    {
      name: "Jobs",
      url: "/admin/job",
    },
    {
      name: `${job.name} (${job.jobId})`,
      url: `/admin/job/${job.jobId}`,
    },
  ];
};

export default function JobHomePage(props: {
  companyId: string;
  schemeId?: string;
}): React.ReactElement {
  const { jobId } = useParams<{ jobId: string }>();

  if (jobId === undefined) {
    throw new Error("jobId must be specified.");
  }

  return (
    <GetEntity
      entityType={"JOB"}
      entityId={jobId}
      error={({ error }) => (
        <OneCardPage
          headerTitle={"Job Detail"}
          breadcrumbs={[{ name: "Error" }]}
          setPageTitle={schemeObjectPageTitle("Jobs Error")}
        >
          <ErrorBanner error={error} />
        </OneCardPage>
      )}
      placeholder={() => (
        <OneCardPage
          headerTitle={"Job Detail"}
          loading={true}
          breadcrumbs={[{ name: "Loading" }]}
          setPageTitle={schemeObjectPageTitle("Jobs Loading")}
        />
      )}
      {...props}
    >
      {({ data, refresh }) => (
        <OneCardPage
          breadcrumbs={data && jobToBreadcrumb(data)}
          headerTitle={"Job Detail"}
        >
          <ObjectDetailHeading
            lgBreakpoint={4}
            leftColumn={{
              content: (
                <ObjectPropertyItem title={"Job Name"} type={"heading"}>
                  {data.name}
                </ObjectPropertyItem>
              ),
            }}
            rightColumn={{
              content: (
                <>
                  <ObjectPropertyItem title={"Job ID"}>
                    <CopyToClipboard value={data.jobId} slice={8} />
                  </ObjectPropertyItem>
                  {data.objectType && (
                    <ObjectPropertyItem title={"Object Filter"}>
                      {startCase(data.objectType)}:{data.objectId}
                    </ObjectPropertyItem>
                  )}
                  <ObjectPropertyItem title={"Status"}>
                    <AssetRegisterStatusLabel
                      status={data.status}
                      displayActive={true}
                    />
                  </ObjectPropertyItem>
                  <ObjectPropertyItem title={"Last Run"}>
                    <HighlightNullAndOldDates
                      ageHighlight={dayjs.duration(1, "day")}
                    >
                      {data.lastRun}
                    </HighlightNullAndOldDates>
                  </ObjectPropertyItem>
                </>
              ),
            }}
          />
          <RoutedTabGroupInterface
            tabs={[
              {
                tabName: "Executions",
                tabPath: "executions",
                tabContent: (
                  <>
                    {data && data.status === JobStatus.Active ? (
                      <div>
                        <Heading>Active Job</Heading>
                        <span>
                          This job is currently active. It runs{" "}
                          {data.schedule.frequency}.
                        </span>
                      </div>
                    ) : (
                      <div>
                        <Heading>Disabled job</Heading>
                        <span>
                          This job is currently disabled. It will not run.
                        </span>
                      </div>
                    )}
                    <div>
                      <ChangeJobStateButton job={data} refresh={refresh} />
                    </div>
                    {data && data.status === JobStatus.Active && (
                      <div>
                        <Heading>Run job now</Heading>
                        <ExecuteJobForm job={data} refresh={refresh} />
                      </div>
                    )}
                    <div>
                      <Heading>Previous Executions</Heading>
                      <ReportExecutionListContainer jobId={data.jobId} />
                    </div>
                  </>
                ),
              },
              {
                tabName: "Configuration",
                tabPath: "configuration",
                tabContent: (
                  <>
                    <JobCreateForm
                      existingJob={data}
                      locked={true}
                      companyId={data.companyId}
                      key={data.jobId}
                    />
                  </>
                ),
              },
              {
                tabName: "Events",
                tabPath: "events",
                tabContent: (
                  <>
                    {data && (
                      <EventList jobId={jobId} companyId={data?.companyId} />
                    )}
                  </>
                ),
              },
            ]}
            topLevelPath={`/admin/job/${data.jobId}`}
          />
        </OneCardPage>
      )}
    </GetEntity>
  );

  /* TODO: fix tab switching */
}
