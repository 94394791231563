import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import type React from "react";
import { type DataPoint, StackedBarChart } from "../../charts/SingleBarChart";
import type {
  DateValueMap,
  DeliveredEnergyByGroup,
  UnaccountedEnergyByGroup,
} from "./model";

dayjs.extend(utc);
dayjs.extend(timezone);

interface NetworkEnergyConsumptionBarChartProps {
  deliveredEnergyData: DeliveredEnergyByGroup;
  unaccountedEnergyData: UnaccountedEnergyByGroup;
  unit?: string;
}

function mergeDeliveredEnergyByGroupToSingleBar(
  data: DeliveredEnergyByGroup,
): DataPoint[] {
  return sumDateValueMaps(
    Object.values(data).flatMap((d) => Object.values(d).map((d2) => d2)),
  );
}

function sumDateValueMaps(data: DateValueMap[]): DataPoint[] {
  return Object.entries(
    data.reduce((sumData, groupData) => {
      for (const [date, dateSum] of Object.entries(groupData)) {
        if (!sumData[date]) {
          sumData[date] = 0;
        }
        sumData[date] += dateSum;
      }
      return sumData;
    }, {}),
  ).map(([v, d]) => [dayjs(v).tz("Europe/London"), d]);
}

function mergeUnaccountedEnergyByGroupToSingleBar(
  data: UnaccountedEnergyByGroup,
): DataPoint[] {
  return sumDateValueMaps(Object.values(data).flat());
}

export default function NetworkEnergyConsumptionBarChart(
  props: NetworkEnergyConsumptionBarChartProps,
): React.ReactElement {
  const d = mergeDeliveredEnergyByGroupToSingleBar(props.deliveredEnergyData);
  const d2 = mergeUnaccountedEnergyByGroupToSingleBar(
    props.unaccountedEnergyData,
  );

  return (
    <>
      <StackedBarChart
        data={[d, d2]}
        definition={[
          { name: "Delivered", unit: props.unit || "Wh", color: "#15803d" },
          { name: "Lost", unit: props.unit || "Wh", color: "#b91c1c" },
        ]}
        loading={false}
        yAxisTitle={"Energy - Heating"}
      />
    </>
  );
}
