import { Form, Formik, type FormikHelpers } from "formik";
import type React from "react";
import * as Yup from "yup";
import { ErrorBanner } from "../../../components/Error/ErrorBanner";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import { Button } from "../../../components/Theme/button";
import useCreateEntity from "../../../hooks/createEntity";
import type { WirelessSurveyDataCollectorIn } from "../../../openapi/model/wirelessSurveyDataCollectorIn";
import type { WirelessSurveyDataCollectorOut } from "../../../openapi/model/wirelessSurveyDataCollectorOut";
import type { WirelessSurveyOut } from "../../../openapi/model/wirelessSurveyOut";
import type { WirelessSurveySamplePoint } from "../../../openapi/model/wirelessSurveySamplePoint";
import AssetSelectFormField from "./AssetSelectFormField";

interface FormValues {
  dataCollectorName: string;
  assetId: string;
  samplePoints: WirelessSurveySamplePoint[];
}

function WirelessSurveyDataCollectorCreateForm(props: {
  survey: WirelessSurveyOut;
  refresh?: () => any;
}) {
  const { survey } = props;
  const { createEntity, error } = useCreateEntity<
    WirelessSurveyDataCollectorOut,
    WirelessSurveyDataCollectorIn
  >(
    "WIRELESS_SURVEY_DATA_COLLECTOR",
    `wireless_survey/${survey.surveyId}/data_collector`,
  );

  function submit(
    value: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ) {
    setSubmitting(true);
    createEntity({
      ...value,
      companyId: survey.companyId,
    })
      .then(() => {
        resetForm();
        props.refresh?.();
      })
      .finally(() => setSubmitting(false));
  }

  return (
    <Formik
      initialValues={
        {
          dataCollectorName: "",
          assetId: "",
          samplePoints: [],
        } as FormValues
      }
      validateOnMount={true}
      validationSchema={Yup.object().shape({
        dataCollectorName: Yup.string().required().min(5),
        assetId: Yup.string().required(),
      })}
      onSubmit={submit}
    >
      {({ touched, isValid, isSubmitting }): React.ReactElement => (
        <>
          <Form>
            <NewTextFormComponent
              fieldName={"dataCollectorName"}
              label={{ label: "Name" }}
            />
            <AssetSelectFormField
              assetType={"gateway"}
              name={"assetId"}
              companyId={survey.companyId}
            />
            <Button
              type="submit"
              color="brandLight"
              disabled={isSubmitting || !isValid || !touched}
            >
              Create
            </Button>
          </Form>
          <ErrorBanner error={error} />
        </>
      )}
    </Formik>
  );
}

export default WirelessSurveyDataCollectorCreateForm;
