import type React from "react";
import { useState } from "react";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import { Button } from "../../components/Theme/button";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import useUpdateEntity from "../../hooks/updateEntity";
import type { JobDefinitionOut } from "../../openapi/model/jobDefinitionOut";

interface Props {
  job: JobDefinitionOut;
  refresh?: () => any;
}

const ChangeJobStateButton = (props: Props): React.ReactElement => {
  const { job } = props;

  const { updateEntity, error } = useUpdateEntity<JobDefinitionOut>(
    "JOB",
    job.jobId,
    props.refresh,
  );
  const [submitting, setSubmitting] = useState(false);

  const active = job.status === "active";

  const switchState = () => {
    setSubmitting(true);
    updateEntity({ ...job, status: active ? "disabled" : "active" }).finally(
      () => {
        setSubmitting(false);
      },
    );
  };

  return (
    <DisplayIfUserType userTypes={"admin"}>
      <span className={"px-2"}>
        <Button
          className={"btn-sm"}
          color={active ? "red" : "green"}
          onClick={switchState}
          disabled={submitting}
        >
          {active ? "Set to disabled" : "Set to active"}
        </Button>
        <ErrorBanner error={error} />
      </span>
    </DisplayIfUserType>
  );
};

export default ChangeJobStateButton;
