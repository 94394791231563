import SelectedScheme from "components/Scheme/SelectedScheme";
import type React from "react";

import { OneCardPage } from "../../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";
import LocationsList from "./LocationsList";

export const LocationsListPage = (): React.ReactElement => {
  return (
    <OneCardPage
      headerTitle={"List Locations"}
      breadcrumbs={[{ url: "/admin/location", name: "Locations" }]}
      setPageTitle={schemeObjectPageTitle("Locations")}
    >
      <SelectedScheme>{(props) => <LocationsList {...props} />}</SelectedScheme>
    </OneCardPage>
  );
};
