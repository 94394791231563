import GetListPaginatedTable from "components/Pagination/GetListPaginatedTable";
import type React from "react";
import { Button } from "../../components/Theme/button";
import { TableHead, TableHeader, TableRow } from "../../components/Theme/table";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { companyObjectPageTitle } from "../../hooks/setPageTitle";
import type { User } from "../../openapi/model/user";
import { useRequiredSelectedCompanyId } from "../../reducers/company";
import UserCompanyListItemForm from "../profile/UserCompanyListItemForm";
import { convertUserToCompanyUser } from "./users";

const UserList = (): React.ReactElement => {
  const companyId = useRequiredSelectedCompanyId();
  const header = (
    <TableHead>
      <TableRow>
        <TableHeader>Name</TableHeader>
        <TableHeader>Email Address</TableHeader>
        <TableHeader>User Type</TableHeader>
        <TableHeader>Receives Alerts</TableHeader>
        <TableHeader>&nbsp;</TableHeader>
      </TableRow>
    </TableHead>
  );

  const row = (value: User): React.ReactElement => {
    const user = convertUserToCompanyUser(value);
    return (
      <UserCompanyListItemForm
        userCompany={user}
        showUserDetails={true}
        profileAction={false}
      />
    );
  };

  return (
    <OneCardPage
      headerTitle={"Users List"}
      breadcrumbs={[
        { url: "/admin/users", name: "Users" },
        { url: "/admin/users", name: "List" },
      ]}
      showSchemeInBreadcrumbs={false}
      setPageTitle={companyObjectPageTitle("Users")}
    >
      <GetListPaginatedTable
        entityType={"USER"}
        params={{ companyId }}
        cursor={{ maxItems: 25 }}
        header={[header, 3]}
        row={row}
        data-cy={"user-list"}
        additionalFunctionButtons={
          <DisplayIfUserType userTypes={["admin"]}>
            <CompanyNavLink
              button={{
                color: "brandLight",
              }}
              to={{ pathname: "/admin/users/create" }}
            >
              Create New User
            </CompanyNavLink>
            <CompanyNavLink
              button={{
                color: "brandLight",
              }}
              to={{ pathname: "/admin/users/api/create" }}
            >
              Create New Api Key
            </CompanyNavLink>
          </DisplayIfUserType>
        }
      />
    </OneCardPage>
  );
};

export default UserList;
