import { ChevronDownIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import type { ButtonProps } from "components/Theme/button";
import { saveAs } from "file-saver";
import type React from "react";
import { useState } from "react";
import Config from "../../../../Config";
import { objectKeysToSnakeCase } from "../../../../actions/helpers/apiService";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
} from "../../../../components/Theme/dropdown";
import DisplayIfUserType from "../../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import type { ActionTypes } from "../../../../openapi/model/actionTypes";
import type { GatewayAsset } from "../../../../openapi/model/gatewayAsset";
import AssetActionConfirmModal from "../../assetActions/AssetActionConfirmModal";

type Props = Omit<ButtonProps, "children" | "onClick"> & {
  gateway: GatewayAsset;
  refresh?: () => any;
};

export const getConfigurationFileForAsset = (
  assetId: string,
  companyId: string,
  configurationType?: string,
  configurationFormat?: string,
): Promise<void> => {
  return axios
    .get(`${Config.apiGateway.URL}/asset/${assetId}/configuration`, {
      params: objectKeysToSnakeCase({
        configurationType,
        configurationFormat,
        companyId,
      }),
      responseType: "arraybuffer",
    })
    .then(({ data, headers }) => {
      const filename = headers["content-disposition"]
        .split(";")[1]
        .split("filename")[1]
        .split("=")[1]
        .trim()
        .replace(/"/g, "");
      saveAs(new Blob([data], { type: headers.contentType }), filename);
    });
};

const GatewayConfigurationDropdown = (props: Props): React.ReactElement => {
  const { gateway, ...rest } = props;

  const downloadDeviceList = (type: string, format?: string): void => {
    getConfigurationFileForAsset(
      gateway.assetId,
      props.gateway.companyId,
      type,
      format,
    );
  };

  const [action, setAction] = useState<
    { actionType: ActionTypes; actionName: string } | undefined
  >(undefined);
  const identifiedGatewayModel: boolean = gateway.model !== undefined;

  const isElvaco = props.gateway.manufacturer === "elvaco";

  const dropDownItem = (actionType: ActionTypes, actionName: string) => {
    return (
      <DropdownItem onClick={() => setAction({ actionType, actionName })}>
        {actionName}
      </DropdownItem>
    );
  };
  return (
    <>
      {action && (
        <AssetActionConfirmModal
          show={true}
          asset={gateway}
          action={{ actionType: action.actionType, payload: undefined }}
          close={() => setAction(undefined)}
          description={action.actionName}
          onComplete={props.refresh}
        />
      )}
      <Dropdown>
        <DropdownButton {...rest} disabled={!isElvaco}>
          Configuration
          <ChevronDownIcon />
        </DropdownButton>

        {isElvaco && (
          <DropdownMenu>
            <DisplayIfUserType userTypes={"admin"}>
              {gateway.simCardId !== undefined &&
                props.gateway.simCardId !== "" && (
                  <>
                    {dropDownItem("FACTORY_RESET_2100", "Factory Reset")}
                    {dropDownItem("SYNC_CONFIG", "Sync Config")}
                    {dropDownItem("GET_CONFIG", "Refresh Config")}
                    {dropDownItem("SYNC_DEVICE_LIST", "Sync Devices")}
                    <DropdownDivider />
                    {dropDownItem("SYNC_CONFIG_SMS", "Sync Config (via SMS)")}
                    {dropDownItem(
                      "SYNC_DEVICE_LIST_SMS",
                      "Sync Devices (via SMS)",
                    )}
                    <DropdownDivider />
                    {dropDownItem("FIRMWARE_UPGRADE", "Firmware Upgrade")}
                    {dropDownItem(
                      "SETUP_WIRELESS_RECEIVERS",
                      "Setup Wireless Receivers",
                    )}
                    <DropdownDivider />
                    <DisplayIfUserType userTypes={"superadmin"}>
                      <>
                        {dropDownItem(
                          "RELEASE_SIM_CARD",
                          "Return sim card to pool",
                        )}
                        {dropDownItem(
                          "ROTATE_CREDENTIALS",
                          "Rotate gateway access credentials",
                        )}
                      </>
                    </DisplayIfUserType>
                  </>
                )}
            </DisplayIfUserType>
            {identifiedGatewayModel ? (
              <>
                <DropdownItem
                  onClick={(): void => downloadDeviceList("DEVICE_LIST")}
                >
                  Download device list
                </DropdownItem>
                <DropdownItem onClick={(): void => downloadDeviceList("SETUP")}>
                  Download setup configuration
                </DropdownItem>
              </>
            ) : (
              <>
                <DropdownItem
                  onClick={(): void =>
                    downloadDeviceList("DEVICE_LIST", "ELVACO_21XX")
                  }
                >
                  Download device list (Elvaco 21XX)
                </DropdownItem>
                <DropdownItem
                  onClick={(): void =>
                    downloadDeviceList("DEVICE_LIST", "ELVACO_31XX")
                  }
                >
                  Download device list (Elvaco 31XX)
                </DropdownItem>
                <DropdownItem
                  onClick={(): void =>
                    downloadDeviceList("SETUP", "ELVACO_21XX")
                  }
                >
                  Download setup configuration list (Elvaco 21XX)
                </DropdownItem>
                <DropdownItem
                  onClick={(): void =>
                    downloadDeviceList("SETUP", "ELVACO_31XX")
                  }
                >
                  Download setup configuration (Elvaco 31XX)
                </DropdownItem>
              </>
            )}
          </DropdownMenu>
        )}
      </Dropdown>
    </>
  );
};

export default GatewayConfigurationDropdown;
