import type React from "react";
import { useParams } from "react-router-dom";
import TabGroupInterface from "./TabGroupInterface";
import type { Tab } from "./model";

interface Props {
  tabs: (Tab | undefined)[];
  tabRouteName?: string;
  topLevelPath: string;
}

export default function RoutedTabGroupInterface(
  props: Props,
): React.ReactElement {
  const { tabs, tabRouteName = "tab" } = props;
  const params = useParams();

  const actualTabs = tabs.filter((t) => {
    return t !== undefined;
  }) as Tab[];

  const liveTab: Tab =
    actualTabs.filter((tab: Tab) => tab?.tabPath === params[tabRouteName])[0] ||
    actualTabs[0];

  return (
    <TabGroupInterface
      tabs={actualTabs.map((tab: Tab) => ({
        ...tab,
        tabPath: `${props.topLevelPath}/${tab.tabPath}`,
      }))}
      liveTab={{
        ...liveTab,
        tabPath: `${props.topLevelPath}/${liveTab.tabPath}`,
      }}
    />
  );
}
