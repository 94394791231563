import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import type { AssetPosition } from "openapi/model/assetPosition";
import AssetRegisterList from "pages/assetRegister/AssetRegisterListPage/AssetRegisterList";
import { AssetRegisterListHeading } from "pages/assetRegister/AssetRegisterListPage/AssetRegisterListItem";
import type React from "react";
import type { AssetRegisterItem } from "../../../../openapi/model/assetRegisterItem";
import type { GatewayAsset } from "../../../../openapi/model/gatewayAsset";
import WirelessClusterGatewayAssignButton from "../../wirelessCluster/WirelessClusterGatewayAssignButton";
import WirelessClusterGatewayLeaveButton from "../../wirelessCluster/WirelessClusterGatewayLeaveButton";
import AssetRegisterItemContainer from "../AssetRegisterItemContainer";
import GatewayConfigurationDropdown from "./GatewayConfigurationDropdown";
import GatewayConnectivityReportDataRow from "./GatewayConnectivityReportDataRow";

export default function GatewayConnectivityReport(props: {
  companyId: string;
  schemeId?: string;
  parentAssetPositionId?: string;
  clusterForAssignment?: AssetPosition;
  additionalFunctionButtons?: React.ReactElement;
}): React.ReactElement {
  // const isAdminUser = useUserHasRoles("admin");
  const { clusterForAssignment, ...rest } = props;
  return (
    <>
      <AssetRegisterList
        {...rest}
        row={GatewayConnectivityReportDataRowWithClusterAssignment(
          clusterForAssignment,
        )}
        header={
          <AssetRegisterListHeading
            column1={"Location - Position - Asset"}
            column2={"Status"}
            column3={"Details"}
            column1Size={"w-7/12 md:w-5/12"}
            column2Size={"w-5/12 md:w-3/12"}
            column3Size={"hidden md:w-4/12"}
          />
        }
        assetType={"gateway"}
        createAssetTags={false}
      />
    </>
  );
}

function GatewayConnectivityReportDataRowWithClusterAssignment(
  clusterForAssignment?: AssetPosition,
) {
  const GatewayConnectivityReportDataRowWithClusterAssignmentInner = (props: {
    assetRegisterItem: AssetRegisterItem;
    showScheme?: boolean;
    refresh?: () => void;
  }) => {
    const { assetRegisterItem, ...rest } = props;
    return (
      <AssetRegisterItemContainer
        assetRegisterItem={assetRegisterItem}
        key={assetRegisterItem.assetPosition.assetPositionId}
      >
        {({ assetRegisterItem, refresh }) => {
          return (
            <>
              {clusterForAssignment ? (
                <GatewayConnectivityReportDataRow
                  assetRegisterItem={assetRegisterItem}
                  {...rest}
                  actionButtons={({ assetRegisterItem }) => (
                    <>
                      {clusterForAssignment &&
                      assetRegisterItem.assetPosition.parentAssetPositionId !==
                        clusterForAssignment.assetPositionId ? (
                        <DisplayIfUserType userTypes={"admin"}>
                          <WirelessClusterGatewayAssignButton
                            clusterPosition={clusterForAssignment}
                            gatewayPosition={assetRegisterItem.assetPosition}
                            refresh={props.refresh}
                          />
                        </DisplayIfUserType>
                      ) : (
                        <DisplayIfUserType userTypes={"admin"}>
                          <WirelessClusterGatewayLeaveButton
                            gatewayPosition={assetRegisterItem.assetPosition}
                            refresh={props.refresh}
                          />
                        </DisplayIfUserType>
                      )}
                    </>
                  )}
                />
              ) : (
                <GatewayConnectivityReportDataRow
                  assetRegisterItem={assetRegisterItem}
                  {...rest}
                  actionButtons={({ assetRegisterItem }) => (
                    <>
                      {assetRegisterItem.asset && (
                        <GatewayConfigurationDropdown
                          size={"sm"}
                          color={"brandLight"}
                          gateway={assetRegisterItem.asset as GatewayAsset}
                          refresh={refresh}
                        />
                      )}
                    </>
                  )}
                />
              )}
            </>
          );
        }}
      </AssetRegisterItemContainer>
    );
  };
  return GatewayConnectivityReportDataRowWithClusterAssignmentInner;
}
