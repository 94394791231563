import type React from "react";
import AssetPositionNumbersToStringList from "../../../components/AssetPosition/AssetPositionNumberSummaryList";
import PrivacyMode from "../../../components/Text/PrivacyMode";
import { StackedListThreeColumnItem } from "../../../components/Theme/stacked-list";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";
import type { AssetRegisterItem } from "../../../openapi/model/assetRegisterItem";

export default function WirelessClusterListRowInner(props: {
  assetRegisterItem: AssetRegisterItem;
  showScheme?: boolean;
}): React.ReactElement {
  const { assetRegisterItem } = props;
  const { location, assetPosition } = assetRegisterItem;

  return (
    <StackedListThreeColumnItem
      key={assetPosition.assetPositionId}
      column1={
        <div className="min-w-0 flex-auto">
          <CompanyNavLink
            className={"p-0 d-inline"}
            to={{
              pathname: `/admin/asset_position/${assetPosition?.assetPositionId}`,
            }}
          >
            <PrivacyMode>{location?.address}</PrivacyMode>
          </CompanyNavLink>
        </div>
      }
      column2={
        <div className="min-w-0 flex-auto">
          {assetRegisterItem.assetPosition.childAssetPositions?.gateway ||
            0}{" "}
        </div>
      }
      column3={
        <div className="min-w-0 flex-auto">
          <AssetPositionNumbersToStringList
            numbers={assetRegisterItem.assetPosition.childAssetPositions}
            includeGateways={false}
          />
        </div>
      }
    />
  );
}
