import type React from "react";

import { ErrorBanner } from "components/Error/ErrorBanner";
import GetAssetRegisterEntitiesCombination from "core/action/GetAssetRegisterEntitiesCombination";
import { useParams } from "react-router-dom";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { useRequiredSelectedCompanyId } from "../../../reducers/company";
import { useSelectedSchemeId } from "../../../reducers/scheme";
import AssetPositionDisplay from "./AssetPositionDisplay";

export default function AssetPositionHomepage(): React.ReactElement {
  const companyId = useRequiredSelectedCompanyId();
  const schemeId = useSelectedSchemeId();
  const { assetPositionId } = useParams();

  if (assetPositionId === undefined) {
    throw new Error("AssetPositionId must be set.");
  }

  return (
    <GetAssetRegisterEntitiesCombination
      assetPositionId={assetPositionId}
      schemeId={schemeId}
      companyId={companyId}
      error={({ error }) => (
        <OneCardPage
          headerTitle={"Asset Position Detail"}
          breadcrumbs={[{ name: "Error" }]}
          setPageTitle={() => "Asset Position: Error"}
        >
          <ErrorBanner error={error} />
        </OneCardPage>
      )}
      placeholder={() => (
        <OneCardPage
          headerTitle={"Asset Position Detail"}
          loading={true}
          breadcrumbs={[{ name: "Loading" }]}
          setPageTitle={() => "Asset Position: Loading"}
        />
      )}
    >
      {(displayProps) => <AssetPositionDisplay {...displayProps} />}
    </GetAssetRegisterEntitiesCombination>
  );
}
