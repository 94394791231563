import clsx from "clsx";
import { useField } from "formik";
import type React from "react";
import type { SetOptional } from "type-fest";
import NewLabelWrapper, { type LabelProps } from "../NewLabelWrapper";

export type Value = string | number | boolean;

export interface Option {
  label: string | React.ReactNode;
  value: Value;
}

export type OptionsList = Option[];

export interface ButtonGroupProps {
  label?: LabelProps;
  onChange: (value: Value) => void;
  options: OptionsList;
  value?: Value;
  disabled?: boolean;
  fullWidth?: boolean;
  size?: keyof typeof styles.size;
  dataTestId?: string;
}

const styles = {
  fullWidth: [],
  base: [
    // Base
    "relative isolate inline-flex items-center justify-center gap-x-2 border text-base/6",
    // Focus
    "focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-blue-500",
    // Disabled
    "data-[disabled]:opacity-50",
    // Icon
    "[&>[data-slot=icon]]:-mx-0.5 [&>[data-slot=icon]]:my-0.5 [&>[data-slot=icon]]:size-5 [&>[data-slot=icon]]:shrink-0 [&>[data-slot=icon]]:text-[--btn-icon] [&>[data-slot=icon]]:sm:my-1 [&>[data-slot=icon]]:sm:size-4 forced-colors:[--btn-icon:ButtonText] forced-colors:data-[hover]:[--btn-icon:ButtonText]",
  ],
  solid: [
    // Optical border, implemented as the button background to avoid corner artifacts
    "bg-[--btn-border]",
    // Dark mode: border is rendered on `after` so background is set to button background
    "dark:bg-[--btn-bg]",
    // Button background, implemented as foreground layer to stack on top of pseudo-border layer
    // 'before:absolute before:inset-0 before:-z-10 before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-[--btn-bg]',
    // Drop shadow, applied to the inset `before` layer so it blends with the border
    // 'before:shadow',
    // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
    "dark:before:hidden",
    // Dark mode: Subtle white outline is applied using a border
    "dark:border-white/5",
    // Shim/overlay, inset to match button foreground and used for hover state + highlight shadow
    "after:absolute after:inset-0 after:-z-10 after:rounded-[calc(theme(borderRadius.lg)-1px)]",
    // Inner highlight shadow
    // 'after:shadow-[shadow:inset_0_1px_theme(colors.white/15%)]',
    // White overlay on hover
    "after:data-[active]:bg-[--btn-hover-overlay] after:data-[hover]:bg-[--btn-hover-overlay]",
    // Dark mode: `after` layer expands to cover entire button
    "dark:after:-inset-px dark:after:rounded-lg",
    // Disabled
    "before:data-[disabled]:shadow-none after:data-[disabled]:shadow-none",
  ],
  outline: [
    // Base
    "border-zinc-950/10 text-zinc-950 data-[active]:bg-zinc-950/[2.5%] data-[hover]:bg-zinc-950/[2.5%]",
    // Dark mode
    "dark:border-white/15 dark:text-white dark:[--btn-bg:transparent] dark:data-[active]:bg-white/5 dark:data-[hover]:bg-white/5",
    // Icon
    "[--btn-icon:theme(colors.zinc.500)] data-[active]:[--btn-icon:theme(colors.zinc.700)] data-[hover]:[--btn-icon:theme(colors.zinc.700)] dark:data-[active]:[--btn-icon:theme(colors.zinc.400)] dark:data-[hover]:[--btn-icon:theme(colors.zinc.400)]",
  ],
  size: {
    sm: ["text-sm/4 sm:text-xs/4 px-2 py-1 h-[30px]"],
    md: ["text-base/6 sm:text-sm/6 px-3 py-2 h-[38px]"],
  },
};

function GroupButton(props: {
  first: boolean;
  last: boolean;
  option: Option;
  selected: boolean;
  onClick: () => any;
  disabled: boolean;
  fullWidth?: boolean;
  size?: keyof typeof styles.size;
  dataCy?: string;
}): React.ReactElement {
  return (
    <button
      data-cy={props.dataCy && `option-${props.dataCy}`}
      type="button"
      className={clsx([
        "relative inline-flex items-center ring-1 ring-inset ring-gray-300 focus:z-10 border-0",
        props.first ? "rounded-l-md" : "-ml-px",
        props.last && "rounded-r-md",
        props.fullWidth && "w-full",
        styles.size[props.size || "md"],
        ...(props.selected
          ? [
              styles.base,
              styles.solid,
              "text-white [--btn-hover-overlay:theme(colors.white/10%)] [--btn-bg:theme(colors.brand-light.600/80%)] [--btn-border:theme(colors.brand-light.600/80%)]",
              "[--btn-icon:theme(colors.white/60%)] data-[active]:[--btn-icon:theme(colors.white/80%)] data-[hover]:[--btn-icon:theme(colors.white/80%)]",
            ]
          : [
              styles.base,
              styles.outline,
              // styles.color.white
            ]),
      ])}
      onClick={() => {
        !props.disabled && props.onClick();
      }}
      disabled={props.disabled}
    >
      {props.option.label}
    </button>
  );
}

export default function ButtonGroup(
  props: ButtonGroupProps,
): React.ReactElement {
  const { options, label, fullWidth = false } = props;

  /* TODO: convert  to use standard button */
  return (
    <NewLabelWrapper {...label}>
      <div>
        <span
          className={clsx(
            "isolate inline-flex rounded-md shadow-none",
            fullWidth && "w-full",
          )}
          data-cy={`btn-group-${props.dataTestId}`}
          data-testid={props.dataTestId}
        >
          {options.map((v, i) => {
            return (
              <GroupButton
                key={v.value.toString()}
                first={i === 0}
                last={i === options.length - 1}
                option={v}
                selected={v.value === props.value}
                onClick={() => props.onChange(v.value)}
                disabled={props.disabled || v.value === props.value}
                fullWidth={fullWidth}
                size={props.size}
                dataCy={v.value.toString()}
              />
            );
          })}
        </span>
      </div>
    </NewLabelWrapper>
  );
}

export type ButtonGroupFieldProps = SetOptional<
  ButtonGroupProps,
  "onChange"
> & { fieldName: string };

export function ButtonGroupField(
  props: ButtonGroupFieldProps,
): React.ReactElement {
  const { fieldName, ...rest } = props;
  /* TODO: handle errors */
  const [{ value }, _, { setValue, setTouched }] = useField(fieldName);

  const onChangeFormikSet = (newValue: Value) => {
    setTouched(true);

    if (props.onChange) {
      props.onChange(newValue);
      setValue(newValue);
    } else {
      setValue(newValue);
    }
  };

  return (
    <ButtonGroup
      dataTestId={props.dataTestId || fieldName}
      {...rest}
      value={value}
      onChange={onChangeFormikSet}
    />
  );
}
