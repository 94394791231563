import dayjs, { type Dayjs } from "dayjs";
import type { Duration } from "dayjs/plugin/duration";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import type React from "react";

dayjs.extend(LocalizedFormat);

type AcceptedStringDates = string | Date | Dayjs;
type Props = {
  children?: AcceptedStringDates;
  ageHighlight?: Duration;
  format?: string;
  showDate?: boolean;
  unknownText?: string;
};

const HighlightNullAndOldDates = ({
  children,
  format,
  ageHighlight,
  showDate,
  unknownText,
}: Props): React.ReactElement => {
  const [className, value] = valueClassAndFormat(
    children,
    format,
    ageHighlight,
    showDate,
    unknownText,
  );
  return <span className={className}>{value}</span>;
};

const valueClassAndFormat = (
  value?: AcceptedStringDates,
  format?: string,
  ageHighlight?: Duration,
  showDate?: boolean,
  unknownText = "Never",
): [string, string | React.ReactNode] => {
  if (!value || value === "") {
    return ["text-danger", unknownText];
  } else {
    const age = _valueAge(value);
    if (ageHighlight && +age > +ageHighlight) {
      return ["text-warning", formatValue(value, age, format, showDate)];
    } else {
      return ["", formatValue(value, age, format, showDate)];
    }
  }
};

export const formatDuration = (duration: Duration): React.ReactNode => {
  if (duration.asDays() > 3) {
    return <>{Math.round(duration.asDays())}&nbsp;days</>;
  }
  if (duration.asHours() > 2) {
    return <>{Math.round(duration.asHours())}&nbsp;hours</>;
  }
  return <>{Math.round(duration.asMinutes())}&nbsp;minutes</>;
};

const formatValue = (
  value: AcceptedStringDates,
  age: Duration,
  format?: string,
  showDate?: boolean,
): string | React.ReactNode => {
  if (showDate === undefined || showDate) {
    return (
      <>
        {formatDate(value, format)} <wbr /> ({formatDuration(age)})
      </>
    );
  } else {
    return `${formatDuration(age)}`;
  }
};

const _valueAge = (value?: AcceptedStringDates): Duration => {
  if (!value) {
    return dayjs.duration(0);
  } else {
    const now = dayjs();
    const valueDayjs = dayjs(value);
    return dayjs.duration(+now - +valueDayjs);
  }
};

export default HighlightNullAndOldDates;
export const formatDate = (
  date?: AcceptedStringDates,
  format?: string,
  includeSeconds = false,
): string => {
  return date
    ? dayjs(date).format(format ? format : includeSeconds ? "L LTS" : "L LT")
    : "";
};
