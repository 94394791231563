import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CompanyNavLink from "components/navigation/CompanyNavLink";
import type React from "react";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import PrivacyMode from "../Text/PrivacyMode";
import { ControlledTooltip } from "../Theme/tooltip";

const useCopyToClipboard = (timeout = 2500) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async (content: string) => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, timeout);
    } catch (_err) {
      setIsCopied(false);
    }
  };

  return { isCopied, copyToClipboard };
};

export default function CopyToClipboard(
  props: {
    value?: string;
    slice?: number;
    link?: string;
    className?: string;
    privacyMode?: boolean;
    children?: React.ReactElement;
  } & React.HTMLAttributes<HTMLSpanElement> & {
      dataCy?: string;
    },
): React.ReactElement {
  const { isCopied, copyToClipboard } = useCopyToClipboard();
  const [id, setId] = useState("");
  if (id === "") {
    setId(`c-${uuidv4().slice(0, 7)}`);
  }

  const { value, slice, link, className, privacyMode, ...rest } = props;
  const text = slice ? `${value?.slice(0, slice)}...` : value;
  const display = privacyMode ? <PrivacyMode>{text}</PrivacyMode> : text;
  return (
    <span
      {...rest}
      className={className}
      onClick={() => copyToClipboard(value || "")}
    >
      {link !== undefined ? (
        <CompanyNavLink className={className} to={{ pathname: link }}>
          {display}
        </CompanyNavLink>
      ) : (
        !props.children && <>{display}</>
      )}
      <ControlledTooltip placement={"top"} open={isCopied} message={"Copied!"}>
        {props.children ? (
          props.children
        ) : (
          <FontAwesomeIcon icon={faClipboard} className={"px-2"} />
        )}
      </ControlledTooltip>
    </span>
  );
}
