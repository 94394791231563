import AlarmListPage from "containers/event/AlarmListPage";
import AdminLandingPage from "layouts/admin/AdminLandingPage";
import AssetRegisterListPage from "pages/assetRegister/AssetRegisterListPage/AssetRegisterListPage";
import DashboardPage from "pages/dashboard/DashboardPage";
import NewSchemePage from "pages/schemes/NewSchemePage/NewSchemePage";
import { SchemesListPage } from "pages/schemes/SchemeListPage/SelectListPage";
import type React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import BulkUpdateList from "../components/BulkUpdate/BulkUpdateList";
import GenericUpdatesPage from "../components/BulkUpdate/GenericUpdatesPage";
import SelectedScheme from "../components/Scheme/SelectedScheme";
import DebugLayout from "../components/navigation/DebugLayout";
import AccountListPage from "../containers/account/AccountListPage";
import CompanyList from "../containers/company/CompanyList";
import ConfigurationHomePage from "../containers/configuration/ConfigurationHomePage";
import EventListPage from "../containers/event/EventListPage";
import JobCreatePage from "../containers/jobs/JobCreatePage";
import JobHomePage from "../containers/jobs/JobHomePage";
import { JobsListPage } from "../containers/jobs/JobsListPage";
import NotesListPage from "../containers/notes/NotesListPage";
import ProfilePage from "../containers/profile/ProfilePage";
import BillingReportPage from "../containers/report/BillingReportPage";
import TimeSeriesDataExportPage from "../containers/report/TimeSeriesDataExportPage/TimeSeriesDataExportPage";
import SchemeAggregatedTimeSeriesDataReport from "../containers/report/aggregatedTimeSeriesReport/SchemeAggregatedTimeSeriesDataReport";
import BillingReportPageView from "../containers/report/billing/BillingReportPage";
import DataQualityReportPageByRows from "../containers/report/dataQualityReport/DataQualityReportPageByRows";
import HeatNetworkAnalysisReportPage from "../containers/report/heatNetworkAnalysis/HeatNetworkAnalysisReportPage";
import SchemeLatestTimeSeriesReport from "../containers/report/latestDataReport/SchemeLatestTimeSeriesDataReport";
import StatsTimeSeriesDataReportPage from "../containers/report/statsReport/StatsTimeSeriesDataReportPage";
import SchemeNewLocationPage from "../containers/schemes/SchemeNewLocationPage";
import SchemeTariffHomepage from "../containers/tariff/SchemeTariffHomepage";
import ApiUserCreateForm from "../containers/users/ApiUserCreateForm";
import CreateUserForm from "../containers/users/UserCreateForm";
import UserList from "../containers/users/UserList";
import AssetPositionHomepage from "../pages/assetRegister/assetPositions/AssetPositionHomepage";
import AssetPositionListPage from "../pages/assetRegister/assetPositions/AssetPositionListPage";
import AssetHomepage from "../pages/assetRegister/assets/AssetHomepage";
import GatewayConnectivityReportPage from "../pages/assetRegister/assets/gateway/GatewayConnectivityReportPage";
import AssetListPage from "../pages/assetRegister/assets/list/AssetListPage";
import { IntegrationCreatePage } from "../pages/assetRegister/integrations/IntegrationCreatePage";
import { IntegrationListPage } from "../pages/assetRegister/integrations/IntegrationListPage";
import LocationHomePage from "../pages/assetRegister/locations/LocationHomePage";
import { LocationsListPage } from "../pages/assetRegister/locations/LocationsListPage";
import WirelessClusterCreatePage from "../pages/assetRegister/wirelessCluster/WirelessClusterCreatePage";
import WirelessClusterListPage from "../pages/assetRegister/wirelessCluster/WirelessClusterListPage";
import WirelessSurveyCreatePage from "../pages/assetRegister/wirelessSurvey/WirelessSurveyCreatePage";
import WirelessSurveyHomepage from "../pages/assetRegister/wirelessSurvey/WirelessSurveyHomepage";
import WirelessSurveyListPage from "../pages/assetRegister/wirelessSurvey/WirelessSurveyListPage";
function Redirect({ path, prefix }: { path: string; prefix: string }) {
  const location = useLocation();

  const post = location.pathname.slice(prefix.length + 1);
  return (
    <Navigate
      replace
      to={{
        ...location,
        pathname:
          path + (post ? `/${location.pathname.slice(prefix.length + 1)}` : ""),
      }}
    />
  );
}

const AdminRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route element={<DebugLayout />}>
        <Route index element={<AdminLandingPage />} />
        <Route path={"dashboard"} element={<DashboardPage />} />
        <Route path={"schemes"}>
          <Route index element={<SchemesListPage />} />
          <Route path="create" element={<NewSchemePage />} />
        </Route>
        <Route path={"asset_register"}>
          <Route index element={<AssetRegisterListPage />} />
          <Route path="integrations">
            <Route path="" element={<IntegrationListPage />} />
            <Route path={"create"} element={<IntegrationCreatePage />} />
          </Route>
          <Route path="asset_position">
            <Route path="" element={<AssetPositionListPage />} />
            <Route
              path="updates"
              element={<BulkUpdateList objectType={"AssetPosition"} />}
            />
            <Route
              path={"updates/:updateId"}
              element={
                <GenericUpdatesPage
                  title={"Asset Positions"}
                  header={"Updated Asset Positions"}
                  tableFields={[
                    "companyId",
                    "schemeId",
                    "locationId",
                    "assetPositionId",
                    "assetId",
                    "customerReference",
                    "assetPositionType",
                    "receiverPositionIds",
                  ]}
                  tableFieldHeaders={[
                    "Company ID",
                    "Scheme ID",
                    "Location ID",
                    "Asset Position Id",
                    "Asset Id",
                    "Customer Reference",
                    "Type",
                  ]}
                  objectKey={"assetPositionId"}
                />
              }
            />
            <Route path=":assetPositionId" element={<AssetPositionHomepage />}>
              <Route path=":tab" element={<AssetPositionHomepage />} />
            </Route>
          </Route>

          <Route path="location">
            <Route path="" element={<LocationsListPage />} />
            <Route
              path="updates"
              element={<BulkUpdateList objectType={"Location"} />}
            />
            <Route
              path={"updates/:updateId"}
              element={
                <GenericUpdatesPage
                  title={"Locations"}
                  header={"Updated Locations"}
                  tableFields={[
                    "companyId",
                    "schemeId",
                    "locationId",
                    "address",
                    "customerReference",
                    "group",
                  ]}
                  tableFieldHeaders={[
                    "Company ID",
                    "Scheme ID",
                    "Location ID",
                    "Address",
                    "Reference",
                    "Group",
                  ]}
                  objectKey={"locationId"}
                />
              }
            />
            <Route path={"create"} element={<SchemeNewLocationPage />} />
            <Route path={":locationId/:tab?"} element={<LocationHomePage />} />
          </Route>
          <Route path="asset">
            <Route path="" element={<AssetListPage />} />
            <Route
              path="updates"
              element={<BulkUpdateList objectType={"Asset"} />}
            />
            <Route
              path={"updates/:updateId"}
              element={
                <GenericUpdatesPage
                  title={"Assets"}
                  header={"Updated Assets"}
                  tableFields={[
                    "companyId",
                    "schemeId",
                    "assetPositionId",
                    "assetId",
                    "serialNumber",
                    "assetType",
                  ]}
                  tableFieldHeaders={[
                    "Company ID",
                    "Scheme ID",
                    "Asset Position Id",
                    "Asset Id",
                    "Serial Number",
                    "Asset Type",
                  ]}
                  objectKey={"assetId"}
                />
              }
            />
            <Route path=":assetId/:tab?" element={<AssetHomepage />} />
          </Route>
          <Route path="wireless_cluster">
            <Route path="" element={<WirelessClusterListPage />} />
            <Route path={"create"} element={<WirelessClusterCreatePage />} />
          </Route>
          <Route path="wireless_survey">
            <Route path="" element={<WirelessSurveyListPage />} />
            <Route path={"create"} element={<WirelessSurveyCreatePage />} />
            <Route
              path={"view/:surveyId/:tab?"}
              element={<WirelessSurveyHomepage />}
            />
          </Route>
        </Route>
        <Route path={"notes"} element={<NotesListPage />} />
        <Route path={"users"}>
          <Route path="" element={<UserList />} />
          <Route path="create" element={<CreateUserForm />} />
          <Route path="api/create" element={<ApiUserCreateForm />} />
          <Route path="profile" element={<ProfilePage />} />
        </Route>
        <Route path={"report"}>
          <Route path="billing/data" element={<BillingReportPageView />} />

          <Route path="gateway" element={<GatewayConnectivityReportPage />} />

          <Route
            path="gateway/unassigned"
            element={<GatewayConnectivityReportPage assigned={false} />}
          />

          <Route
            path="time_series/aggregated"
            element={<SchemeAggregatedTimeSeriesDataReport />}
          >
            <Route
              path=":tab"
              element={<SchemeAggregatedTimeSeriesDataReport />}
            />
          </Route>

          <Route
            path="time_series/stats"
            element={<StatsTimeSeriesDataReportPage />}
          />

          <Route
            path="time_series/latest"
            element={<SchemeLatestTimeSeriesReport />}
          />

          <Route
            path="heat_network/:tab?"
            element={<HeatNetworkAnalysisReportPage />}
          />
          <Route
            path="row_data_quality"
            element={
              <SelectedScheme>
                {(props) => <DataQualityReportPageByRows {...props} />}
              </SelectedScheme>
            }
          />
        </Route>
        <Route path={"job"}>
          <Route path="" element={<JobsListPage />} />
          <Route path="create" element={<JobCreatePage />} />
          <Route
            path={":jobId"}
            element={
              <SelectedScheme>
                {(props) => <JobHomePage {...props} />}
              </SelectedScheme>
            }
          />
          <Route
            path={":jobId/:tab"}
            element={
              <SelectedScheme>
                {(props) => <JobHomePage {...props} />}
              </SelectedScheme>
            }
          />
        </Route>
        <Route path={"company"}>
          <Route path="" element={<CompanyList />} />
        </Route>
        <Route path={"event"} element={<EventListPage />} />
        <Route path={"alarm"} element={<AlarmListPage />} />
        <Route path={"profile"} element={<ProfilePage />} />

        <Route path="export" element={<TimeSeriesDataExportPage />} />
        <Route path={"configuration"} element={<ConfigurationHomePage />} />
        <Route path={"billing/tariff"}>
          <Route path="" element={<SchemeTariffHomepage />} />
        </Route>
        <Route path={"billing/account"}>
          <Route path={""} element={<AccountListPage />} />
        </Route>
        <Route path={"billing/report"} element={<BillingReportPage />} />

        {/* redirects for old routes */}
        <Route
          path={"wireless_cluster/:path?"}
          element={
            <Redirect
              prefix={"/admin/wireless_cluster"}
              path={"/admin/asset_register/wireless_cluster"}
            />
          }
        />
        <Route
          path={"wireless_survey/:path?"}
          element={
            <Redirect
              prefix={"/admin/wireless_survey"}
              path={"/admin/asset_register/wireless_survey"}
            />
          }
        />
        <Route
          path={"integrations/:path?"}
          element={
            <Redirect
              prefix={"/admin/integrations "}
              path={"/admin/asset_register/integrations"}
            />
          }
        />
        <Route
          path={"asset_position/:path?"}
          element={
            <Redirect
              prefix={"/admin/asset_position"}
              path={"/admin/asset_register/asset_position"}
            />
          }
        />
        <Route
          path={"location/:path?/:path?"}
          element={
            <Redirect
              prefix={"/admin/location"}
              path={"/admin/asset_register/location"}
            />
          }
        />
        <Route
          path={"asset/:path?"}
          element={
            <Redirect
              prefix={"/admin/asset"}
              path={"/admin/asset_register/asset"}
            />
          }
        />
      </Route>
      <Route path={"*"} element={<>Page not found</>} />
    </Routes>
  );
};

export default AdminRoutes;
