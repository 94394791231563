import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type React from "react";
import { Actions } from "../../openapi/model/actions";
import UncontrolledTooltip from "../Theme/tooltip";

interface Props {
  index: string;
  status: Actions;
  executed: boolean;
  executedSuccess: boolean;
  immutableChangeErrors: string[];
  validationErrors?: string[];
  executionErrors: string[];
  changedProperties: string[];
}

const GenericUpdateTooltip = (props: Props): React.ReactElement => {
  const {
    index,
    status,
    executed,
    executedSuccess,
    immutableChangeErrors,
    validationErrors = [],
    executionErrors,
    changedProperties,
  } = { ...props };

  const hasErrors =
    immutableChangeErrors.length +
      validationErrors.length +
      executionErrors.length >
    0;

  const tooltipContent = (): string => {
    if (executed) {
      if (executedSuccess || executionErrors.length > 0) {
        const success = executedSuccess && !hasErrors;
        const actionStatus = success
          ? "completed"
          : `failed ${describeErrors()}`;
        return `${status}: ${actionStatus}`;
      } else {
        return `${status}: in progress`;
      }
    } else {
      return `${status} ${
        status === Actions.UPDATE
          ? hasErrors
            ? describeErrors()
            : changedProperties
          : ""
      }`;
    }
  };

  const describeErrors = (): string => {
    if (executed && executionErrors.length > 0) {
      return executionErrors.join(", ");
    } else {
      return hasErrors
        ? validationErrors.join(", ") + immutableChangeErrors
          ? `immutable fields were changed: ${immutableChangeErrors.join(", ")}`
          : ""
        : "";
    }
  };

  const text = tooltipContent();
  const icon = (): IconProp => {
    if (hasErrors) {
      return faExclamationTriangle;
    } else {
      if (status === Actions.NEW) {
        return faPlusCircle;
      }
      if (status === Actions.UPDATE) {
        return faCheckCircle;
      }
    }
    if (status === Actions.DELETED) {
      return faMinusCircle;
    }
    if (status === Actions.NOTFOUND) {
      return faExclamationCircle;
    }
    return faCircle;
  };
  return (
    <UncontrolledTooltip key={index} message={text}>
      <FontAwesomeIcon fixedWidth={true} icon={icon()} />
    </UncontrolledTooltip>
  );
};

export default GenericUpdateTooltip;
