import { Heading } from "components/Theme/heading";
import { Form, Formik } from "formik";
import React from "react";
import { appConfig } from "../../../../Config";
import { ErrorBanner } from "../../../../components/Error/ErrorBanner";
import { ButtonGroupField } from "../../../../components/Forms/ButtonGroup";
import NewTextFormComponent from "../../../../components/Forms/NewTextFormComponent";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import { Button } from "../../../../components/Theme/button";
import useUpdateEntity from "../../../../hooks/updateEntity";
import type { BasicAuthCredentials } from "../../../../openapi/model/basicAuthCredentials";
import type { GatewayAsset } from "../../../../openapi/model/gatewayAsset";
import type { PublicKeyCredentials } from "../../../../openapi/model/publicKeyCredentials";

interface connectivitySettings {
  postUrl: string;
  sftpServer: string;
  username: string;
  password?: string;
  authenticationType: "basic_auth" | "public_key";
  publicKey?: string;
}

function isCredentialsBasic(
  credentials: BasicAuthCredentials | PublicKeyCredentials,
): credentials is BasicAuthCredentials {
  return (credentials as BasicAuthCredentials).type === "basic_auth";
}

function isCredentialsPublicKey(
  credentials: BasicAuthCredentials | PublicKeyCredentials,
): credentials is PublicKeyCredentials {
  return (credentials as PublicKeyCredentials).type === "public_key";
}

function GatewayConnectionDisplay(props: { gateway: GatewayAsset }) {
  const { gateway } = props;

  const { updating, updateEntity, error } = useUpdateEntity(
    "ASSET",
    gateway.assetId,
  );

  const isCMe2100 = gateway.model && gateway.model.indexOf("21") !== -1;

  const initialValues: connectivitySettings = {
    postUrl: isCMe2100
      ? `${appConfig().hes.cme2100}${gateway.companyId}`
      : `${appConfig().hes.cme}/${gateway.companyId}`,
    sftpServer: window.runConfig?.sftpServer || "unknown",
    username: gateway.credentials.username || "",
    password: isCredentialsBasic(gateway.credentials)
      ? gateway.credentials.password
      : "",
    publicKey: isCredentialsPublicKey(gateway.credentials)
      ? gateway.credentials.publicKey
      : "",
    authenticationType: gateway.credentials.type as "basic_auth" | "public_key",
  };

  const canSwitch: boolean = gateway.manufacturer === "evinox";

  const submit = (formValues: connectivitySettings) => {
    updateEntity({
      ...gateway,
      credentials: {
        ...gateway.credentials,
        type: formValues.authenticationType,
        publicKey: formValues.publicKey,
        password: formValues.password,
      },
    });
  };
  return (
    <>
      <Heading>Gateway Connectivity Settings</Heading>

      <Formik initialValues={initialValues} onSubmit={submit}>
        {({ values }) => {
          const basicAuth: boolean = values.authenticationType === "basic_auth";

          return (
            <Form>
              <BlockSpinner loading={updating}>
                {canSwitch && (
                  <ButtonGroupField
                    fieldName={"authenticationType"}
                    label={{ label: "Credentials type" }}
                    options={[
                      {
                        value: "basic_auth",
                        label: "Basic",
                      },
                      {
                        value: "public_key",
                        label: "Key pair",
                      },
                    ]}
                  />
                )}

                {gateway.manufacturer === "elvaco" ? (
                  <NewTextFormComponent
                    fieldName={"postUrl"}
                    label={{ label: "Post URL" }}
                    staticValue
                    disabled
                    clipboard
                  />
                ) : (
                  <NewTextFormComponent
                    fieldName={"sftpServer"}
                    label={{ label: "SFTP Server" }}
                    staticValue
                    disabled
                    clipboard
                  />
                )}
                <NewTextFormComponent
                  fieldName={"username"}
                  label={{ label: "Username" }}
                  staticValue
                  disabled
                  clipboard
                />
                {basicAuth ? (
                  <NewTextFormComponent
                    fieldName={"password"}
                    label={{ label: "Password" }}
                    staticValue
                    disabled
                    clipboard
                  />
                ) : (
                  <NewTextFormComponent
                    fieldName={"publicKey"}
                    label={{ label: "SSH Public Key" }}
                  />
                )}
                {gateway.manufacturer === "evinox" && (
                  <>
                    <span className={"float-right"}>
                      <Button
                        type={"submit"}
                        color={"brandLight"}
                        disabled={updating}
                      >
                        Update SSH Settings
                      </Button>
                    </span>
                    <ErrorBanner error={error} />
                  </>
                )}
              </BlockSpinner>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default GatewayConnectionDisplay;
