import type React from "react";
import { BulkUpdateStatus } from "../../openapi/model/bulkUpdateStatus";
import type { BulkUpdateSummary } from "../../openapi/model/bulkUpdateSummary";
import CompanyNavLink from "../navigation/CompanyNavLink";
import { OneCardPage } from "../utils/OneCardPage";

import GetList, { type PaginatedListResultProps } from "core/action/GetList";
import { snakeCase, startCase } from "lodash-es";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import { useRequiredSelectedCompanyId } from "../../reducers/company";
import { useSelectedSchemeId } from "../../reducers/scheme";
import ApiPaginatedTable from "../Pagination/NewApiPaginatedTable";
import { formatDate } from "../utils/HighlightNullAndOldDates";

interface Props {
  objectType: string;
}

const BulkUpdateList = (props: Props): React.ReactElement => {
  const type_url = snakeCase(props.objectType);

  const header = (
    <tr>
      <th scope="col" className="text-left">
        Filename
      </th>
      <th scope="col" className="text-left">
        Status
      </th>
      <th scope="col" className="text-left">
        Created At
      </th>
      <th scope="col" className="text-left">
        Executed At
      </th>
      <th scope="col" className="text-left">
        New
      </th>
      <th scope="col" className="text-left">
        Updates
      </th>
      <th scope="col" className="text-left">
        Unchanged
      </th>
      <th scope="col" className="text-left">
        Invalid
      </th>
      <th scope={"col"}>&nbsp;</th>
    </tr>
  );

  const row = (value: BulkUpdateSummary): React.ReactElement => {
    const invalid =
      (value.previewStats.newInvalid ?? 0) +
      (value.previewStats.updateInvalid ?? 0) +
      (value.previewStats.notFound ?? 0);

    return (
      <tr data-cy="updates-list-item">
        <td data-cy="update-filename">{value.filename}</td>
        <td data-cy="update-status">{value.status}</td>
        <td data-cy={"update-created"}>{formatDate(value.createdAt)}</td>
        <td data-cy={"update-executed"}>
          {value.executedAt ? formatDate(value.executedAt) : "N/A"}
        </td>
        {value.status === BulkUpdateStatus.PREVIEWCREATED ? (
          <>
            <td data-cy={"update-new"}>{value.previewStats.newValid}</td>
            <td data-cy={"update-updated"}>{value.previewStats.updateValid}</td>
            <td data-cy={"update-unchanged"}>{value.previewStats.unchanged}</td>
            <td data-cy={"update-invalid"}>{invalid}</td>
          </>
        ) : (
          <>
            <td data-cy={"update-new"}>
              {value.executeStats?.created}/{value.previewStats.newValid}
            </td>
            <td data-cy={"update-updated"}>
              {value.executeStats?.updated}/{value.previewStats.updateValid}
            </td>
            <td data-cy={"update-unchanged"}>{value.previewStats.unchanged}</td>
            <td data-cy={"update-invalid"}>{invalid}</td>
          </>
        )}
        <td data-cy={"update-actions"}>
          <CompanyNavLink
            to={{ pathname: `/admin/${type_url}/updates/${value.updateId}` }}
            button={{
              color: "brandLight",
            }}
          >
            View
          </CompanyNavLink>
        </td>
      </tr>
    );
  };

  const title = startCase(`${props.objectType}s`);

  const companyId = useRequiredSelectedCompanyId();
  const schemeId = useSelectedSchemeId();

  return (
    <GetList
      entityType={"UPDATE"}
      params={{
        companyId: companyId,
        schemeId: schemeId,
        objectType: props.objectType,
      }}
    >
      {({
        loading,
        error,
        data,
        refresh,
      }: PaginatedListResultProps<BulkUpdateSummary>) => (
        <OneCardPage
          headerTitle={`${title} Updates`}
          breadcrumbs={[
            { url: `/admin/${type_url}`, name: title },
            { url: `/admin/${type_url}/update`, name: "Bulk Update" },
          ]}
          setPageTitle={schemeObjectPageTitle(
            `Bulk Updates (${props.objectType})`,
          )}
        >
          <ApiPaginatedTable
            data={data}
            error={error}
            loading={loading}
            refresh={refresh}
            header={[header, 9]}
            row={row}
          />
        </OneCardPage>
      )}
    </GetList>
  );
};

export default BulkUpdateList;
