import type React from "react";
import type { AssetPosition } from "../../../openapi/model/assetPosition";
import GatewayConnectivityReport from "../assets/gateway/GatewayConnectivityReport";

export default function WirelessClusterGatewayAddList(props: {
  clusterPosition: AssetPosition;
}): React.ReactElement {
  const { clusterPosition } = props;

  return (
    <GatewayConnectivityReport
      companyId={clusterPosition.companyId}
      schemeId={clusterPosition.schemeId}
      clusterForAssignment={clusterPosition}
      parentAssetPositionId={"NONE"}
    />
  );
}
