import type React from "react";
import AlertBanner from "../Banner/AlertBanner";

const UnusedColumnsBanner = (props: {
  unusedColumns: string[];
}): React.ReactElement => {
  if (props.unusedColumns.length === 0) {
    return <></>;
  }
  if (props.unusedColumns.length === 1) {
    return (
      <AlertBanner>
        The {props.unusedColumns[0]} column in your spreadsheet has been ignored
        as as it is not a valid property.
      </AlertBanner>
    );
  } else {
    return (
      <AlertBanner>
        Columns in your spreadsheet have been ignored as they are not valid
        properties. They are {props.unusedColumns.join(", ")}.
      </AlertBanner>
    );
  }
};

export default UnusedColumnsBanner;
