import { Form, Formik, type FormikHelpers } from "formik";
import type React from "react";
import { useState } from "react";
import * as Yup from "yup";
import AlertBanner from "../../../../components/Banner/AlertBanner";
import { ErrorBanner } from "../../../../components/Error/ErrorBanner";
import NewTextFormComponent from "../../../../components/Forms/NewTextFormComponent";
import {
  yupRequiredString,
  yupString,
} from "../../../../components/Forms/yupValidators";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import { Button } from "../../../../components/Theme/button";
import useCreateEntity from "../../../../hooks/createEntity";
import type { AssetPosition } from "../../../../openapi/model/assetPosition";
import type { AssetType } from "../../../../openapi/model/assetType";
import type { DeviceAsset } from "../../../../openapi/model/deviceAsset";
import type { VirtualAssetDefinition } from "../../../../openapi/model/virtualAssetDefinition";
import { useSelectedCompany } from "../../../../reducers/company";
import AssetTypeSelect from "../AssetTypeSelect";

interface FormValues {
  assetType?: AssetType;
  customerReference?: string;
  sourceField?: string;
}

interface PProps {
  parentAsset: DeviceAsset;
  parentAssetPosition: AssetPosition;
}

const VirtualAssetCreateForm = (props: PProps): React.ReactElement => {
  const { parentAssetPosition } = props;
  const company = useSelectedCompany();

  const [error, setError] = useState<Error | undefined>(undefined);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  const { createEntity } = useCreateEntity<VirtualAssetDefinition>(
    "ASSET",
    `asset_register/${parentAssetPosition.assetPositionId}/virtual`,
  );

  const initialValue: FormValues = {
    customerReference: "",
    sourceField: "",
  };

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    setSuccess(undefined);
    createEntity({
      assetType: values.assetType,
      assetPositionCustomerReference: values.customerReference,
      assetConfiguration: { virtual: { field: values.sourceField } },
      companyId: company?.companyId,
    })
      .then(() => {
        setSuccess(true);
        resetForm();
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValue}
      validationSchema={Yup.object().shape({
        assetType: yupRequiredString,
        customerReference: yupString,
      })}
      validateOnMount={true}
    >
      {({ isValid, isSubmitting }): React.ReactElement => (
        <Form data-cy={"asset-position-create"}>
          <BlockSpinner loading={isSubmitting}>
            <h3 className="text-white mb-0">Add Virtual Asset</h3>
            <AssetTypeSelect fieldName={"assetType"} includeSelect={true} />
            <NewTextFormComponent
              fieldName={"customerReference"}
              label={{ label: "Customer Reference" }}
            />
            <NewTextFormComponent
              fieldName={"sourceField"}
              label={{ label: "Source Field" }}
            />
            <Button
              type={"submit"}
              color={"brandLight"}
              disabled={isSubmitting || !isValid}
            >
              Add Virtual Asset
            </Button>
            {error && <ErrorBanner error={error} />}
            {success && (
              <AlertBanner className={"bg-success"}>
                Virtual Asset Successfully Created.
              </AlertBanner>
            )}
          </BlockSpinner>
        </Form>
      )}
    </Formik>
  );
};

export default VirtualAssetCreateForm;
