import * as Sentry from "@sentry/react";
import axios, {
  type AxiosRequestConfig,
  type AxiosResponse,
  type CancelTokenSource,
  type ResponseType,
} from "axios";
import {
  camelCase,
  forEach,
  isArray,
  isNumber,
  isPlainObject,
  isString,
  snakeCase,
} from "lodash-es";
import Config from "../../Config";
import type { Cursor } from "../../openapi/model/cursor";
import { removeMapBlanks } from "../../utils/object";
import { getAuthHeader } from "./auth";

export function objectKeysToCamelCase(
  snakeCaseObject: { [key: string]: any },
  skipKeys: string[] = [],
  snakeCaseOnly = false,
): { [key: string]: any } {
  const camelCaseObject: { [key: string]: any } = {};
  forEach(snakeCaseObject, (value, key) => {
    const newValue = isArray(value)
      ? value.map((o) => {
          if (isPlainObject(o)) {
            return objectKeysToCamelCase(o, skipKeys, snakeCaseOnly);
          } else {
            return o;
          }
        })
      : isPlainObject(value)
        ? !(skipKeys && key in skipKeys)
          ? objectKeysToCamelCase(value, skipKeys, snakeCaseOnly)
          : value // recursively update keys of any values that are also objects
        : value;
    let newKey = key;
    if (!snakeCaseOnly || snakeCase(key) === key) {
      newKey = camelCase(key);
    }
    camelCaseObject[newKey] = newValue;
  });
  return camelCaseObject;
}

export function objectKeysToSnakeCase(camelCaseObject: {
  [key: string]: any;
}): { [key: string]: any } {
  const snakeCaseObject: { [key: string]: any } = {};
  if (!(isString(camelCaseObject) || isNumber(camelCaseObject))) {
    forEach(camelCaseObject, (value, key) => {
      if (isArray(value)) {
        snakeCaseObject[snakeCase(key)] = value.map((o) => {
          return objectKeysToSnakeCase(o);
        });
      } else if (isPlainObject(value) && !isString(value)) {
        snakeCaseObject[snakeCase(key)] = objectKeysToSnakeCase(value); // recursively update keys of any values that are also objects
      } else {
        snakeCaseObject[snakeCase(key)] = value;
      }
    });
    return snakeCaseObject;
  } else {
    return camelCaseObject;
  }
}

export function arrayValuesToCamelCase(snakeCaseArray: string[]): string[] {
  const camelCaseArray: string[] = [];
  forEach(snakeCaseArray, (value: string) => {
    camelCaseArray.push(camelCase(value));
  });
  return camelCaseArray;
}

export interface GetResponse {
  data: any;
  cursor?: Cursor;
  headers: any;
}

export const parseEtags = (response: GetResponse) => {
  return response.headers.xEtags ? JSON.parse(response.headers.xEtags) : {};
};

export async function DefaultGetHeaders(
  request: RequestHeaders = {},
): Promise<{ [key: string]: string }> {
  const auth = await getAuthHeader();
  const { traceId, spanId, eTag } = request;
  return removeMapBlanks({
    ...auth,
    Accept: "application/xls",
    "X-Transaction-ID": traceId,
    "X-Span-ID": spanId,
    "If-Match": eTag,
  });
}

export interface ApiService {
  get: (
    path: string,
    params: { [key: string]: string },
    convertKeys: boolean,
    binary: boolean,
    skipConvertKeys: string[],
    convertSnakeCaseOnly: boolean,
    cancel?: CancelTokenSource,
  ) => Promise<GetResponse>;
  post: (
    path: string,
    body: any,
    convertSnakeCaseOnly: boolean,
  ) => Promise<any>;
  del: (path: string) => Promise<any>;
  put: (path: string, body: any) => Promise<any>;
}

export interface HttpService {
  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R>;

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R>;

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<R>;

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<R>;
}

export interface RequestHeaders {
  traceId?: string;
  spanId?: string;
  eTag?: string;
}

export class StandardApiService implements ApiService {
  getHeaders: (request?: RequestHeaders) => Promise<{ [key: string]: string }>;
  private http: HttpService;

  constructor(
    http: HttpService = axios,
    getHeaders: (
      request?: RequestHeaders,
    ) => Promise<{ [key: string]: string }> = DefaultGetHeaders,
  ) {
    this.http = http;
    this.getHeaders = getHeaders;
  }

  get = async (
    path: string,
    params: { [key: string]: string | number | undefined },
    convertKeys = true,
    binary = false,
    skipConvertKeys: string[] = [],
    convertSnakeCaseOnly = false,
    cancel?: CancelTokenSource,
    responseType?: ResponseType,
  ): Promise<GetResponse> => {
    const activeSpan = Sentry.getActiveSpan();
    const rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : undefined;

    const sentryTraceHeader = rootSpan
      ? Sentry.spanToTraceHeader(rootSpan)
      : undefined;

    const sentryBaggageHeader = rootSpan
      ? Sentry.spanToBaggageHeader(rootSpan)
      : undefined;
    const p: { [key: string]: string | number } = {};

    for (const [key, value] of Object.entries(params ? params : {})) {
      if (value !== null && value !== undefined) {
        p[key] = value;
      }
    }

    return (
      this.http
        .get(Config.apiGateway.URL + path, {
          params: objectKeysToSnakeCase(p),
          responseType: responseType || (binary ? "arraybuffer" : "json"),
          cancelToken: cancel?.token,
          headers: removeMapBlanks({
            baggage: sentryBaggageHeader,
            "sentry-trace": sentryTraceHeader,
          }),
          // credentials: 'include'
        })
        // .then(handleErrors)
        .then((res) => {
          let data: { [key: string]: any };
          if (convertKeys) {
            if (isArray(res.data)) {
              data = res.data.map((d) =>
                objectKeysToCamelCase(d, skipConvertKeys, convertSnakeCaseOnly),
              );
            } else {
              data = objectKeysToCamelCase(
                res.data,
                skipConvertKeys,
                convertSnakeCaseOnly,
              );
            }
          } else {
            data = res.data;
          }
          let cursor: Cursor = {};
          if (res.headers["x-cursor"]) {
            cursor = objectKeysToCamelCase(JSON.parse(res.headers["x-cursor"]));
          }
          const headers = objectKeysToCamelCase(res.headers);
          return { data, cursor, headers };
        })
    );
  };

  post = async (
    path: string,
    body: any,
    convertSnakeCaseOnly = false,
  ): Promise<any> => {
    const data = isArray(body)
      ? body.map(objectKeysToSnakeCase)
      : objectKeysToSnakeCase(body);

    return (
      this.http
        .post(Config.apiGateway.URL + path, data, {
          headers: await this.getHeaders(),
        })
        // .then(handleErrors)
        .then((res) => {
          return isArray(res.data)
            ? res.data.map((x) =>
                objectKeysToCamelCase(x, undefined, convertSnakeCaseOnly),
              )
            : objectKeysToCamelCase(res.data, undefined, convertSnakeCaseOnly);
        })
    );
  };

  del = async (path: string): Promise<any> => {
    return this.http.delete(Config.apiGateway.URL + path, {
      // credentials: 'include',
      headers: await this.getHeaders(),
    });
  };

  put = async (path: string, body: any, eTag?: string): Promise<any> => {
    return this.http.put(
      Config.apiGateway.URL + path,
      objectKeysToSnakeCase(body),
      {
        headers: await this.getHeaders({ eTag }),
      },
    );
  };
}
