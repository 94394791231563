import { ErrorBanner } from "components/Error/ErrorBanner";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import { Formik } from "formik";
import CreateActionButton from "pages/assetRegister/assetActions/CreateActionButton";
import type React from "react";
import { useState } from "react";
import * as Yup from "yup";
import SuccessBanner from "../../../../../components/Banner/SuccessBanner";
import BlockSpinner from "../../../../../components/Spinners/BlockSpinner";
import { Button } from "../../../../../components/Theme/button";
import useUpdateEntity from "../../../../../hooks/updateEntity";
import type { DeviceAsset } from "../../../../../openapi/model/deviceAsset";
import type { WirelessReceiverConfiguration } from "../../../../../openapi/model/wirelessReceiverConfiguration";
import WirelessReceiverConfigurationForm, {
  WirelessReceiverConfigurationFormSchema,
} from "./WirelessReceiverConfigurationForm";

interface Configuration {
  receiver: WirelessReceiverConfiguration;
}

export default function DeviceConfigurationTab(props: {
  asset: DeviceAsset;
}): React.ReactElement {
  const configuration: WirelessReceiverConfiguration | undefined = props.asset
    .configuration as WirelessReceiverConfiguration | undefined;

  const [updated, setUpdated] = useState(false);
  const { updateEntity, updating, error } = useUpdateEntity(
    "ASSET",
    props.asset.assetId,
    () => {
      setUpdated(true);
    },
  );

  const initialValues: Configuration = {
    receiver: {
      mbusBaudRate: configuration?.mbusBaudRate || 2400,
      ageLimit: configuration?.ageLimit || 1440,
    },
  };

  function submit(values: Configuration) {
    setUpdated(false);
    updateEntity({
      ...props.asset,
      configuration: values.receiver,
    });
  }

  return (
    <div>
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          receiver: WirelessReceiverConfigurationFormSchema,
        })}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {({ submitForm, isValid }): React.ReactElement => {
          return (
            <BlockSpinner loading={updating}>
              <WirelessReceiverConfigurationForm namespace={"receiver"} />
              <DisplayIfUserType userTypes={"admin"}>
                <Button
                  type={"submit"}
                  color={"brandLight"}
                  onClick={submitForm}
                  disabled={updating || !isValid}
                >
                  Save
                </Button>
                {updated && (
                  <SuccessBanner>
                    Configuration was updated successfully.
                  </SuccessBanner>
                )}
                <ErrorBanner error={error} />
              </DisplayIfUserType>
            </BlockSpinner>
          );
        }}
      </Formik>
      <DisplayIfUserType userTypes={"admin"}>
        <CreateActionButton
          asset={props.asset}
          actionType={"SYNC_DEVICE_LIST"}
          actionName={"Set AES keys"}
        />
        <CreateActionButton
          asset={props.asset}
          actionType={"SYNC_CONFIG"}
          actionName={"Update receiver settings"}
        />
      </DisplayIfUserType>
    </div>
  );
}
