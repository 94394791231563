import { ErrorBanner } from "components/Error/ErrorBanner";
import dayjs from "dayjs";
import { isString } from "lodash-es";
import { ActionTypes } from "openapi/model/actionTypes";
import type React from "react";
import { useEffect, useState } from "react";
import { Button } from "../../../../components/Theme/button";
import useCreateEntity from "../../../../hooks/createEntity";
import type { Asset } from "../../../../openapi/model/asset";
import type { InputOutputModuleState } from "../../../../openapi/model/inputOutputModuleState";
import { RelayState } from "../../../../openapi/model/relayState";
import type { RelayStateCommand } from "../../../../openapi/model/relayStateCommand";

export const getRefreshDelay = (
  state: InputOutputModuleState,
): number | undefined => {
  if (
    state.relayState &&
    [RelayState.SWITCHINGON, RelayState.SWITCHINGOFF].includes(state.relayState)
  ) {
    const now = new Date();
    let update = state.lastUpdate;
    if (update !== undefined) {
      if (isString(update)) {
        update = dayjs(update).toDate();
      }
      const diff = now.valueOf() - update.valueOf();
      switch (true) {
        case diff <= 2 * 60 * 1000:
          return 10000;
        case diff <= 10 * 60 * 1000:
          return 20000;
        default:
          return 60000;
      }
    } else {
      return 60000;
    }
  }
  return 0;
};

const SwitchRelayStateButtons = (props: {
  asset: Asset;
  refresh?: () => void;
}): React.ReactElement => {
  const [assetStateSwitching, setAssetStateSwitching] =
    useState<boolean>(false);

  const state: InputOutputModuleState = props.asset
    .state as InputOutputModuleState;

  const { createEntity, error } = useCreateEntity(
    "ASSET",
    `asset/${props.asset.assetId}/action`,
  );
  const { refresh } = props;

  useEffect(() => {
    const delay = getRefreshDelay(state);
    if (delay && delay > 0 && refresh !== undefined) {
      const t = setTimeout(() => {
        refresh();
      }, delay);
      return () => clearTimeout(t);
    }
  }, [refresh, state]);

  const currentState: RelayState = state.relayState || RelayState.UNKNOWN;

  const switchableOn = [
    RelayState.OFF,
    RelayState.UNKNOWN,
    RelayState.SWITCHINGERROR,
  ].includes(currentState);
  const switchableOff = [
    RelayState.ON,
    RelayState.UNKNOWN,
    RelayState.SWITCHINGERROR,
  ].includes(currentState);
  const switching = [RelayState.SWITCHINGOFF, RelayState.SWITCHINGON].includes(
    currentState,
  );

  const buttonClick = (state: RelayStateCommand): void => {
    setAssetStateSwitching(true);
    createEntity({
      actionType: ActionTypes.SWITCHRELAY,
      companyId: props.asset.companyId,
      payload: { newRelayState: state },
    }).then(() => {
      if (props.refresh) {
        props.refresh();
      }
    });
  };

  return (
    <>
      <Button
        disabled={!switchableOn || assetStateSwitching}
        color={"green"}
        onClick={() => buttonClick("ON")}
        left={true}
      >
        Turn On
      </Button>
      <Button
        disabled={!switchableOff || assetStateSwitching}
        color={"red"}
        onClick={() => buttonClick("OFF")}
        right={true}
      >
        Turn Off
      </Button>
      {(switching || assetStateSwitching) && (
        <span className={"text=sm text-gray-600 italic"}>
          State is currently switching. No actions possible.
        </span>
      )}
      <ErrorBanner error={error} />
    </>
  );
};

export default SwitchRelayStateButtons;
