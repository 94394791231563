import type React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";
import { SpreadsheetFileType } from "../../../openapi/model/spreadsheetFileType";

export default function OutputFileTypeSelect(
  props: Omit<SelectComboFieldProps, "options">,
): React.ReactElement {
  const options: Option[] = [
    { label: "CSV", value: SpreadsheetFileType.CSV },
    { label: "XLSX", value: SpreadsheetFileType.XLSX },
  ];

  return <SelectComboField options={options} {...props} />;
}
