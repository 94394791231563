import type { Dayjs } from "dayjs";
import type { AssetDataQualityResponse } from "openapi/model/assetDataQualityResponse";
import type React from "react";
import useGetData from "../../../../hooks/getData";
import {
  type DeviceTimeSeriesData,
  convertTimesToDayjs,
  mapColumns,
} from "../../../../model/deviceTimeSeries";
import type { DeviceAsset } from "../../../../openapi/model/deviceAsset";
import type { GatewayAsset } from "../../../../openapi/model/gatewayAsset";
import type { AssetTimeSeriesRequestParams } from "./AssetDataSelectionForm";
import type {
  DeviceTimeSeriesDisplayComponent,
  DeviceTimeSeriesDisplayComponentProps,
} from "./model";

interface Props {
  device: DeviceAsset | GatewayAsset;
  searchParams: AssetTimeSeriesRequestParams;
  render: DeviceTimeSeriesDisplayComponent;
}

const AssetTimeSeriesQualityGetter = (props: Props): React.ReactElement => {
  const { device, searchParams } = props;
  const { assetId, companyId, schemeId } = device;

  const [{ data, loading, error }] = useGetData<AssetDataQualityResponse>({
    path: `data/asset/${assetId}/time_series/quality`,
    companyId,
    schemeId,
    params: {
      startDatetime: searchParams.startDatetime.format(),
      endDatetime: searchParams.endDatetime.format(),
    },
    singletonApi: true,
  });

  const timeseries: DeviceTimeSeriesData | undefined = data && {
    ...data,
    times: convertTimesToDayjs(data.times),
    errors: [],
  };

  const displayProps = (): DeviceTimeSeriesDisplayComponentProps => {
    if (data && timeseries) {
      return {
        mappedTimeSeriesColumns: mapColumns(timeseries),
        times: timeseries.times as Dayjs[],
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    } else {
      return {
        mappedTimeSeriesColumns: {},
        times: [],
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    }
  };

  return <>{props.render(displayProps())}</>;
};

export default AssetTimeSeriesQualityGetter;
