import DOMPurify from "dompurify";
import PostalMime, { type Email } from "postal-mime";
import React from "react";

interface EmailViewerProps {
  email: string;
}

export function createMarkup(dirty: string, purify = false) {
  if (purify) {
    return {
      __html: DOMPurify.sanitize(dirty, { FORCE_BODY: true }),
    };
  }

  return { __html: dirty };
}

export default function EmailViewer(
  props: EmailViewerProps,
): React.ReactElement {
  const [parsed, setParsed] = React.useState<Email | null>(null);

  React.useEffect(() => {
    const parse = async () => {
      setParsed(await PostalMime.parse(props.email));
    };
    parse();
  }, [props.email]);

  return (
    <div>
      {parsed && (
        <>
          <ul className={"text-sm"}>
            <li>
              <b>From:</b> {parsed.from.name} ({parsed.from.address})
            </li>
            <li>
              <b>To:</b>{" "}
              {parsed.to?.map((to) => (
                <span key={to.address}>
                  {to.name} ({to.address})
                </span>
              ))}
            </li>
            <li>
              <b>Title:</b> {parsed.subject}
            </li>
            <li>
              <b>Date:</b> {parsed.date}{" "}
            </li>
          </ul>
          {parsed.html ? (
            // biome-ignore lint/security/noDangerouslySetInnerHtml: <we have purified the email>
            <div dangerouslySetInnerHTML={createMarkup(parsed.html)} />
          ) : (
            <>{parsed.text}</>
          )}
        </>
      )}
    </div>
  );
}
