import type { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import {
  faEnvelope,
  faFile,
  faFileCode,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrettyBytes from "pretty-bytes";
import type React from "react";
import { useState } from "react";
import { loadFileFromApi } from "../../../../../actions/helpers/presigned_s3";
import PrivacyMode from "../../../../../components/Text/PrivacyMode";
import { TableCell, TableRow } from "../../../../../components/Theme/table";
import DownloadFileTooltip from "../../../../../components/downloadFileTooltip";
import DisplayIfUserType from "../../../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { formatDate } from "../../../../../components/utils/HighlightNullAndOldDates";
import type { AssetFile as GatewayFile } from "../../../../../openapi/model/assetFile";
import type { IntegrationFile } from "../../../../../openapi/model/integrationFile";
import { getGatewayFileFromApi, isGatewayFile } from "../model";
import GatewayFilePreviewButton from "./GatewayFilePreviewButton";
import GatewayFileStatusBadge from "./GatewayFileStatusBadge";
import ResendDataFileButton from "./ResendDataFileButton";

export function isIntegrationFile(
  file: GatewayFile | IntegrationFile,
): file is IntegrationFile {
  return (file as IntegrationFile).assetPositionId !== undefined;
}

function getFileIcon(fileType: string): [IconDefinition, string] {
  switch (fileType.toLowerCase()) {
    case "mime":
      return [faEnvelope, "text-gray-500"];
    case "csv":
      return [faFileExcel, "text-green-500"];
    case "guru_systems_csv":
      return [faFileExcel, "text-green-500"];
    case "guru_systems":
      return [faFileCode, "text-blue-500"];
    default:
      return [faFile, "text-gray-500"];
  }
}

function GatewayFileListRow(props: {
  file: GatewayFile | IntegrationFile;
  index: number | string;
}): React.ReactElement {
  const { file, index } = props;
  const [loading, setLoading] = useState(false);

  const downloadable = file.size !== 0;

  const handleClick = async (): Promise<undefined> => {
    if (downloadable && !loading) {
      setLoading(true);
      await getGatewayFileFromApi(file);
      setLoading(false);
    }
    return;
  };

  const [icon, iconClass] = getFileIcon(file.type);

  return (
    <TableRow key={index}>
      <TableCell scope="row">
        <FontAwesomeIcon
          icon={icon}
          size="lg"
          className={iconClass}
          fixedWidth={true}
        />
      </TableCell>
      <TableCell className="text-left align-middle">
        <PrivacyMode>{file.filename}</PrivacyMode>
      </TableCell>
      <TableCell className="text-left  align-middle">
        {formatDate(file.created)}
      </TableCell>
      <TableCell className="text-left  align-middle">
        {file.size ? PrettyBytes(file.size) : "-"}
      </TableCell>
      <TableCell className="text-left  align-middle">
        <GatewayFileStatusBadge file={file} />
      </TableCell>
      <TableCell className="table-actions">
        <DownloadFileTooltip
          loading={loading}
          downloadable={downloadable}
          fileDescription={"Download raw file"}
          clickHandler={handleClick}
        />
        <DisplayIfUserType userTypes={"superadmin"}>
          <>
            <ResendDataFileButton
              file={file}
              id={`resend-${index}`}
              removeQuarantine={false}
            />
            <ResendDataFileButton
              file={file}
              id={`resend--rq-${index}`}
              removeQuarantine={true}
            />
          </>
        </DisplayIfUserType>
        <GatewayFilePreviewButton file={file} />
      </TableCell>
    </TableRow>
  );
}

export default GatewayFileListRow;
