import type React from "react";
import { ButtonGroupField } from "../../../components/Forms/ButtonGroup";

interface DataProcessingConfigurationFormProps {
  namespace: string;
}

export default function DataProcessingConfigurationForm(
  props: DataProcessingConfigurationFormProps,
): React.ReactElement {
  const { namespace } = props;
  const withNamespace = (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  };

  return (
    <>
      <h4>Data Processing</h4>
      <ButtonGroupField
        fieldName={withNamespace("autoCreateNewAssets")}
        label={{ label: "Auto Create New Assets" }}
        options={[
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ]}
      />
    </>
  );
}
