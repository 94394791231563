import type { Dayjs } from "dayjs";
import { lowerCase } from "lodash-es";
import type React from "react";
import DataTable, {
  type TableColumn,
  type ConditionalStyles,
} from "react-data-table-component";
import { ErrorBanner } from "../../../../components/Error/ErrorBanner";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import numberFormatter from "../../../../components/Table/Formatters";
import { formatDate } from "../../../../components/utils/HighlightNullAndOldDates";
import type {
  ColumnMap,
  TimeSeriesDataParams,
} from "../../../../model/deviceTimeSeries";
import type { DeviceAsset } from "../../../../openapi/model/deviceAsset";
import AssetDataSelectorUrlQuerySetter from "../AssetDataSelectorUrlQuerySetter";
import AssetDataSearchStringParser from "./AssetDataSearchStringParser";
import DeviceTimeSeriesDataContainer from "./DeviceTimeSeriesDataContainer";
import DeviceTimeSeriesDataCsvExportButton from "./DeviceTimeSeriesDataCsvExportButton";

const AssetTimeSeriesDataTableInner = (props: {
  mappedTimeSeriesColumns: ColumnMap;
  times: Dayjs[];
  params: TimeSeriesDataParams;
  loading: boolean;
  error?: Error;
}): React.ReactElement => {
  const columns: TableColumn<{ [key: string]: any }>[] = Object.entries(
    props.mappedTimeSeriesColumns,
  ).reduce(
    (accumulator, [key, columnDefinition]) => {
      return [
        ...accumulator,
        {
          name: (
            <span
              style={{
                textAlign: "right",
                whiteSpace: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {columnDefinition.name}
              {columnDefinition.unit !== "" && (
                <small>
                  <br />({columnDefinition.unit})
                </small>
              )}
            </span>
          ),
          right: true,
          minWidth:
            lowerCase(props.params.projection) === "raw" ? "175px" : "100px",
          selector: (row) => row[key],
          sortable: true,
          format: numberFormatter(key),
          omit: columnDefinition.name === "errors",
        },
      ];
    },
    [
      {
        name: "Time",
        selector: (row) => row.time,
        sortable: true,
        minWidth: "150px",
        format: (row): string => formatDate(row.time as Date),
      },
    ],
  );

  const data: any[] = props.times.reduce(
    (accumulator, time, index) => {
      return [
        ...accumulator,
        Object.entries(props.mappedTimeSeriesColumns).reduce(
          (d, [key, columnDefinition]) => {
            return {
              ...d,
              [key]: columnDefinition.data[index],
            };
          },
          { time: time } as { [key: string]: any },
        ),
      ];
    },
    [] as { [key: string]: any }[],
  );

  const rowHasError = (row: any): boolean => {
    if (row.errors === null || row.errors === undefined) {
      return false;
    } else {
      return row.errors.length > 0;
    }
  };

  const rowHighlighter: ConditionalStyles<{ [key: string]: string }> = {
    when: rowHasError,
    style: { backgroundColor: "orange" },
  };

  return (
    <BlockSpinner loading={props.loading}>
      <span className={"float-right"}>
        <DeviceTimeSeriesDataCsvExportButton {...props} />
      </span>
      <ErrorBanner error={props.error} />
      <DataTable
        columns={columns}
        data={data}
        pagination
        fixedHeader
        responsive={true}
        dense={true}
        conditionalRowStyles={[rowHighlighter]}
      />
    </BlockSpinner>
  );
};

const AssetTimeSeriesDataTableTab = (props: {
  device: DeviceAsset;
}): React.ReactElement => {
  return (
    <>
      <AssetDataSearchStringParser device={props.device}>
        {({ searchParams }) => {
          return (
            <>
              <AssetDataSelectorUrlQuerySetter
                device={props.device}
                {...searchParams}
              />
            </>
          );
        }}
      </AssetDataSearchStringParser>
      <DeviceTimeSeriesDataContainer
        device={props.device}
        render={AssetTimeSeriesDataTableInner}
      />
    </>
  );
};

export default AssetTimeSeriesDataTableTab;
