import React from "react";
import { Heading } from "../../../components/Theme/heading";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import AccountList from "../../../containers/account/AccountList";
import NewAccountForm from "../../../containers/account/NewAccountForm";
import useCreateEntity from "../../../hooks/createEntity";
import type { Account } from "../../../openapi/model/account";
import type { Location } from "../../../openapi/model/location";
import type { NewAccount } from "../../../openapi/model/newAccount";

export interface LocationAccountTabProps {
  location: Location;
}

export default function LocationAccountTab(props: LocationAccountTabProps) {
  const createAccount = useCreateEntity<Account, NewAccount>("ACCOUNT");

  return (
    <>
      <Heading>Accounts</Heading>
      <AccountList
        locationId={props.location.locationId}
        companyId={props.location.companyId}
      />
      <DisplayIfUserType userTypes={"superadmin"}>
        <Heading>Create New Account</Heading>

        <NewAccountForm location={props.location} create={createAccount} />
      </DisplayIfUserType>
    </>
  );
}
