import type React from "react";
import AlertBanner from "../../components/Banner/AlertBanner";

const LoginErrorBanner = (props: { authError?: any }): React.ReactElement => {
  return (
    <>
      {props.authError &&
        (["NotAuthorizedException", "UserNotFoundException"].includes(
          props.authError.code,
        ) ? (
          <AlertBanner className={"mt-3 bg-red-700"}>
            <p className={"text-lg font-weight-bold"}>Login failed</p>
            <p> Your email address or password are not valid.</p>
            <p>Please try again.</p>
          </AlertBanner>
        ) : (
          <AlertBanner className={"mt-3 bg-red-700"}>
            <p className={"text-lg font-weight-bold"}>Login failed</p>
            <p>{props.authError.message}</p>
            <p>Please try again.</p>
          </AlertBanner>
        ))}
    </>
  );
};

export default LoginErrorBanner;
