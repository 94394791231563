import React from "react";
import { TimeFilter } from "../../openapi/model/timeFilter";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../Forms/SelectCombo/SelectCombo";

const timeFilterOptions: Option[] = [
  { label: "All", value: TimeFilter.ALL },
  { label: "30 minute", value: TimeFilter.MINUTE30 },
  { label: "Hourly", value: TimeFilter.HOURLY },
  { label: "Daily", value: TimeFilter.DAILY },
];

type TimeFilterSelectProps = Omit<SelectComboFieldProps, "options">;

function TimeFilterSelect(props: TimeFilterSelectProps) {
  return <SelectComboField {...props} options={timeFilterOptions} />;
}

export default TimeFilterSelect;
