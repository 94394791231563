import type React from "react";
import { ErrorBanner } from "../../../../../components/Error/ErrorBanner";
import BlockSpinner from "../../../../../components/Spinners/BlockSpinner";
import useGetData from "../../../../../hooks/getData";
import type { AssetPosition } from "../../../../../openapi/model/assetPosition";
import type { WirelessReceiverLink } from "../../../../../openapi/model/wirelessReceiverLink";

interface Props {
  assetPosition: AssetPosition;
  children: (props: {
    history: Array<WirelessReceiverLink>;
  }) => React.ReactNode;
}

function ReceiverHistoryGetter(props: Props) {
  const { assetPosition } = props;
  const { assetPositionId, companyId, schemeId } = assetPosition;

  const [{ data, loading, error }] = useGetData<Array<WirelessReceiverLink>>({
    path: `asset_position/${assetPositionId}/wireless_receivers/history`,
    companyId,
    schemeId,
    params: {},
    post: false,
    singletonApi: true,
  });

  return (
    <BlockSpinner loading={loading}>
      {data && props.children({ history: data })}
      {error && <ErrorBanner error={error} />}
    </BlockSpinner>
  );
}

export default ReceiverHistoryGetter;
