import type React from "react";
import type { Scheme } from "../../openapi/model/scheme";
import { useSchemes } from "../../reducers/scheme";
import PrivacyMode from "../Text/PrivacyMode";

const SchemeLabel = (props: {
  scheme?: Scheme;
  schemeId?: string;
}): React.ReactElement => {
  const allSchemes = useSchemes();
  if (props.scheme) {
    return <span>{props.scheme.schemeName}</span>;
  }
  if (props.schemeId) {
    const schemes = allSchemes
      ? allSchemes.filter((scheme) => scheme.schemeId === props.schemeId)
      : [];
    if (schemes.length === 1) {
      return (
        <span>
          <PrivacyMode>{schemes[0].schemeName}</PrivacyMode>
        </span>
      );
    } else {
      return (
        <span>
          <PrivacyMode>{props.schemeId}</PrivacyMode>
        </span>
      );
    }
  }
  throw Error("One of scheme or schemeId must be set");
};

export default SchemeLabel;
