import type React from "react";
import SelectedScheme from "../../../../components/Scheme/SelectedScheme";
import SearchParamPage from "../../../../components/Search/SearchParamPage";
import { OneCardPage } from "../../../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../../../hooks/setPageTitle";
import AssetList from "./AssetList";
import AssetListFilterForm from "./AssetListFilterForm";

const InnerAssetListPage = (props: any): React.ReactElement => {
  return (
    <SelectedScheme>
      <AssetList {...props} />
    </SelectedScheme>
  );
};
const AssetListPage = (): React.ReactElement => {
  return (
    <OneCardPage
      headerTitle={"Asset List"}
      breadcrumbs={[{ url: "/admin/asset", name: "Asset List" }]}
      setPageTitle={schemeObjectPageTitle("Assets")}
    >
      <AssetListFilterForm
        includeGroup={false}
        includeUseCase={false}
        includeStatus={false}
        includeTextSearch={true}
      />
      <SearchParamPage
        initialState={{
          assetType: undefined,
          assetPositionId: undefined,
          textSearch: undefined,
        }}
      >
        <InnerAssetListPage sort={"serial_number"} />
      </SearchParamPage>
    </OneCardPage>
  );
};

export default AssetListPage;
