import type { Company } from "openapi/model/company";
import type React from "react";
import { useContext, useEffect } from "react";
import PageSpinner from "../../components/Spinners/PageSpinner";
import useGetList from "../../hooks/getList";
import { CompanyContext } from "../../reducers/company";
import CompanySelectInit from "./CompanySelectInit";

const CompanyListInit = (props: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { state, dispatch } = useContext(CompanyContext);
  const { stale } = state;

  const [{ data, loading, error }, refresh] = useGetList<Company>(
    "COMPANY",
    {},
    { maxItems: 250 },
  );

  useEffect(() => {
    if (data !== undefined) {
      dispatch({ type: "LOADING_COMPANIES_SUCCESS", payload: data.data });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (loading && !stale) {
      dispatch({ type: "LOADING_COMPANIES_STARTED" });
    }
  }, [loading, stale, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch({ type: "LOADING_COMPANIES_ERROR", payload: error });
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (stale) {
      refresh();
    }
  }, [stale, refresh]);

  return (
    <>
      <PageSpinner spin={loading}>
        {state.companies && (
          <CompanySelectInit>{props.children}</CompanySelectInit>
        )}
      </PageSpinner>
    </>
  );
};

export default CompanyListInit;
