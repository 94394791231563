import { isArray, isNil, isString, startCase } from "lodash-es";
import type { ParsedQs } from "qs";
import type React from "react";
import type { GroupBase } from "react-select";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";
import { AssetType } from "../../../openapi/model/assetType";
import { convertStringToOpenApiEnum } from "../../../utils/enum";

function stringListToOptions(lst: (string | undefined)[]): Option[] {
  return lst
    .filter((v) => v)
    .map((v): Option => {
      return {
        value: v as string,
        label: startCase(v),
      };
    });
}

export function matchStringsToAssetTypeEnum(
  lst: string | string[] | ParsedQs | ParsedQs[] | undefined,
): AssetType[] | undefined {
  if (isNil(lst)) {
    return undefined;
  }
  if (isArray(lst)) {
    return lst
      .map((v) => {
        if (isString(v)) {
          return convertStringToOpenApiEnum<AssetType>(v, AssetType);
        } else {
          return undefined;
        }
      })
      .filter((v) => !isNil(v)) as AssetType[];
  }
  const val = isString(lst)
    ? convertStringToOpenApiEnum<AssetType>(lst, AssetType)
    : undefined;
  if (val) {
    return [val as AssetType];
  }
}

export default function AssetTypeSelect(
  props: {
    allowUnknown?: boolean;
    includeSelect?: boolean;
    includeNonCreateables?: boolean;
    includeWirelessCluster?: boolean;
    displayGroups?: boolean;
  } & Omit<SelectComboFieldProps, "options">,
): React.ReactElement {
  const {
    includeSelect,
    includeNonCreateables,
    includeWirelessCluster,
    allowUnknown,
    ...rest
  } = props;

  const meterOptions = [
    "cooling_meter",
    "electricity_meter",
    "gas_meter",
    "heat_meter",
    "heating_and_cooling_meter",
    "water_meter",
  ];

  const infrastructureOptions = [
    "gateway",
    "wireless_receiver",
    includeWirelessCluster ? "wireless_cluster" : undefined,
    includeNonCreateables ? "sim_card" : undefined,
  ];

  const otherOptions = [
    "room_sensor",
    "co2_room_sensor",
    "input_output_module",
    allowUnknown ? "unknown" : undefined,
  ];

  const options: (Option | GroupBase<Option>)[] = [
    { label: "Meters", options: stringListToOptions(meterOptions) },
    {
      label: "Infrastructure",
      options: stringListToOptions(infrastructureOptions),
    },
    { label: "Other", options: stringListToOptions(otherOptions) },
  ];

  return (
    <SelectComboField
      label={props.label || { label: "Asset Type" }}
      options={
        includeSelect
          ? [{ label: "---Please Select---", value: "undefined" }, ...options]
          : options
      }
      {...rest}
    />
  );
}
