import type { Config } from "tailwindcss";
import forms from "@tailwindcss/forms";
import colors from "tailwindcss/colors";

export default {
  content: [
    "./index.html",
    "./src/**/*.{js,ts,jsx,tsx,.stories.tsx}",
    "./node_modules/react-tailwindcss-datepicker/dist/index.esm.{js,ts}",
  ],
  darkMode: "selector",

  theme: {
    fontFamily: {
      sans: ["Roboto", "Helvetica", "Arial", "sans-serif"],
    },

    extend: {
      colors: {
        "brand-light": {
          "50": "#eefdfd",
          "100": "#d3f6fa",
          "200": "#adecf4",
          "300": "#74dbec",
          "400": "#4ac9e0",
          DEFAULT: "#4ac9e0",
          "500": "#18a5c2",
          "600": "#1785a3",
          "700": "#196c85",
          "800": "#1d586d",
          "900": "#1d4a5c",
          "950": "#0d303f",
        },
        "brand-dark": {
          DEFAULT: "#061629",
          50: "#ABCCF3",
          100: "#9AC1F0",
          200: "#76ABEB",
          300: "#5296E6",
          400: "#2F80E1",
          500: "#1D6CC9",
          600: "#1859A6",
          700: "#134682",
          800: "#0E335E",
          900: "#09203B",
          950: "#061629",
        },
        success: colors.green["600"],
        danger: colors.red["700"],
        warning: colors.amber["600"],
      },
    },
  },
  plugins: [forms],
} satisfies Config;
