import type React from "react";

import Papa, { type ParseResult } from "papaparse";
import { useDropzone } from "react-dropzone";
import { Button } from "../Theme/button";

interface Props {
  parseComplete: (data: Promise<ParseResult<any>>, filename: string) => any;
  buttonLabel?: string;
}

const processAcceptFiles = (
  acceptedFiles: File[],
  parseComplete: (data: Promise<ParseResult<any>>, filename: string) => any,
): void => {
  const f = acceptedFiles[0];
  const parsePromise = new Promise<ParseResult<any>>((resolve, reject) => {
    Papa.parse<any, File>(f, {
      header: true,
      skipEmptyLines: true,
      complete(results) {
        resolve(results);
      },
      error(error: Error) {
        reject(error);
      },
    });
  });
  parseComplete(parsePromise, f.name);
};

const BulkUpdateCSVDropZone = (props: Props): React.ReactElement => {
  const { buttonLabel = "Upload update file", parseComplete } = props;

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles): void => {
      processAcceptFiles(acceptedFiles, parseComplete);
    },
  });

  return (
    <Button {...getRootProps({ color: "brandLight" })}>
      <>
        <input {...getInputProps()} />
        {buttonLabel}
      </>
    </Button>
  );
};

export default BulkUpdateCSVDropZone;
