import type { Dayjs } from "dayjs";
import type React from "react";
import type {
  ColumnMap,
  TimeSeriesDataParams,
} from "../../../../../model/deviceTimeSeries";
import GenericCharts from "../GenericCharts";
// import HeatMeterFlowAndTemperatureChart from "./HeatMeterFlowAndTemperatureChart";

const ElectricityMeterCharts = (props: {
  mappedTimeSeriesColumns: ColumnMap;
  times: Dayjs[];
  params: TimeSeriesDataParams;
  loading: boolean;
  error?: Error;
}): React.ReactElement => {
  if (props.params.projection === "raw") {
    return <GenericCharts {...props} />;
  } else {
    return (
      <>
        <GenericCharts {...props} />
      </>
    );
  }
};

export default ElectricityMeterCharts;
