import { isArray, isNull, toString as loToString } from "lodash-es";
import type React from "react";
import DataTable, {
  type ConditionalStyles,
  type TableColumn,
} from "react-data-table-component";
import type { BulkUpdateInstruction } from "../../openapi/model/bulkUpdateInstruction";
import { Heading } from "../Theme/heading";
import GenericUpdateTooltip from "./GenericUpdateTooltip";

interface Props {
  title: string;
  list: BulkUpdateInstruction[];
  tableFieldHeaders: string[];
  tableFields: string[];
  existingMap: { [key: string]: any };
  actioned: boolean;
  listPrefix: string;
  objectKey: string;
}

const UpdateTooltip = (actioned: boolean, listPrefix: string) => {
  const UpdateTooltipFunc = (row: any, rowIndex: number): React.ReactNode => {
    const instruction: BulkUpdateInstruction = row.instruction;
    const status = instruction.type;
    const immutableChangeErrors = instruction.immutableChangeErrors
      ? instruction.immutableChangeErrors
      : [];
    const actionedError: string[] =
      instruction.executionResult && !instruction.executionResult.success
        ? [
            instruction.executionResult.error
              ? instruction.executionResult.error
              : "Unknown error",
          ]
        : [];
    const changes = instruction.changes ? instruction.changes : [];

    return (
      <GenericUpdateTooltip
        index={`${listPrefix}-${loToString(rowIndex + 1)}`}
        status={status}
        executed={actioned ? actioned : false}
        executedSuccess={
          instruction.executionResult
            ? instruction.executionResult.success
            : false
        }
        immutableChangeErrors={immutableChangeErrors}
        validationErrors={instruction.validationErrors}
        executionErrors={actionedError}
        changedProperties={changes}
      />
    );
  };
  return UpdateTooltipFunc;
};

const FieldCell = (
  row: any,
  _rowIndex: number,
  column: TableColumn<{ [key: string]: any }>,
): React.ReactNode => {
  const select = column.selector;
  const instruction: BulkUpdateInstruction = row.instruction;
  const original: any = row.original;
  const changes = instruction.changes || [];
  const thisFieldValidChange =
    changes.length > 0 ? changes.includes(loToString(column.id)) : false;

  if (select === undefined) {
    throw new Error("No selector");
  }
  const current = select(original);
  const updated = select(row);

  const display = (value: any): string => {
    if (isNull(value)) {
      return "";
    }
    if (isArray(value)) {
      return value.join(", ");
    }
    return value;
  };
  return (
    <>
      <span>{display(updated)}</span>
      {thisFieldValidChange ? (
        <span>
          <del>({display(current)})</del>
        </span>
      ) : (
        <></>
      )}
    </>
  );
};

const GenericUpdateList = (props: Props): React.ReactElement => {
  const columns: TableColumn<{ [key: string]: any }>[] = [
    {
      name: "",
      cell: UpdateTooltip(props.actioned, props.listPrefix),
      width: "20px",
    },
  ];

  const objectTypeColumns: TableColumn<{ [key: string]: any }>[] =
    props.tableFields.map((value, index) => {
      return {
        name: props.tableFieldHeaders[index],
        selector: (row) => row && value in row && row[value],
        id: value,
        cell: FieldCell,
      };
    });

  const data = props.list.map((instruction) => {
    const original = props.existingMap[instruction.object[props.objectKey]];

    return {
      ...instruction.object,
      instruction,
      original,
    };
  });

  /* bootstrap table colours */

  const orange = "#fed3ca";
  const green = "#cdebda";
  const grey = "#fdfefe";
  const red = "#fccac7";

  const conditionalRowStyles: ConditionalStyles<{ [key: string]: any }>[] = [
    {
      when: () => true,
      style: (row: any) => {
        const instruction: BulkUpdateInstruction = row.instruction;
        const status = instruction.type;
        const immutableChangeErrors = instruction.immutableChangeErrors
          ? instruction.immutableChangeErrors
          : [];
        const actionedError: string[] =
          instruction.executionResult && !instruction.executionResult.success
            ? [
                instruction.executionResult.error
                  ? instruction.executionResult.error
                  : "Unknown error",
              ]
            : [];
        const errors = [
          ...(instruction.validationErrors ?? []),
          ...immutableChangeErrors,
          ...actionedError,
        ];
        const hasErrors = errors.length > 0;

        let color: string;
        if (hasErrors) {
          color = orange;
        } else {
          switch (status) {
            case "NOT_FOUND":
              color = orange;
              break;
            case "NEW":
              color = green;
              break;
            case "UPDATE":
              color = green;
              break;
            case "UNCHANGED":
              color = grey;
              break;
            case "DELETED":
              color = red;
              break;
            default:
              color = grey;
          }
        }
        return {
          backgroundColor: color,
        };
      },
    },
  ];

  return (
    <>
      <Heading>{props.title}</Heading>
      <DataTable
        columns={columns.concat(objectTypeColumns)}
        data={data}
        pagination
        fixedHeader
        conditionalRowStyles={conditionalRowStyles}
      />
    </>
  );
};

export default GenericUpdateList;
