import AssetRegisterListItem, {
  AssetRegisterListHeading,
} from "pages/assetRegister/AssetRegisterListPage/AssetRegisterListItem";
import type React from "react";
import List from "../../../../../components/List/List";
import { formatDate } from "../../../../../components/utils/HighlightNullAndOldDates";
import type { WirelessReceiverLink } from "../../../../../openapi/model/wirelessReceiverLink";

interface Props {
  history: Array<WirelessReceiverLink>;
}

function ReceiverHistoryListItem(
  item: WirelessReceiverLink,
  _index: number,
  _refresh?: () => void,
): React.ReactElement {
  const { location, assetPosition, asset, createdOn, deletedOn } = item;

  return AssetRegisterListItem({
    assetRegisterItem: {
      location,
      assetPosition,
      asset,
      installDate: createdOn,
      uninstallDate: deletedOn,
    },
    showType: false,
    showScheme: false,
    denseColumn: () => (
      <>
        {formatDate(createdOn)} -{" "}
        {deletedOn ? formatDate(deletedOn) : "present"}
      </>
    ),
  });
}
function ReceiverHistoryTable(props: Props) {
  return (
    <List
      row={ReceiverHistoryListItem}
      header={<AssetRegisterListHeading />}
      data={props.history}
    />
  );
}

export default ReceiverHistoryTable;
