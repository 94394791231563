import { isArray } from "lodash-es";
import type React from "react";
import useGetAssetRegisterEntitiesCombination, {
  type AssetRegisterEntityResult,
  type GetAssetRegisterEntitiesProps,
} from "../../hooks/getAssetRegisterEntitiesCombination";

export interface AssetRegisterEntityProps extends AssetRegisterEntityResult {
  refresh: () => void;
}

export type AssetRegisterEntitySuccessProps = Omit<
  AssetRegisterEntityProps,
  "error"
>;

interface Props {
  children: (props: AssetRegisterEntitySuccessProps) => React.ReactElement;
  error: (props: { error: Error }) => React.ReactElement;
  placeholder: () => React.ReactElement;
}

const GetAssetRegisterEntitiesCombination = (
  props: Props & GetAssetRegisterEntitiesProps,
): React.ReactElement => {
  const { children, ...params } = props;
  const [{ error, asset, assetPosition, location, loading }, refresh] =
    useGetAssetRegisterEntitiesCombination(params);

  const apiError = error !== null && error !== undefined;

  const notFoundError =
    (props.assetPositionId &&
      (assetPosition === undefined || location === undefined)) ||
    (assetPosition?.assetId && asset === undefined);

  const actualError = apiError
    ? isArray(error)
      ? error[0]
      : error
    : notFoundError && Error("Not found.");

  return (
    <>
      {loading
        ? props.placeholder()
        : actualError
          ? props.error({ error: actualError })
          : !notFoundError &&
            children({ loading, asset, assetPosition, location, refresh })}
    </>
  );
};

export default GetAssetRegisterEntitiesCombination;
