import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import PrivacyMode from "components/Text/PrivacyMode";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
} from "components/Theme/dropdown";
import type React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import type { Scheme } from "../../openapi/model/scheme";
import { useSelectedCompany } from "../../reducers/company";
import { usePrivacyMode } from "../../reducers/privacy";
import { SchemeContext } from "../../reducers/scheme";
import { getUrlHash } from "../navigation/URL";

export default function SchemeSelect(): React.ReactElement {
  const company = useSelectedCompany();
  const privacy = usePrivacyMode();

  const { state } = useContext(SchemeContext);
  const { selected, loading, schemes = [] } = state;

  const navigate = useNavigate();

  const selectScheme = (scheme?: Scheme): void => {
    console.log(scheme);
    if (scheme === undefined) {
      console.log("no scheme");
      navigate({
        hash: getUrlHash(company, scheme, privacy),
      });
    } else if (
      selected?.companyId !== scheme?.companyId ||
      selected?.schemeId !== scheme?.schemeId
    ) {
      navigate({
        hash: getUrlHash(company, scheme, privacy),
      });
    }
  };

  const noSchemes = company && !loading && schemes.length === 0;

  return (
    <>
      <Dropdown>
        <DropdownButton
          outline
          data-cy={"scheme-select"}
          disabled={loading || noSchemes || !company}
        >
          <span className={"hidden md:inline-block"}>Scheme: </span>
          <span
            className={
              "text-grey w-auto md:w-32 text-left overflow-clip text-xs md:text-sm text-nowrap"
            }
          >
            {selected ? (
              <PrivacyMode>{selected.schemeName}</PrivacyMode>
            ) : loading ? (
              <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
            ) : schemes.length > 0 ? (
              "All"
            ) : noSchemes ? (
              "No Schemes"
            ) : (
              "-"
            )}
          </span>
          <ChevronDownIcon />
        </DropdownButton>
        {loading ? (
          <DropdownMenu data-cy={"scheme-select-loading"}>
            <DropdownItem
              data-cy={"scheme-select-loading"}
              className={classNames({})}
            >
              Loading...
            </DropdownItem>
          </DropdownMenu>
        ) : (
          <DropdownMenu data-cy={"scheme-select-content"}>
            <DropdownItem
              data-cy={"scheme-select-all"}
              onClick={(): void => {
                selectScheme();
              }}
              className={classNames({
                active: selected === null,
              })}
            >
              All
            </DropdownItem>
            <DropdownDivider />
            {schemes.map((scheme): React.ReactElement => {
              return (
                <DropdownItem
                  key={scheme.schemeId}
                  data-cy={`scheme-select-${scheme.schemeId}`}
                  onClick={(): void => {
                    selectScheme(scheme);
                  }}
                  className={classNames({
                    "bg-brand-light text-gray-100":
                      scheme.schemeId === selected?.schemeId,
                  })}
                >
                  <PrivacyMode>{scheme.schemeName}</PrivacyMode>
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        )}
      </Dropdown>
    </>
  );
}
