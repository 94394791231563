import type React from "react";

interface CollapseProps extends React.PropsWithChildren {
  isOpen: boolean;
}

export default function Collapse(props: CollapseProps): React.ReactElement {
  /* TODO: make transition nicer */
  return <>{props.isOpen && props.children}</>;
}
