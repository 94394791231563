import type { CompanyRole } from "openapi/model/companyRole";
import type { Status } from "openapi/model/status";
import type { User } from "../../openapi/model/user";
import type { UserCompanyLink } from "../../openapi/model/userCompanyLink";
import type { UserCompanyProperties } from "../../openapi/model/userCompanyProperties";
import { UserType } from "../../openapi/model/userType";

export interface CompanyUser {
  emailAddress?: string;
  fullname: string;
  userId: string;
  companyId: string;
  apiUser: boolean;
  userType: CompanyRole;
  properties?: UserCompanyProperties;
  status: Status;
}

export const convertUserToCompanyUser = (
  user: User,
  companyId?: string,
): CompanyUser => {
  const company: UserCompanyLink = companyId
    ? user.companies.filter((c) => {
        return c.companyId === companyId;
      })[0]
    : user.companies[0];

  return {
    ...user,
    fullname: user.fullname as string,
    apiUser: user.userType === UserType.Api,
    userType: company.userType,
    companyId: company.companyId,
    properties: company.properties,
  };
};

export const updateUserCompany = (
  user: User,
  companyId: string,
  updateCompanyLink: UserCompanyLink,
): User => {
  return {
    ...user,
    companies: user.companies.map((companyLink) => {
      if (companyLink.companyId === companyId) {
        return updateCompanyLink;
      } else {
        return companyLink;
      }
    }),
  };
};

export const updateUserCompanyProperties = (
  user: User,
  companyId: string,
  updateCompanyProperties: UserCompanyProperties,
): User => {
  return {
    ...user,
    companies: user.companies.map((companyLink) => {
      if (companyLink.companyId === companyId) {
        return {
          ...companyLink,
          properties: updateCompanyProperties,
        };
      } else {
        return companyLink;
      }
    }),
  };
};
