import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import type React from "react";
import UpdateButton from "../../../components/Buttons/UpdateButton";
import type { AssetPositionAssetLinkOut } from "../../../openapi/model/assetPositionAssetLinkOut";

type Props = {
  assetPositionAssetLink: AssetPositionAssetLinkOut;
  refresh?: (updatedItem: AssetPositionAssetLinkOut) => void;
};

const UnlinkAssetPositionFromAssetButton = (
  props: Props,
): React.ReactElement => {
  return (
    <UpdateButton
      entityType={"LINK"}
      entityId={props.assetPositionAssetLink.linkId}
      updatedEntity={{ ...props.assetPositionAssetLink, cancelled: true }}
      postUpdate={props.refresh}
      icon={faTrashAlt}
      text={"Cancel Link"}
      color={"red"}
      size={"sm"}
      paddingY={1}
    />
  );
};

export default UnlinkAssetPositionFromAssetButton;
