import { isDayjs } from "dayjs";
import { type ParsedQs, parse, stringify } from "qs";

export const parseSearchString = (search: string): ParsedQs => {
  return parse(search.slice(1));
};

export const createSearchString = (obj: any): string => {
  return stringify(obj);
};

export const convertStringUndefined = (obj: any): any => {
  function convert(obj: any): any {
    if (obj === "undefined") {
      return undefined;
    } else if (isDayjs(obj)) {
      return obj.format();
    } else {
      return obj;
    }
  }

  return Object.keys(obj).reduce((acc, key) => {
    return {
      ...acc,
      [key]: convert(obj[key]),
    };
  }, {});
};
