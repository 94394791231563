import * as Sentry from "@sentry/react";
// import React from "react";
// import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router";

Sentry.init({
  dsn: window.runConfig?.sentry?.dsn,
  ignoreErrors: ["Non-Error promise rejection captured"],
  environment: window.runConfig?.environment,
  release: window.runConfig?.release,
  integrations: [
    // Sentry.reactRouterV7BrowserTracingIntegration({
    //   useEffect: React.useEffect,
    //   useLocation,
    //   useNavigationType,
    //   createRoutesFromChildren,
    //   matchRoutes,
    // }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost"],
});
