import PrivacyMode from "components/Text/PrivacyMode";
import type React from "react";
import AssetIdLink from "../../../components/Asset/AssetIdLink";
import AssetTypeHumanLabel from "../../../components/Asset/AssetTypeHumanLabel";
import AssetPositionIdLink from "../../../components/AssetPosition/AssetPositionIdLink";
import BulkUpdate from "../../../components/BulkUpdate/BulkUpdate";
import AssetRegisterStatusLabel from "../../../components/Labels/AssetRegisterStatusLabel";
import LocationIdLink from "../../../components/Location/LocationIdLink";
import GetListPaginatedTable from "../../../components/Pagination/GetListPaginatedTable";
import { Button } from "../../../components/Theme/button";
import {
  TableCell,
  TableHeader,
  TableRow,
} from "../../../components/Theme/table";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";
import PaginatedApiToCSVExportButton from "../../../components/utils/CSVExport/PaginatedApiToCSVExportButton";
import type { AssetPosition } from "../../../openapi/model/assetPosition";
import type { Status } from "../../../openapi/model/status";

interface PProps {
  companyId: string;
  schemeId?: string;
  assetId?: string;
  assetType?: string;
  parentAssetPositionId?: string;
  group?: string;
  useCase?: string;
  status?: Status;
  locationId?: string;
  showLocationId?: boolean;
  lastUpdated?: string;
  showDownload?: boolean;
  showUploads?: boolean;
}

const AssetPositionList = (props: PProps): React.ReactElement => {
  const listParams = {
    companyId: props.companyId,
    schemeId: props.schemeId,
    locationId: props.locationId,
    parentAssetPositionId: props.parentAssetPositionId,
    group: props.group,
    status: props.status,
    assetType: props.assetType,
    assetId: props.assetId,
    lastUpdated: props.lastUpdated,
    useCase: props.useCase,
  };

  const showLocationID =
    props.showLocationId !== undefined ? props.showLocationId : true;
  const showParentAssetPositionId = props.parentAssetPositionId === undefined;

  const header = (
    <TableRow>
      {showLocationID && (
        <TableHeader className="text-left">Location ID</TableHeader>
      )}
      <TableHeader className="text-left">Position ID</TableHeader>
      <TableHeader className={"text-left"}>Reference</TableHeader>
      <TableHeader className="text-left">Asset ID</TableHeader>
      <TableHeader className="text-left">Type</TableHeader>
      {showParentAssetPositionId && (
        <TableHeader className="text-left">
          Parent Asset Position Id
        </TableHeader>
      )}
      <TableHeader className="text-left">&nbsp;</TableHeader>
    </TableRow>
  );

  const colCount =
    5 + (showLocationID ? 1 : 0) + (showParentAssetPositionId ? 1 : 0);

  const row = (value: AssetPosition): React.ReactElement => (
    <TableRow data-cy={"list-item"} key={value.assetPositionId}>
      {showLocationID && (
        <TableCell className="text-left" data-cy={"locationId"}>
          <LocationIdLink locationId={value.locationId} />
        </TableCell>
      )}
      <TableCell className="text-left" data-cy={"assetPositionId"}>
        <AssetPositionIdLink assetPosition={value} />
      </TableCell>
      <TableCell className={"text-left"}>
        <PrivacyMode>{value.customerReference}</PrivacyMode>
      </TableCell>
      <TableCell className="text-left">
        {value.assetId && <AssetIdLink assetId={value.assetId} />}
      </TableCell>
      <TableCell className="text-left">
        <AssetTypeHumanLabel asset={value} />
      </TableCell>
      {showParentAssetPositionId && (
        <TableCell className="text-left">
          {value.parentAssetPositionId && (
            <AssetPositionIdLink
              assetPositionId={value.parentAssetPositionId}
            />
          )}
        </TableCell>
      )}
      <TableCell className={"text-left"}>
        <AssetRegisterStatusLabel status={value.status} />
      </TableCell>
    </TableRow>
  );

  return (
    <GetListPaginatedTable
      entityType={"ASSET_POSITION"}
      params={listParams}
      cursor={{ maxItems: 10 }}
      data-cy={"asset-position-list"}
      header={[header, colCount]}
      row={row}
      additionalFunctionButtons={
        <div className={"flex gap-2"}>
          <PaginatedApiToCSVExportButton
            entityType={"ASSET_POSITION"}
            params={listParams}
          />
          {props.schemeId &&
            (props.showUploads === undefined || props.showUploads) && (
              <>
                <BulkUpdate
                  companyId={props.companyId}
                  schemeId={props.schemeId}
                  objectType={"asset_position"}
                />
                <CompanyNavLink
                  to={{ pathname: "/admin/asset_position/updates" }}
                  button={{
                    color: "brandLight",
                  }}
                >
                  Previous Uploads
                </CompanyNavLink>
              </>
            )}
        </div>
      }
    />
  );
};

export default AssetPositionList;
